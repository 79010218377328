export default {
	province_list: {
		110000: '北京市',
		120000: '天津市',
		130000: '河北省',
		140000: '山西省',
		150000: '内蒙古自治区',
		210000: '辽宁省',
		220000: '吉林省',
		230000: '黑龙江省',
		310000: '上海市',
		320000: '江苏省',
		330000: '浙江省',
		340000: '安徽省',
		350000: '福建省',
		360000: '江西省',
		370000: '山东省',
		410000: '河南省',
		420000: '湖北省',
		430000: '湖南省',
		440000: '广东省',
		450000: '广西壮族自治区',
		460000: '海南省',
		500000: '重庆市',
		510000: '四川省',
		520000: '贵州省',
		530000: '云南省',
		540000: '西藏自治区',
		610000: '陕西省',
		620000: '甘肃省',
		630000: '青海省',
		640000: '宁夏回族自治区',
		650000: '新疆维吾尔自治区',
		710000: '台湾省',
		810000: '香港特别行政区',
		820000: '澳门特别行政区',
		900000: '海外'
	},
	city_list: {
		110100: '北京市',
		120100: '天津市',
		130100: '石家庄市',
		130200: '唐山市',
		130300: '秦皇岛市',
		130400: '邯郸市',
		130500: '邢台市',
		130600: '保定市',
		130700: '张家口市',
		130800: '承德市',
		130900: '沧州市',
		131000: '廊坊市',
		131100: '衡水市',
		140100: '太原市',
		140200: '大同市',
		140300: '阳泉市',
		140400: '长治市',
		140500: '晋城市',
		140600: '朔州市',
		140700: '晋中市',
		140800: '运城市',
		140900: '忻州市',
		141000: '临汾市',
		141100: '吕梁市',
		150100: '呼和浩特市',
		150200: '包头市',
		150300: '乌海市',
		150400: '赤峰市',
		150500: '通辽市',
		150600: '鄂尔多斯市',
		150700: '呼伦贝尔市',
		150800: '巴彦淖尔市',
		150900: '乌兰察布市',
		152200: '兴安盟',
		152500: '锡林郭勒盟',
		152900: '阿拉善盟',
		210100: '沈阳市',
		210200: '大连市',
		210300: '鞍山市',
		210400: '抚顺市',
		210500: '本溪市',
		210600: '丹东市',
		210700: '锦州市',
		210800: '营口市',
		210900: '阜新市',
		211000: '辽阳市',
		211100: '盘锦市',
		211200: '铁岭市',
		211300: '朝阳市',
		211400: '葫芦岛市',
		220100: '长春市',
		220200: '吉林市',
		220300: '四平市',
		220400: '辽源市',
		220500: '通化市',
		220600: '白山市',
		220700: '松原市',
		220800: '白城市',
		222400: '延边朝鲜族自治州',
		230100: '哈尔滨市',
		230200: '齐齐哈尔市',
		230300: '鸡西市',
		230400: '鹤岗市',
		230500: '双鸭山市',
		230600: '大庆市',
		230700: '伊春市',
		230800: '佳木斯市',
		230900: '七台河市',
		231000: '牡丹江市',
		231100: '黑河市',
		231200: '绥化市',
		232700: '大兴安岭地区',
		310100: '上海市',
		320100: '南京市',
		320200: '无锡市',
		320300: '徐州市',
		320400: '常州市',
		320500: '苏州市',
		320600: '南通市',
		320700: '连云港市',
		320800: '淮安市',
		320900: '盐城市',
		321000: '扬州市',
		321100: '镇江市',
		321200: '泰州市',
		321300: '宿迁市',
		330100: '杭州市',
		330200: '宁波市',
		330300: '温州市',
		330400: '嘉兴市',
		330500: '湖州市',
		330600: '绍兴市',
		330700: '金华市',
		330800: '衢州市',
		330900: '舟山市',
		331000: '台州市',
		331100: '丽水市',
		340100: '合肥市',
		340200: '芜湖市',
		340300: '蚌埠市',
		340400: '淮南市',
		340500: '马鞍山市',
		340600: '淮北市',
		340700: '铜陵市',
		340800: '安庆市',
		341000: '黄山市',
		341100: '滁州市',
		341200: '阜阳市',
		341300: '宿州市',
		341500: '六安市',
		341600: '亳州市',
		341700: '池州市',
		341800: '宣城市',
		350100: '福州市',
		350200: '厦门市',
		350300: '莆田市',
		350400: '三明市',
		350500: '泉州市',
		350600: '漳州市',
		350700: '南平市',
		350800: '龙岩市',
		350900: '宁德市',
		360100: '南昌市',
		360200: '景德镇市',
		360300: '萍乡市',
		360400: '九江市',
		360500: '新余市',
		360600: '鹰潭市',
		360700: '赣州市',
		360800: '吉安市',
		360900: '宜春市',
		361000: '抚州市',
		361100: '上饶市',
		370100: '济南市',
		370200: '青岛市',
		370300: '淄博市',
		370400: '枣庄市',
		370500: '东营市',
		370600: '烟台市',
		370700: '潍坊市',
		370800: '济宁市',
		370900: '泰安市',
		371000: '威海市',
		371100: '日照市',
		371300: '临沂市',
		371400: '德州市',
		371500: '聊城市',
		371600: '滨州市',
		371700: '菏泽市',
		410100: '郑州市',
		410200: '开封市',
		410300: '洛阳市',
		410400: '平顶山市',
		410500: '安阳市',
		410600: '鹤壁市',
		410700: '新乡市',
		410800: '焦作市',
		410900: '濮阳市',
		411000: '许昌市',
		411100: '漯河市',
		411200: '三门峡市',
		411300: '南阳市',
		411400: '商丘市',
		411500: '信阳市',
		411600: '周口市',
		411700: '驻马店市',
		419000: '省直辖县',
		420100: '武汉市',
		420200: '黄石市',
		420300: '十堰市',
		420500: '宜昌市',
		420600: '襄阳市',
		420700: '鄂州市',
		420800: '荆门市',
		420900: '孝感市',
		421000: '荆州市',
		421100: '黄冈市',
		421200: '咸宁市',
		421300: '随州市',
		422800: '恩施土家族苗族自治州',
		429000: '省直辖县',
		430100: '长沙市',
		430200: '株洲市',
		430300: '湘潭市',
		430400: '衡阳市',
		430500: '邵阳市',
		430600: '岳阳市',
		430700: '常德市',
		430800: '张家界市',
		430900: '益阳市',
		431000: '郴州市',
		431100: '永州市',
		431200: '怀化市',
		431300: '娄底市',
		433100: '湘西土家族苗族自治州',
		440100: '广州市',
		440200: '韶关市',
		440300: '深圳市',
		440400: '珠海市',
		440500: '汕头市',
		440600: '佛山市',
		440700: '江门市',
		440800: '湛江市',
		440900: '茂名市',
		441200: '肇庆市',
		441300: '惠州市',
		441400: '梅州市',
		441500: '汕尾市',
		441600: '河源市',
		441700: '阳江市',
		441800: '清远市',
		441900: '东莞市',
		442000: '中山市',
		445100: '潮州市',
		445200: '揭阳市',
		445300: '云浮市',
		450100: '南宁市',
		450200: '柳州市',
		450300: '桂林市',
		450400: '梧州市',
		450500: '北海市',
		450600: '防城港市',
		450700: '钦州市',
		450800: '贵港市',
		450900: '玉林市',
		451000: '百色市',
		451100: '贺州市',
		451200: '河池市',
		451300: '来宾市',
		451400: '崇左市',
		460100: '海口市',
		460200: '三亚市',
		460300: '三沙市',
		460400: '儋州市',
		469000: '省直辖县',
		500100: '重庆市',
		500200: '县',
		510100: '成都市',
		510300: '自贡市',
		510400: '攀枝花市',
		510500: '泸州市',
		510600: '德阳市',
		510700: '绵阳市',
		510800: '广元市',
		510900: '遂宁市',
		511000: '内江市',
		511100: '乐山市',
		511300: '南充市',
		511400: '眉山市',
		511500: '宜宾市',
		511600: '广安市',
		511700: '达州市',
		511800: '雅安市',
		511900: '巴中市',
		512000: '资阳市',
		513200: '阿坝藏族羌族自治州',
		513300: '甘孜藏族自治州',
		513400: '凉山彝族自治州',
		520100: '贵阳市',
		520200: '六盘水市',
		520300: '遵义市',
		520400: '安顺市',
		520500: '毕节市',
		520600: '铜仁市',
		522300: '黔西南布依族苗族自治州',
		522600: '黔东南苗族侗族自治州',
		522700: '黔南布依族苗族自治州',
		530100: '昆明市',
		530300: '曲靖市',
		530400: '玉溪市',
		530500: '保山市',
		530600: '昭通市',
		530700: '丽江市',
		530800: '普洱市',
		530900: '临沧市',
		532300: '楚雄彝族自治州',
		532500: '红河哈尼族彝族自治州',
		532600: '文山壮族苗族自治州',
		532800: '西双版纳傣族自治州',
		532900: '大理白族自治州',
		533100: '德宏傣族景颇族自治州',
		533300: '怒江傈僳族自治州',
		533400: '迪庆藏族自治州',
		540100: '拉萨市',
		540200: '日喀则市',
		540300: '昌都市',
		540400: '林芝市',
		540500: '山南市',
		540600: '那曲市',
		542500: '阿里地区',
		610100: '西安市',
		610200: '铜川市',
		610300: '宝鸡市',
		610400: '咸阳市',
		610500: '渭南市',
		610600: '延安市',
		610700: '汉中市',
		610800: '榆林市',
		610900: '安康市',
		611000: '商洛市',
		620100: '兰州市',
		620200: '嘉峪关市',
		620300: '金昌市',
		620400: '白银市',
		620500: '天水市',
		620600: '武威市',
		620700: '张掖市',
		620800: '平凉市',
		620900: '酒泉市',
		621000: '庆阳市',
		621100: '定西市',
		621200: '陇南市',
		622900: '临夏回族自治州',
		623000: '甘南藏族自治州',
		630100: '西宁市',
		630200: '海东市',
		632200: '海北藏族自治州',
		632300: '黄南藏族自治州',
		632500: '海南藏族自治州',
		632600: '果洛藏族自治州',
		632700: '玉树藏族自治州',
		632800: '海西蒙古族藏族自治州',
		640100: '银川市',
		640200: '石嘴山市',
		640300: '吴忠市',
		640400: '固原市',
		640500: '中卫市',
		650100: '乌鲁木齐市',
		650200: '克拉玛依市',
		650400: '吐鲁番市',
		650500: '哈密市',
		652300: '昌吉回族自治州',
		652700: '博尔塔拉蒙古自治州',
		652800: '巴音郭楞蒙古自治州',
		652900: '阿克苏地区',
		653000: '克孜勒苏柯尔克孜自治州',
		653100: '喀什地区',
		653200: '和田地区',
		654000: '伊犁哈萨克自治州',
		654200: '塔城地区',
		654300: '阿勒泰地区',
		659000: '自治区直辖县级行政区划',
		710100: '台北市',
		710200: '高雄市',
		710300: '台南市',
		710400: '台中市',
		710500: '金门县',
		710600: '南投县',
		710700: '基隆市',
		710800: '新竹市',
		710900: '嘉义市',
		711100: '新北市',
		711200: '宜兰县',
		711300: '新竹县',
		711400: '桃园县',
		711500: '苗栗县',
		711700: '彰化县',
		711900: '嘉义县',
		712100: '云林县',
		712400: '屏东县',
		712500: '台东县',
		712600: '花莲县',
		712700: '澎湖县',
		712800: '连江县',
		810100: '香港岛',
		810200: '九龙',
		810300: '新界',
		820100: '澳门半岛',
		820200: '离岛'
	},
	county_list: {
		110101: '东城区',
		110102: '西城区',
		110105: '朝阳区',
		110106: '丰台区',
		110107: '石景山区',
		110108: '海淀区',
		110109: '门头沟区',
		110111: '房山区',
		110112: '通州区',
		110113: '顺义区',
		110114: '昌平区',
		110115: '大兴区',
		110116: '怀柔区',
		110117: '平谷区',
		110118: '密云区',
		110119: '延庆区',
		120101: '和平区',
		120102: '河东区',
		120103: '河西区',
		120104: '南开区',
		120105: '河北区',
		120106: '红桥区',
		120110: '东丽区',
		120111: '西青区',
		120112: '津南区',
		120113: '北辰区',
		120114: '武清区',
		120115: '宝坻区',
		120116: '滨海新区',
		120117: '宁河区',
		120118: '静海区',
		120119: '蓟州区',
		130102: '长安区',
		130104: '桥西区',
		130105: '新华区',
		130107: '井陉矿区',
		130108: '裕华区',
		130109: '藁城区',
		130110: '鹿泉区',
		130111: '栾城区',
		130121: '井陉县',
		130123: '正定县',
		130125: '行唐县',
		130126: '灵寿县',
		130127: '高邑县',
		130128: '深泽县',
		130129: '赞皇县',
		130130: '无极县',
		130131: '平山县',
		130132: '元氏县',
		130133: '赵县',
		130181: '辛集市',
		130183: '晋州市',
		130184: '新乐市',
		130202: '路南区',
		130203: '路北区',
		130204: '古冶区',
		130205: '开平区',
		130207: '丰南区',
		130208: '丰润区',
		130209: '曹妃甸区',
		130224: '滦南县',
		130225: '乐亭县',
		130227: '迁西县',
		130229: '玉田县',
		130281: '遵化市',
		130283: '迁安市',
		130284: '滦州市',
		130302: '海港区',
		130303: '山海关区',
		130304: '北戴河区',
		130306: '抚宁区',
		130321: '青龙满族自治县',
		130322: '昌黎县',
		130324: '卢龙县',
		130390: '经济技术开发区',
		130402: '邯山区',
		130403: '丛台区',
		130404: '复兴区',
		130406: '峰峰矿区',
		130407: '肥乡区',
		130408: '永年区',
		130423: '临漳县',
		130424: '成安县',
		130425: '大名县',
		130426: '涉县',
		130427: '磁县',
		130430: '邱县',
		130431: '鸡泽县',
		130432: '广平县',
		130433: '馆陶县',
		130434: '魏县',
		130435: '曲周县',
		130481: '武安市',
		130502: '桥东区',
		130503: '桥西区',
		130521: '邢台县',
		130522: '临城县',
		130523: '内丘县',
		130524: '柏乡县',
		130525: '隆尧县',
		130526: '任县',
		130527: '南和县',
		130528: '宁晋县',
		130529: '巨鹿县',
		130530: '新河县',
		130531: '广宗县',
		130532: '平乡县',
		130533: '威县',
		130534: '清河县',
		130535: '临西县',
		130581: '南宫市',
		130582: '沙河市',
		130602: '竞秀区',
		130606: '莲池区',
		130607: '满城区',
		130608: '清苑区',
		130609: '徐水区',
		130623: '涞水县',
		130624: '阜平县',
		130626: '定兴县',
		130627: '唐县',
		130628: '高阳县',
		130629: '容城县',
		130630: '涞源县',
		130631: '望都县',
		130632: '安新县',
		130633: '易县',
		130634: '曲阳县',
		130635: '蠡县',
		130636: '顺平县',
		130637: '博野县',
		130638: '雄县',
		130681: '涿州市',
		130682: '定州市',
		130683: '安国市',
		130684: '高碑店市',
		130702: '桥东区',
		130703: '桥西区',
		130705: '宣化区',
		130706: '下花园区',
		130708: '万全区',
		130709: '崇礼区',
		130722: '张北县',
		130723: '康保县',
		130724: '沽源县',
		130725: '尚义县',
		130726: '蔚县',
		130727: '阳原县',
		130728: '怀安县',
		130730: '怀来县',
		130731: '涿鹿县',
		130732: '赤城县',
		130802: '双桥区',
		130803: '双滦区',
		130804: '鹰手营子矿区',
		130821: '承德县',
		130822: '兴隆县',
		130824: '滦平县',
		130825: '隆化县',
		130826: '丰宁满族自治县',
		130827: '宽城满族自治县',
		130828: '围场满族蒙古族自治县',
		130881: '平泉市',
		130902: '新华区',
		130903: '运河区',
		130921: '沧县',
		130922: '青县',
		130923: '东光县',
		130924: '海兴县',
		130925: '盐山县',
		130926: '肃宁县',
		130927: '南皮县',
		130928: '吴桥县',
		130929: '献县',
		130930: '孟村回族自治县',
		130981: '泊头市',
		130982: '任丘市',
		130983: '黄骅市',
		130984: '河间市',
		131002: '安次区',
		131003: '广阳区',
		131022: '固安县',
		131023: '永清县',
		131024: '香河县',
		131025: '大城县',
		131026: '文安县',
		131028: '大厂回族自治县',
		131081: '霸州市',
		131082: '三河市',
		131090: '开发区',
		131102: '桃城区',
		131103: '冀州区',
		131121: '枣强县',
		131122: '武邑县',
		131123: '武强县',
		131124: '饶阳县',
		131125: '安平县',
		131126: '故城县',
		131127: '景县',
		131128: '阜城县',
		131182: '深州市',
		140105: '小店区',
		140106: '迎泽区',
		140107: '杏花岭区',
		140108: '尖草坪区',
		140109: '万柏林区',
		140110: '晋源区',
		140121: '清徐县',
		140122: '阳曲县',
		140123: '娄烦县',
		140181: '古交市',
		140212: '新荣区',
		140213: '平城区',
		140214: '云冈区',
		140215: '云州区',
		140221: '阳高县',
		140222: '天镇县',
		140223: '广灵县',
		140224: '灵丘县',
		140225: '浑源县',
		140226: '左云县',
		140302: '城区',
		140303: '矿区',
		140311: '郊区',
		140321: '平定县',
		140322: '盂县',
		140403: '潞州区',
		140404: '上党区',
		140405: '屯留区',
		140406: '潞城区',
		140423: '襄垣县',
		140425: '平顺县',
		140426: '黎城县',
		140427: '壶关县',
		140428: '长子县',
		140429: '武乡县',
		140430: '沁县',
		140431: '沁源县',
		140502: '城区',
		140521: '沁水县',
		140522: '阳城县',
		140524: '陵川县',
		140525: '泽州县',
		140581: '高平市',
		140602: '朔城区',
		140603: '平鲁区',
		140621: '山阴县',
		140622: '应县',
		140623: '右玉县',
		140681: '怀仁市',
		140702: '榆次区',
		140721: '榆社县',
		140722: '左权县',
		140723: '和顺县',
		140724: '昔阳县',
		140725: '寿阳县',
		140726: '太谷县',
		140727: '祁县',
		140728: '平遥县',
		140729: '灵石县',
		140781: '介休市',
		140802: '盐湖区',
		140821: '临猗县',
		140822: '万荣县',
		140823: '闻喜县',
		140824: '稷山县',
		140825: '新绛县',
		140826: '绛县',
		140827: '垣曲县',
		140828: '夏县',
		140829: '平陆县',
		140830: '芮城县',
		140881: '永济市',
		140882: '河津市',
		140902: '忻府区',
		140921: '定襄县',
		140922: '五台县',
		140923: '代县',
		140924: '繁峙县',
		140925: '宁武县',
		140926: '静乐县',
		140927: '神池县',
		140928: '五寨县',
		140929: '岢岚县',
		140930: '河曲县',
		140931: '保德县',
		140932: '偏关县',
		140981: '原平市',
		141002: '尧都区',
		141021: '曲沃县',
		141022: '翼城县',
		141023: '襄汾县',
		141024: '洪洞县',
		141025: '古县',
		141026: '安泽县',
		141027: '浮山县',
		141028: '吉县',
		141029: '乡宁县',
		141030: '大宁县',
		141031: '隰县',
		141032: '永和县',
		141033: '蒲县',
		141034: '汾西县',
		141081: '侯马市',
		141082: '霍州市',
		141102: '离石区',
		141121: '文水县',
		141122: '交城县',
		141123: '兴县',
		141124: '临县',
		141125: '柳林县',
		141126: '石楼县',
		141127: '岚县',
		141128: '方山县',
		141129: '中阳县',
		141130: '交口县',
		141181: '孝义市',
		141182: '汾阳市',
		150102: '新城区',
		150103: '回民区',
		150104: '玉泉区',
		150105: '赛罕区',
		150121: '土默特左旗',
		150122: '托克托县',
		150123: '和林格尔县',
		150124: '清水河县',
		150125: '武川县',
		150202: '东河区',
		150203: '昆都仑区',
		150204: '青山区',
		150205: '石拐区',
		150206: '白云鄂博矿区',
		150207: '九原区',
		150221: '土默特右旗',
		150222: '固阳县',
		150223: '达尔罕茂明安联合旗',
		150302: '海勃湾区',
		150303: '海南区',
		150304: '乌达区',
		150402: '红山区',
		150403: '元宝山区',
		150404: '松山区',
		150421: '阿鲁科尔沁旗',
		150422: '巴林左旗',
		150423: '巴林右旗',
		150424: '林西县',
		150425: '克什克腾旗',
		150426: '翁牛特旗',
		150428: '喀喇沁旗',
		150429: '宁城县',
		150430: '敖汉旗',
		150502: '科尔沁区',
		150521: '科尔沁左翼中旗',
		150522: '科尔沁左翼后旗',
		150523: '开鲁县',
		150524: '库伦旗',
		150525: '奈曼旗',
		150526: '扎鲁特旗',
		150581: '霍林郭勒市',
		150602: '东胜区',
		150603: '康巴什区',
		150621: '达拉特旗',
		150622: '准格尔旗',
		150623: '鄂托克前旗',
		150624: '鄂托克旗',
		150625: '杭锦旗',
		150626: '乌审旗',
		150627: '伊金霍洛旗',
		150702: '海拉尔区',
		150703: '扎赉诺尔区',
		150721: '阿荣旗',
		150722: '莫力达瓦达斡尔族自治旗',
		150723: '鄂伦春自治旗',
		150724: '鄂温克族自治旗',
		150725: '陈巴尔虎旗',
		150726: '新巴尔虎左旗',
		150727: '新巴尔虎右旗',
		150781: '满洲里市',
		150782: '牙克石市',
		150783: '扎兰屯市',
		150784: '额尔古纳市',
		150785: '根河市',
		150802: '临河区',
		150821: '五原县',
		150822: '磴口县',
		150823: '乌拉特前旗',
		150824: '乌拉特中旗',
		150825: '乌拉特后旗',
		150826: '杭锦后旗',
		150902: '集宁区',
		150921: '卓资县',
		150922: '化德县',
		150923: '商都县',
		150924: '兴和县',
		150925: '凉城县',
		150926: '察哈尔右翼前旗',
		150927: '察哈尔右翼中旗',
		150928: '察哈尔右翼后旗',
		150929: '四子王旗',
		150981: '丰镇市',
		152201: '乌兰浩特市',
		152202: '阿尔山市',
		152221: '科尔沁右翼前旗',
		152222: '科尔沁右翼中旗',
		152223: '扎赉特旗',
		152224: '突泉县',
		152501: '二连浩特市',
		152502: '锡林浩特市',
		152522: '阿巴嘎旗',
		152523: '苏尼特左旗',
		152524: '苏尼特右旗',
		152525: '东乌珠穆沁旗',
		152526: '西乌珠穆沁旗',
		152527: '太仆寺旗',
		152528: '镶黄旗',
		152529: '正镶白旗',
		152530: '正蓝旗',
		152531: '多伦县',
		152921: '阿拉善左旗',
		152922: '阿拉善右旗',
		152923: '额济纳旗',
		210102: '和平区',
		210103: '沈河区',
		210104: '大东区',
		210105: '皇姑区',
		210106: '铁西区',
		210111: '苏家屯区',
		210112: '浑南区',
		210113: '沈北新区',
		210114: '于洪区',
		210115: '辽中区',
		210123: '康平县',
		210124: '法库县',
		210181: '新民市',
		210190: '经济技术开发区',
		210202: '中山区',
		210203: '西岗区',
		210204: '沙河口区',
		210211: '甘井子区',
		210212: '旅顺口区',
		210213: '金州区',
		210214: '普兰店区',
		210224: '长海县',
		210281: '瓦房店市',
		210283: '庄河市',
		210302: '铁东区',
		210303: '铁西区',
		210304: '立山区',
		210311: '千山区',
		210321: '台安县',
		210323: '岫岩满族自治县',
		210381: '海城市',
		210390: '高新区',
		210402: '新抚区',
		210403: '东洲区',
		210404: '望花区',
		210411: '顺城区',
		210421: '抚顺县',
		210422: '新宾满族自治县',
		210423: '清原满族自治县',
		210502: '平山区',
		210503: '溪湖区',
		210504: '明山区',
		210505: '南芬区',
		210521: '本溪满族自治县',
		210522: '桓仁满族自治县',
		210602: '元宝区',
		210603: '振兴区',
		210604: '振安区',
		210624: '宽甸满族自治县',
		210681: '东港市',
		210682: '凤城市',
		210702: '古塔区',
		210703: '凌河区',
		210711: '太和区',
		210726: '黑山县',
		210727: '义县',
		210781: '凌海市',
		210782: '北镇市',
		210793: '经济技术开发区',
		210802: '站前区',
		210803: '西市区',
		210804: '鲅鱼圈区',
		210811: '老边区',
		210881: '盖州市',
		210882: '大石桥市',
		210902: '海州区',
		210903: '新邱区',
		210904: '太平区',
		210905: '清河门区',
		210911: '细河区',
		210921: '阜新蒙古族自治县',
		210922: '彰武县',
		211002: '白塔区',
		211003: '文圣区',
		211004: '宏伟区',
		211005: '弓长岭区',
		211011: '太子河区',
		211021: '辽阳县',
		211081: '灯塔市',
		211102: '双台子区',
		211103: '兴隆台区',
		211104: '大洼区',
		211122: '盘山县',
		211202: '银州区',
		211204: '清河区',
		211221: '铁岭县',
		211223: '西丰县',
		211224: '昌图县',
		211281: '调兵山市',
		211282: '开原市',
		211302: '双塔区',
		211303: '龙城区',
		211321: '朝阳县',
		211322: '建平县',
		211324: '喀喇沁左翼蒙古族自治县',
		211381: '北票市',
		211382: '凌源市',
		211402: '连山区',
		211403: '龙港区',
		211404: '南票区',
		211421: '绥中县',
		211422: '建昌县',
		211481: '兴城市',
		220102: '南关区',
		220103: '宽城区',
		220104: '朝阳区',
		220105: '二道区',
		220106: '绿园区',
		220112: '双阳区',
		220113: '九台区',
		220122: '农安县',
		220182: '榆树市',
		220183: '德惠市',
		220192: '经济技术开发区',
		220202: '昌邑区',
		220203: '龙潭区',
		220204: '船营区',
		220211: '丰满区',
		220221: '永吉县',
		220281: '蛟河市',
		220282: '桦甸市',
		220283: '舒兰市',
		220284: '磐石市',
		220302: '铁西区',
		220303: '铁东区',
		220322: '梨树县',
		220323: '伊通满族自治县',
		220381: '公主岭市',
		220382: '双辽市',
		220402: '龙山区',
		220403: '西安区',
		220421: '东丰县',
		220422: '东辽县',
		220502: '东昌区',
		220503: '二道江区',
		220521: '通化县',
		220523: '辉南县',
		220524: '柳河县',
		220581: '梅河口市',
		220582: '集安市',
		220602: '浑江区',
		220605: '江源区',
		220621: '抚松县',
		220622: '靖宇县',
		220623: '长白朝鲜族自治县',
		220681: '临江市',
		220702: '宁江区',
		220721: '前郭尔罗斯蒙古族自治县',
		220722: '长岭县',
		220723: '乾安县',
		220781: '扶余市',
		220802: '洮北区',
		220821: '镇赉县',
		220822: '通榆县',
		220881: '洮南市',
		220882: '大安市',
		222401: '延吉市',
		222402: '图们市',
		222403: '敦化市',
		222404: '珲春市',
		222405: '龙井市',
		222406: '和龙市',
		222424: '汪清县',
		222426: '安图县',
		230102: '道里区',
		230103: '南岗区',
		230104: '道外区',
		230108: '平房区',
		230109: '松北区',
		230110: '香坊区',
		230111: '呼兰区',
		230112: '阿城区',
		230113: '双城区',
		230123: '依兰县',
		230124: '方正县',
		230125: '宾县',
		230126: '巴彦县',
		230127: '木兰县',
		230128: '通河县',
		230129: '延寿县',
		230183: '尚志市',
		230184: '五常市',
		230202: '龙沙区',
		230203: '建华区',
		230204: '铁锋区',
		230205: '昂昂溪区',
		230206: '富拉尔基区',
		230207: '碾子山区',
		230208: '梅里斯达斡尔族区',
		230221: '龙江县',
		230223: '依安县',
		230224: '泰来县',
		230225: '甘南县',
		230227: '富裕县',
		230229: '克山县',
		230230: '克东县',
		230231: '拜泉县',
		230281: '讷河市',
		230302: '鸡冠区',
		230303: '恒山区',
		230304: '滴道区',
		230305: '梨树区',
		230306: '城子河区',
		230307: '麻山区',
		230321: '鸡东县',
		230381: '虎林市',
		230382: '密山市',
		230402: '向阳区',
		230403: '工农区',
		230404: '南山区',
		230405: '兴安区',
		230406: '东山区',
		230407: '兴山区',
		230421: '萝北县',
		230422: '绥滨县',
		230502: '尖山区',
		230503: '岭东区',
		230505: '四方台区',
		230506: '宝山区',
		230521: '集贤县',
		230522: '友谊县',
		230523: '宝清县',
		230524: '饶河县',
		230602: '萨尔图区',
		230603: '龙凤区',
		230604: '让胡路区',
		230605: '红岗区',
		230606: '大同区',
		230621: '肇州县',
		230622: '肇源县',
		230623: '林甸县',
		230624: '杜尔伯特蒙古族自治县',
		230702: '伊春区',
		230703: '南岔区',
		230704: '友好区',
		230705: '西林区',
		230706: '翠峦区',
		230707: '新青区',
		230708: '美溪区',
		230709: '金山屯区',
		230710: '五营区',
		230711: '乌马河区',
		230712: '汤旺河区',
		230713: '带岭区',
		230714: '乌伊岭区',
		230715: '红星区',
		230716: '上甘岭区',
		230722: '嘉荫县',
		230781: '铁力市',
		230803: '向阳区',
		230804: '前进区',
		230805: '东风区',
		230811: '郊区',
		230822: '桦南县',
		230826: '桦川县',
		230828: '汤原县',
		230881: '同江市',
		230882: '富锦市',
		230883: '抚远市',
		230902: '新兴区',
		230903: '桃山区',
		230904: '茄子河区',
		230921: '勃利县',
		231002: '东安区',
		231003: '阳明区',
		231004: '爱民区',
		231005: '西安区',
		231025: '林口县',
		231081: '绥芬河市',
		231083: '海林市',
		231084: '宁安市',
		231085: '穆棱市',
		231086: '东宁市',
		231102: '爱辉区',
		231121: '嫩江县',
		231123: '逊克县',
		231124: '孙吴县',
		231181: '北安市',
		231182: '五大连池市',
		231202: '北林区',
		231221: '望奎县',
		231222: '兰西县',
		231223: '青冈县',
		231224: '庆安县',
		231225: '明水县',
		231226: '绥棱县',
		231281: '安达市',
		231282: '肇东市',
		231283: '海伦市',
		232701: '漠河市',
		232721: '呼玛县',
		232722: '塔河县',
		232790: '松岭区',
		232791: '呼中区',
		232792: '加格达奇区',
		232793: '新林区',
		310101: '黄浦区',
		310104: '徐汇区',
		310105: '长宁区',
		310106: '静安区',
		310107: '普陀区',
		310109: '虹口区',
		310110: '杨浦区',
		310112: '闵行区',
		310113: '宝山区',
		310114: '嘉定区',
		310115: '浦东新区',
		310116: '金山区',
		310117: '松江区',
		310118: '青浦区',
		310120: '奉贤区',
		310151: '崇明区',
		320102: '玄武区',
		320104: '秦淮区',
		320105: '建邺区',
		320106: '鼓楼区',
		320111: '浦口区',
		320113: '栖霞区',
		320114: '雨花台区',
		320115: '江宁区',
		320116: '六合区',
		320117: '溧水区',
		320118: '高淳区',
		320205: '锡山区',
		320206: '惠山区',
		320211: '滨湖区',
		320213: '梁溪区',
		320214: '新吴区',
		320281: '江阴市',
		320282: '宜兴市',
		320302: '鼓楼区',
		320303: '云龙区',
		320305: '贾汪区',
		320311: '泉山区',
		320312: '铜山区',
		320321: '丰县',
		320322: '沛县',
		320324: '睢宁县',
		320381: '新沂市',
		320382: '邳州市',
		320391: '工业园区',
		320402: '天宁区',
		320404: '钟楼区',
		320411: '新北区',
		320412: '武进区',
		320413: '金坛区',
		320481: '溧阳市',
		320505: '虎丘区',
		320506: '吴中区',
		320507: '相城区',
		320508: '姑苏区',
		320509: '吴江区',
		320581: '常熟市',
		320582: '张家港市',
		320583: '昆山市',
		320585: '太仓市',
		320590: '工业园区',
		320591: '高新区',
		320602: '崇川区',
		320611: '港闸区',
		320612: '通州区',
		320623: '如东县',
		320681: '启东市',
		320682: '如皋市',
		320684: '海门市',
		320685: '海安市',
		320691: '高新区',
		320703: '连云区',
		320706: '海州区',
		320707: '赣榆区',
		320722: '东海县',
		320723: '灌云县',
		320724: '灌南县',
		320803: '淮安区',
		320804: '淮阴区',
		320812: '清江浦区',
		320813: '洪泽区',
		320826: '涟水县',
		320830: '盱眙县',
		320831: '金湖县',
		320890: '经济开发区',
		320902: '亭湖区',
		320903: '盐都区',
		320904: '大丰区',
		320921: '响水县',
		320922: '滨海县',
		320923: '阜宁县',
		320924: '射阳县',
		320925: '建湖县',
		320981: '东台市',
		321002: '广陵区',
		321003: '邗江区',
		321012: '江都区',
		321023: '宝应县',
		321081: '仪征市',
		321084: '高邮市',
		321090: '经济开发区',
		321102: '京口区',
		321111: '润州区',
		321112: '丹徒区',
		321181: '丹阳市',
		321182: '扬中市',
		321183: '句容市',
		321202: '海陵区',
		321203: '高港区',
		321204: '姜堰区',
		321281: '兴化市',
		321282: '靖江市',
		321283: '泰兴市',
		321302: '宿城区',
		321311: '宿豫区',
		321322: '沭阳县',
		321323: '泗阳县',
		321324: '泗洪县',
		330102: '上城区',
		330103: '下城区',
		330104: '江干区',
		330105: '拱墅区',
		330106: '西湖区',
		330108: '滨江区',
		330109: '萧山区',
		330110: '余杭区',
		330111: '富阳区',
		330112: '临安区',
		330122: '桐庐县',
		330127: '淳安县',
		330182: '建德市',
		330203: '海曙区',
		330205: '江北区',
		330206: '北仑区',
		330211: '镇海区',
		330212: '鄞州区',
		330213: '奉化区',
		330225: '象山县',
		330226: '宁海县',
		330281: '余姚市',
		330282: '慈溪市',
		330302: '鹿城区',
		330303: '龙湾区',
		330304: '瓯海区',
		330305: '洞头区',
		330324: '永嘉县',
		330326: '平阳县',
		330327: '苍南县',
		330328: '文成县',
		330329: '泰顺县',
		330381: '瑞安市',
		330382: '乐清市',
		330402: '南湖区',
		330411: '秀洲区',
		330421: '嘉善县',
		330424: '海盐县',
		330481: '海宁市',
		330482: '平湖市',
		330483: '桐乡市',
		330502: '吴兴区',
		330503: '南浔区',
		330521: '德清县',
		330522: '长兴县',
		330523: '安吉县',
		330602: '越城区',
		330603: '柯桥区',
		330604: '上虞区',
		330624: '新昌县',
		330681: '诸暨市',
		330683: '嵊州市',
		330702: '婺城区',
		330703: '金东区',
		330723: '武义县',
		330726: '浦江县',
		330727: '磐安县',
		330781: '兰溪市',
		330782: '义乌市',
		330783: '东阳市',
		330784: '永康市',
		330802: '柯城区',
		330803: '衢江区',
		330822: '常山县',
		330824: '开化县',
		330825: '龙游县',
		330881: '江山市',
		330902: '定海区',
		330903: '普陀区',
		330921: '岱山县',
		330922: '嵊泗县',
		331002: '椒江区',
		331003: '黄岩区',
		331004: '路桥区',
		331022: '三门县',
		331023: '天台县',
		331024: '仙居县',
		331081: '温岭市',
		331082: '临海市',
		331083: '玉环市',
		331102: '莲都区',
		331121: '青田县',
		331122: '缙云县',
		331123: '遂昌县',
		331124: '松阳县',
		331125: '云和县',
		331126: '庆元县',
		331127: '景宁畲族自治县',
		331181: '龙泉市',
		340102: '瑶海区',
		340103: '庐阳区',
		340104: '蜀山区',
		340111: '包河区',
		340121: '长丰县',
		340122: '肥东县',
		340123: '肥西县',
		340124: '庐江县',
		340181: '巢湖市',
		340190: '高新技术开发区',
		340191: '经济技术开发区',
		340202: '镜湖区',
		340203: '弋江区',
		340207: '鸠江区',
		340208: '三山区',
		340221: '芜湖县',
		340222: '繁昌县',
		340223: '南陵县',
		340225: '无为县',
		340302: '龙子湖区',
		340303: '蚌山区',
		340304: '禹会区',
		340311: '淮上区',
		340321: '怀远县',
		340322: '五河县',
		340323: '固镇县',
		340402: '大通区',
		340403: '田家庵区',
		340404: '谢家集区',
		340405: '八公山区',
		340406: '潘集区',
		340421: '凤台县',
		340422: '寿县',
		340503: '花山区',
		340504: '雨山区',
		340506: '博望区',
		340521: '当涂县',
		340522: '含山县',
		340523: '和县',
		340602: '杜集区',
		340603: '相山区',
		340604: '烈山区',
		340621: '濉溪县',
		340705: '铜官区',
		340706: '义安区',
		340711: '郊区',
		340722: '枞阳县',
		340802: '迎江区',
		340803: '大观区',
		340811: '宜秀区',
		340822: '怀宁县',
		340824: '潜山县',
		340825: '太湖县',
		340826: '宿松县',
		340827: '望江县',
		340828: '岳西县',
		340881: '桐城市',
		341002: '屯溪区',
		341003: '黄山区',
		341004: '徽州区',
		341021: '歙县',
		341022: '休宁县',
		341023: '黟县',
		341024: '祁门县',
		341102: '琅琊区',
		341103: '南谯区',
		341122: '来安县',
		341124: '全椒县',
		341125: '定远县',
		341126: '凤阳县',
		341181: '天长市',
		341182: '明光市',
		341202: '颍州区',
		341203: '颍东区',
		341204: '颍泉区',
		341221: '临泉县',
		341222: '太和县',
		341225: '阜南县',
		341226: '颍上县',
		341282: '界首市',
		341302: '埇桥区',
		341321: '砀山县',
		341322: '萧县',
		341323: '灵璧县',
		341324: '泗县',
		341390: '经济开发区',
		341502: '金安区',
		341503: '裕安区',
		341504: '叶集区',
		341522: '霍邱县',
		341523: '舒城县',
		341524: '金寨县',
		341525: '霍山县',
		341602: '谯城区',
		341621: '涡阳县',
		341622: '蒙城县',
		341623: '利辛县',
		341702: '贵池区',
		341721: '东至县',
		341722: '石台县',
		341723: '青阳县',
		341802: '宣州区',
		341821: '郎溪县',
		341822: '广德县',
		341823: '泾县',
		341824: '绩溪县',
		341825: '旌德县',
		341881: '宁国市',
		350102: '鼓楼区',
		350103: '台江区',
		350104: '仓山区',
		350105: '马尾区',
		350111: '晋安区',
		350112: '长乐区',
		350121: '闽侯县',
		350122: '连江县',
		350123: '罗源县',
		350124: '闽清县',
		350125: '永泰县',
		350128: '平潭县',
		350181: '福清市',
		350203: '思明区',
		350205: '海沧区',
		350206: '湖里区',
		350211: '集美区',
		350212: '同安区',
		350213: '翔安区',
		350302: '城厢区',
		350303: '涵江区',
		350304: '荔城区',
		350305: '秀屿区',
		350322: '仙游县',
		350402: '梅列区',
		350403: '三元区',
		350421: '明溪县',
		350423: '清流县',
		350424: '宁化县',
		350425: '大田县',
		350426: '尤溪县',
		350427: '沙县',
		350428: '将乐县',
		350429: '泰宁县',
		350430: '建宁县',
		350481: '永安市',
		350502: '鲤城区',
		350503: '丰泽区',
		350504: '洛江区',
		350505: '泉港区',
		350521: '惠安县',
		350524: '安溪县',
		350525: '永春县',
		350526: '德化县',
		350527: '金门县',
		350581: '石狮市',
		350582: '晋江市',
		350583: '南安市',
		350602: '芗城区',
		350603: '龙文区',
		350622: '云霄县',
		350623: '漳浦县',
		350624: '诏安县',
		350625: '长泰县',
		350626: '东山县',
		350627: '南靖县',
		350628: '平和县',
		350629: '华安县',
		350681: '龙海市',
		350702: '延平区',
		350703: '建阳区',
		350721: '顺昌县',
		350722: '浦城县',
		350723: '光泽县',
		350724: '松溪县',
		350725: '政和县',
		350781: '邵武市',
		350782: '武夷山市',
		350783: '建瓯市',
		350802: '新罗区',
		350803: '永定区',
		350821: '长汀县',
		350823: '上杭县',
		350824: '武平县',
		350825: '连城县',
		350881: '漳平市',
		350902: '蕉城区',
		350921: '霞浦县',
		350922: '古田县',
		350923: '屏南县',
		350924: '寿宁县',
		350925: '周宁县',
		350926: '柘荣县',
		350981: '福安市',
		350982: '福鼎市',
		360102: '东湖区',
		360103: '西湖区',
		360104: '青云谱区',
		360105: '湾里区',
		360111: '青山湖区',
		360112: '新建区',
		360121: '南昌县',
		360123: '安义县',
		360124: '进贤县',
		360190: '经济技术开发区',
		360192: '高新区',
		360202: '昌江区',
		360203: '珠山区',
		360222: '浮梁县',
		360281: '乐平市',
		360302: '安源区',
		360313: '湘东区',
		360321: '莲花县',
		360322: '上栗县',
		360323: '芦溪县',
		360402: '濂溪区',
		360403: '浔阳区',
		360404: '柴桑区',
		360423: '武宁县',
		360424: '修水县',
		360425: '永修县',
		360426: '德安县',
		360428: '都昌县',
		360429: '湖口县',
		360430: '彭泽县',
		360481: '瑞昌市',
		360482: '共青城市',
		360483: '庐山区',
		360490: '经济技术开发区',
		360502: '渝水区',
		360521: '分宜县',
		360602: '月湖区',
		360603: '余江区',
		360681: '贵溪市',
		360702: '章贡区',
		360703: '南康区',
		360704: '赣县区',
		360722: '信丰县',
		360723: '大余县',
		360724: '上犹县',
		360725: '崇义县',
		360726: '安远县',
		360727: '龙南县',
		360728: '定南县',
		360729: '全南县',
		360730: '宁都县',
		360731: '于都县',
		360732: '兴国县',
		360733: '会昌县',
		360734: '寻乌县',
		360735: '石城县',
		360781: '瑞金市',
		360802: '吉州区',
		360803: '青原区',
		360821: '吉安县',
		360822: '吉水县',
		360823: '峡江县',
		360824: '新干县',
		360825: '永丰县',
		360826: '泰和县',
		360827: '遂川县',
		360828: '万安县',
		360829: '安福县',
		360830: '永新县',
		360881: '井冈山市',
		360902: '袁州区',
		360921: '奉新县',
		360922: '万载县',
		360923: '上高县',
		360924: '宜丰县',
		360925: '靖安县',
		360926: '铜鼓县',
		360981: '丰城市',
		360982: '樟树市',
		360983: '高安市',
		361002: '临川区',
		361003: '东乡区',
		361021: '南城县',
		361022: '黎川县',
		361023: '南丰县',
		361024: '崇仁县',
		361025: '乐安县',
		361026: '宜黄县',
		361027: '金溪县',
		361028: '资溪县',
		361030: '广昌县',
		361102: '信州区',
		361103: '广丰区',
		361121: '上饶县',
		361123: '玉山县',
		361124: '铅山县',
		361125: '横峰县',
		361126: '弋阳县',
		361127: '余干县',
		361128: '鄱阳县',
		361129: '万年县',
		361130: '婺源县',
		361181: '德兴市',
		370102: '历下区',
		370103: '市中区',
		370104: '槐荫区',
		370105: '天桥区',
		370112: '历城区',
		370113: '长清区',
		370114: '章丘区',
		370115: '济阳区',
		370116: '莱芜区',
		370117: '钢城区',
		370124: '平阴县',
		370126: '商河县',
		370190: '高新区',
		370202: '市南区',
		370203: '市北区',
		370211: '黄岛区',
		370212: '崂山区',
		370213: '李沧区',
		370214: '城阳区',
		370215: '即墨区',
		370281: '胶州市',
		370283: '平度市',
		370285: '莱西市',
		370290: '开发区',
		370302: '淄川区',
		370303: '张店区',
		370304: '博山区',
		370305: '临淄区',
		370306: '周村区',
		370321: '桓台县',
		370322: '高青县',
		370323: '沂源县',
		370402: '市中区',
		370403: '薛城区',
		370404: '峄城区',
		370405: '台儿庄区',
		370406: '山亭区',
		370481: '滕州市',
		370502: '东营区',
		370503: '河口区',
		370505: '垦利区',
		370522: '利津县',
		370523: '广饶县',
		370602: '芝罘区',
		370611: '福山区',
		370612: '牟平区',
		370613: '莱山区',
		370634: '长岛县',
		370681: '龙口市',
		370682: '莱阳市',
		370683: '莱州市',
		370684: '蓬莱市',
		370685: '招远市',
		370686: '栖霞市',
		370687: '海阳市',
		370690: '开发区',
		370702: '潍城区',
		370703: '寒亭区',
		370704: '坊子区',
		370705: '奎文区',
		370724: '临朐县',
		370725: '昌乐县',
		370781: '青州市',
		370782: '诸城市',
		370783: '寿光市',
		370784: '安丘市',
		370785: '高密市',
		370786: '昌邑市',
		370790: '开发区',
		370791: '高新区',
		370811: '任城区',
		370812: '兖州区',
		370826: '微山县',
		370827: '鱼台县',
		370828: '金乡县',
		370829: '嘉祥县',
		370830: '汶上县',
		370831: '泗水县',
		370832: '梁山县',
		370881: '曲阜市',
		370883: '邹城市',
		370890: '高新区',
		370902: '泰山区',
		370911: '岱岳区',
		370921: '宁阳县',
		370923: '东平县',
		370982: '新泰市',
		370983: '肥城市',
		371002: '环翠区',
		371003: '文登区',
		371082: '荣成市',
		371083: '乳山市',
		371091: '经济技术开发区',
		371102: '东港区',
		371103: '岚山区',
		371121: '五莲县',
		371122: '莒县',
		371302: '兰山区',
		371311: '罗庄区',
		371312: '河东区',
		371321: '沂南县',
		371322: '郯城县',
		371323: '沂水县',
		371324: '兰陵县',
		371325: '费县',
		371326: '平邑县',
		371327: '莒南县',
		371328: '蒙阴县',
		371329: '临沭县',
		371402: '德城区',
		371403: '陵城区',
		371422: '宁津县',
		371423: '庆云县',
		371424: '临邑县',
		371425: '齐河县',
		371426: '平原县',
		371427: '夏津县',
		371428: '武城县',
		371481: '乐陵市',
		371482: '禹城市',
		371502: '东昌府区',
		371521: '阳谷县',
		371522: '莘县',
		371523: '茌平县',
		371524: '东阿县',
		371525: '冠县',
		371526: '高唐县',
		371581: '临清市',
		371602: '滨城区',
		371603: '沾化区',
		371621: '惠民县',
		371622: '阳信县',
		371623: '无棣县',
		371625: '博兴县',
		371681: '邹平市',
		371702: '牡丹区',
		371703: '定陶区',
		371721: '曹县',
		371722: '单县',
		371723: '成武县',
		371724: '巨野县',
		371725: '郓城县',
		371726: '鄄城县',
		371728: '东明县',
		410102: '中原区',
		410103: '二七区',
		410104: '管城回族区',
		410105: '金水区',
		410106: '上街区',
		410108: '惠济区',
		410122: '中牟县',
		410181: '巩义市',
		410182: '荥阳市',
		410183: '新密市',
		410184: '新郑市',
		410185: '登封市',
		410190: '高新技术开发区',
		410191: '经济技术开发区',
		410192: '郑东新区',
		410202: '龙亭区',
		410203: '顺河回族区',
		410204: '鼓楼区',
		410205: '禹王台区',
		410212: '祥符区',
		410221: '杞县',
		410222: '通许县',
		410223: '尉氏县',
		410225: '兰考县',
		410302: '老城区',
		410303: '西工区',
		410304: '瀍河回族区',
		410305: '涧西区',
		410306: '吉利区',
		410311: '洛龙区',
		410322: '孟津县',
		410323: '新安县',
		410324: '栾川县',
		410325: '嵩县',
		410326: '汝阳县',
		410327: '宜阳县',
		410328: '洛宁县',
		410329: '伊川县',
		410381: '偃师市',
		410402: '新华区',
		410403: '卫东区',
		410404: '石龙区',
		410411: '湛河区',
		410421: '宝丰县',
		410422: '叶县',
		410423: '鲁山县',
		410425: '郏县',
		410481: '舞钢市',
		410482: '汝州市',
		410502: '文峰区',
		410503: '北关区',
		410505: '殷都区',
		410506: '龙安区',
		410522: '安阳县',
		410523: '汤阴县',
		410526: '滑县',
		410527: '内黄县',
		410581: '林州市',
		410590: '开发区',
		410602: '鹤山区',
		410603: '山城区',
		410611: '淇滨区',
		410621: '浚县',
		410622: '淇县',
		410702: '红旗区',
		410703: '卫滨区',
		410704: '凤泉区',
		410711: '牧野区',
		410721: '新乡县',
		410724: '获嘉县',
		410725: '原阳县',
		410726: '延津县',
		410727: '封丘县',
		410728: '长垣县',
		410781: '卫辉市',
		410782: '辉县市',
		410802: '解放区',
		410803: '中站区',
		410804: '马村区',
		410811: '山阳区',
		410821: '修武县',
		410822: '博爱县',
		410823: '武陟县',
		410825: '温县',
		410882: '沁阳市',
		410883: '孟州市',
		410902: '华龙区',
		410922: '清丰县',
		410923: '南乐县',
		410926: '范县',
		410927: '台前县',
		410928: '濮阳县',
		411002: '魏都区',
		411003: '建安区',
		411024: '鄢陵县',
		411025: '襄城县',
		411081: '禹州市',
		411082: '长葛市',
		411102: '源汇区',
		411103: '郾城区',
		411104: '召陵区',
		411121: '舞阳县',
		411122: '临颍县',
		411202: '湖滨区',
		411203: '陕州区',
		411221: '渑池县',
		411224: '卢氏县',
		411281: '义马市',
		411282: '灵宝市',
		411302: '宛城区',
		411303: '卧龙区',
		411321: '南召县',
		411322: '方城县',
		411323: '西峡县',
		411324: '镇平县',
		411325: '内乡县',
		411326: '淅川县',
		411327: '社旗县',
		411328: '唐河县',
		411329: '新野县',
		411330: '桐柏县',
		411381: '邓州市',
		411402: '梁园区',
		411403: '睢阳区',
		411421: '民权县',
		411422: '睢县',
		411423: '宁陵县',
		411424: '柘城县',
		411425: '虞城县',
		411426: '夏邑县',
		411481: '永城市',
		411502: '浉河区',
		411503: '平桥区',
		411521: '罗山县',
		411522: '光山县',
		411523: '新县',
		411524: '商城县',
		411525: '固始县',
		411526: '潢川县',
		411527: '淮滨县',
		411528: '息县',
		411602: '川汇区',
		411621: '扶沟县',
		411622: '西华县',
		411623: '商水县',
		411624: '沈丘县',
		411625: '郸城县',
		411626: '淮阳县',
		411627: '太康县',
		411628: '鹿邑县',
		411681: '项城市',
		411690: '经济开发区',
		411702: '驿城区',
		411721: '西平县',
		411722: '上蔡县',
		411723: '平舆县',
		411724: '正阳县',
		411725: '确山县',
		411726: '泌阳县',
		411727: '汝南县',
		411728: '遂平县',
		411729: '新蔡县',
		419001: '济源市',
		420102: '江岸区',
		420103: '江汉区',
		420104: '硚口区',
		420105: '汉阳区',
		420106: '武昌区',
		420107: '青山区',
		420111: '洪山区',
		420112: '东西湖区',
		420113: '汉南区',
		420114: '蔡甸区',
		420115: '江夏区',
		420116: '黄陂区',
		420117: '新洲区',
		420202: '黄石港区',
		420203: '西塞山区',
		420204: '下陆区',
		420205: '铁山区',
		420222: '阳新县',
		420281: '大冶市',
		420302: '茅箭区',
		420303: '张湾区',
		420304: '郧阳区',
		420322: '郧西县',
		420323: '竹山县',
		420324: '竹溪县',
		420325: '房县',
		420381: '丹江口市',
		420502: '西陵区',
		420503: '伍家岗区',
		420504: '点军区',
		420505: '猇亭区',
		420506: '夷陵区',
		420525: '远安县',
		420526: '兴山县',
		420527: '秭归县',
		420528: '长阳土家族自治县',
		420529: '五峰土家族自治县',
		420581: '宜都市',
		420582: '当阳市',
		420583: '枝江市',
		420590: '经济开发区',
		420602: '襄城区',
		420606: '樊城区',
		420607: '襄州区',
		420624: '南漳县',
		420625: '谷城县',
		420626: '保康县',
		420682: '老河口市',
		420683: '枣阳市',
		420684: '宜城市',
		420702: '梁子湖区',
		420703: '华容区',
		420704: '鄂城区',
		420802: '东宝区',
		420804: '掇刀区',
		420822: '沙洋县',
		420881: '钟祥市',
		420882: '京山市',
		420902: '孝南区',
		420921: '孝昌县',
		420922: '大悟县',
		420923: '云梦县',
		420981: '应城市',
		420982: '安陆市',
		420984: '汉川市',
		421002: '沙市区',
		421003: '荆州区',
		421022: '公安县',
		421023: '监利县',
		421024: '江陵县',
		421081: '石首市',
		421083: '洪湖市',
		421087: '松滋市',
		421102: '黄州区',
		421121: '团风县',
		421122: '红安县',
		421123: '罗田县',
		421124: '英山县',
		421125: '浠水县',
		421126: '蕲春县',
		421127: '黄梅县',
		421181: '麻城市',
		421182: '武穴市',
		421202: '咸安区',
		421221: '嘉鱼县',
		421222: '通城县',
		421223: '崇阳县',
		421224: '通山县',
		421281: '赤壁市',
		421303: '曾都区',
		421321: '随县',
		421381: '广水市',
		422801: '恩施市',
		422802: '利川市',
		422822: '建始县',
		422823: '巴东县',
		422825: '宣恩县',
		422826: '咸丰县',
		422827: '来凤县',
		422828: '鹤峰县',
		429004: '仙桃市',
		429005: '潜江市',
		429006: '天门市',
		429021: '神农架林区',
		430102: '芙蓉区',
		430103: '天心区',
		430104: '岳麓区',
		430105: '开福区',
		430111: '雨花区',
		430112: '望城区',
		430121: '长沙县',
		430181: '浏阳市',
		430182: '宁乡市',
		430202: '荷塘区',
		430203: '芦淞区',
		430204: '石峰区',
		430211: '天元区',
		430212: '渌口区',
		430223: '攸县',
		430224: '茶陵县',
		430225: '炎陵县',
		430281: '醴陵市',
		430302: '雨湖区',
		430304: '岳塘区',
		430321: '湘潭县',
		430381: '湘乡市',
		430382: '韶山市',
		430405: '珠晖区',
		430406: '雁峰区',
		430407: '石鼓区',
		430408: '蒸湘区',
		430412: '南岳区',
		430421: '衡阳县',
		430422: '衡南县',
		430423: '衡山县',
		430424: '衡东县',
		430426: '祁东县',
		430481: '耒阳市',
		430482: '常宁市',
		430502: '双清区',
		430503: '大祥区',
		430511: '北塔区',
		430521: '邵东县',
		430522: '新邵县',
		430523: '邵阳县',
		430524: '隆回县',
		430525: '洞口县',
		430527: '绥宁县',
		430528: '新宁县',
		430529: '城步苗族自治县',
		430581: '武冈市',
		430602: '岳阳楼区',
		430603: '云溪区',
		430611: '君山区',
		430621: '岳阳县',
		430623: '华容县',
		430624: '湘阴县',
		430626: '平江县',
		430681: '汨罗市',
		430682: '临湘市',
		430702: '武陵区',
		430703: '鼎城区',
		430721: '安乡县',
		430722: '汉寿县',
		430723: '澧县',
		430724: '临澧县',
		430725: '桃源县',
		430726: '石门县',
		430781: '津市市',
		430802: '永定区',
		430811: '武陵源区',
		430821: '慈利县',
		430822: '桑植县',
		430902: '资阳区',
		430903: '赫山区',
		430921: '南县',
		430922: '桃江县',
		430923: '安化县',
		430981: '沅江市',
		431002: '北湖区',
		431003: '苏仙区',
		431021: '桂阳县',
		431022: '宜章县',
		431023: '永兴县',
		431024: '嘉禾县',
		431025: '临武县',
		431026: '汝城县',
		431027: '桂东县',
		431028: '安仁县',
		431081: '资兴市',
		431102: '零陵区',
		431103: '冷水滩区',
		431121: '祁阳县',
		431122: '东安县',
		431123: '双牌县',
		431124: '道县',
		431125: '江永县',
		431126: '宁远县',
		431127: '蓝山县',
		431128: '新田县',
		431129: '江华瑶族自治县',
		431202: '鹤城区',
		431221: '中方县',
		431222: '沅陵县',
		431223: '辰溪县',
		431224: '溆浦县',
		431225: '会同县',
		431226: '麻阳苗族自治县',
		431227: '新晃侗族自治县',
		431228: '芷江侗族自治县',
		431229: '靖州苗族侗族自治县',
		431230: '通道侗族自治县',
		431281: '洪江市',
		431302: '娄星区',
		431321: '双峰县',
		431322: '新化县',
		431381: '冷水江市',
		431382: '涟源市',
		433101: '吉首市',
		433122: '泸溪县',
		433123: '凤凰县',
		433124: '花垣县',
		433125: '保靖县',
		433126: '古丈县',
		433127: '永顺县',
		433130: '龙山县',
		440103: '荔湾区',
		440104: '越秀区',
		440105: '海珠区',
		440106: '天河区',
		440111: '白云区',
		440112: '黄埔区',
		440113: '番禺区',
		440114: '花都区',
		440115: '南沙区',
		440117: '从化区',
		440118: '增城区',
		440203: '武江区',
		440204: '浈江区',
		440205: '曲江区',
		440222: '始兴县',
		440224: '仁化县',
		440229: '翁源县',
		440232: '乳源瑶族自治县',
		440233: '新丰县',
		440281: '乐昌市',
		440282: '南雄市',
		440303: '罗湖区',
		440304: '福田区',
		440305: '南山区',
		440306: '宝安区',
		440307: '龙岗区',
		440308: '盐田区',
		440309: '龙华新区',
		440310: '坪山区',
		440311: '光明区',
		440402: '香洲区',
		440403: '斗门区',
		440404: '金湾区',
		440507: '龙湖区',
		440511: '金平区',
		440512: '濠江区',
		440513: '潮阳区',
		440514: '潮南区',
		440515: '澄海区',
		440523: '南澳县',
		440604: '禅城区',
		440605: '南海区',
		440606: '顺德区',
		440607: '三水区',
		440608: '高明区',
		440703: '蓬江区',
		440704: '江海区',
		440705: '新会区',
		440781: '台山市',
		440783: '开平市',
		440784: '鹤山市',
		440785: '恩平市',
		440802: '赤坎区',
		440803: '霞山区',
		440804: '坡头区',
		440811: '麻章区',
		440823: '遂溪县',
		440825: '徐闻县',
		440881: '廉江市',
		440882: '雷州市',
		440883: '吴川市',
		440890: '经济技术开发区',
		440902: '茂南区',
		440904: '电白区',
		440981: '高州市',
		440982: '化州市',
		440983: '信宜市',
		441202: '端州区',
		441203: '鼎湖区',
		441204: '高要区',
		441223: '广宁县',
		441224: '怀集县',
		441225: '封开县',
		441226: '德庆县',
		441284: '四会市',
		441302: '惠城区',
		441303: '惠阳区',
		441322: '博罗县',
		441323: '惠东县',
		441324: '龙门县',
		441402: '梅江区',
		441403: '梅县区',
		441422: '大埔县',
		441423: '丰顺县',
		441424: '五华县',
		441426: '平远县',
		441427: '蕉岭县',
		441481: '兴宁市',
		441502: '城区',
		441521: '海丰县',
		441523: '陆河县',
		441581: '陆丰市',
		441602: '源城区',
		441621: '紫金县',
		441622: '龙川县',
		441623: '连平县',
		441624: '和平县',
		441625: '东源县',
		441702: '江城区',
		441704: '阳东区',
		441721: '阳西县',
		441781: '阳春市',
		441802: '清城区',
		441803: '清新区',
		441821: '佛冈县',
		441823: '阳山县',
		441825: '连山壮族瑶族自治县',
		441826: '连南瑶族自治县',
		441881: '英德市',
		441882: '连州市',
		441901: '中堂镇',
		441903: '南城街道办事处',
		441904: '长安镇',
		441905: '东坑镇',
		441906: '樟木头镇',
		441907: '莞城街道办事处',
		441908: '石龙镇',
		441909: '桥头镇',
		441910: '万江街道办事处',
		441911: '麻涌镇',
		441912: '虎门镇',
		441913: '谢岗镇',
		441914: '石碣镇',
		441915: '茶山镇',
		441916: '东城街道办事处',
		441917: '洪梅镇',
		441918: '道滘镇',
		441919: '高埗镇',
		441920: '企石镇',
		441921: '凤岗镇',
		441922: '大岭山镇',
		441923: '松山湖管委会',
		441924: '清溪镇',
		441925: '望牛墩镇',
		441926: '厚街镇',
		441927: '常平镇',
		441928: '寮步镇',
		441929: '石排镇',
		441930: '横沥镇',
		441931: '塘厦镇',
		441932: '黄江镇',
		441933: '大朗镇',
		441934: '东莞港',
		441935: '东莞生态园',
		441936: '东城区',
		441937: '南城区',
		441990: '沙田镇',
		442001: '南头镇',
		442002: '神湾镇',
		442003: '东凤镇',
		442004: '五桂山街道办事处',
		442005: '黄圃镇',
		442006: '小榄镇',
		442007: '石岐区街道办事处',
		442008: '横栏镇',
		442009: '三角镇',
		442010: '三乡镇',
		442011: '港口镇',
		442012: '沙溪镇',
		442013: '板芙镇',
		442015: '东升镇',
		442016: '阜沙镇',
		442017: '民众镇',
		442018: '东区街道办事处',
		442019: '火炬开发区街道办事处',
		442020: '西区街道办事处',
		442021: '南区街道办事处',
		442022: '古镇镇',
		442023: '坦洲镇',
		442024: '大涌镇',
		442025: '南朗镇',
		445102: '湘桥区',
		445103: '潮安区',
		445122: '饶平县',
		445202: '榕城区',
		445203: '揭东区',
		445222: '揭西县',
		445224: '惠来县',
		445281: '普宁市',
		445302: '云城区',
		445303: '云安区',
		445321: '新兴县',
		445322: '郁南县',
		445381: '罗定市',
		450102: '兴宁区',
		450103: '青秀区',
		450105: '江南区',
		450107: '西乡塘区',
		450108: '良庆区',
		450109: '邕宁区',
		450110: '武鸣区',
		450123: '隆安县',
		450124: '马山县',
		450125: '上林县',
		450126: '宾阳县',
		450127: '横县',
		450202: '城中区',
		450203: '鱼峰区',
		450204: '柳南区',
		450205: '柳北区',
		450206: '柳江区',
		450222: '柳城县',
		450223: '鹿寨县',
		450224: '融安县',
		450225: '融水苗族自治县',
		450226: '三江侗族自治县',
		450302: '秀峰区',
		450303: '叠彩区',
		450304: '象山区',
		450305: '七星区',
		450311: '雁山区',
		450312: '临桂区',
		450321: '阳朔县',
		450323: '灵川县',
		450324: '全州县',
		450325: '兴安县',
		450326: '永福县',
		450327: '灌阳县',
		450328: '龙胜各族自治县',
		450329: '资源县',
		450330: '平乐县',
		450332: '恭城瑶族自治县',
		450381: '荔浦市',
		450403: '万秀区',
		450405: '长洲区',
		450406: '龙圩区',
		450421: '苍梧县',
		450422: '藤县',
		450423: '蒙山县',
		450481: '岑溪市',
		450502: '海城区',
		450503: '银海区',
		450512: '铁山港区',
		450521: '合浦县',
		450602: '港口区',
		450603: '防城区',
		450621: '上思县',
		450681: '东兴市',
		450702: '钦南区',
		450703: '钦北区',
		450721: '灵山县',
		450722: '浦北县',
		450802: '港北区',
		450803: '港南区',
		450804: '覃塘区',
		450821: '平南县',
		450881: '桂平市',
		450902: '玉州区',
		450903: '福绵区',
		450921: '容县',
		450922: '陆川县',
		450923: '博白县',
		450924: '兴业县',
		450981: '北流市',
		451002: '右江区',
		451021: '田阳县',
		451022: '田东县',
		451023: '平果县',
		451024: '德保县',
		451026: '那坡县',
		451027: '凌云县',
		451028: '乐业县',
		451029: '田林县',
		451030: '西林县',
		451031: '隆林各族自治县',
		451081: '靖西市',
		451102: '八步区',
		451103: '平桂区',
		451121: '昭平县',
		451122: '钟山县',
		451123: '富川瑶族自治县',
		451202: '金城江区',
		451203: '宜州区',
		451221: '南丹县',
		451222: '天峨县',
		451223: '凤山县',
		451224: '东兰县',
		451225: '罗城仫佬族自治县',
		451226: '环江毛南族自治县',
		451227: '巴马瑶族自治县',
		451228: '都安瑶族自治县',
		451229: '大化瑶族自治县',
		451302: '兴宾区',
		451321: '忻城县',
		451322: '象州县',
		451323: '武宣县',
		451324: '金秀瑶族自治县',
		451381: '合山市',
		451402: '江州区',
		451421: '扶绥县',
		451422: '宁明县',
		451423: '龙州县',
		451424: '大新县',
		451425: '天等县',
		451481: '凭祥市',
		460105: '秀英区',
		460106: '龙华区',
		460107: '琼山区',
		460108: '美兰区',
		460202: '海棠区',
		460203: '吉阳区',
		460204: '天涯区',
		460205: '崖州区',
		460321: '西沙群岛',
		460322: '南沙群岛',
		460323: '中沙群岛的岛礁及其海域',
		460401: '那大镇',
		460402: '和庆镇',
		460403: '南丰镇',
		460404: '大成镇',
		460405: '雅星镇',
		460406: '兰洋镇',
		460407: '光村镇',
		460408: '木棠镇',
		460409: '海头镇',
		460410: '峨蔓镇',
		460411: '王五镇',
		460412: '白马井镇',
		460413: '中和镇',
		460414: '排浦镇',
		460415: '东成镇',
		460416: '新州镇',
		460417: '洋浦经济开发区',
		460418: '华南热作学院',
		469001: '五指山市',
		469002: '琼海市',
		469005: '文昌市',
		469006: '万宁市',
		469007: '东方市',
		469021: '定安县',
		469022: '屯昌县',
		469023: '澄迈县',
		469024: '临高县',
		469025: '白沙黎族自治县',
		469026: '昌江黎族自治县',
		469027: '乐东黎族自治县',
		469028: '陵水黎族自治县',
		469029: '保亭黎族苗族自治县',
		469030: '琼中黎族苗族自治县',
		500101: '万州区',
		500102: '涪陵区',
		500103: '渝中区',
		500104: '大渡口区',
		500105: '江北区',
		500106: '沙坪坝区',
		500107: '九龙坡区',
		500108: '南岸区',
		500109: '北碚区',
		500110: '綦江区',
		500111: '大足区',
		500112: '渝北区',
		500113: '巴南区',
		500114: '黔江区',
		500115: '长寿区',
		500116: '江津区',
		500117: '合川区',
		500118: '永川区',
		500119: '南川区',
		500120: '璧山区',
		500151: '铜梁区',
		500152: '潼南区',
		500153: '荣昌区',
		500154: '开州区',
		500155: '梁平区',
		500156: '武隆区',
		500229: '城口县',
		500230: '丰都县',
		500231: '垫江县',
		500233: '忠县',
		500235: '云阳县',
		500236: '奉节县',
		500237: '巫山县',
		500238: '巫溪县',
		500240: '石柱土家族自治县',
		500241: '秀山土家族苗族自治县',
		500242: '酉阳土家族苗族自治县',
		500243: '彭水苗族土家族自治县',
		510104: '锦江区',
		510105: '青羊区',
		510106: '金牛区',
		510107: '武侯区',
		510108: '成华区',
		510112: '龙泉驿区',
		510113: '青白江区',
		510114: '新都区',
		510115: '温江区',
		510116: '双流区',
		510117: '郫都区',
		510121: '金堂县',
		510129: '大邑县',
		510131: '蒲江县',
		510132: '新津县',
		510181: '都江堰市',
		510182: '彭州市',
		510183: '邛崃市',
		510184: '崇州市',
		510185: '简阳市',
		510191: '高新区',
		510302: '自流井区',
		510303: '贡井区',
		510304: '大安区',
		510311: '沿滩区',
		510321: '荣县',
		510322: '富顺县',
		510402: '东区',
		510403: '西区',
		510411: '仁和区',
		510421: '米易县',
		510422: '盐边县',
		510502: '江阳区',
		510503: '纳溪区',
		510504: '龙马潭区',
		510521: '泸县',
		510522: '合江县',
		510524: '叙永县',
		510525: '古蔺县',
		510603: '旌阳区',
		510604: '罗江区',
		510623: '中江县',
		510681: '广汉市',
		510682: '什邡市',
		510683: '绵竹市',
		510703: '涪城区',
		510704: '游仙区',
		510705: '安州区',
		510722: '三台县',
		510723: '盐亭县',
		510725: '梓潼县',
		510726: '北川羌族自治县',
		510727: '平武县',
		510781: '江油市',
		510791: '高新区',
		510802: '利州区',
		510811: '昭化区',
		510812: '朝天区',
		510821: '旺苍县',
		510822: '青川县',
		510823: '剑阁县',
		510824: '苍溪县',
		510903: '船山区',
		510904: '安居区',
		510921: '蓬溪县',
		510922: '射洪县',
		510923: '大英县',
		511002: '市中区',
		511011: '东兴区',
		511024: '威远县',
		511025: '资中县',
		511083: '隆昌市',
		511102: '市中区',
		511111: '沙湾区',
		511112: '五通桥区',
		511113: '金口河区',
		511123: '犍为县',
		511124: '井研县',
		511126: '夹江县',
		511129: '沐川县',
		511132: '峨边彝族自治县',
		511133: '马边彝族自治县',
		511181: '峨眉山市',
		511302: '顺庆区',
		511303: '高坪区',
		511304: '嘉陵区',
		511321: '南部县',
		511322: '营山县',
		511323: '蓬安县',
		511324: '仪陇县',
		511325: '西充县',
		511381: '阆中市',
		511402: '东坡区',
		511403: '彭山区',
		511421: '仁寿县',
		511423: '洪雅县',
		511424: '丹棱县',
		511425: '青神县',
		511502: '翠屏区',
		511503: '南溪区',
		511504: '叙州区',
		511523: '江安县',
		511524: '长宁县',
		511525: '高县',
		511526: '珙县',
		511527: '筠连县',
		511528: '兴文县',
		511529: '屏山县',
		511602: '广安区',
		511603: '前锋区',
		511621: '岳池县',
		511622: '武胜县',
		511623: '邻水县',
		511681: '华蓥市',
		511702: '通川区',
		511703: '达川区',
		511722: '宣汉县',
		511723: '开江县',
		511724: '大竹县',
		511725: '渠县',
		511781: '万源市',
		511802: '雨城区',
		511803: '名山区',
		511822: '荥经县',
		511823: '汉源县',
		511824: '石棉县',
		511825: '天全县',
		511826: '芦山县',
		511827: '宝兴县',
		511902: '巴州区',
		511903: '恩阳区',
		511921: '通江县',
		511922: '南江县',
		511923: '平昌县',
		512002: '雁江区',
		512021: '安岳县',
		512022: '乐至县',
		513201: '马尔康市',
		513221: '汶川县',
		513222: '理县',
		513223: '茂县',
		513224: '松潘县',
		513225: '九寨沟县',
		513226: '金川县',
		513227: '小金县',
		513228: '黑水县',
		513230: '壤塘县',
		513231: '阿坝县',
		513232: '若尔盖县',
		513233: '红原县',
		513301: '康定市',
		513322: '泸定县',
		513323: '丹巴县',
		513324: '九龙县',
		513325: '雅江县',
		513326: '道孚县',
		513327: '炉霍县',
		513328: '甘孜县',
		513329: '新龙县',
		513330: '德格县',
		513331: '白玉县',
		513332: '石渠县',
		513333: '色达县',
		513334: '理塘县',
		513335: '巴塘县',
		513336: '乡城县',
		513337: '稻城县',
		513338: '得荣县',
		513401: '西昌市',
		513422: '木里藏族自治县',
		513423: '盐源县',
		513424: '德昌县',
		513425: '会理县',
		513426: '会东县',
		513427: '宁南县',
		513428: '普格县',
		513429: '布拖县',
		513430: '金阳县',
		513431: '昭觉县',
		513432: '喜德县',
		513433: '冕宁县',
		513434: '越西县',
		513435: '甘洛县',
		513436: '美姑县',
		513437: '雷波县',
		520102: '南明区',
		520103: '云岩区',
		520111: '花溪区',
		520112: '乌当区',
		520113: '白云区',
		520115: '观山湖区',
		520121: '开阳县',
		520122: '息烽县',
		520123: '修文县',
		520181: '清镇市',
		520182: '经济技术开发区',
		520201: '钟山区',
		520203: '六枝特区',
		520221: '水城县',
		520281: '盘州市',
		520302: '红花岗区',
		520303: '汇川区',
		520304: '播州区',
		520322: '桐梓县',
		520323: '绥阳县',
		520324: '正安县',
		520325: '道真仡佬族苗族自治县',
		520326: '务川仡佬族苗族自治县',
		520327: '凤冈县',
		520328: '湄潭县',
		520329: '余庆县',
		520330: '习水县',
		520381: '赤水市',
		520382: '仁怀市',
		520402: '西秀区',
		520403: '平坝区',
		520422: '普定县',
		520423: '镇宁布依族苗族自治县',
		520424: '关岭布依族苗族自治县',
		520425: '紫云苗族布依族自治县',
		520502: '七星关区',
		520521: '大方县',
		520522: '黔西县',
		520523: '金沙县',
		520524: '织金县',
		520525: '纳雍县',
		520526: '威宁彝族回族苗族自治县',
		520527: '赫章县',
		520602: '碧江区',
		520603: '万山区',
		520621: '江口县',
		520622: '玉屏侗族自治县',
		520623: '石阡县',
		520624: '思南县',
		520625: '印江土家族苗族自治县',
		520626: '德江县',
		520627: '沿河土家族自治县',
		520628: '松桃苗族自治县',
		522301: '兴义市',
		522302: '兴仁市',
		522323: '普安县',
		522324: '晴隆县',
		522325: '贞丰县',
		522326: '望谟县',
		522327: '册亨县',
		522328: '安龙县',
		522601: '凯里市',
		522622: '黄平县',
		522623: '施秉县',
		522624: '三穗县',
		522625: '镇远县',
		522626: '岑巩县',
		522627: '天柱县',
		522628: '锦屏县',
		522629: '剑河县',
		522630: '台江县',
		522631: '黎平县',
		522632: '榕江县',
		522633: '从江县',
		522634: '雷山县',
		522635: '麻江县',
		522636: '丹寨县',
		522701: '都匀市',
		522702: '福泉市',
		522722: '荔波县',
		522723: '贵定县',
		522725: '瓮安县',
		522726: '独山县',
		522727: '平塘县',
		522728: '罗甸县',
		522729: '长顺县',
		522730: '龙里县',
		522731: '惠水县',
		522732: '三都水族自治县',
		530102: '五华区',
		530103: '盘龙区',
		530111: '官渡区',
		530112: '西山区',
		530113: '东川区',
		530114: '呈贡区',
		530115: '晋宁区',
		530124: '富民县',
		530125: '宜良县',
		530126: '石林彝族自治县',
		530127: '嵩明县',
		530128: '禄劝彝族苗族自治县',
		530129: '寻甸回族彝族自治县',
		530181: '安宁市',
		530302: '麒麟区',
		530303: '沾益区',
		530304: '马龙区',
		530322: '陆良县',
		530323: '师宗县',
		530324: '罗平县',
		530325: '富源县',
		530326: '会泽县',
		530381: '宣威市',
		530402: '红塔区',
		530403: '江川区',
		530422: '澄江县',
		530423: '通海县',
		530424: '华宁县',
		530425: '易门县',
		530426: '峨山彝族自治县',
		530427: '新平彝族傣族自治县',
		530428: '元江哈尼族彝族傣族自治县',
		530502: '隆阳区',
		530521: '施甸县',
		530523: '龙陵县',
		530524: '昌宁县',
		530581: '腾冲市',
		530602: '昭阳区',
		530621: '鲁甸县',
		530622: '巧家县',
		530623: '盐津县',
		530624: '大关县',
		530625: '永善县',
		530626: '绥江县',
		530627: '镇雄县',
		530628: '彝良县',
		530629: '威信县',
		530681: '水富市',
		530702: '古城区',
		530721: '玉龙纳西族自治县',
		530722: '永胜县',
		530723: '华坪县',
		530724: '宁蒗彝族自治县',
		530802: '思茅区',
		530821: '宁洱哈尼族彝族自治县',
		530822: '墨江哈尼族自治县',
		530823: '景东彝族自治县',
		530824: '景谷傣族彝族自治县',
		530825: '镇沅彝族哈尼族拉祜族自治县',
		530826: '江城哈尼族彝族自治县',
		530827: '孟连傣族拉祜族佤族自治县',
		530828: '澜沧拉祜族自治县',
		530829: '西盟佤族自治县',
		530902: '临翔区',
		530921: '凤庆县',
		530922: '云县',
		530923: '永德县',
		530924: '镇康县',
		530925: '双江拉祜族佤族布朗族傣族自治县',
		530926: '耿马傣族佤族自治县',
		530927: '沧源佤族自治县',
		532301: '楚雄市',
		532322: '双柏县',
		532323: '牟定县',
		532324: '南华县',
		532325: '姚安县',
		532326: '大姚县',
		532327: '永仁县',
		532328: '元谋县',
		532329: '武定县',
		532331: '禄丰县',
		532501: '个旧市',
		532502: '开远市',
		532503: '蒙自市',
		532504: '弥勒市',
		532523: '屏边苗族自治县',
		532524: '建水县',
		532525: '石屏县',
		532527: '泸西县',
		532528: '元阳县',
		532529: '红河县',
		532530: '金平苗族瑶族傣族自治县',
		532531: '绿春县',
		532532: '河口瑶族自治县',
		532601: '文山市',
		532622: '砚山县',
		532623: '西畴县',
		532624: '麻栗坡县',
		532625: '马关县',
		532626: '丘北县',
		532627: '广南县',
		532628: '富宁县',
		532801: '景洪市',
		532822: '勐海县',
		532823: '勐腊县',
		532901: '大理市',
		532922: '漾濞彝族自治县',
		532923: '祥云县',
		532924: '宾川县',
		532925: '弥渡县',
		532926: '南涧彝族自治县',
		532927: '巍山彝族回族自治县',
		532928: '永平县',
		532929: '云龙县',
		532930: '洱源县',
		532931: '剑川县',
		532932: '鹤庆县',
		533102: '瑞丽市',
		533103: '芒市',
		533122: '梁河县',
		533123: '盈江县',
		533124: '陇川县',
		533301: '泸水市',
		533323: '福贡县',
		533324: '贡山独龙族怒族自治县',
		533325: '兰坪白族普米族自治县',
		533401: '香格里拉市',
		533422: '德钦县',
		533423: '维西傈僳族自治县',
		540102: '城关区',
		540103: '堆龙德庆区',
		540104: '达孜区',
		540121: '林周县',
		540122: '当雄县',
		540123: '尼木县',
		540124: '曲水县',
		540127: '墨竹工卡县',
		540202: '桑珠孜区',
		540221: '南木林县',
		540222: '江孜县',
		540223: '定日县',
		540224: '萨迦县',
		540225: '拉孜县',
		540226: '昂仁县',
		540227: '谢通门县',
		540228: '白朗县',
		540229: '仁布县',
		540230: '康马县',
		540231: '定结县',
		540232: '仲巴县',
		540233: '亚东县',
		540234: '吉隆县',
		540235: '聂拉木县',
		540236: '萨嘎县',
		540237: '岗巴县',
		540302: '卡若区',
		540321: '江达县',
		540322: '贡觉县',
		540323: '类乌齐县',
		540324: '丁青县',
		540325: '察雅县',
		540326: '八宿县',
		540327: '左贡县',
		540328: '芒康县',
		540329: '洛隆县',
		540330: '边坝县',
		540402: '巴宜区',
		540421: '工布江达县',
		540422: '米林县',
		540423: '墨脱县',
		540424: '波密县',
		540425: '察隅县',
		540426: '朗县',
		540502: '乃东区',
		540521: '扎囊县',
		540522: '贡嘎县',
		540523: '桑日县',
		540524: '琼结县',
		540525: '曲松县',
		540526: '措美县',
		540527: '洛扎县',
		540528: '加查县',
		540529: '隆子县',
		540530: '错那县',
		540531: '浪卡子县',
		540602: '色尼区',
		540621: '嘉黎县',
		540622: '比如县',
		540623: '聂荣县',
		540624: '安多县',
		540625: '申扎县',
		540626: '索县',
		540627: '班戈县',
		540628: '巴青县',
		540629: '尼玛县',
		540630: '双湖县',
		542521: '普兰县',
		542522: '札达县',
		542523: '噶尔县',
		542524: '日土县',
		542525: '革吉县',
		542526: '改则县',
		542527: '措勤县',
		610102: '新城区',
		610103: '碑林区',
		610104: '莲湖区',
		610111: '灞桥区',
		610112: '未央区',
		610113: '雁塔区',
		610114: '阎良区',
		610115: '临潼区',
		610116: '长安区',
		610117: '高陵区',
		610118: '鄠邑区',
		610122: '蓝田县',
		610124: '周至县',
		610202: '王益区',
		610203: '印台区',
		610204: '耀州区',
		610222: '宜君县',
		610302: '渭滨区',
		610303: '金台区',
		610304: '陈仓区',
		610322: '凤翔县',
		610323: '岐山县',
		610324: '扶风县',
		610326: '眉县',
		610327: '陇县',
		610328: '千阳县',
		610329: '麟游县',
		610330: '凤县',
		610331: '太白县',
		610402: '秦都区',
		610403: '杨凌区',
		610404: '渭城区',
		610422: '三原县',
		610423: '泾阳县',
		610424: '乾县',
		610425: '礼泉县',
		610426: '永寿县',
		610428: '长武县',
		610429: '旬邑县',
		610430: '淳化县',
		610431: '武功县',
		610481: '兴平市',
		610482: '彬州市',
		610502: '临渭区',
		610503: '华州区',
		610522: '潼关县',
		610523: '大荔县',
		610524: '合阳县',
		610525: '澄城县',
		610526: '蒲城县',
		610527: '白水县',
		610528: '富平县',
		610581: '韩城市',
		610582: '华阴市',
		610602: '宝塔区',
		610603: '安塞区',
		610621: '延长县',
		610622: '延川县',
		610623: '子长县',
		610625: '志丹县',
		610626: '吴起县',
		610627: '甘泉县',
		610628: '富县',
		610629: '洛川县',
		610630: '宜川县',
		610631: '黄龙县',
		610632: '黄陵县',
		610702: '汉台区',
		610703: '南郑区',
		610722: '城固县',
		610723: '洋县',
		610724: '西乡县',
		610725: '勉县',
		610726: '宁强县',
		610727: '略阳县',
		610728: '镇巴县',
		610729: '留坝县',
		610730: '佛坪县',
		610802: '榆阳区',
		610803: '横山区',
		610822: '府谷县',
		610824: '靖边县',
		610825: '定边县',
		610826: '绥德县',
		610827: '米脂县',
		610828: '佳县',
		610829: '吴堡县',
		610830: '清涧县',
		610831: '子洲县',
		610881: '神木市',
		610902: '汉滨区',
		610921: '汉阴县',
		610922: '石泉县',
		610923: '宁陕县',
		610924: '紫阳县',
		610925: '岚皋县',
		610926: '平利县',
		610927: '镇坪县',
		610928: '旬阳县',
		610929: '白河县',
		611002: '商州区',
		611021: '洛南县',
		611022: '丹凤县',
		611023: '商南县',
		611024: '山阳县',
		611025: '镇安县',
		611026: '柞水县',
		620102: '城关区',
		620103: '七里河区',
		620104: '西固区',
		620105: '安宁区',
		620111: '红古区',
		620121: '永登县',
		620122: '皋兰县',
		620123: '榆中县',
		620201: '市辖区',
		620290: '雄关区',
		620291: '长城区',
		620292: '镜铁区',
		620293: '新城镇',
		620294: '峪泉镇',
		620295: '文殊镇',
		620302: '金川区',
		620321: '永昌县',
		620402: '白银区',
		620403: '平川区',
		620421: '靖远县',
		620422: '会宁县',
		620423: '景泰县',
		620502: '秦州区',
		620503: '麦积区',
		620521: '清水县',
		620522: '秦安县',
		620523: '甘谷县',
		620524: '武山县',
		620525: '张家川回族自治县',
		620602: '凉州区',
		620621: '民勤县',
		620622: '古浪县',
		620623: '天祝藏族自治县',
		620702: '甘州区',
		620721: '肃南裕固族自治县',
		620722: '民乐县',
		620723: '临泽县',
		620724: '高台县',
		620725: '山丹县',
		620802: '崆峒区',
		620821: '泾川县',
		620822: '灵台县',
		620823: '崇信县',
		620825: '庄浪县',
		620826: '静宁县',
		620881: '华亭市',
		620902: '肃州区',
		620921: '金塔县',
		620922: '瓜州县',
		620923: '肃北蒙古族自治县',
		620924: '阿克塞哈萨克族自治县',
		620981: '玉门市',
		620982: '敦煌市',
		621002: '西峰区',
		621021: '庆城县',
		621022: '环县',
		621023: '华池县',
		621024: '合水县',
		621025: '正宁县',
		621026: '宁县',
		621027: '镇原县',
		621102: '安定区',
		621121: '通渭县',
		621122: '陇西县',
		621123: '渭源县',
		621124: '临洮县',
		621125: '漳县',
		621126: '岷县',
		621202: '武都区',
		621221: '成县',
		621222: '文县',
		621223: '宕昌县',
		621224: '康县',
		621225: '西和县',
		621226: '礼县',
		621227: '徽县',
		621228: '两当县',
		622901: '临夏市',
		622921: '临夏县',
		622922: '康乐县',
		622923: '永靖县',
		622924: '广河县',
		622925: '和政县',
		622926: '东乡族自治县',
		622927: '积石山保安族东乡族撒拉族自治县',
		623001: '合作市',
		623021: '临潭县',
		623022: '卓尼县',
		623023: '舟曲县',
		623024: '迭部县',
		623025: '玛曲县',
		623026: '碌曲县',
		623027: '夏河县',
		630102: '城东区',
		630103: '城中区',
		630104: '城西区',
		630105: '城北区',
		630121: '大通回族土族自治县',
		630122: '湟中县',
		630123: '湟源县',
		630202: '乐都区',
		630203: '平安区',
		630222: '民和回族土族自治县',
		630223: '互助土族自治县',
		630224: '化隆回族自治县',
		630225: '循化撒拉族自治县',
		632221: '门源回族自治县',
		632222: '祁连县',
		632223: '海晏县',
		632224: '刚察县',
		632321: '同仁县',
		632322: '尖扎县',
		632323: '泽库县',
		632324: '河南蒙古族自治县',
		632521: '共和县',
		632522: '同德县',
		632523: '贵德县',
		632524: '兴海县',
		632525: '贵南县',
		632621: '玛沁县',
		632622: '班玛县',
		632623: '甘德县',
		632624: '达日县',
		632625: '久治县',
		632626: '玛多县',
		632701: '玉树市',
		632722: '杂多县',
		632723: '称多县',
		632724: '治多县',
		632725: '囊谦县',
		632726: '曲麻莱县',
		632801: '格尔木市',
		632802: '德令哈市',
		632803: '茫崖市',
		632821: '乌兰县',
		632822: '都兰县',
		632823: '天峻县',
		640104: '兴庆区',
		640105: '西夏区',
		640106: '金凤区',
		640121: '永宁县',
		640122: '贺兰县',
		640181: '灵武市',
		640202: '大武口区',
		640205: '惠农区',
		640221: '平罗县',
		640302: '利通区',
		640303: '红寺堡区',
		640323: '盐池县',
		640324: '同心县',
		640381: '青铜峡市',
		640402: '原州区',
		640422: '西吉县',
		640423: '隆德县',
		640424: '泾源县',
		640425: '彭阳县',
		640502: '沙坡头区',
		640521: '中宁县',
		640522: '海原县',
		650102: '天山区',
		650103: '沙依巴克区',
		650104: '新市区',
		650105: '水磨沟区',
		650106: '头屯河区',
		650107: '达坂城区',
		650109: '米东区',
		650121: '乌鲁木齐县',
		650202: '独山子区',
		650203: '克拉玛依区',
		650204: '白碱滩区',
		650205: '乌尔禾区',
		650402: '高昌区',
		650421: '鄯善县',
		650422: '托克逊县',
		650502: '伊州区',
		650521: '巴里坤哈萨克自治县',
		650522: '伊吾县',
		652301: '昌吉市',
		652302: '阜康市',
		652323: '呼图壁县',
		652324: '玛纳斯县',
		652325: '奇台县',
		652327: '吉木萨尔县',
		652328: '木垒哈萨克自治县',
		652701: '博乐市',
		652702: '阿拉山口市',
		652722: '精河县',
		652723: '温泉县',
		652801: '库尔勒市',
		652822: '轮台县',
		652823: '尉犁县',
		652824: '若羌县',
		652825: '且末县',
		652826: '焉耆回族自治县',
		652827: '和静县',
		652828: '和硕县',
		652829: '博湖县',
		652901: '阿克苏市',
		652922: '温宿县',
		652923: '库车县',
		652924: '沙雅县',
		652925: '新和县',
		652926: '拜城县',
		652927: '乌什县',
		652928: '阿瓦提县',
		652929: '柯坪县',
		653001: '阿图什市',
		653022: '阿克陶县',
		653023: '阿合奇县',
		653024: '乌恰县',
		653101: '喀什市',
		653121: '疏附县',
		653122: '疏勒县',
		653123: '英吉沙县',
		653124: '泽普县',
		653125: '莎车县',
		653126: '叶城县',
		653127: '麦盖提县',
		653128: '岳普湖县',
		653129: '伽师县',
		653130: '巴楚县',
		653131: '塔什库尔干塔吉克自治县',
		653201: '和田市',
		653221: '和田县',
		653222: '墨玉县',
		653223: '皮山县',
		653224: '洛浦县',
		653225: '策勒县',
		653226: '于田县',
		653227: '民丰县',
		654002: '伊宁市',
		654003: '奎屯市',
		654004: '霍尔果斯市',
		654021: '伊宁县',
		654022: '察布查尔锡伯自治县',
		654023: '霍城县',
		654024: '巩留县',
		654025: '新源县',
		654026: '昭苏县',
		654027: '特克斯县',
		654028: '尼勒克县',
		654201: '塔城市',
		654202: '乌苏市',
		654221: '额敏县',
		654223: '沙湾县',
		654224: '托里县',
		654225: '裕民县',
		654226: '和布克赛尔蒙古自治县',
		654301: '阿勒泰市',
		654321: '布尔津县',
		654322: '富蕴县',
		654323: '福海县',
		654324: '哈巴河县',
		654325: '青河县',
		654326: '吉木乃县',
		659001: '石河子市',
		659002: '阿拉尔市',
		659003: '图木舒克市',
		659004: '五家渠市',
		659005: '北屯市',
		659006: '铁门关市',
		659007: '双河市',
		659008: '可克达拉市',
		659009: '昆玉市',
		710101: '中正区',
		710102: '大同区',
		710103: '中山区',
		710104: '松山区',
		710105: '大安区',
		710106: '万华区',
		710107: '信义区',
		710108: '士林区',
		710109: '北投区',
		710110: '内湖区',
		710111: '南港区',
		710112: '文山区',
		710199: '其它区',
		710201: '新兴区',
		710202: '前金区',
		710203: '芩雅区',
		710204: '盐埕区',
		710205: '鼓山区',
		710206: '旗津区',
		710207: '前镇区',
		710208: '三民区',
		710209: '左营区',
		710210: '楠梓区',
		710211: '小港区',
		710241: '苓雅区',
		710242: '仁武区',
		710243: '大社区',
		710244: '冈山区',
		710245: '路竹区',
		710246: '阿莲区',
		710247: '田寮区',
		710248: '燕巢区',
		710249: '桥头区',
		710250: '梓官区',
		710251: '弥陀区',
		710252: '永安区',
		710253: '湖内区',
		710254: '凤山区',
		710255: '大寮区',
		710256: '林园区',
		710257: '鸟松区',
		710258: '大树区',
		710259: '旗山区',
		710260: '美浓区',
		710261: '六龟区',
		710262: '内门区',
		710263: '杉林区',
		710264: '甲仙区',
		710265: '桃源区',
		710266: '那玛夏区',
		710267: '茂林区',
		710268: '茄萣区',
		710299: '其它区',
		710301: '中西区',
		710302: '东区',
		710303: '南区',
		710304: '北区',
		710305: '安平区',
		710306: '安南区',
		710339: '永康区',
		710340: '归仁区',
		710341: '新化区',
		710342: '左镇区',
		710343: '玉井区',
		710344: '楠西区',
		710345: '南化区',
		710346: '仁德区',
		710347: '关庙区',
		710348: '龙崎区',
		710349: '官田区',
		710350: '麻豆区',
		710351: '佳里区',
		710352: '西港区',
		710353: '七股区',
		710354: '将军区',
		710355: '学甲区',
		710356: '北门区',
		710357: '新营区',
		710358: '后壁区',
		710359: '白河区',
		710360: '东山区',
		710361: '六甲区',
		710362: '下营区',
		710363: '柳营区',
		710364: '盐水区',
		710365: '善化区',
		710366: '大内区',
		710367: '山上区',
		710368: '新市区',
		710369: '安定区',
		710399: '其它区',
		710401: '中区',
		710402: '东区',
		710403: '南区',
		710404: '西区',
		710405: '北区',
		710406: '北屯区',
		710407: '西屯区',
		710408: '南屯区',
		710431: '太平区',
		710432: '大里区',
		710433: '雾峰区',
		710434: '乌日区',
		710435: '丰原区',
		710436: '后里区',
		710437: '石冈区',
		710438: '东势区',
		710439: '和平区',
		710440: '新社区',
		710441: '潭子区',
		710442: '大雅区',
		710443: '神冈区',
		710444: '大肚区',
		710445: '沙鹿区',
		710446: '龙井区',
		710447: '梧栖区',
		710448: '清水区',
		710449: '大甲区',
		710450: '外埔区',
		710451: '大安区',
		710499: '其它区',
		710507: '金沙镇',
		710508: '金湖镇',
		710509: '金宁乡',
		710510: '金城镇',
		710511: '烈屿乡',
		710512: '乌坵乡',
		710614: '南投市',
		710615: '中寮乡',
		710616: '草屯镇',
		710617: '国姓乡',
		710618: '埔里镇',
		710619: '仁爱乡',
		710620: '名间乡',
		710621: '集集镇',
		710622: '水里乡',
		710623: '鱼池乡',
		710624: '信义乡',
		710625: '竹山镇',
		710626: '鹿谷乡',
		710701: '仁爱区',
		710702: '信义区',
		710703: '中正区',
		710704: '中山区',
		710705: '安乐区',
		710706: '暖暖区',
		710707: '七堵区',
		710799: '其它区',
		710801: '东区',
		710802: '北区',
		710803: '香山区',
		710899: '其它区',
		710901: '东区',
		710902: '西区',
		710999: '其它区',
		711130: '万里区',
		711132: '板桥区',
		711133: '汐止区',
		711134: '深坑区',
		711135: '石碇区',
		711136: '瑞芳区',
		711137: '平溪区',
		711138: '双溪区',
		711139: '贡寮区',
		711140: '新店区',
		711141: '坪林区',
		711142: '乌来区',
		711143: '永和区',
		711144: '中和区',
		711145: '土城区',
		711146: '三峡区',
		711147: '树林区',
		711148: '莺歌区',
		711149: '三重区',
		711150: '新庄区',
		711151: '泰山区',
		711152: '林口区',
		711153: '芦洲区',
		711154: '五股区',
		711155: '八里区',
		711156: '淡水区',
		711157: '三芝区',
		711158: '石门区',
		711287: '宜兰市',
		711288: '头城镇',
		711289: '礁溪乡',
		711290: '壮围乡',
		711291: '员山乡',
		711292: '罗东镇',
		711293: '三星乡',
		711294: '大同乡',
		711295: '五结乡',
		711296: '冬山乡',
		711297: '苏澳镇',
		711298: '南澳乡',
		711299: '钓鱼台',
		711387: '竹北市',
		711388: '湖口乡',
		711389: '新丰乡',
		711390: '新埔镇',
		711391: '关西镇',
		711392: '芎林乡',
		711393: '宝山乡',
		711394: '竹东镇',
		711395: '五峰乡',
		711396: '横山乡',
		711397: '尖石乡',
		711398: '北埔乡',
		711399: '峨眉乡',
		711414: '中坜区',
		711415: '平镇区',
		711417: '杨梅区',
		711418: '新屋区',
		711419: '观音区',
		711420: '桃园区',
		711421: '龟山区',
		711422: '八德区',
		711423: '大溪区',
		711425: '大园区',
		711426: '芦竹区',
		711487: '中坜市',
		711488: '平镇市',
		711489: '龙潭乡',
		711490: '杨梅市',
		711491: '新屋乡',
		711492: '观音乡',
		711493: '桃园市',
		711494: '龟山乡',
		711495: '八德市',
		711496: '大溪镇',
		711497: '复兴乡',
		711498: '大园乡',
		711499: '芦竹乡',
		711520: '头份市',
		711582: '竹南镇',
		711583: '头份镇',
		711584: '三湾乡',
		711585: '南庄乡',
		711586: '狮潭乡',
		711587: '后龙镇',
		711588: '通霄镇',
		711589: '苑里镇',
		711590: '苗栗市',
		711591: '造桥乡',
		711592: '头屋乡',
		711593: '公馆乡',
		711594: '大湖乡',
		711595: '泰安乡',
		711596: '铜锣乡',
		711597: '三义乡',
		711598: '西湖乡',
		711599: '卓兰镇',
		711736: '员林市',
		711774: '彰化市',
		711775: '芬园乡',
		711776: '花坛乡',
		711777: '秀水乡',
		711778: '鹿港镇',
		711779: '福兴乡',
		711780: '线西乡',
		711781: '和美镇',
		711782: '伸港乡',
		711783: '员林镇',
		711784: '社头乡',
		711785: '永靖乡',
		711786: '埔心乡',
		711787: '溪湖镇',
		711788: '大村乡',
		711789: '埔盐乡',
		711790: '田中镇',
		711791: '北斗镇',
		711792: '田尾乡',
		711793: '埤头乡',
		711794: '溪州乡',
		711795: '竹塘乡',
		711796: '二林镇',
		711797: '大城乡',
		711798: '芳苑乡',
		711799: '二水乡',
		711982: '番路乡',
		711983: '梅山乡',
		711984: '竹崎乡',
		711985: '阿里山乡',
		711986: '中埔乡',
		711987: '大埔乡',
		711988: '水上乡',
		711989: '鹿草乡',
		711990: '太保市',
		711991: '朴子市',
		711992: '东石乡',
		711993: '六脚乡',
		711994: '新港乡',
		711995: '民雄乡',
		711996: '大林镇',
		711997: '溪口乡',
		711998: '义竹乡',
		711999: '布袋镇',
		712180: '斗南镇',
		712181: '大埤乡',
		712182: '虎尾镇',
		712183: '土库镇',
		712184: '褒忠乡',
		712185: '东势乡',
		712186: '台西乡',
		712187: '仑背乡',
		712188: '麦寮乡',
		712189: '斗六市',
		712190: '林内乡',
		712191: '古坑乡',
		712192: '莿桐乡',
		712193: '西螺镇',
		712194: '二仑乡',
		712195: '北港镇',
		712196: '水林乡',
		712197: '口湖乡',
		712198: '四湖乡',
		712199: '元长乡',
		712451: '崁顶乡',
		712467: '屏东市',
		712468: '三地门乡',
		712469: '雾台乡',
		712470: '玛家乡',
		712471: '九如乡',
		712472: '里港乡',
		712473: '高树乡',
		712474: '盐埔乡',
		712475: '长治乡',
		712476: '麟洛乡',
		712477: '竹田乡',
		712478: '内埔乡',
		712479: '万丹乡',
		712480: '潮州镇',
		712481: '泰武乡',
		712482: '来义乡',
		712483: '万峦乡',
		712484: '莰顶乡',
		712485: '新埤乡',
		712486: '南州乡',
		712487: '林边乡',
		712488: '东港镇',
		712489: '琉球乡',
		712490: '佳冬乡',
		712491: '新园乡',
		712492: '枋寮乡',
		712493: '枋山乡',
		712494: '春日乡',
		712495: '狮子乡',
		712496: '车城乡',
		712497: '牡丹乡',
		712498: '恒春镇',
		712499: '满州乡',
		712584: '台东市',
		712585: '绿岛乡',
		712586: '兰屿乡',
		712587: '延平乡',
		712588: '卑南乡',
		712589: '鹿野乡',
		712590: '关山镇',
		712591: '海端乡',
		712592: '池上乡',
		712593: '东河乡',
		712594: '成功镇',
		712595: '长滨乡',
		712596: '金峰乡',
		712597: '大武乡',
		712598: '达仁乡',
		712599: '太麻里乡',
		712686: '花莲市',
		712687: '新城乡',
		712688: '太鲁阁',
		712689: '秀林乡',
		712690: '吉安乡',
		712691: '寿丰乡',
		712692: '凤林镇',
		712693: '光复乡',
		712694: '丰滨乡',
		712695: '瑞穗乡',
		712696: '万荣乡',
		712697: '玉里镇',
		712698: '卓溪乡',
		712699: '富里乡',
		712794: '马公市',
		712795: '西屿乡',
		712796: '望安乡',
		712797: '七美乡',
		712798: '白沙乡',
		712799: '湖西乡',
		712896: '南竿乡',
		712897: '北竿乡',
		712898: '东引乡',
		712899: '莒光乡',
		810101: '中西区',
		810102: '湾仔区',
		810103: '东区',
		810104: '南区',
		810201: '九龙城区',
		810202: '油尖旺区',
		810203: '深水埗区',
		810204: '黄大仙区',
		810205: '观塘区',
		810301: '北区',
		810302: '大埔区',
		810303: '沙田区',
		810304: '西贡区',
		810305: '元朗区',
		810306: '屯门区',
		810307: '荃湾区',
		810308: '葵青区',
		810309: '离岛区',
		820102: '花地玛堂区',
		820103: '花王堂区',
		820104: '望德堂区',
		820105: '大堂区',
		820106: '风顺堂区',
		820202: '嘉模堂区',
		820203: '路氹填海区',
		820204: '圣方济各堂区'
	},
	hospital_list: {
		/* 110101001: '中国中医科学院针灸医院',
		110101003: '北京王军整形',
		110101004: '李氏修牙所',
		110101005: '北京联合大学中医药学院附属鼓搂中医医院',
		110101006: '北京市东城区第一人民医院',
		110101007: '北京联合大学中医药学院附属医院',
		110101008: '北京张建鱼鳞病研究院',
		110101009: '北京东四中医院',
		110101010: '北京施恩红斑狼疮医院',
		110101011: '北京东城中医院', */
		110101012: '八一儿童医院',
		// 110101013: '北京京科医院',
		// 110101014: '北京京华友好哮喘医院',
		110101015: '首都医科大学附属北京中医医院',
		110101016: '北京航星机器制造公司北京东城航星医院',
		// 110101017: '北京市东城区朝阳门医院',
		// 110101018: '北京中新颐和医院',
		// 110101019: '北京市东城区东四医院',
		// 110101020: '北京市东城区长安医院',
		// 110101021: '北京市东城区北新桥医院',
		// 110101022: '北京市东城区精神卫生保健院',
		// 110101023: '北京市东城区建国门医院',
		// 110101024: '北京大学第一医院肿瘤诊所中心',
		// 110101025: '北京市东城区口腔医院',
		// 110101026: '北京市东城区妇幼保健院',
		// 110101027: '首医大中医药学院附属鼓楼中医医院',
		// 110101028: '北京市隆福医院',
		// 110101029: '北京市第六医院',
		// 110101030: '北京市和平里医院',
		110101031: '北京市公安医院',
		110101032: '卫生部北京医院',
		110101033: '北京中医药大学东直门医院',
		110101034: '北京军区总医院',
		110101035: '首都医科大学附属北京同仁医院',
		110101036: '首都医科大学附属北京地坛医院',
		110101037: '北京协和医院',
		110101038: '北京妇幼保健院',
		110101039: '首都医科大学附属北京同仁医院',
		// 110101040: '北京朱家坟社区卫生服务中心',
		// 110101041: '龙潭社区卫生服务中心',
		// 110101042: '北京市崇文区龙潭医院',
		// 110101043: '北京两广中医医院',
		// 110101044: '北京东华医院',
		// 110101045: '北京市崇文光明医院',
		// 110101046: '北京市崇文区口腔医院',
		// 110101047: '北京市崇文区体育馆路医院',
		// 110101048: '北京市崇文区前门医院',
		// 110101049: '北京同仁堂崇文中医院',
		// 110101050: '北京市崇文区妇幼保健院',
		// 110101051: '北京市普仁医院',
		110101052: '首都医科大学附属北京天坛医院',
		110101053: '首都医科大学附属北京口腔医院',
		// 110106001: '北京市丰台区卢沟桥国医社区卫生服务中心',
		// 110106002: '北京中联国际血管瘤研究院',
		// 110106003: '北京肛肠专科医院',
		// 110106004: '北京市丰台区永南医院',
		// 110106005: '北京市丰台区丰北医院',
		// 110106006: '北京市丰台区兴隆中医院',
		// 110106007: '北京市红十字会和平医院',
		// 110106008: '北京市丰台区华丰医院',
		// 110106009: '北京市丰台区燕竹医院',
		// 110106010: '北京市丰台区方庄医院',
		// 110106011: '北京市丰台区兴海医院',
		// 110106012: '北京市丰台区蒲黄榆医院',
		// 110106013: '北京市丰台区京材医院',
		// 110106014: '北京市丰台区和平医院',
		// 110106015: '北京市丰台区大红门医院',
		// 110106016: '北京市丰台区三路居医院',
		// 110106017: '北京市三环英和医院',
		// 110106018: '北京市统计学会国泰医院',
		// 110106019: '北京市北方车辆制造厂职工医院',
		// 110106020: '北京市二七车辆厂医院',
		// 110106021: '北京市城建第二医院',
		// 110106022: '北京市丰台区花乡医院',
		// 110106023: '北京市丰台区卢沟桥医院',
		// 110106024: '北京市丰台区华兴医院',
		// 110106025: '北京市丰台区华西医院',
		// 110106026: '中国兵器工业集团第二0一所医院',
		// 110106027: '国营第618厂职工医院',
		// 110106028: '北京丰台医星医院',
		// 110106029: '北京市丰台区洋桥医院',
		// 110106030: '北京市丰台区卢沟桥国医院',
		// 110106031: '北京市丰台区右外医院',
		// 110106032: '北京市丰台区西罗园第一医院',
		// 110106033: '北京市丰台区铁营医院',
		// 110106034: '北京安太医院',
		// 110106035: '中国航天科工集团七三一医院',
		// 110106036: '航天海鹰中心医院',
		// 110106037: '北京丰台医院',
		110106038: '中国人民解放军三零七医院',
		110106039: '中国康复研究中心北京博爱医院',
		110106040: '北京中医药大学东方医院',
		110106041: '中国人民解放军第三0二医院',
		110106042: '首都医科大学附属北京佑安医院',
		// 110106043: '北京国济中医医院',
		// 110106044: '北京卢沟桥社区卫生服务中心',
		// 110106045: '北京二七北社区卫生服务中心',
		// 110106046: '北京木材厂社区卫生服务站',
		110106047: '中国人民解放军第302医院',
		// 110106048: '北京市丰台区医院',
		// 110106049: '北京丰台银龄中医医院',
		// 110106050: '北京东管头投资管理公司丽泽医院',
		110106051: '解放军第307医院',
		// 110106052: '北京市丰台区广济医院',
		// 110115001: '北京市大兴区北臧村镇卫生院',
		// 110115002: '北京市大兴区半壁店卫生院',
		// 110115003: '北京市大兴区采育镇中心卫生院',
		// 110115004: '北京市大兴区长子营镇卫生院',
		// 110115005: '北京市大兴区安定镇中心卫生院',
		// 110115006: '北京耀华中医肿瘤门诊',
		// 110115007: '大兴区精神病医院',
		110115008: '北京同仁医院亦庄分院',
		// 110115009: '北京市天堂河医院',
		// 110115010: '北京市团河农场医院',
		// 110115011: '中国建筑第一工程局第二建筑公司...',
		// 110115012: '北京市大兴区红星医院',
		// 110115013: '北京市大兴区精神病院',
		// 110115014: '北京市大兴区人民医院',
		// 110115015: '北京市大兴区妇幼保健院',
		// 110115016: '北京市大兴区中医院',
		// 110115017: '北京仁和医院',
		// 110115018: '北京普祥中医肿瘤医院',
		// 110115019: '北京中科白癜风医院',
		// 110102001: '北京市宣武区椿树医院',
		// 110102002: '北京市宣武区大栅栏天桥医院',
		// 110102003: '北京市宣武区陶然亭医院',
		// 110102004: '北京市宣武区白纸坊医院',
		// 110102005: '北京市宣武区广河医院',
		// 110102006: '宣武区南城中医门诊部',
		// 110102007: '北京市政二公司白云路医院',
		// 110102008: '北京市天堂河农场医院',
		// 110102009: '北京印钞厂医院',
		110102010: '北京医院协会右安门医院',
		// 110102011: '北京市监狱管理局清河农场医院',
		// 110102012: '北京市宣武区广外医院',
		// 110102013: '北京市回民医院',
		// 110102014: '北京健宫医院',
		// 110102015: '北京市监狱管理局中心医院',
		110102016: '北京市宣武区中医院',
		110102017: '北京友谊医院',
		110102018: '中国中医科学研究院广安门医院',
		110102019: '首都医科大学附属宣武医院',
		110102020: '阜外医院',
		// 110102021: '广内社区卫生服务中心',
		110102022: '北京市西城区中医院',
		// 110102023: '陶然亭医院',
		// 110102024: '中卫整形美容中心',
		// 110102025: '北京市丰盛中医骨伤专科医院',
		// 110102026: '北京市监狱管理局清河分局医院',
		// 110102027: '北京市第二医院西城佳华骨关节病...',
		110102028: '北京市总队第二医院',
		110102029: '北京家圆医院',
		// 110102030: '北京市西城区厂桥医院',
		// 110102031: '北京市西城区月坛医院',
		// 110102032: '北京闹市口医院',
		// 110102033: '北京市西城区新街口医院',
		// 110102034: '北京明都中医院',
		// 110102035: '北京丰盛中医骨伤专科医院',
		// 110102036: '北京市肛肠医院',
		// 110102037: '北京市西城区平安医院',
		// 110102038: '北京市护国寺中医医院',
		// 110102039: '北京市第二医院',
		110102040: '首都医科大学附属复兴医院',
		110102041: '北京大学第一医院',
		110102042: '中国人民解放军第三零五医院',
		110102043: '中国人民解放军第二炮兵总医院',
		110102044: '北京协和医院西院',
		110102045: '北京大学人民医院',
		110102046: '北京积水潭医院',
		110102047: '北京结核病控制研究所',
		110102048: '阜外心血管病医院',
		110102049: '首都医科大学附属北京安定医院',
		110102050: '北京市急救中心',
		110102051: '首都医科大学附属北京儿童医院',
		// 110102052: '北京鹤年堂中医医院',
		// 110118001: '北京脑血管病医院',
		// 110118002: '密云县滨河医院',
		// 110118003: '北京密云水库医院',
		// 110118004: '密云县鼓楼社区卫生服务中心',
		// 110118005: '密云县第二医院',
		// 110118006: '密云县太师屯镇社区卫生服务中心',
		// 110118007: '密云县妇幼保健院',
		// 110117001: '北京市平谷区结核病医院',
		// 110117002: '北京市平谷区金海湖中心卫生院',
		// 110117003: '北京市平谷区岳协医院',
		// 110117004: '北京市平谷区妇幼保健院',
		// 110117005: '北京市平谷区第二医院',
		// 110117006: '北京市平谷区精神病医院',
		// 110117007: '北京平谷区医院',
		// 110117008: '北京市平谷区中医院',
		// 110119001: '北京市延庆县第二医院',
		// 110119002: '北京市延庆县医院',
		// 110119003: '延庆县妇幼保健院',
		// 110116001: '北京市怀柔区精康医院',
		// 110116002: '北京市怀柔区肛肠医院',
		// 110116003: '北京市怀柔安佳医院',
		// 110116004: '北京市怀柔区第二医院',
		// 110116005: '北京市怀柔区妇幼保健院',
		// 110116006: '北京市怀柔区第一医院',
		// 110116007: '北京市怀柔区中医医院',
		// 110111001: '北京房山仁德医院',
		110111002: '北京市房山区中医医院',
		110111003: '驻京部队医院',
		// 110111004: '北京市房山区卫生学校附属医院',
		// 110111005: '北京市房山区老年病医院',
		// 110111006: '北京市房山区燕山医院',
		// 110111007: '北京市房山区精神卫生保健院',
		// 110111008: '北京矿务局房山煤矿职工医院',
		// 110111009: '中国铁道建筑总公司房山桥梁厂职...',
		// 110111010: '北京煤矿机械厂职工医院',
		// 110111011: '北京市电力设备总厂职工医院',
		// 110111012: '北京房山区鹏园医院',
		// 110111013: '北京市琉璃河水泥职工医院',
		// 110111014: '中国核工业北京四O一医院',
		110111015: '北京房山区中医院',
		// 110111016: '北京市房山区第一医院',
		// 110111017: '北京市房山区妇幼保健院',
		110111018: '北京燕化医院',
		110111019: '北京安康医院',
		// 110111020: '北京良乡优贝口腔门诊部',
		// 110114001: '北京泰康燕园康复医院',
		// 110114002: '北京建功口腔',
		// 110114003: '北京中医药大学天通苑医院',
		// 110114004: '北京沙河社区卫生服务中心',
		// 110114005: '中国医学院耳病研究中心',
		// 110114006: '北京市昌平区南口铁路医院',
		110114007: '北京市昌平区中西医结合医院',
		// 110114008: '北京市博仁医院（民营）',
		// 110114009: '北京昌平卫生学校附属医院',
		// 110114010: '北京首钢红冶钢厂职工医院',
		// 110114011: '昌平南口农场职工医院',
		// 110114012: '北京针刀总医院',
		// 110114013: '北京市昌平区东关医院',
		// 110114014: '北京市昌平区兴寿镇医院',
		// 110114015: '北京首钢股份有限公司第一线材厂...',
		// 110114016: '北京北郊肿瘤医院',
		// 110114017: '华北电力大学',
		// 110114018: '北京市二毛医院',
		// 110114019: '北京龙禧医院',
		// 110114020: '北京皇城股骨头坏死专科医院',
		// 110114021: '北京国医堂中医医院',
		// 110114022: '北京南口铁路医院',
		// 110114023: '北京市昌平区南口医院',
		// 110114024: '铁道部南口机车车辆厂医院',
		// 110114025: '北京市昌平区红十字会北郊医院',
		// 110114026: '北京市昌平区精神卫生保健院',
		// 110114027: '北京市昌平区妇幼保健院',
		// 110114028: '北京民康医院',
		// 110114029: '北京密云县中医院',
		// 110114030: '北京市昌平区中医院',
		// 110114031: '北京沙河医院',
		// 110114032: '北京市昌平区医院',
		110114033: '北京回龙观医院',
		// 110105001: '北京科瓦嘉铭口腔门诊部',
		// 110105002: '北京世纪村门诊部',
		// 110105003: '北京相国泽仁科贸西诺口腔医院',
		// 110105004: '北京伊美尚国际医学美容连锁医院',
		110105005: '北京爱耳英智眼科医院',
		// 110105006: '北京市东城区前门医院',
		// 110105008: '北京藏医院泌尿科',
		// 110105009: '黄寺美容外科医院',
		// 110105010: '朝阳区红庙医院',
		// 110105011: '朝阳区北亚医院',
		// 110105012: '北内集团总公司北京双井医院',
		// 110105013: '北京市红十字新华医院',
		// 110105014: '北京市第一轻工业总公司医院',
		// 110105015: '北京市朝阳区双龙医院',
		// 110105016: '朝阳区管庄乡卫生院',
		// 110105017: '航空工业中心第二门诊部',
		// 110105018: '北京市朝阳区世纪村门诊部',
		// 110105019: '北京十里河医院',
		// 110105020: '北京藏医院',
		// 110105021: '解放军306医院',
		// 110105022: '北京五洲妇儿医院',
		// 110105023: '北京炼焦化学厂医院',
		// 110105024: '北京市朝阳区左家庄医院',
		// 110105025: '北京市朝阳区来广营医院',
		// 110105026: '北京市朝阳区大柳树医院',
		// 110105027: '北京市朝阳区将台医院',
		// 110105028: '北京凯诺脊椎健康研究中心',
		// 110105029: '北京朝阳凌和望京医院',
		// 110105030: '北京市朝阳区常营回民医院',
		// 110105031: '北京市朝阳区管庄医院',
		// 110105032: '北京市朝阳区劲松医院',
		// 110105033: '北京市朝阳区东坝医院',
		// 110105034: '北京市朝阳区洼里医院',
		// 110105035: '北京市朝阳区小红门医院',
		// 110105036: '北京市朝阳区高碑店医院',
		// 110105037: '北京市朝阳区三里屯医院',
		// 110105038: '中国建筑材料科学研究院管庄东里...',
		// 110105039: '北京市第五建筑工程公司职工医院',
		// 110105040: '北京市城建青年沟医院',
		// 110105041: '朝阳医院第五住院部',
		// 110105042: '北京市城建祁家豁子医院',
		// 110105043: '北京市朝阳区和平医院',
		// 110105044: '北京市朝阳区安苑中医院',
		// 110105045: '北京起重机器厂朝阳广仁医院',
		// 110105046: '中国舰船研究院北京舰船医院',
		// 110105047: '北京广仁医院',
		// 110105048: '北京第三城市建设工程公司医院',
		// 110105049: '北京化工实验厂医院',
		// 110105050: '北京城建水碓子医院',
		// 110105051: '北京朝阳光华医院',
		// 110105052: '石佛营医院',
		// 110105053: '北京市朝阳区豆各庄医院',
		// 110105054: '北京青年沟医院',
		// 110105055: '北京兆维电子',
		// 110105056: '北京第七建设工程公司职工医院',
		// 110105057: '中国人民解放军总装备部后勤部中...',
		// 110105058: '北京市朝阳区金盏医院',
		// 110105059: '北京市朝阳区新源里医院',
		// 110105060: '北京市朝阳区太阳宫医院',
		// 110105061: '北京市朝阳区团结湖医院',
		// 110105062: '北京市朝阳区十八里店医院',
		// 110105063: '北京市桓兴肿瘤医院',
		// 110105064: '北京市朝阳区亚运村医院',
		// 110105065: '人民日报社医院',
		// 110105066: '中国水利水电第二工程局医院',
		// 110105067: '北京市朝阳区第三医院',
		// 110105068: '北京金台中医院',
		// 110105069: '朝阳区常营回民医院',
		// 110105070: '朝阳区康欣医院',
		// 110105071: '朝阳区曙光医院',
		// 110105072: '朝阳区平房医院',
		// 110105073: '朝阳区双桥医院',
		// 110105074: '朝阳区劲松医院',
		// 110105075: '朝阳区和平医院',
		// 110105076: '朝阳区安华医院',
		// 110105077: '朝阳区高碑店医院',
		// 110105078: '北京市朝阳区化工路医院',
		// 110105079: '朝阳区皮肤性病防治所',
		// 110105080: '北京市朝阳区三间房医院',
		// 110105081: '朝阳区东风医院',
		// 110105082: '北京朝阳三环肿瘤医院',
		// 110105083: '中国航空工业中心医院',
		// 110105084: '北京中医药大学第三附属医院',
		// 110105085: '航空工业中心医院',
		// 110105086: '北京市朝阳区第二医院',
		// 110105087: '北京藏医院',
		// 110105088: '北京市垂杨柳医院',
		// 110105089: '北京国医堂中医院',
		// 110105090: '北京现代女子医院',
		// 110105091: '首都国际机场医院',
		// 110105092: '北京市朝阳区中医医院',
		// 110105093: '北京市朝阳区妇幼保健院',
		// 110105094: '北京市惠新医院',
		110105095: '北京华信医院',
		110105096: '北京军区总医院分院',
		110105097: '民航总医院',
		110105098: '首都医科大学附属北京朝阳医院',
		110105099: '中国中医科学院望京医院',
		110105100: '卫生部中日友好医院',
		110105101: '中国人民解放军第306医院',
		110105102: '首都医科大学附属北京安贞医院',
		110105103: '首都儿科研究所附属儿童医院',
		110105104: '中国医学科学院肿瘤医院',
		// 110105105: '北京四惠中医医院',
		// 110105106: '北京芽美口腔门诊部',
		// 110105107: '北京崔玉涛育学园儿科诊所',
		// 110105108: '北京谐和美医疗',
		// 110105109: '嘉和口腔',
		// 110105110: '安贞社区卫生服务中心',
		// 110105111: '三间房社区卫生服务中心',
		// 110105112: '北京朝阳中西医结合医院',
		// 110105113: '航空总医院',
		110105114: '首都医科大学附属北京妇产医院',
		// 110105115: '北京海文妇科医院',
		// 110105116: '北京基恩医院',
		// 110105117: '北京瑞丽妇科医院',
		// 110105118: '雅之杰口腔医院',
		110105119: '北京民众眼科医院',
		110105120: '北京航天总医院',
		110108001: '北京外国语大学医院',
		// 110108002: '北京市红十字会塔院医院',
		110108003: '北京解放军309医院',
		// 110108004: '北京宝岛妇产医院',
		// 110108005: '北京康乐医院',
		// 110108006: '北京青云航空仪表有限公司职工医...',
		110108007: '北京长青肛肠医院',
		// 110108008: '北京新兴医院',
		// 110108009: '北京市海淀区苏家坨精神病院',
		// 110108010: '北京市海淀区中医院',
		// 110108011: '北京市海淀区塔院社区服务中心',
		// 110108012: '北京市海淀区志新村医院',
		// 110108013: '北京市海淀区四季青医院',
		// 110108014: '北京理工大学医院',
		// 110108015: '清华大学校医院',
		// 110108016: '北京语言文化大学医院',
		// 110108017: '北京市海淀区颐园医院',
		// 110108018: '北京市海淀区玉渊潭医院',
		// 110108019: '北京市海淀区万寿路医院',
		// 110108020: '中国兵器工业集团公司北京北方医...',
		// 110108021: '北京市红十字血液中心',
		// 110108022: '中国农业大学（东区）医院',
		// 110108023: '海淀区红十字医院',
		// 110108024: '北京市海淀区北太平庄医院',
		// 110108025: '北京市海淀区清河医院',
		// 110108026: '中国人民大学医院',
		// 110108027: '首都师范大学校医院',
		// 110108028: '海淀区甘家口医院',
		// 110108029: '京民医院',
		// 110108030: '北京市羊坊店医院',
		// 110108031: '北京市海淀区妇幼保健院',
		// 110108032: '北京市中西医结合医院',
		// 110108033: '北京市化工职业病防治院北京化工...',
		// 110108034: '北京市中关村医院',
		// 110108035: '北京市海淀区青龙桥医院',
		// 110108036: '中国人民解放军六六四00部队医...',
		// 110108037: '中国人民解放军五一一一二医院',
		// 110108038: '北京市海淀医院',
		// 110108039: '北京市第四社会福利院',
		// 110108040: '北京市上地医院',
		// 110108041: '中国人民解放军三一六医院',
		// 110108042: '北京水利医院',
		110108043: '北京老年医院',
		110108044: '航天中心医院',
		110108045: '中国人民解放军二六一医院',
		110108046: '北京大学第三医院',
		110108047: '解放军总医院第二附属医院',
		110108048: '中国人民解放军空军总医院',
		110108049: '海军总医院',
		110108050: '解放军总医院第一附属医院',
		110108051: '北京世纪坛医院',
		110108052: '中国中医科学院西苑医院',
		110108053: '北京大学口腔医院',
		110108054: '北京肿瘤医院',
		110108055: '北京大学第六医院',
		110108056: '北京市海淀区北方肿瘤医院',
		// 110108057: '海淀区蓟门里医院',
		// 110108058: '北京市海淀区北下关医院',
		// 110108059: '北京因苏林糖尿病医院',
		// 110108060: '北京水建二医院',
		// 110108061: '北京城建集团有限责任公司第四医...',
		// 110108062: '北京市海淀区八里庄医院',
		// 110108063: '北京市海淀区北蜂窝医院',
		// 110108064: '北京军区炮兵海淀区香山骨科医院',
		// 110108065: '北京核工业二院',
		// 110108066: '北京科技大学医院',
		// // 110108067: '北京外国语学院医院',
		// // 110108068: '北京邮电大学医院',
		// 110108069: '北京航空航天大学校医院',
		// 110108070: '北京香山医院',
		// 110108071: '北京市清河医院',
		// 110108072: '北京东升镇社区卫生服务中心',
		// 110108073: '北京四季青镇社区卫生服务中心',
		// 110108074: '北京羊坊店社区卫生服务中心',
		// 110108075: '北京中国人民大学社区卫生服务中...',
		// 110108076: '北京聂各庄社区卫生服务站',
		// 110108077: '北京林业大学社区卫生服务中心',
		// 110108078: '北京航材院社区卫生服务站',
		// 110108079: '北京大学医学部社区卫生服务中心',
		// 110108080: '北京中国气象局社区卫生服务站',
		// 110108081: '北京北方社区卫生服务站',
		// 110108082: '北京体育大学社区卫生服务中心',
		// 110108083: '北京中国农业大学西区社区卫生服...',
		// 110108084: '北京清华大学社区卫生服务中心',
		// 110108085: '北京北京理工大学社区卫生服务中...',
		// 110108086: '北京新材社区卫生服务站',
		// 110108087: '北京第316医院',
		// 110108088: '北京华安中医医院',
		// 110108089: '烧伤创伤专科',
		// 110108090: '南山医馆',
		// 110108091: '中美北京癫痫中心',
		// 110108092: '博爱堂中医门诊',
		// 110108093: '北京凤凰妇婴医院',
		110108094: '北京整形美容医院',
		// 110108095: '中国气象局医院',
		// 110108096: '海淀区长青医院',
		// 110108097: '北京李春光美之星医疗美容诊所',
		// 110108098: '北京道合肛肠医院',
		// 110108099: '中共中央党校医院',
		// 110108100: '海淀区永丰卫生院',
		// 110108101: '海淀区温泉乡中心医院',
		// 110108102: '海淀区苏家坨中心卫生院',
		// 110108103: '海淀区松堂医院',
		// 110108104: '海淀区四季青乡中心卫生院',
		// 110108105: '海淀区聂各庄乡卫生院',
		// 110108106: '海淀区京西医院',
		// 110108107: '海淀区皇苑医院',
		// 110108108: '海淀区东北旺乡中心医院',
		// 110108109: '北京中科院自动化研究所中自医院',
		110108110: '中国人民解放军总医院',
		110108111: '解放军261医院干细胞治疗中心',
		110108112: '航天中心医院(原721医院)',
		// 110108113: '首都师范大学社区卫生服务中心',
		// 110108114: '北京甘家口社区卫生服务中心',
		// 110108115: '北京大学医院',
		// 110108116: '北大口腔医院',
		// 110107001: '中国瑞达系统装备公司瑞达医院',
		// 110107002: '北京市石景山区八角医院',
		// 110107003: '北京市石景山医院皮肤性病治疗中...',
		// 110107004: '石景山区妇幼保健院',
		// 110107005: '北京市石景山医院',
		// 110107006: '清华大学玉泉医院',
		110107007: '中国中医科学院眼科医院',
		110107008: '北京朝阳医院京西院区',
		110107009: '北京八大处整形美容外科医院',
		110107010: '北京联科中医肾病医院',
		110107011: '中国医学科学院整形外科医院',
		// 110112001: '北京台湖社区服务中心',
		// 110112002: '北京大杜社社区服务中心',
		// 110112003: '北京漷县社区服务中心',
		// 110112004: '北京京都博爱医院',
		// 110112005: '解放军263医院',
		110112006: '北京中医药大学东直门医院东区',
		// 110112007: '北京市通州区永乐店卫生院',
		110112008: '北京胸科医院',
		// 110112009: '北京市通州区东辰医院',
		// 110112010: '核工业北京化工冶金研究院职工医...',
		// 110112011: '北京市通州区第二医院',
		// 110112012: '北京市通州区老年病医院',
		// 110112013: '中国人民解放军第二六三医院',
		// 110112014: '北京市通州区中西医结合骨伤医院',
		// 110112015: '北京市通州区新华医院',
		// 110112016: '北京市通州区妇幼保健院',
		// 110112017: '北京市通州区中医医院',
		110112018: '首都医科大学附属北京潞河医院',
		110112019: '北京胸部肿瘤结核病医院',
		// 110109001: '北京永泰肛肠医院',
		// 110109002: '北京市门头沟区清水镇卫生院',
		// 110109003: '北京京煤集团杨坨煤矿职工医院',
		// 110109004: '北京京煤集团木城涧煤矿王平医院',
		// 110109005: '煤炭工业部职业医学研究所',
		// 110109006: '煤炭科学研究总院石龙医院',
		// 110109007: '北京京煤集团木城涧煤矿职工医院',
		// 110109008: '北京京煤集团木城涧煤矿医院',
		// 110109009: '北京市门头沟区中医骨伤科医院',
		// 110109010: '北京市门头沟区斋堂医院',
		// 110109011: '北京市门头沟区医院',
		// 110109012: '北京市门头沟区中医院',
		// 110109013: '北京市门头沟区妇幼保健院',
		110109014: '北京京煤集团总医院',
		// 110113001: '北京市顺义区精神病疗养院',
		// 110113002: '北京市顺义区副食品公司医院',
		// 110113003: '北京市顺义区传染病医院',
		// 110113004: '北京市顺义区结核病防治所',
		// 110113005: '北京市顺义区第三医院',
		// 110113006: '北京市顺义区第二医院',
		// 110113007: '北京市顺义区医院',
		// 110113008: '中国人民解放军六六0五五医院',
		// 110113009: '中国人民解放军五一一一六医院',
		// 110113010: '北京潮白河骨伤医院',
		// 110113011: '北京市顺义区空港医院',
		// 110113012: '北京市顺义区妇幼老年保健院',
		// 110113013: '北京市顺义区中医院',
		// 310101000: '淮海中路地段医院',
		// 310101001: '卢湾区五里桥街道社区卫生服务中...',
		// 310101002: '瑞金二路地段医院',
		// 310101003: '卢湾区顺昌医院',
		// 310101004: '打浦桥街道社区卫生服务中心',
		// 310101005: '上海市市政医院',
		// 310101006: '上海市卢湾区香山中医医院',
		// 310101007: '上海市卢湾区东南医院',
		// 310101008: '上海卢湾区妇幼保健院',
		// 310101009: '上海交通大学附属瑞金医院卢湾分...',
		// 310101010: '上海市卢湾区精神卫生中心',
		310101011: '上海曙光医院',
		310101012: '上海交通大学附属瑞金医院',
		// 310101581: '上海百瑞肿瘤门诊部',
		// 310101582: '上海豫园街道社区卫生服务中心',
		// 310101583: '医博肛肠连锁医院',
		// 310101584: '上海老西门街道社区卫生服务中心',
		// 310101585: '上海小东门街道社区卫生服务中心',
		// 310101586: '上海百瑞肿瘤门诊部',
		// 310101587: '上海豫园街道社区卫生服务中心',
		// 310101588: '医博肛肠连锁医院',
		// 310101589: '上海老西门街道社区卫生服务中心',
		// 310101590: '上海小东门街道社区卫生服务中心',
		// 310101591: '上海淮海中路街道社区卫生服中心',
		// 310101592: '上海市黄浦区南京东路地段医院',
		// 310101593: '上海市黄浦区肿瘤防治院',
		// 310101594: '上海拜尔口腔门诊部',
		// 310101595: '上海百瑞肿瘤门诊部',
		// 310101596: '上海豫园街道社区卫生服务中心',
		// 310101597: '医博肛肠连锁医院',
		// 310101598: '上海老西门街道社区卫生服务中心',
		// 310101599: '上海小东门街道社区卫生服务中心',
		// 310101600: '上海淮海中路街道社区卫生服中心',
		// 310101601: '上海市黄浦区南京东路地段医院',
		// 310101602: '上海市黄浦区肿瘤防治院',
		// 310101603: '上海拜尔口腔门诊部',
		// 310101604: '南京东路地段医院',
		// 310101605: '黄浦区推拿门诊部',
		// 310101606: '外滩地段医院',
		// 310101607: '上海豫园地段医院',
		// 310101608: '上海市黄浦区老西门地段医院',
		// 310101609: '上海小东门地段医院',
		// 310101610: '上海市强生职工医院',
		// 310101611: '半淞园地段医院',
		// 310101612: '黄浦区精神卫生中心',
		// 310101613: '黄浦南市区精神卫生中心',
		// 310101614: '区结核病病院',
		// 310101615: '华仁医院',
		// 310101616: '上海市第二人民医院',
		// 310101617: '上海市黄浦区精神卫生中心',
		// 310101618: '上海市黄浦区传染病医院',
		// 310101619: '黄浦区肿瘤防治所',
		// 310101620: '黄浦区妇幼保健院',
		// 310101621: '上海港医院',
		// 310101622: '上海市黄浦区中心医院',
		// 310101623: '上海市黄浦区中西医结合医院',
		310101624: '上海长征医院',
		310101625: '上海交通大学医学院附属仁济医院',
		310101626: '上海交通大学医学院附属第九人民...',
		310101627: '复旦大学附属妇产科医院',
		// 310104158: '上海真安堂中医门诊部',
		// 310104159: '上海长桥街道社区卫生服务中心',
		// 310104160: '上海徐家汇街道社区卫生服务中心',
		// 310104161: '上海康健街道社区卫生服务中心',
		// 310104162: '上海天平街道社区卫生服务中心',
		// 310104163: '上海田林街道社区卫生服务中心',
		// 310104164: '上海虹梅街道社区卫生服务中心',
		// 310104165: '上海徐汇区日月星养老院',
		// 310104166: '上海德种口腔门诊部',
		// 310104167: '复旦大学附属眼耳鼻喉科医院浦东...',
		// 310104168: '上海市徐汇区天平路地段医院',
		// 310104169: '上海市徐汇区田林路地段医院',
		// 310104170: '上海海工医院（民营）',
		// 310104171: '上海万众整形医院',
		// 310104172: '上海洛伊克巴德门诊部',
		// 310104173: '上海市徐汇区新乐地段医院',
		// 310104174: '上海市徐汇区牙病防治所',
		// 310104175: '美年体检',
		// 310104176: '上海沐阳医院',
		// 310104177: '徐汇区虹梅地段医院',
		// 310104178: '徐汇区凌云街道社区卫生服务中心',
		// 310104179: '上海市公安消防总队职工医院',
		// 310104180: '上海市徐汇区宛平地段医院',
		// 310104181: '上海市徐汇区日晖地段医院',
		// 310104182: '上海市徐汇区妇幼保健所',
		// 310104183: '徐汇区大华医院',
		// 310104184: '徐汇区日晖地段医院',
		// 310104185: '徐汇区精神卫生中心龙华地段医院',
		// 310104186: '上海远洋医院',
		// 310104187: '上海市第八人民医院',
		// 310104188: '上海市徐汇区中心医院',
		310104189: '上海市精神卫生中心',
		310104190: '上海市针灸经络研究所医疗门诊部',
		310104191: '上海市气功研究所医疗门诊部',
		310104192: '复旦大学附属华山医院',
		310104193: '上海中医药大学附属龙华医院',
		310104194: '复旦大学附属儿科医院徐汇院区',
		310104195: '上海交通大学附属第六人民医院',
		310104196: '复旦大学附属肿瘤医院',
		310104197: '复旦大学附属眼耳鼻喉科医院',
		310104198: '复旦大学附属中山医院',
		310104199: '上海交通大学附属胸科医院',
		310104200: '上海市徐汇区精神卫生中心',
		310104201: '中国福利会国际和平妇幼保健院',
		310105426: '中国人民解放军第八五医院',
		310105427: '武警上海总队医院口腔科',
		// 310105428: '上海西郊骨科医院',
		// 310105429: '上海市长宁区男科医院',
		// 310105430: '上海美莱医疗美容门诊部',
		310105431: '武警上海市总队医院',
		310105432: '中国人民解放军第455医院分院',
		// 310105433: '上海周桥街道社区卫生服务中心',
		// 310105434: '上海江苏街道社区卫生服务中心',
		// 310105435: '上海仙霞街道社区卫生服务中心',
		// 310105436: '上海华阳街道社区卫生服务中心',
		// 310105437: '上海虹桥街道社区卫生服务中心',
		// 310105438: '上海程家桥街道社区卫生服务中心',
		// 310105439: '上海长宁区妇幼保健院',
		// 310105440: '上海普瑞眼科医院',
		310105441: '中国人民解放军第455医院',
		310105442: '武警上海市总队医院口腔中心',
		310105443: '上海口腔康复网',
		310105444: '上海华山东方乳房专科医院',
		310105445: '中国人民解放军第四五五医院分院',
		310105446: '上海85医院',
		310105447: '上海解放军455医院',
		310105448: '上海八五医院',
		// 310105449: '华阳地段医院',
		// 310105450: '北新泾地段医院',
		// 310105451: '上海市长宁区仙霞地段医院',
		// 310105452: '上海市长宁区华阳地段医院',
		// 310105453: '上海市长宁区程家桥地段医院',
		// 310105454: '上海市长宁区武夷地段医院',
		// 310105455: '上海市长宁区新华地段医院',
		// 310105456: '上海市长宁区北新泾地段医院',
		// 310105457: '上海市长宁区遵义地段医院',
		// 310105458: '上海市长宁区疾病预防控制中心',
		// 310105459: '民航上海医院',
		// 310105460: '上海市东方乳腺疾病医院',
		// 310105461: '上海市长宁区区慢性病防治院',
		// 310105462: '上海市长宁区天山中医医院',
		// 310105463: '中山医院肿瘤中心',
		// 310105464: '上海市长宁区精神卫生中心',
		// 310105465: '上海市长宁区妇幼保健院',
		310105466: '上海市长宁区光华中西医结合医院',
		// 310105467: '中国人民武装警察部队上海市总队...',
		// 310105468: '长宁区同仁医院',
		// 310105469: '长宁区妇幼保健院',
		// 310105470: '长宁区中心医院',
		// 310105471: '上海电力医院',
		310105472: '中国人民解放军第四五五医院',
		310105473: '上海市皮肤病性病医院',
		// 310106512: '上海天目西路街道社区卫生服务中...',
		// 310106513: '上海太乙堂中医门诊部',
		// 310106514: '上海盛德护理院',
		// 310106515: '闸北区北站医院',
		// 310106516: '上海申江医院',
		// 310106517: '上海市闸北区市北医院',
		// 310106518: '闸北区海宁地段医院',
		310106519: '同济大学附属口腔医院',
		// 310106520: '上海市闸北区北站街道社区卫生服...',
		// 310106521: '上海市闸北区宝山地段医院',
		// 310106522: '上海市闸北区大宁社区卫生服务中...',
		// 310106523: '上海市闸北区天目西街道社区卫生...',
		// 310106524: '上海市闸北区华山医院永和分院',
		// 310106525: '上海市闸北区彭浦地段医院',
		// 310106526: '上海市闸北区芷江西地段医院',
		// 310106527: '上海市闸北区万荣地段医院',
		// 310106528: '上海市闸北区市北医院',
		// 310106529: '上海市闸北区精神卫生中心',
		// 310106530: '上海市闸北区中心医院',
		// 310106531: '上海市闸北区民政第三精神病院',
		310106532: '上海市中医医院',
		310106533: '上海市第十人民医院',
		// 310106564: '上海德伦口腔门诊部',
		// 310106565: '静安区老年医院',
		// 310106566: '静安区精神卫生中心',
		// 310106567: '上海市公惠医院',
		// 310106568: '上海市静安区石门二路地段医院',
		// 310106569: '上海市静安区曹家渡地段医院',
		// 310106570: '上海市南京西路地段医院',
		// 310106571: '上海市静安区精神卫生中心',
		// 310106572: '上海邮电医院',
		// 310106573: '上海市静安区老年医院',
		// 310106574: '上海市静安区中心医院',
		310106575: '上海华东医院',
		310106576: '上海市第一妇婴保健院',
		310106577: '上海市眼病防治中心',
		310106578: '上海市儿童医院',
		// 310106579: '上海石门二路街道社区卫生服务中...',
		// 310106580: '上海南京西路街道社区卫生服务中...',
		// 310107202: '上海普陀牙科医院',
		// 310107203: '上海宜川街道社区卫生服务中心',
		// 310107204: '上海长寿街道社区卫生服务中心',
		// 310107205: '上海长风街道白玉社区卫生服务中...',
		// 310107206: '上海曹杨街道社区卫生服务中心',
		// 310107207: '上海宜川养老院',
		// 310107209: '上海白癜风专科医院',
		// 310107210: '上海环球健康管理中心',
		// 310107211: '长风社区卫生服务中心',
		// 310107212: '上海肤康医院',
		// 310107213: '上海市普陀区甘泉地段医院',
		// 310107214: '上海市普陀区长风地段医院',
		// 310107215: '普陀区老年医院',
		// 310107216: '利群医院',
		// 310107217: '上海普陀区中医医院',
		// 310107218: '上海市普陀区人民医院体检中心',
		// 310107219: '上海市普陀区中心医院',
		// 310107220: '上海普陀区妇婴保健院',
		310107221: '同济大学附属同济医院',
		310109381: '上海欧阳路街道社区卫生服务中心',
		310109382: '上海市劳动局中心医院',
		310109383: '上海肿瘤生物治疗中心',
		// 310109384: '上海太平洋口腔医院（民营）',
		// 310109385: '上海市虹口区乍浦地段医院',
		// 310109386: '上海市虹口区欧阳地段医院',
		// 310109387: '赤峰医院',
		// 310109388: '上海蓝鹰医院',
		// 310109389: '上海建材治疗所',
		// 310109390: '上海市赤峰医院',
		// 310109391: '上海东华医院',
		// 310109392: '上海市虹口区新港路地段医院',
		// 310109393: '上海市虹口区嘉兴地段医院',
		// 310109394: '上海市虹口区提篮桥地段医院',
		// 310109395: '上海市虹口区江湾医院',
		// 310109396: '上海航道医院',
		// 310109397: '上海市海员医院',
		// 310109398: '上海建工医院',
		// 310109399: '上海市虹口区妇幼保健院',
		// 310109400: '上海市中西医结合医院',
		// 310109401: '上海市曲阳医院',
		// 310109402: '上海市虹口区精神卫生中心',
		// 310109403: '上海市第一人民医院分院（第四人...',
		// 310109404: '上海市东医院',
		310109405: '上海市第一人民医院',
		310109406: '岳阳医院',
		// 310110222: '上海市五角场街道社区卫生服务中...',
		// 310110223: '上海市杨浦区沪东老年护理院',
		// 310110224: '上海市杨浦区延吉地段医院',
		// 310110225: '杨浦区四平地段医院',
		// 310110226: '四平社区卫生服务中心',
		// 310110227: '上海杨浦区定海地段医院',
		// 310110228: '上海杨浦区凤城地段医院',
		// 310110229: '上海市杨浦区江浦地段医院',
		// 310110230: '上海市杨浦区殷行地段医院',
		// 310110231: '上海市杨浦区妇幼保健院',
		// 310110232: '上海市杨浦区老年医院',
		// 310110233: '上海市杨浦区中医医院',
		// 310110234: '上海市杨浦区控江医院',
		// 310110235: '上海市杨浦区精神卫生中心',
		// 310110236: '上海市杨浦区民星地段医院',
		// 310110237: '上海市杨浦区安图医院',
		// 310110238: '杨浦区工人医院',
		// 310110239: '上海市杨浦区中心医院',
		// 310110240: '第二军医大学附属东方肝胆外科医...',
		310110241: '第二军医大学附属长海医院',
		310110242: '同济大学附属肺科医院',
		310110243: '上海交通大学医学院附属新华医院',
		// 310110244: '上海杨浦区日月星养老院',
		310110245: '上海东方肝胆医院',
		310110246: '杨浦区市东医院',
		// 310110247: '上海市杨浦区平凉路街道医院',
		// 310110248: '上海控江社区卫生服务中心',
		// 310110249: '上海长白社区卫生服务中心',
		// 310110250: '上海平凉社区卫生服务中心',
		// 310110251: '上海大桥社区卫生服务中心',
		// 310110252: '上海市广群医院（民营）',
		// 310110253: '上海市杨浦区老年医院',
		// 310112474: '上海南亚医院',
		// 310112475: '紫兴精神病康复院',
		// 310112476: '吴泾镇卫生院',
		// 310112477: '上海市颛桥镇卫生院',
		// 310112478: '上海市华漕镇卫生院',
		// 310112479: '上海市江川地段医院',
		// 310112480: '上海市浦江镇卫生院',
		// 310112481: '上海市闵行区妇幼保健所',
		// 310112482: '马桥镇卫生院',
		// 310112483: '龙柏地段医院',
		// 310112484: '上海市闵行区牙病防治所',
		// 310112485: '上海市闵行区精神卫生中心',
		// 310112486: '上海市民政第一精神病院',
		// 310112487: '上海市闵行区吴泾医院',
		// 310112488: '上海市闵行区传染病医院',
		// 310112489: '上海市闵行区中心医院',
		// 310112490: '上海市闵行区肿瘤医院',
		// 310112491: '复旦大学附属上海市第五人民医院',
		// 310112492: '上海市化工职业病防治院',
		310112493: '复旦大学附属儿科医院',
		// 310112494: '上海虹桥医院精神科',
		// 310112495: '上海虹桥医院癫痫专病',
		// 310112496: '上海新起点康复医院',
		// 310112497: '上海江川社区卫生服务中心',
		// 310112498: '上海莘庄社区卫生服务中心',
		// 310112499: '上海龙柏社区卫生服务中心',
		// 310112500: '上海七宝社区卫生服务中心',
		// 310112501: '上海梅陇社区卫生服务中心',
		// 310112502: '上海颛桥社区卫生服务中心',
		// 310112503: '上海浦江社区卫生服务中心',
		// 310112504: '上海华漕社区卫生服务中心',
		// 310112505: '上海马桥社区卫生服务中心',
		// 310112506: '吴泾镇卫生院',
		// 310112507: '上海市军民护理院（民营）',
		310112508: '上海华东医院闵行门诊部',
		// 310112509: '上海市闵行区华坪地段医院',
		// 310112510: '上海市闵行区航华门诊部',
		310112511: '上海康贝佳口腔医院',
		// 310113085: '上海海滨医院',
		// 310113086: '上海宝山部队医院',
		310113087: '解放军上海第一直属增高总院',
		// 310113088: '双鸭山矿务局宝山矿医院',
		// 310113089: '上海第一人民医院宝山分院',
		// 310113090: '解放军91860部队医院',
		// 310113091: '上海第一钢铁厂职工医院',
		// 310113092: '上海广福医院',
		// 310113093: '上海海淞医院',
		// 310113094: '宝山区杨行镇社区卫生服务中心',
		// 310113095: '上海罗泾镇社区卫生服务中心',
		// 310113096: '月浦地段医院',
		// 310113097: '民办建工老年护理医院',
		// 310113098: '市民政老年医院',
		// 310113099: '张庙街道长江路社区卫生服务中心',
		// 310113100: '上海市宝山区罗南镇卫生院',
		// 310113101: '上海市凇南地段医院',
		// 310113102: '泗塘地段医院',
		// 310113103: '宝山区盛桥地段医院',
		// 310113104: '宝山区月浦地段医院',
		// 310113105: '上海市宝山区精神卫生中心',
		// 310113106: '宝山区大场医院',
		// 310113107: '宝山区罗店医院',
		// 310113108: '上海市公安局安康医院',
		// 310113109: '宝山区仁和医院',
		// 310113110: '宝山区一钢医院',
		// 310113111: '上海中冶职工医院',
		// 310113112: '上海市宝山区罗店医院',
		// 310113113: '宝山区传染病医院',
		// 310113114: '上海市嘉定区安亭医院',
		// 310113115: '上海市宝山区中心医院',
		// 310113116: '宝山区老年护理院',
		// 310113117: '宝山区精神卫生中心',
		// 310113118: '宝山区妇幼保健所',
		// 310113119: '上海交通大学医学院附属第三人民...',
		// 310113120: '宝山区吴淞中心医院',
		// 310113121: '哈尔滨哈二院',
		// 310114013: '上海青春痘专科医院',
		// 310114014: '上海菊园社区卫生服务中心',
		// 310114015: '上海性病医院',
		// 310114016: '嘉定区中心医院',
		// 310114017: '上海嘉定区迎园医院',
		// 310114018: '上海安国医院',
		// 310114019: '嘉定区南翔镇社区卫生服务中心',
		// 310114020: '嘉定区民办大宅综合门诊部',
		// 310114021: '嘉定区菊院新区社区卫生服务中心',
		// 310114022: '上海市嘉定区真新社区卫生服务中...',
		// 310114023: '嘉定区嘉定镇街道医院',
		// 310114024: '马陆镇卫生院',
		// 310114025: '嘉定工业区社区卫生服务中心',
		// 310114026: '上海市安亭镇社区卫生服务中心',
		// 310114027: '嘉定区牙病防治所',
		// 310114028: '上海市嘉定区南翔医院',
		// 310114029: '嘉定区中心医院',
		// 310114030: '上海市嘉定区中医医院',
		// 310114031: '上海市嘉定区妇幼保健院',
		// 310115273: '上海浦东新区日月星敬老院',
		// 310115274: '上海中医药大学附属曙光医院（张...',
		// 310115275: '上海老港社区卫生服务中心',
		// 310115276: '上海书院社区卫生服务中心',
		// 310115277: '上海祝桥社区卫生服务中心',
		// 310115278: '上海六灶社区卫生服务中心',
		// 310115279: '上海迎博社区卫生服务中心',
		// 310115280: '上海凌桥社区卫生服务中心',
		// 310115281: '上海高桥社区卫生服务中心',
		// 310115282: '上海王港社区卫生服务中心',
		// 310115283: '上海合庆社区卫生服务中心',
		// 310115284: '上海唐镇社区卫生服务中心',
		// 310115285: '上海三林社区卫生服务中心',
		// 310115286: '上海上钢社区卫生服务中心',
		// 310115287: '上海潍坊社区卫生服务中心',
		// 310115288: '上海光博士医疗美容医院',
		// 310115289: '高行镇卫生院',
		// 310115290: '杨思地段医院',
		// 310115291: '上海市浦东新区崂山西路地段医院',
		// 310115292: '沪东地段医院',
		// 310115293: '上海晨仪哮喘研究所',
		// 310115294: '上海中医药大学附属龙华医院浦东...',
		// 310115295: '浦东新区精神卫生中心',
		// 310115296: '老港镇卫生院',
		// 310115297: '上海市东海老年护理医院',
		// 310115298: '陆家嘴社区卫生服务中心',
		// 310115299: '南码头社区卫生服务中心',
		// 310115300: '上海市龙华浦东医院',
		// 310115301: '上海尚德医院',
		// 310115302: '上海浦东新区公利医院',
		// 310115303: '上海市浦东新区精神卫生中心',
		// 310115304: '上海市浦东新区中医院',
		// 310115305: '上海市浦东新区杨思地段医院',
		// 310115306: '神光心理咨询中心',
		// 310115307: '上海市唐镇社区卫生院',
		// 310115308: '滨海旅游度假区卫生院',
		// 310115309: '芦潮港镇社区卫生服务中心',
		// 310115310: '上海市宣桥镇社区卫生服务中心',
		// 310115311: '上海市浦东新区民办龚路精神病康...',
		// 310115312: '上海市浦东新区孙桥镇卫生院',
		// 310115313: '上海浦东新区张江社区卫生服务中...',
		// 310115314: '上海市浦东新区张江社区卫生服务...',
		// 310115315: '上海市浦东新区凌桥镇卫生院',
		// 310115316: '上海浦东新区洋泾社区卫生服务中...',
		// 310115317: '东海农场职工医院',
		// 310115318: '上海浦东新区迎博社区卫生服务站',
		// 310115319: '上海南汇区祝桥镇光明医院',
		// 310115320: '上海市南汇区三墩镇卫生院',
		// 310115321: '上海市南汇区坦直镇卫生院',
		// 310115322: '上海市浦东新区花木社区卫生服务...',
		// 310115323: '沪东造船厂职工医院',
		// 310115324: '金桥地段医院',
		// 310115325: '机场镇卫生院',
		// 310115326: '高行镇卫生院',
		// 310115327: '沪东地段医院',
		// 310115328: '潍坊地段医院',
		// 310115329: '周家渡地段医院',
		// 310115330: '塘桥地段医院',
		// 310115331: '陆家嘴社区卫生服务中心',
		// 310115332: '浦东新区联洋地段医院',
		// 310115333: '上海市浦东新区金杨地段医院',
		// 310115334: '高东社区卫生服务中心',
		// 310115335: '上海市浦东新区六里桥地段医院',
		// 310115336: '上海市浦东新区民办安达医院',
		// 310115337: '上海市浦东新区外高桥镇卫生院',
		// 310115338: '上海市东海老年护理医院',
		// 310115339: '上海市浦东新区黄楼镇卫生院',
		// 310115340: '上海市浦东新区合庆镇卫生院',
		// 310115341: '杨思地段医院',
		// 310115342: '上海市南汇区新场镇社区卫生服务...',
		// 310115343: '上海市浦东新区蔡路镇卫生院',
		// 310115344: '上海市浦东区六团镇卫生院',
		// 310115345: '上海市南汇区泥城镇社区卫生服务...',
		// 310115346: '上海市六灶镇社区卫生院',
		// 310115347: '上海市南汇区大团镇卫生院',
		// 310115348: '上海市南汇区万祥镇卫生院',
		// 310115349: '上钢地段医院',
		// 310115350: '书院镇卫生院',
		// 310115351: '盐仓镇卫生院',
		// 310115352: '老港镇卫生院',
		// 310115353: '新港镇卫生院',
		// 310115354: '上海港联门诊部',
		// 310115355: '上海南汇区周浦医院',
		// 310115356: '上海儿童医学中心',
		// 310115357: '浦东新区妇幼保健院',
		// 310115358: '浦东新区中医医院',
		// 310115359: '浦东新区精神卫生中心',
		// 310115360: '上海市南汇县结核病防治院',
		// 310115361: '上海市民政第二精神病院',
		// 310115362: '上海长航医院',
		// 310115363: '上海第三钢铁厂职工医院',
		// 310115364: '上海市南汇区精神卫生中心',
		// 310115365: '上海市南汇南华医院',
		// 310115366: '浦东新区中医医院罗山分院',
		// 310115367: '长江航运医院',
		310115368: '上海市第七人民医院',
		// 310115369: '上海市浦东新区公利医院',
		// 310115370: '上海市南汇区中心医院',
		// 310115371: '上海市南汇区光明中医院',
		// 310115372: '浦东新区肺科医院',
		310115373: '上海东方医院',
		// 310115374: '浦东新区人民医院',
		// 310115375: '浦东新区浦南医院',
		// 310115376: '浦东新区传染病医院',
		// 310115377: '南市区妇幼保健院',
		// 310115378: '南市区肿瘤防治院',
		310115379: '上海仁济医院',
		// 310115380: '上海清沁医疗美容门诊部',
		// 310116407: '上海朱泾社区卫生服务中心',
		// 310116408: '上海市金山区朱泾地段医院',
		// 310116409: '上海市金山区山阳镇卫生院',
		// 310116410: '上海金山区吕巷镇卫生院',
		// 310116411: '上海市金山区干巷镇卫生院',
		// 310116412: '上海市金山区众仁老年护理医院',
		// 310116413: '上海市金山卫镇卫生院',
		// 310116414: '金山区枫泾医院',
		// 310116415: '上海市第六人民医院金山分院',
		// 310116416: '上海市金山区精神卫生中心',
		// 310116417: '上海市金山区疾病防治控制中心上...',
		// 310116418: '上海市金山区中心医院',
		// 310116419: '上海民办钱圩医院',
		// 310116420: '上海市金山区枫泾镇兴塔社区卫生...',
		// 310116421: '上海市金山区朱泾镇新农社区卫生...',
		310116422: '上海市金山区亭林医院',
		// 310116423: '上海市金山区枫镇泾社区卫生服务...',
		310116424: '上海市复旦大学附属金山医院',
		310116425: '上海市公共卫生中心',
		// 310117254: '上海佘山镇社区卫生服务中心分中...',
		// 310117255: '上海中山街道社区卫生服务中心',
		// 310117256: '上海九亭街道社区卫生服务中心',
		// 310117257: '上海岳阳街道社区卫生服务中心',
		// 310117258: '上海市松江区洞泾镇卫生服务中心',
		// 310117259: '上海市松江区新桥镇社区卫生服务...',
		// 310117260: '上海市松江区车墩镇卫生院',
		// 310117261: '上海市松江区中山地段医院',
		// 310117262: '上海市松江区新浜镇卫生院',
		// 310117263: '上海市松江区叶榭镇卫生院',
		// 310117264: '上海市松江区方松地段医院',
		// 310117265: '上海市松江区佘山镇卫生院',
		// 310117266: '上海市松江区九亭医院',
		// 310117267: '上海市松江区妇幼保健院',
		// 310117268: '上海市松江区泗泾医院',
		// 310117269: '上海市松江区乐都医院',
		// 310117270: '上海市松江区中心医院',
		// 310117271: '上海市松江区精神卫生中心',
		// 310117272: '上海市松江区方塔中医医院',
		// 310118534: '上海赵巷镇社区卫生服务中心',
		// 310118535: '上海重固镇社区卫生服务中心',
		// 310118536: '上海徐泾镇社区卫生服务中心',
		// 310118537: '上海白鹤社区卫生服务中心',
		// 310118538: '上海练塘镇社区卫生服务中心',
		// 310118539: '上海金泽镇社区卫生服务中心',
		// 310118540: '练塘镇卫生院',
		// 310118541: '徐泾镇卫生院',
		// 310118542: '上海市青浦县结核病防治医院',
		// 310118543: '华新镇卫生院',
		// 310118544: '徐泾镇卫生院',
		// 310118545: '重固镇卫生院',
		// 310118546: '练塘镇卫生院',
		// 310118547: '上海青东农场医院',
		// 310118548: '青浦区宜川地段医院',
		// 310118549: '朱家角镇卫生院',
		// 310118550: '金泽镇卫生院',
		// 310118551: '商榻镇卫生院',
		// 310118552: '西岑镇卫生院',
		// 310118553: '赵屯镇卫生院',
		// 310118554: '白鹤镇卫生院',
		// 310118555: '赵巷镇卫生院',
		// 310118556: '盈浦街道社区卫生服务中心',
		// 310118557: '青浦县万寿医院',
		// 310118558: '青浦区妇幼保健所',
		// 310118559: '上海市青浦区朱家角人民医院',
		// 310118560: '上海市青浦区中医院',
		// 310118561: '复旦大学附属中山医院青浦分院',
		// 310118562: '上海市青浦区精神卫生中心',
		// 310118563: '上海市青浦区中心医院',
		// 310120032: '上海五四社区卫生服务中心',
		// 310120033: '上海海湾镇社区卫生服务中心',
		// 310120034: '上海平安社区卫生服务中心',
		// 310120035: '上海四团镇社区卫生服务中心',
		// 310120036: '上海塘外社区卫生服务中心',
		// 310120037: '上海钱桥社区卫生服务中心',
		// 310120038: '上海青村镇社区卫生服务中心',
		// 310120039: '上海新寺社区卫生服务中心',
		// 310120040: '上海胡桥社区卫生服务中心',
		// 310120041: '上海柘林镇社区卫生服务中心',
		// 310120042: '上海泰日社区卫生服务中心',
		// 310120043: '上海金汇镇社区卫生服务中心',
		// 310120044: '上海邬桥社区卫生服务中心',
		// 310120045: '上海庄行镇社区卫生服务中心',
		// 310120046: '上海西渡社区卫生服务中心',
		// 310120047: '上海市奉贤区海湾医院',
		// 310120048: '上海市奉贤区青村镇社区卫生服务...',
		// 310120049: '南桥地段医院',
		// 310120050: '星火农场职工医院',
		// 310120051: '上海奉民门诊部',
		// 310120052: '上海市燎原农场职工医院',
		// 310120053: '南桥镇贝港门诊部',
		// 310120054: '南桥地段医院',
		// 310120055: '上海市奉贤区齐贤卫生院',
		// 310120056: '上海市奉贤区邬桥社区卫生服务中...',
		// 310120057: '上海市奉贤区头桥镇卫生院',
		// 310120058: '上海市奉贤区钱桥镇卫生院',
		// 310120059: '上海市奉贤区西渡镇卫生院',
		// 310120060: '上海市奉贤区柘林镇卫生院',
		// 310120061: '上海市奉贤区胡桥镇卫生院',
		// 310120062: '上海市奉献区新寺镇卫生院',
		// 310120063: '上海市奉贤区庄行镇卫生服务中心',
		// 310120064: '上海市奉贤区青村镇卫生院',
		// 310120065: '上海市奉贤区海湾镇卫生院',
		// 310120066: '上海市奉贤区光明镇卫生院',
		// 310120067: '泰日镇卫生院',
		// 310120068: '海湾镇燎原卫生院',
		// 310120069: '平安镇卫生院',
		// 310120070: '海湾镇五四卫生院',
		// 310120071: '邵厂镇卫生院',
		// 310120072: '上海市奉贤区塘外镇卫生院',
		// 310120073: '上海市奉贤区四团镇卫生院',
		// 310120074: '上海市奉贤区金汇镇卫生院',
		// 310120075: '奉城镇卫生院',
		// 310120076: '奉贤县传染病医院',
		// 310120077: '奉贤区牙病防治所',
		// 310120078: '奉贤区结核病防治所',
		// 310120079: '上海市奉贤区妇幼保健所',
		// 310120080: '奉贤区精神卫生中心',
		// 310120081: '上海市奉贤区古华医院',
		// 310120082: '奉贤区奉城医院',
		// 310120083: '上海市奉贤区中医医院',
		// 310120084: '上海市奉贤区中心医院',
		// 310151122: '上海长兴镇社区卫生服务中心',
		// 310151123: '前哨农场职工医院',
		// 310151124: '陈家镇卫生院',
		// 310151125: '港西镇卫生院',
		// 310151126: '绿华镇卫生院',
		// 310151127: '港沿镇卫生院',
		// 310151128: '竖新镇卫生院',
		// 310151129: '城桥镇社区卫生服务中心',
		// 310151130: '中兴镇卫生院',
		// 310151131: '堡镇镇卫生院',
		// 310151132: '绿华镇卫生院',
		// 310151133: '三星镇卫生院',
		// 310151134: '城桥镇社区卫生服务中心',
		// 310151135: '港西镇卫生院',
		// 310151136: '建设镇卫生院',
		// 310151137: '新河镇卫生院',
		// 310151138: '竖新镇卫生院',
		// 310151139: '陈家镇卫生院',
		// 310151140: '跃进农场职工医院',
		// 310151141: '新海农场职工医院',
		// 310151142: '红星农场职工医院',
		// 310151143: '东风农场职工医院',
		// 310151144: '长江农场职工医院',
		// 310151145: '前进农场职工医院',
		// 310151146: '前哨农场职工医院',
		// 310151147: '新村乡卫生院',
		// 310151148: '庙镇镇卫生院合作分院',
		// 310151149: '港沿镇卫生院',
		// 310151150: '长征农场职工医院',
		// 310151151: '向化镇卫生院',
		// 310151152: '崇明县庙镇人民医院',
		// 310151153: '崇明县精神卫生中心',
		// 310151154: '崇明县传染病医院',
		// 310151155: '崇明县中心医院',
		// 310151156: '上海交通大学医学院附属仁济医院...',
		// 310151157: '崇明县堡镇人民医院',
		// 440103434: '妙医斋国医馆',
		// 440103435: '广东药学院广钢附属医院',
		// 440103436: '广州伊丽莎白妇产医院',
		// 440103437: '广州荔湾区中医医院',
		// 440103438: '广州市荔湾区红十字会医院',
		// 440103439: '广州医学院附属肿瘤医院',
		// 440103440: '广州市荔湾区妇幼保健院',
		// 440103441: '广州医学院荔湾医院',
		// 440103442: '广州市荔湾区人民医院',
		// 440103443: '广州市荔湾区第二人民医院',
		// 440103444: '广州市荔湾区中医医院',
		// 440103445: '广州市芳村区中医院',
		// 440103446: '广州钢铁集团公司医院',
		440103447: '广东省中医院芳村分院',
		440103448: '广州医学院第三附属医院',
		440103449: '广州市妇婴医院',
		440103450: '广州市中医院',
		440103451: '广州市脑科医院',
		440103452: '广州市儿童医院',
		440103453: '广州市脑科医院荔湾门诊部',
		// 440104457: '广州市越秀区正骨医院',
		// 440104458: '广州市越秀区妇幼保健院东山院区',
		// 440104459: '广州市越秀区第二人民医院东风分...',
		// 440104460: '广州市越秀区第二中医医院',
		// 440104461: '广州市越秀区中医医院',
		// 440104462: '广州市越秀区妇幼保健院',
		// 440104463: '中山大学附属第一医院东山院区',
		440104464: '广东药学院附属第一医院',
		440104465: '广州空军医院',
		440104466: '广东省中医院二沙岛分院',
		440104467: '中山大学附属第一医院',
		440104468: '广州医学院第一附属医院',
		440104469: '广州军区总医院',
		440104470: '广东省人民医院',
		440104471: '广东省中医院',
		440104472: '广州市第一人民医院',
		440104473: '中山大学中山眼科中心',
		440104474: '中山大学附属肿瘤医院',
		440104475: '中山大学附属口腔医院',
		440104476: '广州市第八人民医院',
		// 440104477: '广东省残疾人康复中心',
		// 440104478: '广东体育医院',
		// 440104479: '广州军区机关门诊部哮喘气管炎治...',
		// 440104480: '广州军区机关医院哮喘气管炎诊疗...',
		// 440104481: '越秀区诗书街社区卫生服务中心',
		// 440104482: '广州市东山区妇幼保健院',
		// 440104483: '广州市越秀区第二人民医院',
		// 440104484: '广州圣堂医院（民营）',
		// 440104485: '广州凤凰美容整形医院',
		440104486: '广州第458医院',
		440104487: '广东省口腔医院',
		440104488: '广州机关医院妇科',
		// 440104489: '越秀区口腔防治所',
		// 440104490: '越秀区洪桥街社区卫生服务中心',
		// 440104491: '广州博仕整形',
		// 440104492: '广东省光明医院',
		// 440104493: '中山大学《家庭医生》整形中心',
		// 440104494: '广东药学院附属第一医院激光整形...',
		// 440104495: '广州东大肛肠医院',
		// 440104496: '广州市金典牙科门诊部',
		// 440104497: '广州市南方医科大学珠江医院',
		// 440104498: '东山区建设街社区卫生服务中心',
		// 440104499: '越秀区大新街社区卫生服务中心',
		// 440104500: '广州越秀区光塔街社区卫生服务中...',
		// 440104501: '广州空军后勤医院',
		440104502: '广州军区机关医院肝病中心',
		// 440104503: '广州颐康老年病医院',
		// 440104504: '广州银翔医院',
		440104505: '广州四五八皮肤病医院',
		// 440104506: '广州市市政工程总公司医院',
		// 440104507: '广东省高级中医馆',
		// 440104508: '广东省建筑中心医院',
		// 440104509: '广州愈生医院',
		// 440104510: '广东省交通医院',
		// 440104511: '广州市越秀区红十字会医院',
		// 440104512: '广州市越秀区第三人民医院',
		// 440104513: '广州市越秀区中医杂病医院',
		// 440104514: '广州市交运医院',
		// 440104515: '何伟志中医诊所',
		440105297: '南方医科大学中西医结合医院',
		// 440105298: '广州医学院第二附属医院整形激光...',
		// 440105299: '广州市老年病医院',
		// 440105300: '广州渔轮厂职工医院',
		// 440105301: '国家海洋局南海分局职工医院',
		// 440105302: '广州造纸厂职工医院',
		// 440105303: '广州圣亚泌尿外科医院',
		// 440105304: '广州鹤洞骨伤科医院',
		440105305: '广州红十字会医院',
		// 440105306: '南方中西医结合医院',
		// 440105307: '广州市海珠区第二中医院',
		// 440105308: '广州市海珠区中医医院',
		// 440105309: '广州海军医院',
		// 440105310: '广州市海珠区红十字会医院',
		// 440105311: '沙园街西基东社区卫生服务站',
		// 440105312: '凤阳街泰沙社区卫生服务站',
		// 440105313: '广州市海珠区妇幼保健院',
		// 440105314: '广州海员医院',
		// 440105315: '广州市海珠区第二人民医院',
		// 440105316: '广州市海珠区中医院',
		// 440105317: '广东省荣誉军人康复医院',
		// 440105318: '广州市海珠区第一人民医院',
		// 440105319: '广州新海医院',
		// 440105320: '广州市海珠区石溪中医院',
		440105321: '中山大学孙逸仙纪念医院',
		440105322: '珠江医院',
		440105323: '中国人民解放军第421医院',
		440105324: '广州医学院第二附属医院',
		440105325: '广东省第二人民医院',
		440105326: '广州市红十字会医院',
		440105327: '广州中医药大学附属骨伤科医院',
		440105328: '广东省口腔医院',
		// 440106255: '广州白癜风医院',
		// 440106256: '广州爱博恩妇产医院',
		// 440106257: '广州市天河区红十字会医院',
		// 440106258: '广州华医医院',
		// 440106259: '广州南国妇科医院',
		// 440106260: '广州佳和医院',
		// 440106261: '广州圣亚医院',
		440106262: '武警广东总队医院',
		// 440106263: '天源医疗门诊部',
		// 440106264: '广东省中医院下塘门诊部',
		// 440106265: '广州市天河区沙河人民医院',
		// 440106266: '东方类风湿病骨科医院',
		// 440106267: '广州市天河区中医医院',
		// 440106268: '广州市天河区妇幼保健院',
		// 440106269: '南方医科大学第三附属医院',
		// 440106270: '中山大学附属第六医院',
		// 440106271: '广东职业技术师范学院医院',
		440106272: '暨南大学附属第一医院',
		440106273: '广东省第二中医院',
		440106274: '中山大学附属第三医院',
		440106275: '中国人民解放军第一五七医院',
		440106276: '广州市第十二人民医院',
		440106277: '武警广东省总队医院',
		440106278: '广州市胸科医院',
		440106279: '广州皮肤病研治中心',
		// 440106280: '广州联友医院',
		// 440106281: '天河区新塘街社区卫生服务中心',
		// 440106282: '广州韩佳人医疗美容门诊部',
		// 440106283: '广州天河柏德口腔门诊部',
		// 440106284: '佳美友谊门诊部',
		// 440106285: '银辉针灸馆',
		440106286: '广州军体医院',
		// 440106287: '广州海峡整形美容医院',
		// 440106288: '妙康心理咨询工作室',
		// 440106289: '广州中信医疗门诊部',
		// 440106290: '广州中白力德白癜风治疗中心',
		// 440106291: '华南农业大学医院',
		// 440106292: '广州微侵袭神经外科中心（民营）',
		// 440106293: '武警部队耳鼻喉专科技术中心',
		// 440106294: '广州市龙洞人民医院',
		// 440106295: '广州氮肥厂职工医院',
		// 440106296: '华南理工大学医院',
		// 440111366: '中山医博济医院',
		// 440111367: '广州市珠江水泥厂职工医院',
		// 440111368: '广东省设备安装公司职工医院',
		// 440111369: '广州新市医院',
		// 440111370: '广州市白云区石井人民医院',
		// 440111371: '广州市白云区钟落潭镇医院',
		// 440111372: '广州市白云区第二人民医院',
		// 440111373: '广州市白云山医院',
		// 440111374: '广州市白云精神病康复医院',
		// 440111375: '袁浩骨病研究所',
		// 440111376: '广州白云山医院',
		// 440111377: '广州市白云区人民医院',
		// 440111378: '广州东方医院',
		// 440111379: '广州市白云区神山镇医院',
		// 440111380: '广州市白云区钟落潭医院',
		// 440111381: '广州白云同德医院',
		// 440111382: '广州东方心脑血管医院',
		// 440111383: '广州白云心理医院',
		// 440111384: '广州益寿医院',
		// 440111385: '广州市白云区黄石医院',
		// 440111386: '广州红康医院',
		// 440111387: '广州市白云区中医院',
		// 440111388: '广州南方钢厂职工医院',
		// 440111389: '广东省司法警察医院',
		// 440111390: '铁道部石门疗养院',
		// 440111391: '广州海军粤海医院',
		// 440111392: '广州一格疤痕痤疮中心',
		// 440111393: '东华医疗门诊部',
		// 440111394: '广州玛莱妇产医院',
		// 440111395: '广州协同皮肤病研究院',
		// 440111396: '广州市白云区韩妃医学美容门诊部',
		// 440111397: '广州市白云区石井镇医院',
		// 440111398: '广州康德康复医院（民营）',
		// 440111399: '广州市白云区人和华侨医院',
		// 440111402: '广州嘉禾益民医院',
		// 440111403: '广州市白云区中医医院',
		// 440111404: '广州市白云区太和镇医院',
		// 440111405: '广州市白云区龙归华侨医院',
		// 440111406: '广州市白云区神山镇医院',
		// 440111407: '广州东方医院',
		// 440111408: '民航广州医院',
		// 440111409: '广州白云区石井人民医院',
		// 440111410: '广州市白云区第一人民医院',
		// 440111411: '广州市白云区人民医院',
		// 440111412: '白云精神病康复医院',
		// 440111413: '广州市民政局精神病院',
		// 440111414: '广州市白云区妇幼保健院',
		440111415: '广东省妇幼保健院',
		440111416: '广东三九脑科医院',
		440111417: '南方医科大学南方医院',
		440111418: '广州中医药大学第一附属医院',
		// 440112454: '广州市萝岗区九龙镇中心卫生院',
		// 440112455: '广州经济技术开发区红十字会医院',
		// 440112456: '广州经济技术开发区医院',
		// 440112516: '暨南大学医学院附属黄埔中医院',
		// 440112517: '广州南方男科医院',
		440112518: '广州武警医院|武警广东总队医院',
		// 440112519: '黄埔区红十字会医院',
		// 440112520: '广东省第二工人医院',
		// 440112521: '黄埔区中医院',
		// 440112522: '广州港湾医院',
		// 440112523: '广东省电力一局医院',
		// 440112524: '广州亿仁医院',
		440112525: '中山大学附属第一医院黄埔院区',
		// 440112526: '刘国锐骨科诊所',
		// 440112527: '交通部文冲船舶修造厂医院',
		// 440112528: '广东省电力工业局第一工程局职工...',
		// 440112529: '广东省鱼珠企业集团医院',
		// 440113329: '广东康诚堂糖尿病研究所',
		// 440113330: '广州市番禺中心医院',
		// 440113331: '武警广东省总队医院番禺分院',
		// 440113332: '广州番禺肛肠医院',
		// 440113333: '番禺第二人民医院',
		// 440113334: '番禺普惠微创医院',
		// 440113335: '沙湾人民医院',
		440113336: '广东祈福医院',
		// 440113337: '广州市番禺区大岗人民医院潭洲分...',
		// 440113338: '广州市番禺区钟村医院',
		// 440113339: '广州市番禺区东涌医院',
		// 440113340: '广州市番禺区石楼人民医院',
		// 440113341: '广州市番禺区石基人民医院',
		// 440113342: '广州番禺区石楼人民医院莲花山分...',
		// 440113343: '广州市番禺区市桥医院',
		// 440113344: '广州市番禺区沙湾人民医院',
		// 440113345: '广州市番禺区岐山医院',
		// 440113346: '番禺区横沥镇医院',
		// 440113347: '广州市番禺区社会福利院',
		// 440113348: '广州市番禺区南村医院',
		// 440113349: '广州市番禺区榄核医院',
		// 440113350: '广州市番禺区化龙医院',
		// 440113351: '广州市番禺区新造医院',
		// 440113352: '广州市番禺区万顷沙人民医院',
		// 440113353: '广州市番禺区灵山医院',
		// 440113354: '广州市番禺区榄核医院镇南路综合...',
		// 440113355: '广州市番禺区鱼窝头医院大同分院',
		// 440113356: '广州市番禺区鱼窝头镇医院',
		// 440113357: '广州市珠江管理区医院',
		// 440113358: '广州市番禺区大石人民医院',
		// 440113359: '广东武警总队医院番禺分院',
		// 440113360: '番禺区人民医院三堂门诊部',
		// 440113361: '广州市番禺区人民医院',
		// 440113362: '广州市番禺区中医院',
		// 440113363: '广州市番禺区大岗人民医院',
		// 440113364: '广州市番禺区何贤纪念医院',
		440113365: '广东省中医院大学城分院',
		// 440114419: '花都新女子医院',
		// 440114420: '广州花都时代妇产医院',
		// 440114421: '广州市花都区港龙医疗门诊部',
		// 440114422: '广州花都人爱医院',
		// 440114423: '广州市花都区芙蓉卫生院',
		// 440114424: '广州市花都区雅瑶医院',
		// 440114425: '广州市花都区花东医院',
		// 440114426: '广州市花都区花山医院',
		// 440114427: '广州市花都区赤坭医院',
		// 440114428: '广州市花都区狮岭医院',
		// 440114429: '广州市花都区新华医院',
		// 440114430: '广州市花都区胡忠医院',
		// 440114431: '广州市中西医结合医院',
		440114432: '广州市花都区人民医院',
		// 440114433: '花都武警医院',
		// 440115226: '广州市南沙区中医医院',
		// 440115227: '南沙区第六人民医院',
		// 440115228: '广州市南沙区妇幼保健院',
		// 440115229: '广州市南沙区横沥医院',
		// 440115230: '广州市南沙区第一人民医院',
		// 440115231: '广州市珠江管理区医院红岭分院',
		440115232: '广州市南沙中心医院',
		// 440117210: '广州市正骨医院',
		// 440117211: '从化市妇幼保健院',
		// 440117212: '广州工伤康复医院',
		// 440117213: '从化市神岗镇医院',
		// 440117214: '从化市良口镇医院',
		// 440117215: '从化市街口街医院',
		// 440117216: '从化市太平镇医院',
		// 440117217: '鳌头镇医院',
		// 440117218: '江浦街医院',
		// 440117219: '龙潭医院',
		// 440117220: '广州市从化温泉镇医院',
		// 440117221: '从化市慢性病防治中心',
		// 440117222: '广东省工伤康复中心',
		// 440117223: '从化市中医院',
		// 440117224: '从化市中心医院',
		// 440117225: '从化市人民医院',
		// 440118233: '增城中大口腔医院',
		// 440118234: '增城市派潭医院',
		// 440118235: '增城市石滩医院',
		// 440118236: '苏国洪门诊',
		// 440118237: '广州增城曙光男科医院',
		// 440118238: '广东省水电二局医院',
		// 440118239: '沙埔卫生院',
		// 440118240: '荔城医院',
		// 440118241: '石滩医院',
		// 440118242: '增城元盛肿瘤医院（民营）',
		// 440118243: '宁西卫生院',
		// 440118244: '派潭卫生院',
		// 440118245: '增城市永和卫生院',
		// 440118246: '沙浦卫生院',
		// 440118247: '石滩医院',
		// 440118248: '荔城医院',
		// 440118249: '广州市黄陂医院',
		// 440118250: '增城市中医院',
		// 440118251: '增城市新塘医院',
		// 440118252: '增城市人民医院',
		// 440118253: '广东省水电医院',
		// 440118254: '增城市妇幼保健院',
		// 440303697: '深圳仁康医院',
		// 440303698: '深圳美和门诊部',
		// 440303699: '深圳爱视眼科专科门诊部',
		// 440303700: '深圳微创种植牙研究院',
		// 440303701: '深圳南方男科医院',
		// 440303702: '深圳鹏程医院',
		// 440303703: '深圳曙光医院',
		// 440303704: '深圳康宁医院',
		// 440303705: '深圳平乐骨伤科医院',
		// 440303706: '深圳市第五人民医院',
		// 440303707: '深圳市第三人民医院',
		// 440303708: '深圳市职业病防治院',
		// 440303709: '深圳罗湖区中医院',
		// 440303710: '深圳流花医院',
		440303711: '深圳市人民医院',
		// 440303712: '深圳远大肛肠医院',
		// 440303713: '深圳泰和门诊部',
		// 440303714: '敏柏宁白癜风咨询中心',
		// 440303715: '武警深圳特检站医院',
		// 440303716: '赵群口腔诊所',
		// 440303717: '深圳新兴医疗',
		// 440303718: '深圳市罗湖区人民医院',
		// 440303719: '深圳铁路医院',
		// 440303720: '深圳中心泌尿外科医院（民营）',
		// 440303721: '深圳市东湖医院深圳市传染病医院',
		// 440304665: '深圳市皇岗医院',
		// 440304666: '深圳李秋涛皮肤激光美容',
		// 440304667: '惠群医馆',
		// 440304668: '深圳市心希望心理咨询公司',
		// 440304669: '王荣华妇科诊室',
		// 440304670: '信军妇科诊室',
		// 440304671: '深圳深华医院（民营）',
		// 440304672: '维雅齿科 种植中心',
		// 440304673: '深圳市天健医院',
		// 440304674: '深圳市彩田医院',
		// 440304675: '深圳市华泰医院',
		// 440304676: '深圳市新沙医院',
		// 440304677: '深圳洪仁门诊部',
		// 440304678: '深圳素兰妇科门诊部',
		// 440304679: '深圳任医生中医馆',
		// 440304680: '深圳广济门诊部',
		// 440304681: '龙华美弗儿妇科医院',
		// 440304682: '深圳市福田区人民医院第二门诊部',
		// 440304683: '深圳白癜风东港医院',
		// 440304684: '深圳市眼科医院',
		// 440304685: '深圳岭南医院',
		// 440304686: '深圳市福田区人民医院肛肠分院',
		// 440304687: '深圳市第四人民医院香蜜湖分院',
		// 440304688: '深圳市福田区慢性病防治院',
		// 440304689: '深圳市第四人民医院',
		// 440304690: '深圳市福田区妇幼保健院',
		// 440304691: '深圳市儿童医院',
		// 440304692: '深圳市福田区中医院',
		440304693: '深圳市妇幼保健院',
		440304694: '深圳市第二人民医院',
		440304695: '深圳市中医院',
		440304696: '北京大学深圳医院',
		// 440305627: '深圳市同信医院',
		// 440305628: '深圳建国泌尿外科医院',
		// 440305629: '深圳太平洋口腔医院',
		// 440305630: '深圳泰康门诊部',
		// 440305631: '鹏爱医院中日联合牙科中心',
		440305632: '深圳市南山区人民医院',
		// 440305633: '深圳鹏爱医院',
		// 440305634: '深圳市西丽人民医院',
		// 440305635: '深圳五洲中西医结合医院',
		// 440305636: '深州市仁济医院',
		// 440305637: '深圳市南山区妇幼保健院',
		// 440305638: '深圳市南山人民医院',
		// 440305639: '深圳市蛇口人民医院',
		// 440306640: '深圳九州门诊部',
		// 440306641: '深圳伊丽门诊部',
		// 440306642: '深圳永恒皮肤病医院',
		// 440306643: '深圳中源门诊部',
		// 440306644: '深圳盛安医院',
		// 440306645: '深圳同仁妇产医院',
		// 440306646: '深圳市福永医院',
		// 440306647: '深圳市光明医院',
		// 440306648: '广东公安边防总队医院宝安分院',
		// 440306649: '深圳市宝安区中医院',
		// 440306650: '深圳市西乡人民医院',
		// 440306651: '深圳市公明人民医院',
		// 440306652: '深圳市宝安区观澜医院',
		// 440306653: '深圳市宝安区松岗人民医院',
		// 440306654: '深圳市宝安区沙井人民医院',
		// 440306655: '深圳市宝安区妇幼保健院',
		// 440306656: '深圳市宝安区人民医院',
		// 440306657: '黎汉祺牙科诊所',
		// 440306658: '朝晖齿科',
		// 440306659: '深圳乙肝疑难杂症专科',
		// 440306660: '深圳市宝安区福永人民医院',
		// 440306661: '深圳鹏联门诊',
		// 440306662: '深圳龙济医院（民营）',
		// 440307725: '布吉牙科医院赵生祥口腔',
		// 440307726: '深圳慈海医院',
		// 440307727: '深圳华南妇科医院',
		// 440307728: '深圳九龙男性医院',
		// 440307729: '深圳市龙岗区坪山人民医院',
		// 440307730: '深圳市龙岗区平湖人民医院',
		// 440307731: '深圳市南岭医院',
		// 440307732: '深圳市龙岗区大鹏镇人民医院',
		// 440307733: '深圳深联医院',
		440307734: '深圳龙城医院',
		// 440307735: '深圳龙岗区葵涌镇人民医院',
		// 440307736: '深圳市龙岗区人民医院',
		// 440307737: '深圳市龙岗区横岗人民医院',
		// 440307738: '深圳龙岗区坪山人民医院',
		// 440307739: '深圳市龙岗区妇幼保健院',
		// 440307740: '深圳华夏医院',
		// 440307741: '深圳市龙岗区第二人民医院',
		// 440307742: '深圳市布吉人民医院',
		// 440307743: '深圳市龙岗中心医院',
		// 440307744: '龙岗区平湖人民医院',
		// 440308663: '深圳市梅沙医院',
		// 440308664: '深圳市第七人民医院',
		// 440309722: '深圳济生门诊部',
		// 440309723: '深圳民华门诊部',
		// 440309724: '深圳健安医院',
		440402745: '广东省中医院珠海医院',
		// 440402760: '珠海天伦不孕不育专科医院',
		440402761: '珠海陆达医院',
		// 440402762: '珠海市金鼎医院',
		// 440402763: '珠海武警医院',
		// 440402764: '珠海传统医学研究所',
		// 440402765: '珠海陆达美容外科医院',
		// 440402766: '珠海同济医院',
		// 440402767: '珠海市拱北医院',
		// 440402768: '珠海惠爱医院男科',
		// 440402769: '珠海瑞桦戒毒康复医院',
		// 440402770: '珠海男科医院',
		// 440402771: '珠海永安医院',
		// 440402772: '珠海陈科医疗美容诊所',
		// 440402773: '珠海九龙医院',
		// 440402774: '珠海莱茵医疗美容整形医院',
		// 440402775: '珠海仁爱医院',
		// 440402776: '珠海博爱女子医院',
		// 440402777: '珠海博爱医院',
		// 440402778: '珠海平安康复院',
		// 440402779: '珠海博雅医院',
		// 440402780: '珠海方华医院',
		// 440402781: '珠海市香洲区香洲医院',
		// 440402782: '珠海市香洲区人民医院',
		440402783: '珠海市第二人民医院',
		440402784: '中山大学附属第五医院',
		440402785: '珠海市妇幼保健院',
		440402786: '珠海市人民医院',
		440402787: '珠海市第二人民医院',
		// 440402788: '珠海市湾仔镇卫生院',
		// 440402789: '珠海市香洲医院',
		// 440402790: '珠海市三灶妇幼保健院',
		// 440402791: '珠海六和医院',
		// 440402792: '珠海平安康复医院',
		// 440402793: '珠海市香洲区妇幼保健院',
		// 440403746: '珠海曙光男科',
		// 440403747: '珠海市斗门区人民医院',
		// 440403748: '珠海凤凰妇科医院',
		440403749: '珠海原广州军区司令部现代医院',
		// 440403750: '斗门现代妇产医院',
		// 440403751: '斗门传统医院',
		// 440404752: '珠海市金湾区妇幼保健院',
		// 440404753: '珠海市金湾区三灶医院',
		// 440404754: '珠海市平沙医院',
		// 440404755: '珠海市红旗医院',
		// 440404756: '珠海市平沙管理区医院',
		// 440404757: '珠海市湾仔医院',
		// 440404758: '珠海市三灶区妇幼保健院',
		// 440404759: '珠海市红旗红十字医院',
		// 440507625: '汕头大学医学院第一附属医院龙湖...',
		// 440507626: '龙湖区人民医院',
		// 440511610: '汕头市中医医院',
		// 440511611: '汕头市金园区中医医院',
		// 440511612: '汕头市金园区妇幼保健院',
		// 440511613: '汕头市金平区第二人民医院',
		// 440511614: '汕头市中医院',
		// 440511615: '金平区人民医院',
		// 440511616: '汕头大学医学院附属肿瘤医院',
		// 440511617: '汕头市第四人民医院',
		// 440511618: '汕头市妇幼保健院',
		// 440511619: '汕头市金园区人民医院',
		// 440511620: '汕头大学医学院精神卫生中心',
		440511621: '汕头大学第二附属医院',
		440511622: '汕头国际眼科中心',
		440511623: '汕头大学医学院第一附属医院',
		440511624: '中山大学附属汕头医院',
		// 440512609: '汕头市达濠区人民医院',
		// 440513600: '汕头市潮南区人民医院',
		// 440513601: '汕头市潮阳区中医院',
		// 440513602: '汕头市潮阳区人民医院',
		// 440513603: '汕头潮阳耀辉医院',
		// 440513604: '东洋卫生站',
		// 440514599: '潮南区人民医院',
		// 440515605: '澄海区人民医院',
		// 440515606: '汕头市澄海区华侨医院',
		// 440515607: '汕头市澄海区人民医院',
		// 440515608: '澄海市人民医院',
		// 440523598: '南澳县人民医院',
		// 440604142: '佛山名仕医院',
		// 440604143: '佛山健翔颈腰医院',
		// 440604144: '佛山肛泰肛肠医院',
		// 440604145: '佛山市禅城区中心医院',
		// 440604146: '佛山妇幼保健院',
		// 440604147: '佛山市中医院禅城高新区医院',
		// 440604148: '佛山市第一人民医院脑科康复医院',
		// 440604149: '佛山市第一人民医院禅城医院',
		// 440604150: '佛山市禅城区向阳医院',
		// 440604151: '佛山市禅城区南庄医院',
		// 440604152: '佛山市朝阳医院',
		// 440604153: '佛山市禅城区张槎医院',
		// 440604154: '佛山市禅城区澜石医院',
		// 440604155: '佛山市禅城区环市医院',
		440604156: '佛山市中山医院',
		440604157: '健翔颈腰医院',
		440604158: '佛山市第二人民医院',
		440604159: '佛山市口腔医院',
		440604160: '佛山市妇幼保健院',
		440604161: '佛山市慢性病防治院',
		440604162: '佛山市第三人民医院',
		440604163: '佛山市禅城区石湾人民医院',
		440604164: '佛山市中医院',
		440604165: '佛山市第一人民医院',
		440604166: '佛山市第一人民医院肿瘤医院',
		// 440604167: '佛山岭南颈腰医院',
		440604168: '佛山禅城武警医院',
		// 440604169: '佛山都市专科医院',
		// 440604170: '佛山口腔医院',
		// 440604171: '佛山市岭南颈腰医院',
		// 440605111: '佛山市南海区狮山镇官窑医院',
		// 440605112: '佛山市南海区罗村医院',
		// 440605113: '佛山市南海区黄岐医院',
		// 440605114: '佛山市南海区桂城医院',
		// 440605115: '佛山市南海区中医院沙头分院',
		440605116: '广东省中西医结合医院',
		// 440605117: '佛山市南海区第三人民医院',
		440605118: '佛山市南海区人民医院',
		// 440605119: '佛山市南海康亚中医门诊部',
		// 440605120: '佛山金鹰医院',
		// 440605121: '佛山市南海区官窑医院',
		// 440605122: '佛山市南海区小塘医院',
		// 440605123: '佛山市南海区南庄医院',
		// 440605124: '佛山市南海区九江医院',
		// 440605125: '佛山市南海区丹灶医院',
		// 440605126: '佛山市南海区大沥医院',
		// 440605127: '佛山市南海区盐步医院',
		// 440605128: '佛山市南海区平洲医院',
		// 440605129: '佛山市南海区西樵人民医院',
		// 440605130: '南海区第二人民医院',
		// 440605131: '佛山市第五人民医院',
		// 440605132: '广东省康复医院',
		// 440605133: '佛山市南海区妇幼保健院',
		// 440605134: '佛山市南海区中医院',
		// 440605135: '南海市人民医院',
		440605136: '广东省人民医院平洲分院',
		// 440605137: '空军医院',
		// 440605138: '广东省康复医院广东省皮肤病防治...',
		// 440605139: '黄岐医院',
		// 440605140: '佛山市南海区红十字会医院',
		// 440605141: '狮山华立医院（民营）',
		// 440606172: '佛山市顺德区均安镇医院',
		// 440606173: '佛山市顺德区容奇医院',
		// 440606174: '佛山市顺德区伦教医院',
		// 440606175: '佛山市顺德区第一人民医院',
		// 440606176: '佛山市顺德区龙江医院',
		// 440606177: '佛山市顺德区三桂医院',
		// 440606178: '佛山市顺德区桂洲医院',
		// 440606179: '佛山市顺德区陈村医院',
		// 440606180: '佛山市顺德区北滘医院',
		// 440606181: '佛山市顺德区伍仲佩纪念医院',
		// 440606182: '佛山市顺德区乐从医院',
		// 440606183: '佛山市顺德区妇儿医院',
		// 440606184: '佛山市顺德医院',
		// 440606185: '佛山市顺德区均安医院',
		// 440606186: '顺德新世纪泌尿专科医院',
		// 440606187: '顺德玛丽娅医疗美容医院',
		// 440606188: '佛山市顺德区乐从镇社区卫生服务...',
		// 440606189: '佛山市顺德区陈村镇社区卫生服务...',
		// 440606190: '佛山市顺德区勒流医院',
		// 440606191: '佛山市顺德区大良医院',
		440606192: '佛山市顺德区中医院',
		// 440606193: '南方医科大学北滘医院',
		// 440606194: '佛山市顺德区妇幼保健院',
		// 440606195: '佛山市顺德区伦教镇医院',
		// 440606196: '佛山市顺德区北窖镇医院',
		// 440606197: '顺德市中西医结合医院',
		// 440606198: '顺德国丹康复医院',
		// 440606199: '勒流医院',
		// 440607098: '佛山市三水区青岐卫生院',
		// 440607099: '佛山市三水区乐平镇中心医院',
		// 440607100: '佛山市三水区金本民信医院',
		// 440607101: '佛山市三水区同方芦苞医院',
		// 440607102: '佛山市中医院三水医院',
		// 440607103: '佛山市三水区西南街地质增康康复...',
		// 440607104: '三水区人民医院',
		// 440607105: '三水区中医院',
		// 440607106: '白坭华立医院',
		// 440607107: '佛山市三水区妇幼保健院',
		// 440607108: '佛山市三水区人民医院',
		// 440607109: '佛山市中医院三水分院',
		// 440607110: '佛山市三水区欣华南边医院',
		// 440608200: '佛山市高明区杨和镇卫生院',
		// 440608201: '佛山市高明区更合镇合水卫生院',
		// 440608202: '佛山市高明杨和仁爱医院',
		// 440608203: '佛山市高明区明城华立医院',
		// 440608204: '广东省高明监狱医院',
		// 440608205: '高明区人民医院',
		// 440608206: '佛山市高明区新市医院',
		// 440608207: '佛山市高明区人民医院',
		// 440608208: '佛山市高明区妇幼保健院',
		// 440608209: '佛山市高明区中医院',
		// 440902804: '茂名东江医院',
		// 440902805: '茂名致美口腔医院',
		// 440902806: '茂名仁爱医院',
		// 440902807: '茂名市茂南区袂花医院',
		// 440902808: '茂南区山阁卫生院',
		// 440902809: '茂名市茂南区金塘医院',
		// 440902810: '茂名市妇幼保健院',
		440902811: '茂名市中医院',
		440902812: '茂名市人民医院',
		// 440902813: '茂名市茂南区中医院',
		// 440902814: '茂名市茂南区人民医院',
		// 440902815: '茂名市茂港区小良卫生院',
		// 440902816: '茂名市茂港区七迳卫生院',
		// 440902817: '茂名市茂港区人民医院',
		// 440904801: '电白县中医院',
		// 440904802: '茂名石化医院',
		// 440904803: '茂名市电白县慢性病防治中心',
		// 440981818: '高州市石鼓医院',
		// 440981819: '高州市云潭卫生所',
		// 440981820: '茂名市第三人民医院',
		// 440981821: '高州市妇幼保健院',
		// 440981822: '高州市中医院',
		// 440981823: '高州市人民医院',
		// 440981824: '茂名市农垦医院',
		// 440982797: '广东省国营新华农场医院',
		// 440982798: '广东省建设农场医院',
		// 440982799: '化州市中医院',
		// 440982800: '化州市中心医院',
		// 440983794: '信宜仁爱医院',
		// 440983795: '信宜市中医院',
		// 440983796: '信宜市人民医院',
		441302541: '惠州市中医院',
		// 441302542: '惠州市第四人民医院',
		// 441302543: '惠州惠康医院',
		// 441302544: '惠州鹏爱医疗美容医院',
		// 441302545: '惠环医院湖北咸宁医学联合医院',
		// 441302546: '惠州惠康医院（民营）',
		// 441302547: '惠州市杨村华侨柑橘场医院',
		// 441302548: '惠州惠斌医院（民营）',
		// 441302549: '惠州第三人民医院',
		// 441302550: '惠州市红十字会惠康医院',
		// 441302551: '惠州市惠城区中医医院',
		// 441302552: '惠州爱尔眼科医院',
		// 441302553: '南方医科大学附属三院友和医院',
		441302554: '惠州市第173医院',
		// 441302555: '惠州市水口医院',
		// 441302556: '惠环医院',
		// 441302557: '惠州市惠城区江南医院',
		// 441302558: '惠城区龙丰卫生院',
		// 441302559: '惠城区小金口医院',
		// 441302560: '惠州市横沥医院',
		// 441302561: '惠州市潼侨医院',
		// 441302562: '惠州市妇幼保健院',
		// 441302563: '惠州市妇幼保健院江北分院',
		// 441302564: '惠州市红十字会丰湖门诊部',
		// 441302565: '惠州市第二人民医院',
		// 441302566: '惠州市人民医院',
		441302567: '惠州市中医医院',
		// 441302568: '惠州市复退军人医院',
		// 441302569: '惠州市城区红十字会医院',
		// 441302570: '惠州市白露医院',
		// 441302571: '惠州市第二妇幼保健院',
		// 441303572: '惠阳区永湖医院',
		// 441303573: '惠州市惠阳区五官医院',
		// 441303574: '惠阳区淡水镇医院',
		// 441303575: '惠阳区秋长医院',
		// 441303576: '惠阳区新圩医院',
		// 441303577: '大亚湾经济技术开发区霞涌医院',
		// 441303578: '惠阳区平潭医院',
		// 441303579: '惠阳区良井医院',
		// 441303580: '惠阳长安医院',
		// 441303581: '惠阳区慢病站',
		// 441303582: '惠州市惠阳区中医院',
		// 441303583: '惠州市惠阳区第一人民医院',
		// 441303584: '惠阳市妇幼保健院',
		// 441303585: '大亚湾经济技术开发区人民医院',
		// 441303586: '惠阳三和医院',
		// 441303587: '惠阳区永湖医院',
		// 441303588: '惠阳区镇隆医院',
		441303589: '南方医科大学惠州协和医院',
		// 441303590: '惠阳区第二人民医院',
		// 441303591: '惠州市惠阳区人民医院',
		// 441303592: '惠州惠民医院',
		// 441303593: '惠州市惠阳区妇幼保健院',
		// 441303594: '惠州市新鹏塘吓医院',
		// 441303595: '惠州市惠阳区侨联医院',
		// 441303596: '惠州市红会医院',
		// 441322530: '广东药学院附属第一医院惠州分院',
		// 441322531: '博罗县中医医院',
		// 441322532: '博罗县人民医院',
		// 441322533: '广东省杨村复退军人精神病医院',
		// 441322534: '惠州市杨村医院',
		// 441323535: '惠东第二人民医院',
		// 441323536: '惠东县中医院',
		// 441323537: '惠东县妇幼保健院',
		// 441323538: '惠东县稔山医院',
		// 441323539: '惠东县人民医院',
		441323540: '惠州市中心人民医院',
		// 441324597: '龙门县人民医院',
		// 441901009: '东莞市新涌医院',
		// 441901010: '东莞市中堂医院',
		// 441904051: '东莞长安港湾医院',
		// 441904052: '东莞长安霄边医院',
		// 441904053: '东莞长安新安医院',
		// 441904054: '长安厦边医院',
		// 441904055: '东莞市长安医院',
		// 441905003: '东莞市东坑医院',
		// 441906031: '樟木头冠和女子医院',
		// 441907037: '东莞仁康医院',
		// 441907038: '东莞市康宁妇科医院',
		// 441907039: '东莞市莞城医院',
		// 441907040: '东莞市妇幼保健院',
		441907041: '东莞市中医院',
		441907042: '东莞市人民医院',
		// 441908035: '东莞市石龙人民医院',
		// 441908036: '东莞市石龙博爱医院',
		// 441910000: '东莞万江康民门诊部',
		// 441910001: '东莞市万江医院',
		// 441910002: '东莞市康怡医院',
		// 441911056: '东莞市麻涌医院',
		// 441911057: '广东省泗安医院',
		// 441912043: '东莞市虎门镇南栅医院（民营）',
		// 441912044: '虎门真爱医院',
		// 441912045: '东莞虎门拜尔口腔医院',
		// 441912046: '东莞北栅医院',
		// 441912047: '东莞市虎门中医院',
		// 441912048: '东莞市太平人民医院',
		// 441912049: '东莞市虎门医院',
		// 441914034: '东莞市石碣医院',
		// 441917032: '东莞市洪梅医院',
		// 441918050: '东莞市道滘医院',
		// 441920011: '东莞市企石医院',
		// 441921012: '东莞市凤岗医院',
		// 441921013: '凤岗华侨医院',
		// 441921014: '南方医科大广济医院',
		// 441922023: '东莞市大岭山医院',
		// 441924033: '东莞市清溪医院',
		// 441925030: '东莞市望牛墩医院',
		// 441926019: '东莞市厚街方树泉医院',
		// 441927028: '东莞市常平医院',
		// 441927029: '广东黄菊英骨髓炎专科',
		// 441928026: '东莞友华医院',
		// 441928027: '东莞市寮步医院',
		// 441931020: '东莞塘厦莞华医院',
		// 441931021: '东莞市塘厦医院',
		// 441931022: '东莞三局医院',
		// 441932058: '东莞市黄江医院',
		// 441933024: '东莞市大朗医院',
		// 441933025: '东莞爱普康医院',
		// 441936004: '东莞东方泌尿专科医院',
		// 441936005: '东莞东城特美口腔门诊部',
		// 441936006: '东莞缔美美容医院',
		// 441936007: '东莞现代妇科医院',
		441936008: '中山大学东华医院',
		// 441937015: '东莞市附城医院',
		// 441937016: '广东医学院附属生殖中心',
		// 441937017: '东莞市东城医院',
		// 441937018: '东莞市南城医院',
		// 442001068: '中山市南头医院',
		// 442002093: '中山市神湾医院',
		// 442003061: '中山市东凤人民医院',
		// 442005096: '中山市黄布医院',
		// 442005097: '中山市黄圃人民医院',
		// 442006077: '中山市陈星海医院',
		// 442006078: '中山市小榄人民医院',
		// 442007090: '中山市神湾医院
		// 442008080: '中山市横栏医院',
		// 442010059: '中山市三乡医院',
		// 442010060: '中山市三乡医院',
		// 442011083: '中山市港口医院',
		// 442011084: '中山市浪网医院',
		// 442011085: '中山市第二人民医院',
		// 442012082: '中山市沙溪隆都医院',
		// 442013079: '中山市板芙医院',
		// 442015066: '中山市东升医院',
		// 442015067: '中山市东升医院',
		// 442017081: '中山市民众医院',
		// 442018062: '中山爱尔眼科医院',
		// 442018063: '中山市埠湖医院',
		442018064: '中山市博爱医院',
		442018065: '中山市人民医院',
		// 442019086: '中山火炬开发区医院',
		// 442019087: '中山国丹中医院',
		// 442019088: '中山市东凤医院',
		// 442019089: '中山市火炬开发区医院',
		442020094: '中山市中医院',
		// 442020095: '中山市西区医院',
		// 442022070: '中山市古镇人民医院',
		// 442022071: '中山市古镇医院',
		// 442023072: '万青门诊部',
		// 442023073: '家庭医院',
		// 442023074: '中山市坦背医院',
		// 442023075: '中山市坦洲医院',
		// 442024076: '中山市大涌医院',
		// 442025069: '中山市南朗医院',
		// 330102107: '杭州长安医院',
		// 330102108: '杭州泰山堂中医门诊部',
		// 330102109: '杭州市民政综合医疗门诊部',
		// 330102110: '杭州军区机关医院妇科',
		// 330102111: '浙江省军区门诊部',
		// 330102112: '杭州华山医疗美容医院',
		// 330102113: '杭州市上城区横河医院',
		// 330102114: '浙江大学医学院附属第一医院城站...',
		// 330102115: '杭州市口腔医院',
		// 330102116: '浙江大学医学院附属第一医院分院',
		// 330102117: '杭州铁路医院',
		330102118: '浙江省中医院',
		330102119: '浙江大学医学院附属妇产科医院',
		330102120: '浙江大学医学院附属第二医院',
		330102121: '浙江大学医学院附属第一医院',
		// 330102122: '杭州玛莉亚妇女医院',
		330103123: '杭州整形医院',
		330103124: '杭州爱德医院',
		// 330103125: '杭州骨科医院',
		// 330103126: '杭州宝善堂中西医结合门诊部',
		// 330103127: '杭州市下城区人民医院',
		330103128: '浙江中医药大学附属中西医结合医院',
		330103129: '浙江省人民医院',
		330103130: '杭州市红十字会医院',
		330103131: '浙江大学医学院附属儿童医院',
		// 330103132: '杭州中河肝病治疗中心',
		// 330103133: '杭州同欣整形美容医院',
		// 330104172: '杭州市江干区人民医院',
		// 330104173: '杭州市第四人民医院杭州市肿瘤医...',
		// 330104174: '浙江省青春医院浙江省监狱中心医...',
		// 330104175: '杭州市烧伤医院',
		330104176: '浙江大学医学院附属邵逸夫医院',
		// 330104177: '江干区九堡医院',
		// 330104178: '杭州新城医院',
		// 330104179: '杭州市江干区九堡医院',
		// 330105156: '浙江省邮电医院',
		// 330105157: '杭州仁德妇产医院',
		// 330105158: '杭州博雅整形医院',
		330105159: '杭州市老年病医院',
		// 330105160: '杭州京都皮肤病研究所',
		// 330105161: '杭州广仁不孕不育研究所',
		// 330105162: '杭州华晨门诊部',
		// 330105163: '杭州市拱墅区第二人民医院',
		330105164: '浙江大学医学院肿瘤医院',
		// 330105165: '浙江老年关怀医院',
		// 330105166: '杭州市拱墅中西医结合医院',
		330105167: '杭州师范学院医学院附属医院',
		330105168: '杭州师范大学附属医院',
		// 330106204: '浙江大学校医院（玉泉校区）',
		330106205: '杭州西溪医院',
		// 330106206: '杭州康源口腔医疗文二路口腔门诊...',
		// 330106207: '杭州美莱医疗美容医院',
		// 330106208: '浙江大学校医院',
		// 330106209: '浙江医院分院',
		// 330106210: '杭州市西湖区人民医院',
		// 330106211: '杭州市妇幼保健院',
		330106212: '杭州市第一人民医院',
		330106213: '浙江医院',
		330106214: '中国人民解放军第117医院',
		330106215: '浙江中医药大学附属广兴医院',
		330106216: '浙江省立同德医院',
		330106217: '杭州市中医院',
		330106218: '浙江中医药大学附属杭州第三医院',
		330106219: '西湖区妇幼保健所',
		330106220: '杭州市五云山疗养院',
		330106221: '杭州天目山医院（民营）',
		330106222: '杭州时光医疗美容医院',
		330106223: '浙江中医药大学附属第三医院',
		330106224: '杭州市第六人民医院',
		330108183: '杭州武警医院',
		// 330108184: '中国人民武警部队浙江省总队杭州...',
		// 330109185: '杭州二棉职工医院',
		// 330109186: '萧山区盈丰卫生院',
		// 330109187: '萧山东方医院',
		// 330109188: '萧山九龙男科医院',
		// 330109189: '萧山区精神病医院',
		// 330109190: '杭州萧然女子医院',
		// 330109191: '杭州第二棉纺织厂职工医院',
		// 330109192: '杭州市萧山区皮肤病防治院',
		// 330109193: '杭州市萧山区中医骨伤科医院',
		// 330109194: '杭州市萧山区第六人民医院',
		// 330109195: '萧山经济技术开发区医院',
		// 330109196: '杭州市萧山区第三人民医院',
		// 330109197: '杭州市萧山区第二人民医院',
		330109198: '杭州市萧山区中医院',
		330109199: '杭州市萧山区第一人民医院',
		330109200: '浙江中医药大学附属第二医院',
		// 330109201: '萧山区妇幼保健院',
		// 330109202: '杭州市萧山区第五人民医院杭州市...',
		// 330109203: '萧山医院',
		330110139: '余杭区第一人民医院',
		// 330110140: '杭州市余杭区第二人民医院',
		// 330110141: '杭州市余杭区中医院',
		// 330110142: '杭州师范学院附属医院',
		// 330111143: '杭州富阳富春综合门诊部',
		330111144: '富阳区中医骨伤医院',
		330111145: '杭州市富阳区第一人民医院',
		// 330111146: '富阳区中医医院',
		// 330111147: '富阳中山综合门诊部',
		330111148: '富阳市妇幼保健院',
		// 330111149: '富阳市第三人民医院富阳市精神病...',
		// 330111150: '富阳市中医院',
		// 330111151: '富阳市人民医院',
		// 330111152: '富阳市中医骨伤科医院',
		// 330111153: '富阳市第二人民医院',
		// 330112134: '杭州临安华山医院',
		// 330112135: '临安博爱医院',
		// 330112136: '临安市昌化人民医院',
		// 330112137: '临安市中医院',
		// 330112138: '临安市人民医院',
		// 330122169: '浙江省桐庐县第一人民医院',
		// 330122170: '桐庐县中医院',
		// 330122171: '桐庐县第一人民医院',
		// 330127180: '淳安县第二人民医院',
		// 330127181: '淳安县中医院',
		// 330127182: '淳安县第一人民医院',
		// 330182154: '建德市第四人民医院',
		// 330182155: '国家电力公司职业病防治院',
		330203078: '宁波海曙康肤皮肤病专科门诊部',
		330203079: '宁波市肝病医院',
		330203080: '宁波市第二医院',
		330203081: '宁波市中医院',
		330203082: '浙江中医学院宁波附属医院',
		330203083: '宁波市妇女儿童医院',
		330203084: '宁波市第一医院',
		330203085: '中国人民解放军第一一三医院',
		// 330203086: '宁波珈禾整形医院',
		// 330203087: '宁波仁爱不孕不育医院',
		// 330203088: '宁波市第二医院（原宁波华美医院...',
		// 330203089: '宁波市传染病医院宁波肝病医院',
		330203090: '浙江中医药大学附属宁波中医院',
		// 330205072: '宁波市第九医院',
		// 330205073: '宁波江北马应龙博爱医院',
		// 330205074: '宁波江北恩庄桥恩吉口腔门诊部',
		// 330205075: '宁波市保黎医院',
		330205076: '宁波大学医学院附属医院',
		// 330205077: '宁波市精神病院',
		// 330206043: '宁波市现代口腔医院',
		// 330206044: '北仑区小港医院',
		// 330206045: '宁波市北仑区中医院星阳分院',
		// 330206046: '宁波市北仑区中医院',
		// 330206047: '宁波经济技术开发区医院',
		// 330206048: '宁波经济技术开发区中心医院',
		// 330211100: '宁波市镇海区九龙湖镇社区卫生服...',
		// 330211101: '庄市佩珍医院',
		330211102: '宁波第五医院',
		// 330211103: '宁波市镇海区炼化医院',
		// 330211104: '宁波市镇海区中医医院',
		330211105: '宁波市康宁医院',
		// 330211106: '宁波市镇海区龙赛医院',
		// 330212065: '海军四一二医院宁波分院',
		// 330212066: '宁波雅戈尔医院（民营）',
		// 330212067: '宁波市眼科医院',
		// 330212068: '宁波江东艾博尔妇产医院',
		// 330212069: '宁波同仁男科医院',
		330212070: '宁波市医疗中心李惠利医院',
		// 330212071: '宁波市第六医院',
		// 330212096: '宁波鄞州区日月星养老院',
		// 330212097: '宁波明州医院',
		330212098: '宁波市鄞州人民医院',
		330212099: '宁波市鄞州区第二医院',
		// 330213049: '奉化市溪口医院',
		// 330213050: '奉化市中医医院',
		// 330213051: '奉化市安康医院',
		// 330213052: '奉化市人民医院',
		// 330213053: '奉化市中医院',
		// 330225091: '象山县中医医院',
		// 330225092: '象山县爱心医院',
		330225093: '象山县第一人民医院',
		// 330225094: '象山县第三人民医院',
		// 330225095: '象山县中医院',
		// 330226054: '宁海县中医医院',
		// 330226055: '宁海县妇幼保健院',
		// 330226056: '宁海县第一医院',
		// 330226057: '宁海县中医院',
		// 330281036: '余姚市第三人民医院',
		// 330281037: '余姚市梨洲医院',
		// 330281038: '余姚市中医院',
		// 330281039: '余姚市第四人民医院',
		// 330281040: '余姚市第二人民医院',
		// 330281041: '惠爱医院',
		330281042: '余姚市人民医院',
		330282058: '慈溪市中医医院',
		330282059: '慈溪市第七人民医院',
		330282060: '慈溪市红十字医院',
		330282061: '慈溪市中医院',
		330282062: '慈溪市人民医院',
		// 330282063: '慈溪市宗汉医院',
		// 330282064: '慈溪欧亚医院',
		330302264: '温州市人民医院',
		// 330302265: '温州市和平整形医院',
		330302266: '温州中医院',
		330302267: '温州市中医院',
		330302268: '温州医学院附属第二医院',
		330302269: '温州市中西医结合医院',
		330302270: '温州医科大学附属第二医院',
		// 330302271: '温州建国医院',
		330302272: '温州康宁医院',
		// 330302273: '浙江中医药大学附属温州中医院景...',
		// 330302274: '温州市妇幼保健所',
		// 330302275: '温州长征妇产科医院',
		// 330302276: '温州和平烧伤整形医院',
		330303277: '温州医学院附属第一医院',
		// 330303278: '温州爱尔五官医院',
		// 330303279: '温州医学院附属儿童医院',
		// 330304251: '温州市瓯海区丽岙街道社区卫生服...',
		// 330304252: '温州东方整形医院',
		// 330304253: '温州东方妇产医院',
		330304254: '温州市第三人民医院',
		330304255: '温州市第二人民医院',
		// 330304256: '温州中山医院',
		// 330304257: '温州市瓯海区瞿溪中心卫生院',
		// 330324240: '永嘉县妇幼保健院',
		// 330324241: '永嘉县人民医院',
		// 330324242: '永嘉县中医院',
		// 330326237: '平阳县妇幼保健站',
		// 330326238: '平阳县人民医院',
		// 330327258: '苍南县妇幼保健院',
		// 330327259: '苍南县中医院',
		// 330327260: '苍南县宜山医院',
		// 330327261: '苍南县中医院上海中山医院苍南分...',
		// 330327262: '苍南县第二人民医院',
		// 330327263: '苍南县人民医院',
		// 330328239: '文成县中医医院',
		// 330329243: '泰顺县人民医院',
		// 330381244: '瑞安瑞丽医疗美容医院',
		// 330381245: '瑞安市第五人民医院温州市精神病...',
		// 330381246: '瑞安市第三人民医院',
		// 330381247: '瑞安市第二人民医院瑞安市仙降医...',
		// 330381248: '瑞安市妇幼保健院',
		330381249: '瑞安市中医院',
		// 330381250: '瑞安市人民医院温州医学院附属第...',
		// 330382225: '乐清市乐成中心卫生院',
		// 330382226: '乐清市芙蓉镇第一社区卫生服务中...',
		// 330382227: '乐清市第五人民医院',
		// 330382228: '乐清同济门诊部',
		// 330382229: '乐清协和中医门诊部',
		// 330382230: '乐清市中医院',
		// 330382231: '乐清市第二人民医院',
		// 330382232: '乐清市第三人民医院',
		330382233: '乐清市人民医院',
		// 330382234: '东清柳台医院（民营）',
		// 330382235: '黄华镇卫生院',
		// 330382236: '浙江乐清济民中医骨伤科医院',
		// 330402024: '嘉兴市南湖区七星镇卫生院',
		// 330402025: '浙江省荣军医院',
		330402026: '嘉兴市中医院',
		330402027: '嘉兴市第二医院',
		330402028: '嘉兴市第一医院',
		330402029: '嘉兴市妇幼保健院',
		// 330411030: '浙江新安国际医院',
		330411031: '武警浙江省总队医院',
		// 330411032: '秀洲区油车港镇卫生院',
		// 330411033: '嘉兴市新塍人民医院',
		// 330411034: '嘉兴曙光中西医结合医院（民营）',
		// 330411035: '嘉兴市第一医院嘉兴医学院附属第...',
		// 330421000: '嘉善县中医院',
		// 330421001: '嘉善县第二人民医院',
		// 330421002: '嘉善县第一人民医院',
		// 330424019: '海盐县中医院',
		// 330424020: '海盐县人民医院',
		// 330424021: '中国核工业二四公司核电工程公司...',
		// 330424022: '中国核工业第二三建筑公司三工程...',
		// 330424023: '中国核工业第二二建筑公司二工程...',
		// 330481014: '海宁市长安镇卫生院',
		330481015: '海宁康华医院',
		// 330481016: '海宁市妇幼保健院',
		// 330481017: '海宁市人民医院',
		// 330481018: '海宁市中医院',
		// 330482003: '平湖市第二人民医院',
		// 330482004: '平湖市第一人民医院',
		// 330482005: '平湖市中医院',
		// 330483006: '桐乡星光医院',
		// 330483007: '嘉兴市康慈医院',
		// 330483008: '桐乡市第二人民医院',
		// 330483009: '桐乡市第一人民医院',
		// 330483010: '桐乡市中医院',
		// 330483011: '桐乡市第三人民医院',
		// 330483012: '嘉兴市康慈医院',
		// 330483013: '嘉兴市康慈医院嘉兴市心理卫生中...',
		// 330702321: '金华婺城民安烧伤专科门诊部',
		// 330702322: '文荣医院',
		// 330702323: '金华口腔医院',
		// 330702324: '金华邦尔正骨医院',
		330702325: '金华人民医院',
		// 330702326: '金华市第五医院',
		330702327: '金华市人民医院',
		330702328: '金华市中医院',
		330702329: '金华市中心医院',
		330702330: '浙江金华中医院',
		// 330702331: '金华博爱医院',
		// 330702332: '金华市艾克医院（民营）',
		// 330702333: '金华市近视治疗中心',
		// 330702334: '金华铁路医院',
		// 330702335: '金华同济泌尿外科医院',
		// 330702336: '金华市精神病院',
		// 330702337: '金华仁爱医院',
		// 330703352: '金三角医院',
		// 330723338: '武义县中医院',
		// 330723339: '武义县第一人民医院',
		// 330726347: '浦江博爱骨伤手外科医院',
		// 330726348: '浦江第二医院',
		// 330726349: '浦江县岩头镇卫生院',
		// 330726350: '浦江县人民医院',
		// 330727351: '磐安县人民医院',
		// 330781312: '兰溪市梅江镇中心卫生院',
		// 330781313: '兰溪市人民医院（西院区）',
		// 330781314: '兰溪永球医院',
		// 330781315: '兰溪瑞康医院',
		// 330781316: '兰溪市红十字会医院',
		// 330781317: '兰溪市中医院',
		// 330781318: '兰溪市第二人民医院',
		// 330781319: '兰溪市人民医院',
		// 330781320: '兰溪市马涧镇卫生服务中心',
		330782304: '义乌张小红医疗美容医院',
		// 330782305: '义乌復元私立医院',
		// 330782306: '义乌市廿三里医院',
		// 330782307: '义乌市中医院',
		// 330782308: '义乌市第三人民医院义乌市肿瘤医...',
		330782309: '义乌市中心医院',
		// 330782310: '义乌华山康复医院',
		// 330782311: '义乌市皮肤病医院',
		// 330783297: '横店医院',
		// 330783298: '东阳市中医院',
		// 330783299: '横店集团医院',
		330783300: '东阳市人民医院',
		330783301: '东阳市中医院(新院)',
		// 330783302: '东阳市精神病医院',
		// 330783303: '东阳市横店医院',
		// 330784340: '永康瑞金儿童医院',
		// 330784341: '永康光大医疗美容医院',
		// 330784342: '永康康美诺口腔医院',
		// 330784343: '永康市红十字会医院',
		// 330784344: '永康市中医院',
		// 330784345: '永康市第一人民医院',
		// 330784346: '永康市骨科医院',
		// 330902280: '舟山现代妇科医院',
		// 330902281: '定海广华医院',
		330902282: '舟山市妇幼保健院',
		330902283: '舟山医院',
		330902284: '舟山市第三人民医院',
		// 330902285: '舟山市骨伤医院',
		330902286: '舟山医院中医骨伤院区',
		330902287: '浙江省舟山市人民医院',
		// 330903292: '舟山明光眼科医院',
		// 330903293: '舟山顾鹤传骨伤医院',
		// 330903294: '舟山存德医院',
		330903295: '浙江普陀医院',
		// 330903296: '舟山市普陀区人民医院',
		330903297: '舟山市普陀区第二人民医院',
		// 330921288: '岱山县第二人民医院',
		// 330921289: '岱山县中医院',
		// 330921290: '岱山县第一人民医院上海新华医院...',
		// 330922291: '嵊泗县人民医院',
		320102071: '南京和燕生殖医学研究所',
		// 320102072: '南京龙蟠医院',
		// 320102073: '南京市中西医结合医院',
		// 320102074: '南京市玄武医院',
		// 320102075: '南京扬子医院',
		320102076: '中国医学科学院皮肤病院',
		320102077: '江苏省肿瘤医院',
		320102078: '南京军区机关医院',
		// 320102079: '南京铁医红山医院',
		// 320102080: '中国医学科学院皮肤病研究所（医...',
		// 320102081: '江苏省东南大学医院',
		// 320102082: '东南大学医院',
		// 320102083: '南京红十字康宁医院（民营）',
		320104084: '南京市红十字医院',
		320104085: '南京新协和不孕不育医院',
		320104086: '南京市红十字会医院',
		320104087: '南京建工医院',
		320104088: '解放军第四五四医院',
		// 320104089: '江苏省妇幼保健院',
		// 320104090: '南京恒大中医医院',
		// 320104091: '南京市蓝旗医院',
		// 320104092: '南京和平医院',
		320104093: '南京市妇幼保健院',
		320104094: '南京军区南京总医院',
		320104095: '中国人民解放军第八一医院',
		320104096: '中国人民解放军第四五四医院',
		// 320104097: '南京市白下区中医院',
		// 320104098: '南京东南眼科医院',
		// 320104099: '国营第五二八厂职工医院',
		// 320104100: '南京水西门医院',
		// 320104101: '南京爱尔眼科医院',
		320104102: '南京中医院',
		320104103: '南京市第一医院',
		// 320104104: '南京市秦淮医院',
		// 320104105: '南京市秦淮中医院',
		// 320104106: '南京市第一医院分院',
		// 320105021: '南京中医药大学第二附属医院',
		// 320105022: '南京市建邺医院',
		320105023: '南京市中医院',
		320105024: '江苏省第二中医院',
		320105025: '江苏省中医院',
		// 320105026: '南京市建中中医院',
		// 320111058: '南京浦镇车辆厂医院',
		// 320111059: '南京大桥四处职工医院',
		// 320111060: '江苏省浦口监狱医院',
		// 320111061: '南京市浦口区中心医院',
		320111062: '铁道部南京高原病康复医院',
		// 320111063: '南京市浦口城南门诊部',
		// 320111064: '南京港江北医院',
		// 320111065: '南京市浦口医院',
		// 320111066: '南京市浦口区中医院',
		// 320111067: '江浦县人民医院',
		// 320111068: '铁道部浦镇车辆厂职工医院',
		// 320113027: '金陵石油化工公司化肥厂医院',
		// 320113028: '金陵石油化工公司烷基苯厂职工医...',
		// 320113029: '金陵石油化工公司塑料厂职工医院',
		// 320113030: '金陵石油化工公司南京市炼油厂职...',
		// 320113031: '金陵石油化工热电厂职工医院',
		// 320113032: '金陵石油化工公司化工一厂职工医...',
		// 320113033: '中国石化集团第二建设公司职工医...',
		// 320113034: '南京市化学纤维厂职工医院',
		// 320113035: '金陵石油化工公司化肥厂职工医院',
		// 320113036: '南京市华东电子集团公司医院',
		// 320113037: '南京电气(集团)有限责任公司职...',
		320113038: '南京烷基苯医院',
		// 320113039: '栖霞市妇幼保健院',
		// 320113040: '南京市栖霞区医院',
		320113042: '江苏省中西医结合医院',
		320113043: '江苏省中医药研究院',
		// 320113044: '南京市栖霞区龙潭医院',
		// 320113045: '南京市栖霞医院',
		// 320113046: '南京市市级机关医院',
		// 320113047: '南京东瑞医院',
		// 320114107: '上海梅山医院',
		// 320114108: '上海梅山第二医院',
		// 320114109: '上海梅山冶金公司铁矿医院',
		// 320114110: '江苏省南京监狱医院',
		// 320115048: '南京世纪现代妇科医院',
		// 320115049: '南京吉山铁矿职工医院',
		// 320115050: '江苏省江宁监狱医院',
		// 320115051: '南京江宁新城医院',
		// 320115052: '南京市江宁区人民医院',
		// 320115053: '南京市江宁区第二人民医院',
		// 320115054: '江宁县中医医院',
		// 320115055: '南京市青龙山精神病院',
		// 320115056: '南京市江宁区妇幼保健所',
		// 320115057: '江苏邮政老年康复中心',
		// 320116011: '扬郭医院',
		// 320116012: '扬子石油化工公司医院扬子医院',
		// 320116013: '中国石化集团南京化学工业有限公...',
		// 320116014: '南钢集团公司医院',
		// 320116015: '南京钢铁集团冶山矿业有限公司医...',
		// 320116016: '中国化学工程第十四建设公司职工...',
		// 320116017: '六合区大厂医院',
		// 320116018: '六合县人民医院',
		// 320116019: '南京市六合区人民医院',
		// 320116020: '南京市大厂医院',
		// 320117069: '溧水县城西医院',
		// 320117070: '溧水县人民医院',
		// 320118111: '高淳县顾陇医院',
		// 320118112: '高淳县下坝医院',
		// 320118113: '高淳县西莲医院',
		// 320118114: '高淳县漕塘医院',
		// 320118115: '高淳县古柏医院',
		// 320118116: '高淳县薛城医院',
		// 320118117: '高淳县人民医院',
		// 320118118: '高淳县中医医院',
		// 320205465: '无锡华山医院',
		// 320205466: '锡山区张泾人民医院',
		// 320205467: '无锡市安国医院',
		// 320205468: '无锡市东方肛肠医院',
		// 320205469: '无锡市锡山区人民医院',
		// 320205470: '无锡市安国医院',
		// 320206443: '无锡市惠山区人民医院',
		320206444: '江苏省荣军医院',
		// 320211454: '无锡市新区老年护理院',
		// 320211455: '无锡市滨湖区荣巷街道社区卫生服...',
		// 320211456: '无锡嘉仕恒信医院',
		// 320211457: '无锡市第六人民医院',
		// 320211458: '无锡市滨湖区河埒街道社区卫生服...',
		// 320211459: '无锡市滨湖区中医院',
		// 320211460: '无锡市手外科医院',
		// 320211461: '无锡市第五人民医院',
		// 320211462: '无锡市郊区荣巷医院',
		// 320211463: '无锡旺庄医院',
		// 320211464: '无锡男科医院',
		// 320213393: '无锡同大皮肤病中医研究所',
		// 320213394: '无锡太湖中医医院',
		// 320213395: '无锡市南站医院',
		// 320213396: '无锡太湖国家旅游度假区迎晖社区...',
		// 320213397: '无锡市精神卫生中心',
		// 320213398: '无锡市北塘区人民医院',
		320213399: '无锡市中西医结合医院',
		320213400: '无锡市人民医院',
		320213401: '中国人民解放军第一Ｏ一医院',
		// 320213402: '无锡二泉医院',
		// 320213403: '无锡市虹桥医院',
		// 320213404: '无锡市商业医院',
		// 320213405: '无锡市南长人民医院',
		// 320213406: '无锡市肺科医院',
		320213407: '无锡市南长人医院',
		// 320213427: '无锡市第八人民医院',
		// 320213428: '无锡美联臣医疗美容医院有限公司',
		// 320213429: '无锡整形美容医院',
		320213430: '无锡市崇安区口腔医院',
		// 320213431: '无锡尚美整形美容医院',
		// 320213432: '无锡博爱医院',
		// 320213433: '无锡玛利亚医院',
		// 320213434: '无锡天一医院',
		// 320213435: '无锡市崇安人民医院',
		// 320213436: '无锡市传染病医院',
		320213437: '无锡市第四人民医院',
		320213438: '无锡市第二人民医院',
		320213439: '无锡市妇幼保健院',
		320213440: '无锡市中医院',
		// 320213441: '无锡市滨湖区人民医院',
		// 320213442: '无锡市新区医院',
		// 320281445: '江阴朝阳门诊',
		// 320281446: '江阴东方妇科医院',
		// 320281447: '江阴长江医院',
		// 320281448: '江阴申港仁爱医院',
		// 320281449: '江阴市第三人民医院',
		// 320281450: '江阴市华士中心卫生院',
		320281451: '江阴市人民医院',
		// 320281452: '解放军515（远望)医院',
		// 320281453: '江阴市中医院',
		// 320282408: '宜兴市肿瘤医院',
		// 320282409: '江苏省丁山监狱医院',
		// 320282410: '宜兴友谊妇科医院',
		// 320282411: '宜兴友谊妇科',
		// 320282412: '宜兴市新建卫生院',
		// 320282413: '宜兴市闸口卫生院',
		// 320282414: '宜兴市洋溪卫生院',
		// 320282415: '宜兴市高塍卫生院',
		// 320282416: '宜兴市周铁医院',
		// 320282417: '宜兴市第四人民医院',
		// 320282418: '宜兴市和桥医院',
		// 320282419: '宜兴市杨巷医院',
		// 320282420: '宜兴市官林医院',
		// 320282421: '宜兴市张渚人民医院',
		// 320282422: '宜兴市太华卫生院',
		// 320282423: '宜兴市中医院',
		// 320282424: '宜兴市第二人民医院',
		// 320282425: '宜兴市人民医院',
		// 320282426: '武警八六九Ｏ医院',
		320302000: '南京414医院',
		// 320302001: '东南大学附属中大医院下关分院',
		320302002: '上海长征医院南京分院',
		// 320302003: '江苏紫金集团有限公司医院',
		// 320302004: '南京港口医院',
		320302005: '南京医科大学第二附属医院',
		320302006: '第二军医大学长征医院南京分院',
		320302007: '第二军医大学长征医院南京分院',
		// 320302008: '南京康爱医院',
		320302009: '南京大学医学院第二附属医院',
		320302010: '中国人民解放军四一四医院',
		// 320302119: '江苏武警总队南京医院',
		// 320302120: '南京市房产管理局职工医院',
		// 320302121: '南京市鼓楼医院北院',
		// 320302122: '中国人民武装警察部队江苏总队南...',
		// 320302123: '南京爱康医院',
		// 320302124: '南京铁路分局中心医院',
		// 320302125: '江苏省老年医院',
		// 320302126: '南京铁路中心医院',
		// 320302127: '康贝佳口腔医院',
		// 320302128: '徐州市鼓楼区朱庄医院',
		320302129: '南京市第二医院',
		// 320302130: '南京金陵老年病康复医院',
		320302131: '东南大学附属中大医院',
		320302132: '南京医科大学附属脑科医院',
		320302133: '江苏省口腔医院',
		320302134: '南京市胸科医院',
		320302135: '南京市儿童医院',
		320302136: '南京市口腔医院',
		320302137: '南京大学医学院附属鼓楼医院',
		320302138: '江苏省人民医院',
		320302139: '南京鼓楼医院',
		// 320302140: '南京市爱康医院',
		// 320302141: '南京市第三医院',
		320302142: '江苏省妇幼卫生保健中心',
		// 320302143: '徐州爱牙口腔医院',
		// 320302144: '徐州市财贸职工医院',
		// 320302145: '徐州市电力医院',
		320302146: '徐州市第一人民医院',
		320302147: '徐州市第三人民医院',
		// 320302282: '徐州矿务集团有限公司夹河煤矿物...',
		// 320302283: '徐州市九里区九里医院',
		// 320302284: '徐州矿务集团第一医院',
		// 320302285: '徐州矿务集团总医院庞庄分院',
		// 320302355: '徐州电力医院',
		// 320303286: '徐州光大牙科',
		// 320303287: '徐州济仁中医肿瘤专科医院',
		320303288: '徐州国康中医医院',
		320303289: '徐州市中心医院',
		// 320303290: '徐州市彭城人民医院',
		// 320303291: '徐州爱心医院',
		// 320303292: '徐州市云龙区人民医院',
		320303293: '徐州医学院附属第三医院',
		320303294: '徐州市中医院',
		320303295: '徐州市第四人民医院',
		320303296: '中国人民解放军第97医院',
		// 320305331: '贾汪区盐诚医院',
		// 320305332: '徐州矿务集团第二医院',
		// 320305333: '徐州市贾汪区夏桥医院',
		// 320305334: '徐州矿务集团有限公司旗山煤矿医...',
		// 320305335: '徐州市贾汪区董庄医院',
		// 320305336: '徐州矿务集团有限公司权台煤矿医...',
		// 320305337: '徐州矿务集团水泥厂医院',
		// 320305338: '徐州市贾汪区人民医院',
		// 320305339: '徐州市贾汪区第二人民医院',
		// 320305340: '徐州贾汪区人民医院',
		// 320305341: '徐州矿务集团第三医院',
		// 320305342: '徐州矿务集团有限公司韩桥矿医院',
		// 320311313: '徐州精神病院',
		320311314: '铜山县中医院',
		320311315: '徐州市口腔医院',
		// 320311316: '徐州市泉山区潘塘医院',
		// 320311317: '徐州百汇医院',
		// 320311318: '徐州市建筑工人医院',
		// 320311319: '徐州管道医院',
		// 320311320: '徐州市矿山医院',
		// 320311321: '徐州市性医学医疗研究中心',
		320311322: '徐州矿务集团总医院',
		320311323: '徐州市儿童医院',
		320311324: '徐州医学院附属医院',
		320311325: '徐州市第二医院',
		// 320311326: '徐州市老年病医院',
		// 320311327: '中国石化集团管道储运公司职工医...',
		// 320311328: '中国石化管道医院',
		// 320311329: '中煤第五建设公司职工医院',
		// 320312352: '徐州利国医院',
		// 320312353: '铜山县第三人民医院',
		// 320312354: '徐州市第六人民医院',
		// 320321279: '江苏省丰县人民医院',
		320321280: '江苏省丰县中医院',
		// 320321281: '丰县人民医院',
		// 320322304: '中煤五公司第一工程处职工医院',
		// 320322305: '大屯煤电（集团）有限责任公司中...',
		// 320322306: '江苏省沛县人民医院',
		// 320322307: '沛县华佗医院',
		// 320322308: '中煤大屯煤电（集团）公司中心医...',
		// 320322309: '沛县中医院',
		// 320322310: '沛县人民医院',
		// 320322311: '大屯煤电集团公司职工中心医院',
		// 320322312: '张双楼煤矿医院',
		// 320324330: '睢宁县人民医院',
		// 320381297: '新沂市马站骨科医院',
		// 320381298: '新沂市仁济医院',
		// 320381299: '新沂市博爱医院',
		// 320381300: '新沂安定医院',
		// 320381301: '徐州铁路分局新沂铁路医院',
		// 320381302: '新沂市人民医院',
		// 320381303: '新沂市中医院',
		// 320382343: '邳州市传染病医院',
		// 320382344: '铁道部第二工程局第二工程处医院',
		// 320382345: '邳州市职工医院',
		// 320382346: '邳州王昕口腔诊所',
		// 320382347: '邳州同济医院',
		// 320382348: '邳州市第三人民医院',
		// 320382349: '邳州市中医院',
		// 320382350: '邳州市第二人民医院',
		// 320382351: '邳州市人民医院',
		// 320505718: '苏州市虎丘镇卫生院',
		// 320505719: '苏州市精神病福利院',
		// 320506521: '苏州市吴中区第二人民医院',
		// 320506522: '吴中区木渎人民医院',
		// 320506523: '苏州吴中经济开发区惠民门诊部',
		// 320506524: '苏州市吴中区长桥人民医院',
		// 320506525: '苏州市吴中皮肤病医院',
		// 320506526: '苏州市第七人民医院',
		// 320506527: '苏州市吴中人民医院',
		// 320506528: '苏州市沧浪医院',
		// 320506529: '苏州市吴中区老年病医院',
		// 320506530: '江苏省太湖采石公司医院',
		// 320506531: '苏州大学附属第一医院广慈分院',
		// 320506532: '苏州博大男科医院',
		// 320506533: '苏州瑞兴医院',
		// 320506534: '苏州市吴中区第三人民医院',
		// 320507685: '苏州市相城人民医院',
		// 320507686: '吴江市松陵卫生院',
		// 320507687: '吴江市平望镇梅埝卫生院',
		// 320507688: '相城人民医院（里口门诊部）',
		// 320507689: '相城区元和卫生院',
		// 320507690: '苏州市相城区元和蠡口社区卫生服...',
		// 320507691: '苏州市相城区元和泰元社区卫生服...',
		// 320507692: '苏州市相城区元和卫生院娄花卫生...',
		// 320507693: '苏州市相城区元和行政中心卫生所',
		// 320507694: '苏州市相城区湖沁社区卫生服务站',
		// 320507695: '苏州市相城区渭塘卫生院',
		// 320507696: '苏州市相城区渭塘新区诊所',
		// 320507697: '苏州市相城区渭北社区卫生服务站',
		// 320507698: '苏州市相城区渭西社区卫生服务站',
		// 320507699: '苏州市相城区太平街道卫生院',
		// 320507700: '苏州市相城区太平金澄社区卫生所',
		// 320507701: '苏州市相城区太平凤凰社区卫生所',
		// 320507702: '苏州市相城区太平卫生院沈桥卫生...',
		// 320507703: '苏州市相城区太平街道乐安社区卫...',
		// 320507704: '苏州市相城区阳澄湖（湘城）卫生...',
		// 320507705: '苏州市相城区陆巷社区卫生所',
		// 320507706: '苏州市相城区阳澄湖镇新康诊所',
		// 320507707: '苏州市相城区北桥卫生院',
		// 320507708: '苏州市相城区北桥寺泾门诊所',
		// 320507709: '苏州市相城区阳澄湖镇（田泾）卫...',
		// 320507710: '苏州市相城区阳澄湖镇消泾门诊部',
		// 320507711: '苏州市相城区黄埭镇黄埭超市门诊...',
		// 320507712: '苏州市相城区黄桥镇卫生院',
		// 320507713: '苏州市相城区黄桥镇中门诊部',
		// 320507714: '苏州市相城区东桥镇卫生院',
		// 320507715: '苏州市相城区东桥镇卫生院康太诊...',
		// 320507716: '苏州市相城区望亭望亭新区诊所',
		// 320507717: '苏州市相城区计划生育指导站',
		// 320508591: '苏州口腔医院',
		// 320508592: '苏州市平江区人民医院',
		320508593: '苏州市立医院东区支社',
		320508594: '苏州市中医医院',
		320508595: '苏州大学附属儿童医院',
		// 320508668: '中国人民解放军七三Ｏ三二部队医...',
		// 320508669: '苏州市第二人民医院',
		// 320508670: '苏州市第二人民医院苏州母子医疗...',
		// 320508671: '苏州眼耳鼻喉科医院',
		320508672: '苏州沧浪医院',
		// 320508673: '苏州100医院',
		// 320508674: '苏州市爱心护理院',
		// 320508675: '苏州同济医院',
		// 320508676: '中国人民解放军第一00医院（苏...',
		// 320508677: '苏州市眼耳鼻喉科医院',
		// 320508678: '苏州市第五人民医院',
		// 320508679: '苏州市华夏口腔医院',
		320508680: '苏州市立医院',
		320508681: '苏州市眼视光医院',
		320508682: '苏州大学附属第一医院',
		320508683: '苏州大学附属第二医院',
		320508684: '苏州第一人民医院',
		// 320508720: '苏州圣爱医院',
		// 320508721: '苏州美莱美容医院',
		// 320508722: '苏州工业园区登特口腔诊所',
		// 320508723: '苏州瑞芙臣美容医院',
		// 320508724: '苏州新民医院',
		// 320508725: '苏州高新区横塘人民医院',
		// 320508726: '苏州市红十字会老年康复医院',
		// 320508727: '苏州市金阊医院',
		// 320508728: '苏州市广济医院',
		320508729: '苏州市立医院北区',
		320508730: '苏州市中医院',
		// 320509535: '苏州大学附属第一医院盛泽分院',
		// 320509536: '苏州九龙医院（合资）',
		// 320509537: '吴江市妇幼保健所',
		// 320509538: '吴江市疾病控制中心门诊部',
		// 320509539: '吴江市桃源医院',
		// 320509540: '吴江市同里镇屯村卫生院',
		// 320509541: '吴江市铜罗卫生院',
		// 320509542: '吴江市横扇镇菀坪卫生院',
		// 320509543: '吴江市七都镇庙港卫生院',
		// 320509544: '吴江市七都镇卫生院',
		// 320509545: '吴江市横扇镇卫生院',
		// 320509546: '吴江市盛泽镇南麻卫生院',
		// 320509547: '吴江市康复医院',
		// 320509548: '苏州市吴中区浦庄镇卫生院',
		// 320509549: '吴江市震泽镇中心卫生院',
		// 320509550: '吴江市黎里镇中心卫生院',
		// 320509551: '吴江市第三人民医院',
		// 320509552: '吴江市第二人民医院',
		// 320509553: '吴江市第一人民医院',
		// 320581575: '常熟王庄医院',
		// 320581576: '常熟东南医院',
		// 320581577: '常熟瑞丽医院',
		// 320581578: '张家港市南丰镇医院',
		// 320581579: '常熟第一人民医院',
		// 320581580: '常熟市谢桥卫生院',
		// 320581581: '常熟市大义卫生院',
		// 320581582: '常熟市兴隆卫生院',
		// 320581583: '常熟市第六人民医院',
		// 320581584: '常熟市第五人民医院',
		// 320581585: '常熟市第二人民医院',
		// 320581586: '常熟市老年医院',
		// 320581587: '常熟市中医院',
		// 320581588: '常熟市第一人民医院',
		// 320581589: '常熟市第三人民医院',
		// 320581590: '常熟市妇幼保健院',
		// 320582596: '张家港朝阳五官科医院',
		320582597: '张家港澳洋医院',
		// 320582598: '张家港市大新镇医院',
		// 320582599: '张家港常阴沙医院',
		// 320582600: '张家港澳洋顺康医院',
		// 320582601: '张家港常阴沙医院',
		// 320582602: '张家港博爱医院',
		// 320582603: '张家港妙桥医院',
		// 320582604: '张家港鹿苑医院',
		// 320582605: '张家港万红医院',
		// 320582606: '张家港市第二人民医院',
		// 320582607: '张家港市乐余镇人民医院',
		// 320582608: '张家港市金港镇人民医院',
		// 320582609: '张家港大新卫生院',
		// 320582610: '张家港晨阳医院有限公司',
		// 320582611: '张家港中兴卫生院',
		// 320582612: '张家港双山卫生院',
		// 320582613: '张家港市香山医院有限公司',
		// 320582614: '张家港沙洲医院',
		// 320582615: '张家港市南丰卫生院',
		// 320582616: '常阴沙医院有限公司',
		// 320582617: '兆丰医院有限公司',
		// 320582618: '张家港澳洋顺慈医院有限公司[三...',
		// 320582619: '张家港市东沙医院',
		// 320582620: '张家港澳洋顺济医院有限公司[原...',
		// 320582621: '张家港市澳洋顺康医院[原塘市卫...',
		// 320582622: '张家港市鹿苑医院有限公司',
		// 320582623: '张家港乘航医院有限公司',
		// 320582624: '张家港中心医院有限公司[原泗港...',
		// 320582625: '张家港东莱医院有限公司',
		// 320582626: '张家港合兴医院有限公司',
		// 320582627: '张家港市妙桥医院有限责任公司',
		// 320582628: '张家港市凤凰镇医院',
		// 320582629: '张家港西张医院有限公司',
		// 320582630: '张家港市博爱医院有限公司',
		// 320582631: '张家港市妇幼保健院',
		// 320582632: '张家港市康乐医院',
		// 320582633: '张家港市疾病预防控制中心',
		// 320582634: '张家港市第三人民医院',
		// 320582635: '张家港市第一人民医院',
		// 320582636: '张家港市中医医院',
		// 320582637: '万春专科医院',
		// 320582638: '张家港市朝阳五官科医院',
		// 320582639: '澳洋医院',
		// 320890001: '淮安市徐扬乡卫生院',
		// 320890002: '淮安市清河医院',
		320890003: '淮安市第二人民医院',
		// 320583640: '昆山南站门诊部',
		// 320583641: '昆山清华医院',
		// 320583642: '昆山男科医院',
		// 320583643: '昆山中医院东方医院合作医院',
		// 320583644: '昆山第一人民医院东方医院',
		// 320583645: '昆山女子医院',
		// 320583646: '昆山仁济医院（民营）',
		// 320583647: '昆山金陵医院',
		// 320583648: '昆山市同心门诊部',
		// 320583649: '昆山市广慈医院',
		// 320583650: '昆山市第四人民医院',
		// 320583651: '江苏省昆山市周庄人民医院',
		// 320583652: '昆山市锦溪人民医院',
		// 320583653: '淀山湖人民医院',
		// 320583654: '昆山市千灯人民医院',
		// 320583655: '昆山市花桥人民医院',
		// 320583656: '开发区蓬朗医院',
		// 320583657: '周市镇人民医院',
		// 320583658: '昆山市周市镇陆杨医院',
		// 320583659: '巴城镇人民医院',
		// 320583660: '昆山市巴城镇正仪医院',
		// 320583661: '昆山市肾脏病诊疗中心',
		// 320583662: '昆山市第六人民医院',
		// 320583663: '昆山市红十字中医院',
		// 320583664: '昆山市第一人民医院',
		320583665: '昆山市中医院',
		// 320583666: '昆山市第三人民医院',
		// 320583667: '昆山市第二人民医院',
		// 320585554: '太仓男科医院',
		// 320585555: '太仓华山医院',
		// 320585556: '太仓市王秀卫生院',
		// 320585557: '太仓市归庄卫生院',
		// 320585558: '太仓市老闸卫生院',
		// 320585559: '太仓市新毛卫生院',
		// 320585560: '太仓市新湖卫生院',
		// 320585561: '太仓市时思卫生院',
		// 320585562: '太仓市牌楼卫生院',
		// 320585563: '太仓市港区医院',
		// 320585564: '太仓市金浪卫生院',
		// 320585565: '太仓市鹿河卫生院',
		// 320585566: '太仓市城厢镇卫生院',
		// 320585567: '太仓市岳王卫生院',
		// 320585568: '太仓市直塘卫生院',
		// 320585569: '太仓市双凤卫生院',
		// 320585570: '太仓友谊医院',
		// 320585571: '太仓市沙溪人民医院',
		// 320585572: '太仓市中医院',
		// 320585573: '太仓市第二人民医院',
		// 320585574: '太仓市第一人民医院',
		// 320602159: '南通文峰医院',
		// 320602160: '南通市第三人民医院',
		320602161: '南通大学附属医院',
		320602162: '南通市肿瘤医院南院',
		320602163: '南通中医院',
		320602164: '南通市肿瘤医院北院',
		320602165: '南通市中医院',
		320602166: '南通大学院附属医院',
		320602167: '南通市中西医结合医院',
		320602168: '南通市妇幼保健院',
		320602169: '南通市第一人民医院',
		// 320602170: '南通市朝阳区医院通棉二厂职工医...',
		// 320602171: '南通市老年康复医院',
		// 320602172: '南通市妇幼保健所',
		// 320602173: '南通长城医院',
		// 320602174: '南通眼病医院（民营）',
		// 320611185: '南通大生集团有限公司职工医院',
		// 320611186: '南通市第四人民医院',
		// 320611187: '南通市第二人民医院',
		// 320611188: '南通市肺科医院（原南通市结核病...',
		// 320612189: '通州人民医院',
		// 320612190: '南通通州区中医院',
		// 320612191: '通州市博爱医院',
		// 320612192: '通州市结核病防治院',
		// 320612193: '通州市中医院',
		// 320612194: '通州市人民医院',
		// 320623153: '如东县丰利医院',
		// 320623154: '如东县第三人民医院',
		// 320623155: '如东县中医院',
		// 320623156: '如东县人民医院',
		// 320681148: '启东市第四人民医院',
		// 320681149: '启东市第二人民医院',
		// 320681150: '启东市人民医院',
		// 320681151: '启东市中医院',
		// 320681152: '启东市近海医院',
		// 320682157: '如皋市人民医院',
		// 320682158: '如皋市中医院',
		// 320684178: '江苏省国营江心沙农场医院',
		// 320684179: '海门女子医院',
		// 320684180: '海门中山医院',
		// 320684181: '海门市妇幼保健所',
		// 320684182: '海门市第四人民医院',
		// 320684183: '海门市人民医院',
		// 320684184: '海门市中医院',
		// 320685175: '海安生殖健康医院',
		// 320685176: '海安县中医院',
		// 320685177: '海安县人民医院',
		// 320703751: '连云港台北医院',
		// 320703752: '金桥盐业公司台南盐场医院',
		// 320703753: '金桥盐业公司徐圩盐场医院',
		// 320703754: '南化(集团)公司连云港碱厂职工...',
		// 320703755: '连云港市第二人民医院连云港市肿...',
		// 320703756: '连云港市恒康医院',
		// 320703757: '连云港市第三人民医院',
		// 320703758: '连云港圣良骨科医院',
		// 320703759: '连云港友好医院',
		// 320703760: '连云港海港医院',
		// 320703761: '连云港长寿医院',
		// 320703762: '连云港市新浦区人民医院',
		320703763: '连云港市东方医院',
		// 320703764: '连云港市海港医院',
		// 320706733: '嘉家医院',
		// 320706734: '连云港明仁医院',
		// 320706735: '连云港光明医院',
		// 320706736: '连云港市友好医院',
		// 320706737: '连云港市第二人民医院东院区',
		// 320706738: '连云港盐业公司总医院',
		320706739: '连云港市中医院',
		320706740: '连云港市第一人民医院',
		// 320706741: '阜新市海州区东梁医院',
		// 320706742: '连云港市第二人民医院西院区',
		// 320722731: '东海仁慈医院',
		// 320722732: '东海县人民医院',
		320723743: '灌云县博爱医院',
		320723744: '连云港市东辛农场医院',
		// 320723745: '灌云县永泰医院',
		// 320723746: '灌云监狱医院',
		// 320723747: '灌云县人民医院',
		// 320724748: '秦礼洲五官科医院',
		// 320724749: '灌南县人民医院',
		// 320902480: '盐城盐阜医院',
		320902481: '盐城第一人民医院',
		// 320902482: '盐城市东仁医院（民营）',
		320902483: '盐城城南医院',
		// 320902484: '盐城市文峰医院',
		// 320903513: '盐城市第四人民医院',
		320903514: '盐城市妇幼保健院',
		320903515: '盐城市第一人民医院',
		// 320903516: '盐城市慈航医院',
		320903517: '盐城市中医院',
		320904490: '大丰市精神病医院',
		// 320904491: '大丰新华医院',
		// 320904492: '大丰市三圩爱民医院',
		// 320904493: '大丰市仁爱骨质增生专科医院',
		// 320904494: '大丰市人民医院',
		320921485: '响水县东方康复医院',
		320921486: '江苏省黄海农场医院',
		// 320921487: '响水嘉明医院',
		// 320921488: '响水现代医院',
		// 320921489: '江苏省银宝盐业有限公司卫生所',
		320922506: '滨海城北医院',
		320922507: '滨海淤尖医院',
		320922508: '滨海阜东医院',
		320922509: '江苏省新滩盐场医院',
		// 320922510: '滨海县虹济医院',
		// 320922511: '滨海县第二人民医院',
		// 320922512: '滨海县人民医院',
		320923518: '阜宁县城北医院',
		// 320923519: '阜宁县中医院',
		// 320923520: '阜宁县人民医院',
		320924495: '江苏省国营新洋农场医院',
		320924496: '江苏省国营淮海农场医院',
		// 320924497: '射阳县新洋农场医院',
		// 320924498: '江苏省农垦肿瘤医院',
		// 320924499: '射阳县中医院',
		// 320924500: '射阳县人民医院',
		// 320924501: '射阳县洋马镇卫生院',
		// 320924502: '射阳县临海中心卫生院',
		// 320925503: '建湖县建阳眼科医院',
		// 320925504: '建湖县第二人民医院',
		// 320925505: '建湖县人民医院',
		320981471: '东台市弶港镇医院',
		// 320981472: '东台东津医院',
		// 320981473: '东台台东医院',
		// 320981474: '东台市精神病医院',
		// 320981475: '东台北海骨科医院',
		// 320981476: '东台富腾微创医院',
		// 320981477: '东台市新曹农场医院',
		// 320981478: '东台市中医院',
		// 320981479: '东台市人民医院',
		// 321002364: '江苏省扬州五台山医院',
		// 321002365: '扬州华东慧康医院',
		// 321002366: '扬州仁爱医院',
		// 321002367: '扬州城东医院',
		// 321002368: '扬州蓝天医院',
		321002369: '江苏省武警医院',
		321002370: '扬州市第一人民医院',
		321002371: '扬州市苏北人民医院',
		// 321002372: '扬州市广陵医院',
		// 321002373: '扬州市第三人民医院',
		// 321002374: '扬州市妇幼保健院',
		321002375: '江苏省苏北人民医院',
		321002376: '扬州市中医院',
		321002377: '扬州中医院',
		// 321002378: '扬州市口腔医院',
		// 321003384: '扬州市第二人民医院',
		// 321003385: '扬州市第四人民医院',
		// 321003386: '扬州扬子妇科医院',
		// 321003387: '扬州市邗江区中医院',
		// 321003388: '扬州市邗江区人民医院',
		// 321003389: '江苏省苏北人民医院新区分院',
		// 321003390: '华东石油地质医院',
		// 321012379: '扬州友好医院',
		// 321012380: '扬州洪泉医院',
		// 321012381: '江都市人民医院',
		321012382: '武警江苏总队医院',
		// 321012383: '扬州市友好医院',
		321023359: '宝应县韦镇医院',
		// 321023360: '宝应县中港医院',
		// 321023361: '宝应县伤骨科医院',
		// 321023362: '宝应县中医医院',
		// 321023363: '宝应县人民医院',
		// 321081356: '南京医科大学第三附属医院',
		// 321081357: '仪征市人民医院',
		// 321081358: '中国核工业总公司华兴建设公司职...',
		// 321084391: '高邮光明眼科医院',
		// 321084392: '高邮市人民医院',
		// 321102776: '镇江市中医院',
		// 321102777: '镇江市东吴医院',
		321102778: '镇江市第四人民医院',
		321102779: '江苏大学附属医院',
		// 321102780: '镇江市第二人民医院',
		321102781: '解放军第三五九医院',
		// 321111791: '镇江市痔科医院',
		// 321111792: '中国地方煤矿镇江职业病医院',
		// 321111793: '镇江市润州区南徐医院',
		// 321111794: '镇江中山医院',
		// 321111795: '镇江市润州区妇幼保健所',
		// 321111796: '镇江市润州区韦岗医院',
		// 321111797: '镇江市润州区金江医院',
		// 321111798: '镇江市润州区黄山医院',
		321111799: '镇江市第一人民医院',
		321111800: '镇江市第三人民医院',
		321112765: '镇江市丹徒区中医院',
		// 321112766: '镇江迎江医院',
		// 321112767: '镇江云台医院',
		// 321112768: '镇江市丹徒区人民医院',
		// 321112769: '镇江市丹徒区第二人民医院',
		// 321181770: '丹阳华山医院',
		// 321181771: '丹阳曙光医院',
		// 321181772: '丹阳市儿童医院',
		// 321181773: '丹阳市第二人民医院',
		// 321181774: '丹阳市中医院',
		// 321181775: '丹阳市人民医院',
		// 321182788: '扬中八桥医院',
		// 321182789: '扬中市中医院',
		// 321182790: '扬中市人民医院',
		// 321183782: '句容市妇幼保健院',
		// 321183783: '江苏省句东劳动教养管理所医院',
		// 321183784: '江苏省未成年犯管教所医院',
		// 321183785: '江苏省边城监狱医院',
		// 321183786: '江苏省句容监狱医院',
		// 321183787: '句容市人民医院',
		// 321302195: '宿城区郑楼医院',
		// 321302196: '宿城区中扬医院',
		// 321302197: '宿城区仓集医院',
		// 321302198: '宿城区洋河仁爱医院',
		// 321302199: '宿城区埠子医院',
		// 321302200: '宿城区耿车医院',
		// 321302201: '宿城区罗圩乡医院',
		// 321302202: '宿城区红十字医院',
		// 321302203: '宿迁市宿城区双庄镇支口卫生院',
		// 321302204: '宿城区陈集镇医院',
		// 321302205: '宿迁市宿城区双庄镇医院',
		// 321302206: '宿迁现代医院',
		// 321302207: '宿城区洋北镇医院',
		// 321302208: '宿迁市宿城区项里医院',
		321302209: '宿豫区新庄镇医院',
		321302210: '宿豫区王官集镇医院',
		// 321302211: '宿迁市儿童医院',
		// 321302212: '宿迁市仁济骨科医院',
		// 321302213: '宿迁市人民医院',
		321302214: '宿迁市中医院',
		// 321302215: '宿迁市钟吾医院',
		// 321302216: '宿迁市工人医院',
		// 321302217: '宿迁市妇产医院',
		// 321322223: '沭阳马厂医院',
		// 321322224: '沭阳吴集医院',
		// 321322225: '沐阳塘沟医院',
		// 321322226: '沭阳中山医院',
		// 321322227: '沭阳脑科医院',
		321322228: '沭阳十字医院',
		// 321322229: '沭阳扎下医院',
		// 321322230: '沭阳县长城医院',
		// 321322231: '唐氏雀斑专科',
		// 321322232: '沭阳哮喘病医院',
		// 321322233: '沭阳县人民医院',
		// 321323260: '泗阳县口腔医院',
		321323261: '泗阳县魏圩医院',
		321323262: '泗阳县城南医院',
		// 321323263: '泗阳大众医院',
		321323264: '泗阳县同济医院',
		// 321323265: '泗阳博爱医院',
		// 321323266: '泗阳泗沭医院',
		// 321323267: '泗阳卫民医院',
		// 321323268: '泗阳淮泗医院',
		// 321323269: '泗阳协和医院',
		// 321323270: '泗阳县佑安医院',
		// 321323271: '泗阳县瑞鑫医院',
		// 321323272: '泗阳五官科医院',
		// 321323273: '泗阳县精神病防治院',
		// 321323274: '泗阳县第三人民医院',
		// 321323275: '泗阳爱心医院',
		// 321323276: '泗阳县临河医院',
		// 321323277: '泗阳县人民医院',
		// 321323278: '泗阳县中医院',
		321324234: '泗洪县天岗湖医院',
		// 321324235: '泗洪县魏营医院',
		321324236: '泗洪县车门医院',
		// 321324237: '泗洪县梅花医院',
		// 321324238: '泗洪县归仁医院',
		321324239: '泗洪县朱湖医院',
		321324240: '泗洪县曹庙医院',
		// 321324241: '泗洪县龙集医院',
		// 321324242: '泗洪县孙园医院',
		// 321324243: '泗洪县陈圩医院',
		// 321324244: '泗洪县临淮医院',
		321324245: '泗洪县城头医院',
		// 321324246: '泗洪县双沟医院',
		// 321324247: '泗洪县石集医院',
		// 321324248: '泗洪县青阳医院',
		// 321324249: '泗洪县康复医院',
		321324250: '泗洪县峰山医院',
		321324251: '泗洪县四河医院',
		// 321324252: '泗洪县界集医院',
		// 321324253: '泗洪县半城医院',
		// 321324254: '泗洪县金镇医院',
		// 321324255: '泗洪县洪泽湖监狱医院',
		// 321324256: '泗洪县分金亭肿瘤专科医院',
		// 321324257: '泗洪县中医院',
		// 321324258: '泗洪县人民医院',
		// 321324259: '宿迁市康宁医院',
		// 321311218: '来龙镇安康医院',
		321311219: '宿豫区井头镇医院',
		321311220: '宿豫区丁嘴镇医院',
		321311221: '宿豫区章山林场医院',
		// 321311222: '宿豫县神经病医院',
		// 321311750: '赣榆县人民医院',
		// 420102120: '武汉市普仁医院江岸医院',
		420102121: '中国人民解放军第457医院',
		// 420102122: '武汉美基元医疗美容门诊部',
		// 420102123: '武汉市江岸中爱医疗美容门诊部',
		420102124: '武汉百佳妇产医院',
		420102125: '武汉脑科医院',
		// 420102126: '武汉现十代男科医院',
		// 420102127: '武汉一方堂研究所',
		// 420102128: '武汉国医世纪胃肠研究院',
		// 420102129: '武汉爱尚整形医疗美容门诊部',
		// 420102130: '武汉虎泉医院',
		420102131: '武汉市儿童医院',
		420102132: '武汉161医院',
		// 420102133: '武汉新特中医医院',
		// 420102134: '武汉市江岸区劳动医院',
		// 420102135: '武汉市江岸区朝阳医院同济朝港医...',
		// 420102136: '华中航运集团有限公司武汉华泰医...',
		420102137: '武汉长江航运总医院',
		420102138: '武汉市第六医院',
		420102139: '武汉大学中山医院',
		// 420102140: '华中科技大学同济医学院附属同济...',
		420102141: '武汉市中医医院',
		420102142: '武汉市传染病医院',
		// 420102143: '华中科技大学同济医学院附属协和...',
		420102144: '湖北省新华医院中山分院',
		420102145: '武汉市中心医院',
		// 420103146: '武汉天霖中西医结合肾病医院',
		// 420103147: '武汉韩辰医疗美容医院',
		// 420103148: '武汉漾颜医疗美容门诊部',
		// 420103149: '武汉抗白白癜风专科门诊部',
		// 420103150: '江汉石油管理局第二职工医院',
		// 420103151: '武汉世纪国医堂医院',
		// 420103152: '武汉民生耳鼻喉专科医院',
		// 420103153: '武汉艾格眼科医院',
		// 420103154: '武汉普济肝病医院',
		// 420103155: '武汉红桥脑科医院',
		// 420103156: '武汉市汉口医院',
		// 420103157: '江汉油田总医院',
		// 420103158: '武汉市第八医院南院',
		// 420103159: '武汉市第八医院',
		420103160: '湖北省新华医院',
		// 420103161: '武汉市商业职工医院',
		// 420103162: '湖北省新华医院江北分院',
		// 420103163: '武汉市第十一医院',
		// 420104183: '武汉北大白癜风医院',
		// 420104184: '武汉叶子整形医院',
		// 420104185: '武汉京科联合牛皮癣研究所',
		// 420104186: '武汉南威医院',
		// 420104187: '武汉仁爱植发',
		// 420104188: '汉口爱尔眼科医院',
		// 420104189: '武汉长诚医院',
		// 420104190: '武汉天一中医医院',
		420104191: '武汉市第四医院',
		420104192: '武汉精神卫生中心',
		// 420104193: '武汉市第十四医院',
		420104194: '武汉市中西医院结合医院',
		420104195: '武汉市中西医结合医院',
		420104196: '武汉亚洲心脏病医院',
		420104197: '武汉首大发育医学研究院',
		420104198: '武汉市结核病防治所',
		420104199: '武汉市皮肤病防治研究所',
		420104200: '七五二厂职工医院',
		// 420105112: '中国人民解放军三七Ｏ医院',
		// 420105113: '华康矫形专科医院（民营）',
		// 420105114: '武汉汉纸医院',
		// 420105115: '武汉仁济中西医结合医院',
		// 420105116: '武汉冠美口腔医院',
		// 420105117: '武汉市汉阳区人民医院',
		420105118: '武汉市商业职工医院汉阳分院',
		420105119: '武汉大学广慈医院',
		// 420106075: '爱尔眼科医院（民营）',
		// 420106076: '铁道部武汉工程机械厂医院',
		// 420106077: '铁道部第四勘测设计院医院',
		// 420106078: '武重医院（民营）',
		// 420106079: '中南财经政法大学医院',
		420106080: '武汉玛丽亚妇产医院',
		// 420106081: '铁道部第十一工程局中心医院',
		// 420106082: '武汉中原医院',
		// 420106083: '武汉紫荆医院',
		420106084: '武汉科技大学附属天佑医院',
		// 420106085: '华中科技大学同济医学院附属梨园...',
		// 420106086: '武汉爱普之光眼科医院',
		// 420106087: '武汉亚太整形美容医院',
		// 420106088: '武汉东方丽人医院',
		// 420106089: '武汉名仕泌尿外科医院',
		420106090: '梨园医院',
		// 420106091: '铁四院医院',
		// 420106092: '武汉京都结石病医院',
		// 420106093: '武汉市武昌康复护理医院',
		// 420106094: '湖北省地质矿产局职工医院',
		420106095: '武昌铁路医院',
		420106096: '武汉市三医院',
		420106097: '武警湖北总队医院',
		420106098: '湖北省人民医院',
		420106099: '武汉新世纪中西医结合医院',
		420106100: '武汉科技大学附属医院',
		420106101: '湖北省中医院光谷院区',
		// 420106102: '爱尔眼科医院',
		// 420106103: '武汉市第七医院',
		420106104: '武汉大学中南医院',
		420106105: '湖北省新华医院民航分院',
		420106106: '武汉市第三医院',
		// 420106107: '武汉铁路分局武昌医院',
		// 420106108: '武汉中翰整形美容医院',
		// 420106109: '武汉曙光男科医院',
		// 420106110: '武汉曙光中西医结合医院',
		420107202: '武汉市普仁医院广惠分院',
		420107203: '武钢重工集团冶金医院',
		420107204: '武汉市青山区第一医院',
		420107205: '武汉市武东医院',
		420107206: '武汉市第九医院',
		420107207: '武汉钢铁公司职工总医院',
		420107208: '武钢总医院',
		// 420111164: '武汉美立方医疗美容门诊部',
		// 420111165: '武汉武中精神病医院',
		// 420111166: '武汉市洪山区珞南街社区卫生服务...',
		// 420111167: '武汉市第十二医院',
		420111168: '湖北省妇幼保健院',
		420111169: '湖北省口腔医院',
		420111170: '湖北省肿瘤医院',
		420111171: '武汉老年病医院',
		420111172: '湖北六七二中西医结合骨科医院',
		420111173: '武汉华夏医院',
		420111174: '广州军区武汉总医院',
		// 420111175: '武汉市公安局安康医院',
		// 420111176: '华中师范大学医院',
		// 420111177: '中国建筑三局第二工程公司职工医...',
		// 420111178: '中国地质大学医院',
		// 420111179: '国营七三三厂职工医院',
		// 420111180: '中国长江动力公司（集团）职工医...',
		// 420111181: '华中科技大学医院',
		// 420111182: '武汉市洪山区建设乡卫生院',
		// 420112069: '武汉东西湖养殖场何家庙职工医院',
		// 420112070: '武汉市东西湖区人民医院',
		420112071: '武汉医疗救治中心',
		// 420113111: '武汉市汉南区人民医院',
		420114201: '武汉市第十三医院',
		// 420116209: '武汉市黄陂区疾病预防控制中心',
		// 420116210: '武汉市黄陂区精神防治医院',
		// 420116211: '武汉市黄陂区人民医院',
		// 420116212: '武汉市黄陂区中医医院',
		// 420117072: '武汉市新洲区第二人民医院',
		// 420117073: '武汉市新洲区中医院',
		// 420117074: '武汉市新洲区人民医院',
		// 420302023: '十堰市茅箭区人民医院',
		// 420302024: '十堰市妇幼保健院',
		// 420302025: '十堰铁路医院',
		// 420302026: '东风轮胎集团公司职工医院',
		// 420302027: '东风汽车公司茅箭医院',
		420302028: '十堰市太和医院',
		// 420302029: '二机电安装公司职工医院',
		420302030: '十堰太和医院',
		// 420302031: '郧阳医学院白浪医院',
		420303010: '十堰人民医院',
		// 420303011: '东风公司茅箭医院',
		420303012: '十堰市人民医院',
		// 420303013: '东风轮胎集团公司总医院',
		// 420303014: '东风公司花果医院',
		420303015: '东风公司总医院',
		// 420303016: '二汽第四医院',
		// 420304032: '郧县人民医院',
		// 420322033: '郧西县妇幼保健院',
		// 420322034: '郧西县人民医院',
		420323018: '竹山县妇幼保健院',
		// 420323019: '竹山县第二人民医院',
		// 420323020: '十堰市按摩医院铁三处医院',
		// 420324021: '竹溪县妇幼保健院',
		// 420324022: '龙坝卫生院',
		// 420325017: '房县人民医院',
		// 420381000: '汉江集团汉江医院',
		// 420381001: '湖北省武当山医院',
		// 420381002: '江口县人民医院',
		// 420381003: '丹江工程局职工医院',
		// 420381004: '丹江口卫校附属医院',
		// 420381005: '丹江口市妇幼保健院',
		// 420381006: '丹江口市中医院',
		// 420381007: '丹江口市第二医院',
		// 420381008: '丹江口市第一医院',
		420381009: '十堰市中医院',
		420703213: '鄂州市华容区临江卫生院',
		// 420704214: '鄂州市疾病预防控制中心',
		// 420704215: '鄂州二医院',
		// 420704216: '鄂州康复中心',
		// 420704217: '鄂州市妇幼保健院',
		// 420704218: '鄂州市第三医院自愿戒毒所',
		420704219: '鄂州市中医院',
		420704220: '鄂州市中心医院',
		// 420902043: '孝棉集团公司医院',
		// 420902044: '国营万山特种车辆制造厂职工医院',
		// 420902045: '国营四四零四厂职工医院',
		// 420902046: '孝感东方妇科医院',
		// 420902047: '孝感市中医医院',
		// 420902048: '孝感市第一人民医院',
		// 420902049: '孝感市妇幼保健院',
		420902050: '孝感市中心医院',
		// 420921051: '孝昌县中医院',
		// 420921052: '孝昌县人民医院',
		// 420922037: '大悟县河口第二人民医院',
		// 420922038: '大悟县人民医院',
		// 420922039: '大悟县城关镇卫生院',
		// 420922040: '大悟县卫生防疫站',
		// 420922041: '黄麦岭磷化工集团公司职工医院',
		// 420922042: '大悟县皮防所',
		// 420923035: '云梦县中医院',
		// 420923036: '云梦县人民医院',
		// 420981059: '应城市妇幼保健院',
		// 420981060: '应城市人民医院',
		// 420981061: '应城市中医院',
		// 420981062: '应城市杨岭镇中心卫生院',
		// 420982053: '安陆市第二人民医院',
		// 420982054: '安陆市中医院',
		// 420982055: '安陆市蒲爱医院',
		// 420982056: '安陆市计划生育服务站',
		// 420982057: '粮食机械厂职工医院',
		// 420982058: '铝业集团有限责任公司职工医院',
		// 420984063: '三五零九工厂职工医院',
		// 420984064: '汉川市血防医院',
		// 420984065: '汉川市妇幼保健院',
		// 420984066: '汉川市第二人民医院',
		// 420984067: '汉川市中医院',
		// 420984068: '汉川市人民医院',
		// 421303224: '曾都区洪山医院',
		// 421303225: '随州新华中西医结合医院',
		// 421303226: '随州市妇幼保健院',
		// 421303227: '随州市第二人民医院',
		421303228: '随州市中心医院',
		421303229: '曾都区白云医院',
		// 421381221: '广水市中医院',
		// 421381222: '广水市第二人民医院',
		// 421381223: '广水市第一人民医院',
		// 650102004: '新疆福寿康医院',
		// 650102005: '乌鲁木齐都市丽人医院',
		// 650102006: '乌鲁木齐男健医院',
		// 650102007: '乌鲁木齐中山医院',
		// 650102008: '乌鲁木齐美奥口腔医院',
		650102009: '中国人民解放军乌鲁木齐23医院',
		// 650102010: '乌鲁木齐普瑞眼科医院',
		// 650102011: '五洲男科医院',
		// 650102012: '新疆医科大学第六附属医院',
		650102013: '乌鲁木齐市妇幼保健院',
		650102014: '新疆生产建设兵团总医院',
		650102015: '新疆自治区人民医院',
		650102016: '乌鲁木齐市友谊医院',
		// 650102017: '中国人民解放军第二十三医院',
		// 650102018: '乌鲁木齐市一零四团医院',
		// 650102019: '乌鲁木齐市第一人民医院',
		// 650102020: '乌鲁木齐市一Ｏ四团医院',
		// 650102021: '乌鲁木齐市五星截瘫医院',
		// 650102022: '乌鲁木齐市精神病院',
		650102023: '新疆兵团肿瘤医院',
		650102024: '新疆生产建设兵团医院',
		650102025: '兰州军区乌鲁木齐总医院',
		// 650102026: '新疆佳音医院',
		650102027: '新疆兵团肿瘤医院',
		650102028: '新疆维吾尔自治区中医医院',
		// 650102029: '新疆天山医院',
		// 650102030: '乌鲁木齐市眼耳鼻喉专科医院',
		// 650102031: '乌鲁木齐市南门第二中医院',
		650102032: '新疆自治区人民医院第一附属医院',
		650102033: '新疆医科大学附属中医医院',
		// 650102034: '新疆建工医院新疆骨科（脊柱外科...',
		// 650102035: '新疆维吾尔自治区建工医院',
		650103054: '乌鲁木齐新光明医院',
		// 650103055: '乌鲁木齐市西山医院',
		// 650103056: '新疆自治区人民医院分院',
		// 650103057: '新疆煤矿总医院',
		// 650103058: '中国人民武装警察部队新疆总队医...',
		// 650103059: '乌鲁木齐大西北皮肤病医院',
		650103060: '乌鲁木齐市中医院',
		650103061: '乌鲁木齐市口腔医院',
		// 650104039: '乌鲁木齐市第1人民医院',
		650104040: '新疆心脑血管病医院',
		// 650104041: '乌鲁木齐市安宁医院',
		650104042: '新疆维吾尔自治区肿瘤医院',
		650104043: '中国人民解放军第四七四医院',
		650104044: '新疆医科大学第一附属医院',
		650104045: '新疆医科大学第五附属医院',
		650104046: '中国人民解放军第474医院',
		// 650105047: '新疆纺织医院',
		650105048: '乌鲁木齐石油天然气运输公司医',
		// 650105049: '新疆纺织职工医院',
		// 650105050: '新疆医科大学第二附属医院七道湾...',
		650105051: '新疆医科大学第二附属医院',
		// 650105052: '新疆自治区第一济困医院',
		650105053: '新疆创伤外科医院',
		// 650106036: '乌鲁木齐市精神病福利院',
		// 650106037: '新疆八一钢铁公司医院',
		// 650106038: '鲁木齐铁路局乌西医院',
		// 650121000: '乌鲁木齐石油天然气运输公司医...',
		// 650121001: '乌鲁木齐石油化工总厂医院',
		// 650121002: '西山铁路医院',
		// 650121003: '乌鲁木齐县人民医院',
		654002062: '新疆伊犁哈萨克自治州友谊医院',
		// 654002063: '伊犁哈萨克自治州中医医院',
		// 654002064: '伊犁哈萨克自治州妇幼保健院',
		// 654002065: '新疆伊犁市人民医院',
		// 654002066: '新疆伊犁地区人民医院',
		// 654002067: '武警8660部队医院',
		// 654002068: '伊犁哈萨克自治州精神病院',
		// 654002069: '伊宁县人民医院',
		// 654002070: '新疆兵团农四师医院',
		// 654002071: '新疆伊犁州中医院',
		// 654002072: '伊宁市人民医院',
		// 654002073: '伊犁州民政医院',
		// 654002074: '新疆伊犁哈萨克自治州妇幼保健院',
		// 654003075: '新疆伊犁州奎屯市兵团农七师一三...',
		654003076: '新疆伊犁州奎屯医院',
		// 654003077: '新疆兵团奎屯医院',
		// 654003078: '新疆伊犁州奎屯市兵团农七师一二...',
		// 654003079: '奎屯铁路医院',
		// 654003080: '奎屯市农七师棉纺织厂职工医院',
		// 654003081: '奎屯市激光医疗中心',
		// 654003082: '奎屯农七师一建职工医院',
		// 654003083: '奎屯市妇幼保健院',
		// 654003084: '奎屯市人民医院',
		654003085: '伊犁哈萨克自治州奎屯医院',
		// 654022086: '新疆察布查尔锡伯自治县人民医院',
		// 654022087: '察布查尔县妇幼保健站',
		// 654023096: '霍城县妇幼保健站',
		// 654023097: '新疆伊犁兵团农四师六十一团医院',
		// 654023098: '兵团农四师六十六团医院',
		// 654023099: '霍城县第二人民医院',
		// 654023100: '霍城县第一人民医院',
		// 654023101: '霍城县中医院',
		// 654024089: '巩留县康复医院',
		// 654024090: '巩留县妇幼保健站',
		// 654025091: '新疆伊犁农四师七十一团医院',
		// 654025092: '新源县人民医院',
		// 654025093: '新疆伊犁新源县人民医院',
		// 654026094: '新疆伊犁兵团农四师昭管处医院',
		// 654027095: '特克斯县人民医院',
		// 654028088: '尼勒克县人民医院',
		// 510104153: '空军成都医院中国人民解放军第四...',
		// 510104154: '成都市针灸按摩专科医院',
		// 510104155: '成都骨科医院',
		// 510104156: '成都皮肤研究所',
		// 510104157: '成都五洲儿童医学研究中心',
		// 510104158: '成都市锦江区第二人民医院',
		// 510104159: '成都南光实业股份有限公司医院',
		// 510104160: '四川省直属机关第一医院',
		// 510104161: '成都锦江中医专科医院',
		// 510104162: '成都成量集团公司医院',
		510104163: '成都市锦江区妇幼保健院',
		// 510104164: '成都市锦江区第三人民医院',
		// 510104165: '成都市锦江区人民医院',
		510104166: '四川省第四人民医院',
		// 510104167: '成都无缝钢管厂职工医院',
		510104168: '成都市第一人民医院分院',
		510104169: '成都市第二人民医院',
		510104170: '成都市第十人民医院',
		// 510104171: '成都中仁泌尿外科医院',
		// 510104172: '成都锦江颈腰椎病医院',
		// 510104173: '成都长峰医院',
		// 510104174: '成都锦江电器制造有限公司医院',
		// 510105182: '西南肝病研究院',
		// 510105183: '成都仁品耳鼻喉医院',
		// 510105184: '成都军区联勤部机关医院',
		// 510105185: '成都华山肝病医院',
		// 510105186: '都江堰嘉昊眼科医院',
		// 510105187: '华阳彤济医院',
		// 510105188: '成都安定医院',
		510105189: '四川消防医院胃肠诊疗中心',
		// 510105190: '武警四川省消防总队医院胃肠诊疗...',
		// 510105191: '成都圣贝牙科医院',
		510105192: '成都肛肠专科医院',
		// 510105193: '成都市青羊区人民医院',
		// 510105194: '成都儿童专科医院',
		// 510105195: '成都市肺结核医院',
		// 510105196: '成都市城建医院',
		// 510105197: '成都市青羊区妇幼保健院',
		// 510105198: '成都市青羊区第十人民医院',
		// 510105199: '成都市青羊区第九人民医院',
		510105200: '成飞医院',
		// 510105201: '成都心血管病医院',
		// 510105202: '四川省第五人民医院',
		// 510105203: '成都市儿童医院',
		// 510105204: '成都市第九人民医院',
		510105205: '四川省人民医院',
		510105206: '成都市妇幼保健院',
		510105207: '成都市第三人民医院',
		510105208: '四川省第三人民医院',
		510105209: '成都第三人民医院',
		// 510105210: '成都安定医院',
		510105211: '中国人民解放军成都军区总医院',
		// 510105212: '四川省红十字肿瘤医院',
		// 510105213: '四川武警消防总队医院耳鼻喉科',
		// 510105214: '成都市青羊区草堂社区卫生服务中...',
		// 510105215: '成都中医药大学第三附属医院',
		// 510105216: '铁道第二勘察设计院医院',
		510105217: '武警四川省消防总队医院',
		// 510105218: '成都市青羊区中医院',
		510105219: '成都军区八一骨科医院',
		// 510105220: '成都民政局精神病院',
		// 510105221: '成都市仁济医院',
		// 510106129: '成都玛诗特肿瘤医院',
		// 510106130: '成都骨伤医院',
		// 510106131: '四川必有医院',
		// 510106132: '四川天祥骨科医院',
		510106133: '成都市金牛区妇幼保健院',
		// 510106134: '成都市金牛区人民医院',
		// 510106135: '成都铁路分局医院',
		// 510106136: '成都市建工医院',
		// 510106137: '成都市金牛区中医院',
		// 510106138: '四川省林业中心医院',
		// 510106139: '成都第五冶职工医院',
		// 510106140: '四川省建筑医院',
		510106141: '成都铁路局中心医院',
		510106142: '成都市第八人民医院',
		510106143: '成都军区总医院',
		510106144: '成都中医药大学附属医院',
		510106145: '四川省妇幼保健院',
		// 510106146: '金牛城北梁家巷中西医门诊部',
		// 510106147: '四川省建筑医院',
		// 510106148: '铁道部第二勘测设计院医院',
		// 510106149: '前锋电子股份有限公司职工医院',
		// 510106150: '电子工业部第十研究所职工医院',
		// 510106151: '中国五冶医院',
		// 510106152: '中铁二局集团中心医院',
		// 510107051: '四川何氏骨科医院',
		510107052: '四川大学华西第四医院',
		// 510107053: '四川大学华西第一医院西藏成办分...',
		// 510107054: '成都市武侯区第五人民医院',
		// 510107055: '四川长城肾脏病医院',
		// 510107056: '成都华西牙种植医院',
		// 510107057: '四川省中医药研究院附属医院',
		// 510107058: '成都市武侯区人民医院',
		// 510107059: '四川省交通厅公路局成都医院',
		// 510107060: '成都市武侯区第三人民医院',
		// 510107061: '四川省国防医院',
		510107062: '成都三六三医院',
		510107063: '成都市第七人民医院',
		510107064: '成都友谊医院',
		// 510107065: '成都医学院附属康桥眼科医院',
		// 510107066: '成都维多利亚女子医院',
		510107067: '四川大学华西临床医学院',
		// 510107068: '武警成都医院',
		// 510107069: '成都蓝天体检中心',
		// 510107070: '成都安琪儿妇产医院',
		// 510107071: '成都高新高鹏疼痛病医院',
		// 510107072: '成都康福肾脏病医院',
		// 510107073: '成都平安医院',
		// 510107074: '成都武侯博仕胃肠病医院',
		510107075: '四川（西藏）华西普济医院',
		510107076: '四川大学华西第二医院',
		510107077: '成都市第一人民医院',
		510107078: '四川省肿瘤医院',
		510107079: '四川大学华西口腔医院',
		510107080: '四川省骨科医院',
		// 510107081: '四川肛肠医院',
		// 510107082: '四川长征医院（民营）',
		// 510107083: '成都大才骨伤诊所',
		// 510107084: '四川省红十字皮肤病医院',
		// 510107085: '四川省生殖卫生学校附属医院',
		// 510107086: '成都安琪儿医院',
		510107087: '成都军区机关医院',
		// 510107088: '成都市中西医结合医院北区',
		// 510107089: '四川生殖卫生学院附属医院',
		// 510107090: '成都运动创伤研究所成都体院附院',
		// 510108022: '成都东区爱尔眼科医院',
		// 510108023: '成都成华脑康医院',
		510108024: '成都汇康中西医结合医院',
		// 510108025: '成都君康医院',
		// 510108026: '成华东区医院',
		// 510108027: '成都市交通医院',
		// 510108028: '成都市成华区红十字医院',
		// 510108029: '成都市成华区妇幼保健院',
		// 510108030: '四川省地质医院',
		// 510108031: '成都国光电气总公司医院',
		// 510108032: '四川电力医院',
		// 510108033: '四川石油管理局成都医院',
		510108034: '成都市第六人民医院',
		// 510108035: '成都市新华人民医院',
		// 510108036: '成都一Ｏ四医院',
		// 510108037: '成都市成华区第六人民医院',
		// 510108038: '成都誉美医院',
		// 510108039: '国营新兴仪器厂医院',
		// 510108040: '成都市传染病医院',
		// 510108041: '成都市成华区第三人民医院',
		// 510112222: '成都玛丽亚妇产医院',
		// 510112223: '成都市龙泉驿区中医院',
		// 510112224: '成都市龙泉驿区第二人民医院',
		510112225: '成都市龙泉驿区第一人民医院',
		// 510113175: '四川省化工机械厂职工医院',
		// 510113176: '成都市青白江区妇幼保健院',
		// 510113177: '成都青白江区人民医院',
		// 510113178: '青白江友爱医院',
		// 510113179: '成都钢铁厂职工医院',
		// 510113180: '成都市青白江区人民医院',
		// 510113181: '川化集团公司医院',
		// 510114045: '成都市新都区第二人民医院',
		// 510114046: '四川革命伤残军人医院',
		// 510114047: '成都市新都区中医院',
		510114048: '成都市新都区人民医院',
		510114049: '成都医学院第一附属医院',
		510114050: '成都市新都区妇幼保健院',
		// 510115091: '成都市印钞公司医院',
		// 510115092: '成都市温江区寿安镇中心卫生院',
		510115093: '四川省八一康复中心',
		// 510115094: '成都市温江区红十字医院',
		510115095: '成都市第五人民医院',
		// 510115096: '成都市温江区人民医院',
		// 510115097: '成都市温江区妇幼保健院',
		// 510115098: '成都市温江区中医院',
		// 510115099: '温江恒康医院',
		510116000: '双流县妇幼保健院',
		// 510116001: '双流县第二人民医院',
		// 510116002: '四川省石油管理局总医院',
		510116003: '双流县中医院',
		510116004: '双流县第一人民医院',
		// 510116005: '双流县合江镇卫生院',
		// 510117104: '郫县安靖镇公立卫生院',
		// 510117105: '犀浦镇社区卫生服务中心',
		// 510117106: '西南兵工成都医院',
		510117107: '郫县妇幼保健院',
		// 510117108: '郫县第二人民医院',
		// 510117109: '四川省交通厅公路局医院',
		510117110: '郫县人民医院',
		// 510117111: '郫县东区医院',
		// 510121120: '金堂县第三人民医院',
		510121121: '金堂县妇幼保健院',
		// 510121122: '金堂骨伤科医院',
		// 510121123: '金堂县精神卫生保健院',
		// 510121124: '金堂县红十字医院',
		// 510121125: '金堂县中医院',
		// 510121126: '金堂县第二人民医院',
		510121127: '金堂县第一人民医院',
		// 510121128: '四川省201医院',
		// 510129006: '沙渠镇公立卫生院',
		// 510129007: '大邑县蔡场镇公立卫生院',
		// 510129008: '大邑县韩场镇公立卫生院',
		// 510129009: '大邑县董场镇公立卫生院',
		// 510129010: '大邑县骨科医院',
		510129011: '大邑县妇幼保健院',
		// 510129012: '大邑县中医院',
		510129013: '大邑县人民医院',
		// 510131100: '蒲江县人民医院',
		// 510132042: '新津县妇幼保健院',
		// 510132043: '新津县中医院',
		// 510132044: '新津县人民医院',
		510181112: '都江堰市中医院',
		// 510181113: '中国水力水电第十工程局职工医院',
		// 510181114: '岷江局职工医院',
		510181115: '都江堰市妇幼保健院',
		510181116: '都江堰市人民医院',
		// 510181117: '成都市阿坝州林业中心医院',
		// 510181118: '都江堰市第二人民医院',
		// 510181119: '都江堰市中医骨科专科医院',
		// 510182019: '彭州市精神病医院',
		510182020: '彭州市中医院',
		510182021: '彭州市人民医院',
		// 510183101: '邛崃市妇幼保健院',
		// 510183102: '邛崃市中医院',
		// 510183103: '邛崃市人民医院',
		// 510184014: '四川省退伍军人精神病院（疗养)',
		// 510184015: '崇州市第二人民医院',
		// 510184016: '崇州市中医院',
		510184017: '崇州市妇幼保健院',
		510184018: '崇州市人民医院',
		510802173: '广元市中医院',
		510802174: '广元市第一人民医院',
		// 500101000: '重庆万州博生和美妇产医院',
		// 500101001: '万州阳光眼科医院',
		// 500101002: '万州和平医院',
		// 500101003: '万州九龙医院',
		// 500101004: '万州区走马镇中心卫生院',
		// 500101005: '重庆市万州区中西医结合医院',
		// 500101006: '重庆市万州区第四人民医院',
		// 500101007: '重庆市万州区人民医院',
		// 500101008: '万州卫校附属医院',
		500101009: '重庆三峡中心医院百安分院',
		500101010: '重庆三峡中心医院平湖分院',
		500101011: '重庆三峡中心医院儿童分院',
		500101012: '重庆三峡中心医院',
		// 500101013: '重庆三峡中心医院御安分院',
		// 500101014: '万州友谊医院',
		// 500102128: '重庆市涪陵区第二人民医院',
		// 500102129: '重庆市涪陵区中医院',
		// 500102130: '重庆市涪陵区妇幼保健院',
		500102131: '重庆市涪陵中心医院',
		// 500103132: '重庆市大坪中医院',
		// 500103133: '重庆市第八人民医院',
		// 500103134: '重庆疝气骨髓炎医院',
		// 500103135: '重庆市渝中区中医院',
		// 500103136: '重庆市中医骨科医院',
		// 500103137: '重庆长航医院',
		500103138: '重庆医科大学附属儿童医院',
		500103139: '重庆医科大学附属第一医院',
		500103140: '重庆市第三人民医院',
		500103141: '重庆市第一人民医院',
		500103142: '重庆市中山医院',
		500103143: '重庆市妇幼保健院',
		500103144: '重庆医科大学附属第二医院',
		500103145: '重庆医科大学附属口腔医院',
		500103146: '重庆市第四人民医院',
		500103147: '重庆市大坪医院',
		// 500103148: '武警重庆市消防总队医院',
		500103149: '重庆大坪医院',
		// 500103150: '重庆普瑞眼科医院',
		// 500103151: '重庆美伽整形医院',
		// 500103152: '重庆红岭医院',
		// 500104064: '重庆长峰医院',
		// 500104065: '重钢总医院',
		// 500104066: '重庆市大渡口区第一人民医院',
		// 500104067: '重庆市大渡口区第二人民医院',
		// 500105091: '重庆景城医院',
		// 500105092: '重庆红十字博爱医院',
		// 500105093: '重庆江北黄泥磅医院',
		// 500105094: '重庆诚嘉口腔医院',
		// 500105095: '宁波市江北区妇幼保健院',
		500105096: '重庆骑士医院',
		// 500105097: '重庆市通用工业集团公司职工医院',
		// 500105098: '重庆市江北区第五人民医院',
		// 500105099: '重庆市江北区精神卫生中心',
		// 500105100: '重庆市第十二人民医院',
		// 500105101: '重庆市江北区第一人民医院',
		// 500105102: '重庆望江医院',
		// 500105103: '重庆江陵医院长安汽车有限责任公...',
		// 500105104: '重庆市江北区中医院',
		500105105: '重庆市第十人民医院',
		500105106: '解放军第324医院',
		// 500106115: '重庆佳和中医肛肠医院',
		// 500106116: '嘉陵工业有限公司职工医院',
		// 500106117: '陈家桥中心医院',
		// 500106118: '重庆市石柱县人民医院',
		500106119: '重庆新桥医院',
		// 500106120: '重庆市沙坪坝区第二人民医院',
		// 500106121: '重庆市东华医院',
		// 500106122: '重庆市传染病医院',
		// 500106123: '重庆市嘉陵医院',
		// 500106124: '重庆市沙坪坝区中医院',
		// 500106125: '重庆市沙坪坝区人民医院',
		500106126: '重庆市肿瘤医院',
		500106127: '新桥医院',
		// 500107020: '重庆牙博士口腔医院',
		// 500107021: '重庆三九医院',
		// 500107022: '重庆渝武医院',
		// 500107023: '重庆格林医院',
		// 500107024: '重庆市九龙坡区第一中医院',
		// 500107025: '重庆渝西职工医院',
		// 500107026: '西南车辆制造厂职工医院重庆渝西...',
		// 500107027: '重庆二郎医院',
		// 500107028: '重庆二郎医院',
		// 500107029: '重庆长城医院',
		// 500107030: '重庆市九龙坡区第二中医院',
		// 500107031: '重庆市九龙坡区第一人民医院',
		// 500107032: '重庆市中梁山煤矿职工医院',
		// 500107033: '重庆建设机床厂职工医院',
		// 500107034: '重庆西郊医院',
		// 500107035: '重庆市九龙坡区第二人民医院',
		// 500107036: '九龙坡区中西医结合医院',
		// 500108043: '重庆爱尔麦格眼科医院',
		// 500108044: '重庆国防医院',
		// 500108045: '重庆现代女子医院整形美容科',
		500108046: '重庆市第五人民医院',
		// 500108047: '重庆圣保罗医院',
		// 500108048: '重庆港口医院',
		// 500108049: '重庆市南岸区第二人民医院',
		// 500108050: '重庆市南岸区第一中医院',
		// 500108051: '重庆市南岸区中西医结合医院',
		// 500108052: '重庆市南岸区人民医院',
		// 500109038: '西南师范大学医院',
		// 500109039: '天府矿务局职工总医院',
		// 500109040: '重庆市北碚区红十字医院',
		// 500109041: '重庆市北碚区中医院',
		500109042: '重庆市第九人民医院',
		// 500110015: '重庆市万盛区中医院',
		// 500110016: '重庆市万盛区人民医院',
		// 500110017: '重庆市南桐矿务局总医院',
		// 500110165: '綦江县妇幼保健院',
		// 500110166: '重庆綦江县中医院',
		// 500110167: '重庆市綦江齿轮厂职工医院',
		// 500110168: '重庆市松藻矿务局职工医院',
		// 500110169: '重庆綦江县人民医院',
		// 500111068: '重庆大足县中医院',
		// 500111069: '重庆大足县人民医院',
		// 500112153: '重庆市渝北区妇幼保健院',
		// 500112154: '渝北区妇幼保健院',
		// 500112155: '重庆市渝北区中医院',
		// 500112156: '重庆市渝北区人民医院',
		// 500113075: '重庆化工厂化工烧伤医院',
		// 500113076: '重庆市巴南区人民医院',
		// 500113077: '重庆市第七人民医院',
		// 500115174: '重庆长寿第二人民医院',
		// 500115175: '重庆市长寿区中医院',
		// 500115176: '重庆市长寿区人民医院',
		// 500115177: '重庆长寿化工园区医院',
		// 500116107: '重庆市江津区中心医院',
		// 500116108: '江津第二人民医院',
		// 500116109: '重庆江津市第二人民医院',
		// 500116110: '重庆江津市工人医院',
		// 500116111: '重庆江津市妇幼保健院',
		// 500116112: '重庆江津市人民医院',
		// 500116113: '重庆Ｏ九四职工医院',
		// 500116114: '重庆零九四职工医院',
		// 500117055: '合川南屏医院',
		// 500117056: '重庆市合川区清平中心卫生院',
		// 500117057: '重庆合川小沔中心医院',
		// 500117058: '合川博雅妇科医院',
		// 500117059: '重庆合川市中西医结合医院',
		// 500117060: '重庆合川市中医院',
		// 500117061: '重庆合川市人民医院',
		// 500118084: '中国船舶工业总公司六五二医院',
		// 500118085: '电子工业部四一二医院',
		// 500118086: '重庆永川华夏医院',
		500118087: '重庆永川通达医院',
		// 500118088: '重庆永川市妇幼保健院',
		500118089: '重庆永川市中医院',
		500118090: '重庆市第二人民医院',
		// 500119053: '重庆南川市第二人民医院',
		// 500119054: '重庆南川市人民医院',
		// 500120161: '璧山县第三人民医院',
		// 500120162: '璧山县人民医院',
		// 500120163: '重庆壁山县人民医院',
		// 500151172: '重庆铜梁县人民医院',
		// 500151173: '重庆铜梁县中医院',
		// 500152157: '潼南县桂林乡卫生院',
		// 500152158: '潼南县古溪镇中心卫生院',
		// 500152159: '重庆潼南县中医院',
		// 500152160: '重庆潼南县人民医院',
		// 500153170: '重庆荣昌县人民医院',
		// 500154078: '开县精神卫生保健院',
		// 500154079: '重庆开县人民医院',
		// 500155081: '重庆梁平县人民医院',
		// 500156082: '武隆县人民医院',
		// 500156083: '重庆市武隆县医院',
		// 500229063: '重庆城口县人民医院',
		// 500230018: '重庆丰都县中医院',
		// 500230019: '重庆丰都县人民医院',
		// 500231062: '重庆垫江县人民医院',
		// 500235037: '重庆云阳县中医院',
		// 500236070: '奉节友谊医院',
		// 500236071: '重庆奉节县中医院',
		// 500236072: '重庆奉节县人民医院',
		// 500237073: '大庙中心卫生院',
		// 500238074: '重庆巫溪县人民医院',
		// 500241164: '秀山土家族苗族自治县妇幼保健院',
		// 500242171: '酉阳土家族苗族自治县人民医院',
		// 500243080: '重庆市彭水县中医院',
		// 120101039: '天津和平九龙男健医院',
		// 120101041: '天津市和平区中医院',
		120101042: '天津市整形外科医院',
		// 120101043: '天津市和平区中医医院',
		// 120101044: '和平区第八医院',
		// 120101045: '和平区朝鲜族医院',
		// 120101046: '和平区妇婴医院',
		// 120101047: '和平区红十字会医院',
		// 120101048: '和平区第三中医院风湿病专科',
		// 120101049: '和平区第三中医院',
		// 120101050: '和平区第二中医院',
		// 120101051: '和平区中医院',
		// 120101052: '和平区第七医院',
		// 120101053: '和平区第四医院',
		// 120101054: '和平区第二医院',
		// 120101055: '和平区第一医院',
		// 120101056: '和平区人民医院',
		// 120101057: '和平区中心医院',
		// 120101058: '辽宁中医学院附属肛肠医院',
		// 120101059: '和平机器制造厂职工医院',
		// 120101060: '辽宁省建设集团股份有限公司职工...',
		120101061: '中国医科大学附属口腔医院',
		120101062: '中国人民解放军第二零二医院',
		// 120101063: '解放军第272医院',
		// 120101064: '和平区兴安医院',
		// 120101065: '和平区四面钟医院',
		// 120101066: '和平区新兴医院',
		// 120101067: '天津市和平区兴安医院',
		// 120101068: '天津市和平区四面钟医院',
		// 120101069: '天津市和平区新兴医院',
		// 120101070: '中国人民解放军第二七二医院',
		// 120101071: '天津市公安医院',
		// 120101072: '天津市和平区妇产医院',
		// 120101073: '和平区南营门医院',
		// 120101074: '和平区小白楼医院',
		// 120101075: '和平区牙科医院',
		// 120101076: '和平区妇产医院',
		// 120101077: '和平区中医院',
		// 120101078: '和平医院',
		// 120101079: '和平区安定医院',
		120101080: '天津代谢医院',
		120101081: '天津市天和医院',
		120101082: '天津市长征医院',
		120101083: '天津市中心妇产科医院',
		120101084: '天津医科大学总医院',
		120101085: '天津市口腔医院',
		120101086: '天津市胸科医院',
		120101087: '天津市眼科医院',
		// 120101088: '天津市心理卫生医院',
		120101089: '天津医科大学代谢病医院',
		120101090: '天津市人民医院滨江分院',
		120101091: '中国医学科学院血液病医院',
		120101092: '天津医科大学口腔医院',
		// 120101093: '天津市新生医院',
		// 120102123: '天津市河东区中山门医院',
		// 120102124: '天津河东美莱医学美容医院',
		// 120102125: '天津天铁冶金集团职工医院',
		// 120102126: '武警天津市总队医院',
		// 120102127: '天津民众体检中心',
		// 120102128: '天津市河东区广宁医院',
		// 120102129: '天津市河东区盘山医院',
		// 120102130: '天津市河东区向阳医院',
		// 120102131: '天津市河东区二号桥医院',
		// 120102132: '天津市冶金医院',
		// 120102133: '天津市河东区结核病呼吸系统疾病...',
		// 120102134: '天津市第一中心医院东院',
		// 120102135: '天津电力医院',
		// 120102136: '天津市河东区中医院',
		// 120102137: '天津市河东区赤峰医院',
		120102138: '武警天津部队医院',
		120102139: '天津市第三中心医院',
		120102140: '天津市武警医学院附属医院',
		120103158: '天津市环湖医院',
		// 120103159: '天津铁路分局天津结核防治疗养院',
		// 120103160: '河西区尖山医院',
		// 120103161: '天津市河西区马场医院',
		// 120103162: '天津市河西区下瓦房医院',
		// 120103163: '天津市河西区尖山医院',
		// 120103164: '天津康宁医院',
		// 120103165: '天津市河西区柳林医院',
		// 120103166: '天津市河西医院',
		// 120103167: '天津市河西口腔医院',
		120103168: '天津儿童医院',
		120103169: '天津市安定医院',
		120103170: '天津医科大学第三附属医院',
		120103171: '天津海河医院',
		120103172: '天津市天津医院',
		120103173: '天津市肺科医院',
		120103174: '天津市医科大学第二附属医院',
		120103175: '天津环湖医院',
		120103176: '天津医科大学第三医院',
		120103177: '天津市肿瘤医院',
		// 120104011: '天津南开博莱美医疗美容门诊部',
		120104012: '天津市南开医院',
		// 120104013: '中国人民解放军空军水上村医院',
		// 120104014: '天津市怡泰生殖专科医院',
		120104015: '中国人民解放军第四六四医院',
		// 120104016: '天津市干部疗养院',
		120104017: '天津中医药大学第一附属医院',
		120104018: '天津中医药大学第二附属医院',
		120104019: '天津医科大学第二医院',
		// 120104020: '天津中医药大学保康医院',
		120104021: '中国人民解放军第464医院',
		// 120104022: '中国人民解放军第271医院',
		// 120104023: '天津市东升医院',
		// 120104024: '天津市南开区西北角医院',
		// 120104025: '天津市南开区六合医院',
		// 120104026: '天津市南开区长江医院',
		// 120104027: '天津市南开区三潭医院',
		// 120104028: '南开区三潭医院',
		// 120104029: '天津市黄河医院',
		// 120104030: '天津市南开区理疗专科医院',
		// 120104031: '天津市南开区王顶堤医院',
		// 120104032: '天津市南开区中医院',
		// 120104033: '中国人民解放军第二七一医院',
		120104034: '天津市第一中心医院',
		120104035: '天津中医学院第一附属医院',
		120104036: '天津市中西医结合医院',
		120104037: '泰达心血管病医院',
		120104038: '天津市传染病医院',
		// 120105141: '天津市第一医院',
		// 120105142: '中国人民解放军第254医院泌尿...',
		120105143: '中国人民解放军第254医院',
		// 120105144: '天津市河北区光复道医院',
		// 120105145: '天津市江都路医院',
		// 120105146: '天津市河北区北宁医院',
		// 120105147: '天津市河北区王串场医院',
		// 120105148: '天津市河北区津鸿医院',
		// 120105149: '天津市河北区建昌医院',
		// 120105150: '天津市河北医院',
		// 120105151: '天津市河北区妇幼保健院',
		// 120105152: '天津市第二医院',
		120105153: '中国人民解放军第二五四医院',
		120105154: '天津中医学院第二附属医院',
		120105155: '天津市第四中心医院',
		120105156: '天津市公安局安康医院',
		120105157: '天津市中医院',
		120106180: '天津人民医院',
		// 120106181: '天津市红桥区河北街医院',
		// 120106182: '天津市红桥区咸阳医院',
		// 120106183: '天津市民族医院',
		// 120106184: '天津市红桥医院',
		// 120106185: '红桥区口腔医院',
		// 120106186: '红桥区妇幼保健院',
		// 120106187: '天津市红十字会医院',
		// 120106188: '天津市红桥区口腔医院',
		// 120106189: '天津市红桥区妇幼保健院',
		// 120106190: '天津市红桥区中医院',
		120106191: '天津市人民医院',
		120106192: '天津长征医院',
		// 120110000: '天津市东丽区军粮城医院',
		// 120110001: '天津市东丽区妇幼保健院',
		// 120110002: '天津市东丽区中医院',
		// 120110003: '天津市东丽区东丽医院',
		120110004: '天津市安宁医院',
		// 120111197: '天津市西青医院',
		// 120111198: '天津市东环医院',
		// 120112178: '天津市咸水沽医院',
		// 120112179: '天津拖拉机制造厂职工医院',
		// 120113005: '天津市北辰医院',
		// 120113006: '天津市北辰区中医院',
		// 120113007: '天津天穆骨科医院',
		// 120113008: '天津市北辰区妇幼保健所',
		// 120113009: '天津市北辰区第一医院',
		// 120113010: '天津安捷医院',
		// 120114113: '天津中医药大学附属武清中医医院',
		// 120114114: '天津武清泰济生医院',
		// 120114115: '天津市武清区中医院',
		// 120114116: '天津市武清区人民医院',
		// 120114117: '武清区中医院',
		// 120115110: '天津市宝坻区中医院',
		// 120115111: '天津技术开发区医院',
		// 120115112: '天津市宝坻区人民医院',
		// 120116094: '天津长芦海晶集团有限公司医院',
		// 120116095: '天津博爱医院',
		// 120116096: '中国建筑第六工程局职工医院',
		// 120116097: '天津市塘沽区口腔医院',
		// 120116098: '天津港口医院',
		// 120116099: '中国海洋石油总公司渤海石油公司...',
		// 120116100: '天津市塘沽区妇幼保健院',
		// 120116101: '天津大沽化工厂医院',
		// 120116102: '天津碱厂医院',
		120116103: '天津市第五中心医院',
		// 120116104: '天津华兴医院',
		// 120116105: '大港油田总医院',
		// 120116106: '天津市石化医院',
		// 120116107: '天津市大港医院',
		// 120116118: '天津滨海新区津滨医院',
		// 120116119: '天津市汉沽农场医院',
		// 120116120: '天津化工厂医院',
		// 120116121: '天津市汉沽区中医院',
		// 120116122: '汉沽农场职工医院',
		// 120117108: '宁河县中医院',
		// 120117109: '宁河县医院',
		// 120118199: '静海县中医院',
		// 120118200: '静海县医院',
		// 120119193: '蓟县妇幼保健院',
		// 120119194: '蓟县中医院',
		// 120119195: '蓟县人民医院',
		120119196: '天津市蓟县人民医院',
		430102097: '湖南省军区医院',
		430102098: '湖南省儿童医院',
		// 430102099: '长沙五洲医院',
		430102100: '中南大学附属湘雅三医院',
		430102101: '湖南省第二人民医院',
		// 430102102: '长沙市芙蓉区平价医院',
		// 430102103: '湖南省交通医院',
		430102104: '湖南省老年医院',
		430102105: '长沙市第七医院',
		430102106: '湖南省人民医院',
		// 430102107: '湖南军区机关医院',
		// 430102108: '长沙楚雅医院',
		430102109: '湖南省马王堆医院',
		// 430102110: '长沙市芙蓉区红十字会医院',
		// 430102111: '中国建筑第五工程局中心医院',
		// 430103052: '长沙市天心区固德口腔门诊部',
		// 430103053: '长沙市天心区真爱医疗美容门诊部',
		// 430103054: '湖南省药物依赖治疗中心湖南省精...',
		// 430103055: '长沙市中医医院',
		// 430103056: '湖南省财贸医院',
		// 430103057: '长沙市中医院',
		// 430103058: '长沙中医院',
		// 430104064: '武警湖南省消防总队医院',
		430104065: '长沙楚雅医院多动症诊疗中心',
		430104066: '武警湖南总队医院肝病治疗中心',
		430104067: '长沙市第三医院',
		430104068: '中南大学附属湘雅二医院',
		430104069: '湖南省中医院',
		// 430104070: '长沙市望城人民医院',
		// 430104071: '湖南省肿瘤医院',
		430104072: '湖南省中医研究院附属医院',
		430104073: '长沙市第四医院',
		// 430104074: '长沙汇兴医院',
		// 430104075: '湖湘中医肿瘤医院',
		// 430104076: '长沙东华生殖与不孕医院',
		// 430104077: '航天722医院',
		// 430105078: '长沙市开福区新港镇卫生院',
		// 430105079: '湖南省政府机关医院',
		// 430105080: '中国人民解放军国防科技大学医院',
		430105081: '中南大学湘雅医院',
		// 430105082: '长沙市第二医院',
		430105083: '长沙市第一医院',
		430105084: '湖南省妇幼保健院',
		430105085: '湖南省生殖保健医院',
		430111118: '湖南中医学院附属第一医院',
		430111119: '长沙市中心医院',
		// 430111120: '长沙市妇幼保健院',
		// 430111121: '长沙市按摩医院',
		430111122: '湖南中医药大学第一附属医院',
		// 430111123: '长沙年轮骨科医院',
		// 430111124: '长沙中南白癜风医院',
		// 430111125: '长沙仁和医院',
		// 430111126: '中国水电中南勘测设计研究院职工...',
		// 430112086: '航天部七二二医院',
		// 430112087: '中南传动机厂职工医院',
		// 430112088: '湖南省中医肿瘤医院（民营）（原...',
		// 430112089: '望城县人民医院',
		// 430121112: '中国水利水电第八局中心医院',
		// 430121113: '长沙县精神病医院',
		// 430121114: '长沙铁路医院',
		// 430121115: '长沙铁路总公司株洲医院',
		// 430121116: '长沙县第一人民医院',
		430121117: '中国人民武警部队湖南省总队医院',
		// 430181090: '浏阳市大瑶中心医院',
		// 430181091: '浏阳市大瑶中心医院浏阳市第六医...',
		// 430181092: '湖南省浏阳市第三医院',
		// 430181093: '浏阳市妇幼保健院',
		// 430181094: '浏阳市中医院',
		// 430181095: '浏阳市人民医院',
		// 430181096: '浏阳市中医医院',
		// 430182059: '湖南省煤炭坝煤矿职工医院',
		// 430182060: '宁乡县人民医院',
		// 430182061: '宁乡县中医医院',
		// 430182062: '核工业七一六矿职工医院',
		// 430182063: '宁乡县大屯营乡卫生院',
		// 430405011: '衡阳市第一人民医院',
		// 430405012: '衡阳市铁路医院',
		// 430405013: '衡阳市肛肠医院',
		// 430405014: '衡阳市中西医结合医院',
		// 430405015: '衡阳市宁康医院',
		// 430405016: '衡阳市宁康医院衡阳市第一精神病...',
		// 430405017: '滨江医院',
		430405018: '衡阳169医院',
		// 430405019: '衡阳市江东中医院',
		430405020: '中国人民解放军169医院',
		// 430406046: '衡阳雅齐口腔医院',
		// 430406047: '衡阳市滨江医院',
		// 430406048: '衡阳市第二精神病医院',
		// 430406049: '衡阳市妇幼保健院',
		430406050: '衡阳市中心医院',
		// 430406051: '核工业301大队职工医院',
		// 430407021: '衡阳华程医院',
		// 430407022: '衡阳市协合口腔医院',
		// 430407023: '衡阳市传染病医院',
		// 430408029: '南华大学附属第二医院衡钢分院',
		430408030: '南华大学附属南华医院',
		// 430408031: '衡阳男科医院',
		// 430408032: '衡阳市中医院',
		430408033: '南华大学附属第二医院',
		430412008: '南华大学附属第三医院',
		// 430421044: '湖南省界牌瓷厂职工医院',
		// 430421045: '衡阳县人民医院',
		// 430422034: '水口山矿务局职工医院',
		// 430422035: '衡南县第二人医院',
		// 430422036: '衡南县第三人民医院',
		// 430422037: '衡南县中医院',
		// 430422038: '衡南县人民医院',
		// 430422039: '衡南市中医院',
		// 430422040: '衡南市人民医院',
		// 430423041: '衡山县中医院',
		// 430423042: '衡山县人民医院',
		// 430423043: '湖南天宇湘华化工厂职工医院',
		// 430426024: '祁东县中医院',
		// 430426025: '祁东县人民医院',
		// 430481026: '耒阳市太平圩乡卫生院',
		// 430481027: '耒阳市人民医院',
		// 430481028: '耒阳市中医院',
		// 430482009: '水口山有色金属公司职工医院',
		// 430482010: '常宁市人民医院',
		// 430802003: '张家界铁路医院',
		// 430802004: '张家界市永定医院',
		// 430802005: '张家界中医院',
		// 430802006: '张家界市第二人民医院',
		// 430802007: '张家界市人民医院',
		// 430821000: '慈利县中医院',
		// 430821001: '慈利县人民医院',
		// 430822002: '桑植县人民医院',
		340102036: '安徽中医药附属医院',
		340102037: '合肥益康胃肠中医医院',
		// 340102038: '安徽合肥贝杰口腔医院',
		// 340102039: '合肥同仁关怀癫痫病医院',
		// 340102040: '蚌埠铁路分局合肥铁路医院',
		// 340102041: '中国人民解放军陆军军官学院医院',
		// 340102042: '安徽省中医药临床研究中心附属医...',
		// 340102043: '合肥人流医院',
		// 340102044: '合肥中山医院',
		// 340102045: '合肥市瑶海区第一人民医院',
		// 340102046: '合肥钢铁公司职工医院',
		// 340102047: '安徽省立友谊医院',
		// 340103017: '金谷康复医院',
		// 340103018: '合肥现代妇产医院',
		// 340103019: '合肥肛泰肛肠医院',
		// 340103020: '庐江平安康复医院',
		// 340103021: '安徽中医学院第二附属医院安徽中...',
		340103022: '合肥市第一人民医院',
		340103023: '安徽省立医院',
		// 340103024: '合肥市城建医院',
		340103025: '安徽中医药大学第二附属医院',
		340103026: '安徽中医药大学第一附属医院',
		340103027: '安徽省妇幼保健院',
		340103028: '安徽省第二人民医院',
		// 340103029: '中铁四局第四医院',
		// 340103030: '铁道部第四工程局八公司第一医院',
		// 340103031: '铁道部第四工程局新线铁路运输工...',
		// 340103032: '安徽中医学院神经病学研究所附属...',
		// 340103033: '合肥凤凰肿瘤医院',
		// 340104051: '合肥瑞康骨科医院',
		340104052: '合肥高新心血管病医院',
		340104053: '安徽省口腔医院',
		340104054: '安徽省胸科医院',
		340104055: '安徽省中西医结合医院',
		// 340104056: '合肥长淮中医医院',
		340104057: '安徽医科大学第二附属医院',
		// 340104058: '合肥市第一人民医院蜀山分院',
		// 340104059: '安徽省红十字会医院',
		// 340104060: '安徽省肺科医院',
		340104061: '合肥市第四人民医院',
		340104062: '安徽中医学院第一附属医院',
		340104063: '中国人民解放军第一0五医院',
		340104064: '安徽医科大学第一附属医院',
		// 340104065: '安徽省精神卫生防治中心',
		// 340104066: '安徽省康复医院',
		// 340104067: '合肥市第一人民医院分院',
		340104068: '中国人民解放军第105医院',
		// 340104069: '中国人民解放军陆军安徽皮肤病医...',
		// 340104070: '国营江淮航空仪表厂职工医院',
		// 340104071: '安徽省立医院南区',
		340111000: '合肥壹加壹整形美容医院',
		// 340111001: '合肥丽人妇科医院',
		340111002: '安徽省儿童医院',
		// 340111003: '合肥苗老祖专业祛痘',
		// 340111004: '合肥阳光消化病医院',
		340111005: '合肥市第二人民医院',
		// 340111006: '合肥市第三人民医院',
		// 340111007: '安徽省建医院',
		// 340111008: '安徽省水利厅职工医院',
		// 340111009: '安徽医学高等专科学校附属医院',
		// 340111010: '合肥市友好医院',
		// 340111011: '中国人民解放军安徽省军区医院',
		// 340111012: '安徽医科大学第四附属医院',
		// 340111013: '中铁四局集团中心医院',
		// 340111014: '中国科学技术大学医院',
		// 340111015: '合肥铁路医院',
		// 340111016: '合肥市红十字会美容医院',
		340111034: '合肥市滨湖医院',
		// 340111035: '合肥正大中西医结合医院',
		// 340122048: '肥东新安医院',
		// 340123049: '肥西县人民医院',
		// 340123050: '肥西县中医院',
		// 340202125: '安徽师范大学医院',
		340202126: '皖南医学院第二附属医院',
		// 340202127: '芜湖市第五人民医院',
		// 340202128: '芜湖市镜湖区医院',
		340202129: '芜湖市中医医院',
		340202130: '芜湖市第三人民医院',
		340202131: '芜湖市第二人民医院',
		// 340202132: '芜湖县中医院',
		340202133: '皖南医学院弋矶山医院',
		// 340203120: '芜湖玛丽娅医院',
		// 340203134: '芜湖市弋江区医院',
		// 340207135: '裕溪口医院',
		// 340207136: '中国人民解放军五七二0厂医院',
		// 340221123: '芜湖市裕溪口医院',
		// 340221124: '无为朝阳医院',
		// 340222121: '繁昌县人民医院',
		// 340222122: '繁昌县中医院',
		// 340223119: '南陵县中医院',
		// 340302137: '蚌埠市第二人民医院',
		// 340302148: '蚌埠市交通医院',
		340302149: '蚌埠医学院第一附属医院',
		340303138: '蚌埠市第三人民医院',
		// 340304145: '蚌埠医学院第二附属医院',
		// 340304146: '蚌埠市中医院',
		// 340304147: '蚌埠市第一人民医院',
		// 340321141: '定远县中医院',
		// 340321142: '怀远县中医院',
		// 340321143: '怀远县人民医院',
		// 340321144: '安徽省荣军医院',
		// 340322139: '五河县人民医院',
		// 340323140: '固镇县人民医院',
		// 340402096: '淮南市第一矿工医院',
		// 340403099: '淮南建国泌尿专科医院',
		// 340403100: '淮南博士医院',
		// 340403101: '淮南新康医院',
		// 340403102: '淮南矿业集团职业病防治院',
		// 340403103: '淮南市安成镇人民医院',
		// 340403104: '中国化学工程第三建公司职工医院',
		// 340403105: '安徽理工大学附属医院淮南东方医...',
		// 340403106: '淮南市纺织厂职工医院',
		// 340403107: '中国化学工程第三建筑公司职工医...',
		// 340403108: '淮南现代医院',
		// 340403109: '淮南市和睦医院',
		// 340403110: '淮南矿务局职业病防治院',
		340403111: '淮南市第一人民医院',
		// 340403112: '淮南市中医院',
		// 340403113: '淮南市新康医院',
		// 340403114: '淮南第三矿工医院',
		// 340404115: '淮南第二矿工医院',
		// 340404116: '淮南矿业集团医疗卫生管理中心李...',
		// 340404117: '淮南市精神病医院',
		340404118: '新华医院',
		// 340405095: '淮南市第二人民医院',
		// 340406097: '淮南康德医院',
		// 340406098: '淮南市新华医疗集团第四矿工医院',
		// 340722087: '枞阳县人民医院',
		// 340802091: '中国人民解放军海军安庆医院',
		// 340802092: '安庆同济医院',
		// 340802093: '安庆市郊区人民医院',
		340802094: '安庆市立医院',
		// 340803072: '安庆市第二人民医院',
		// 340803073: '安庆胃康医院',
		// 340803074: '安庆市宜城医院',
		// 340803075: '安庆市第一人民医院',
		// 340803076: '安庆市中医院',
		// 340803077: '安庆市第三人民医院',
		// 340803078: '安庆市纺织医院',
		// 340822082: '怀宁县黄龙中心卫生院',
		// 340822083: '皖河农场医院',
		// 340822084: '怀宁县人民医院',
		// 340822085: '怀宁县中医骨伤医院',
		// 340824089: '潜山县中医院',
		// 340824090: '潜山县医院',
		// 340825079: '太湖县人民医院',
		// 340826080: '宿松县中医院',
		// 340826081: '安徽省国营农场华阳河总场医院',
		// 340827086: '望江县医院',
		// 340881088: '桐城市人民医院',
		// 341202196: '颍州区西湖镇卫生院',
		// // 341202197: '颍州区文峰办事处社区卫生服务中...',
		// 341202198: '颍州区王店镇中心卫生院',
		// 341202199: '颍州区三十里铺镇卫生院',
		// 341202200: '颍州区三合镇卫生院',
		// // 341202201: '颍州区清河办事处社区卫生服务中...',
		// 341202202: '颍州区马寨乡卫生院',
		// 341202203: '颍州区九龙镇中心卫生院',
		// // 341202204: '阜阳经开区京九办事处陈庄社区卫...',
		// 341202205: '颍州区程集中心卫生院',
		// 341202206: '颍州区袁集镇卫生院',
		// 341202207: '阜阳双龙医院',
		// 341202208: '阜阳创伤医院',
		// 341202209: '阜阳天德中医康复医院',
		// 341202210: '阜阳瑞康医院',
		// 341202211: '阜阳市红十字骨科医院',
		// 341202212: '阜阳五官科医院',
		// 341202213: '阜阳市红十字博爱医院',
		// 341202214: '阜阳泽明眼科医院',
		// 341202215: '阜阳华夏高血压病医院',
		// 341202216: '阜阳华山医院',
		// 341202217: '阜阳中西医结合肛肠医院',
		// 341202218: '阜阳同济外科医院',
		// 341202219: '阜阳疝病专科医院',
		// 341202220: '阜阳糖尿病医院',
		// 341202221: '阜阳龙奇椎间盘病医院',
		// 341202222: '阜阳百佳妇产医院',
		// 341202223: '阜阳神康医院',
		// 341202224: '阜阳万生医院',
		// 341202225: '阜阳市人民医院纺织分院',
		// 341202226: '颍州区三塔集镇中心卫生院',
		// 341202227: '阜阳华东医院',
		// // 341202228: '阜阳经济技术开发区京九办事处社...',
		// 341202229: '颍州区三塔集镇洲孜村卫生室',
		// 341202230: '颍州区三塔集镇盛庄村卫生室',
		// 341202231: '颍州区三塔集镇花园村卫生室',
		// 341202232: '颍州区三塔集镇胜华村卫生室',
		// 341202233: '颍州区三塔集镇拐湾村卫生室',
		// 341202234: '颍州区三塔集镇徐寨村卫生室',
		// 341202235: '颍州区三塔集镇前进村卫生室',
		// 341202236: '颍州区三塔集镇大塘村卫生室',
		// 341202237: '颍州区三塔集镇冯于村卫生室',
		// 341202238: '颍州区三塔集镇张寨村卫生室',
		// 341202239: '颍州区三塔集镇葛庙村卫生室',
		// 341202240: '颍州区三塔集镇倪寨村卫生室',
		// 341202241: '颍州区三塔集镇吴大村卫生室',
		// 341202242: '颍州区三塔集镇张湖村卫生室',
		// 341202243: '颍州区三塔集镇李小郢村卫生室',
		// 341202244: '颍州区三塔集镇三塔村卫生室',
		// 341202245: '颍州区三塔集镇周赵村卫生室',
		// 341202246: '阜阳仁和医院',
		// 341202247: '阜阳第九人民医院',
		// 341202248: '阜阳市妇幼保健医院',
		// 341202249: '阜阳市妇幼保健院',
		// 341202250: '阜阳市肿瘤医院',
		// 341202251: '阜阳市第三人民医院',
		// 341202252: '阜阳市妇幼保健院',
		341202253: '阜阳市第二人民医院',
		341202254: '阜阳市人民医院',
		341202255: '阜阳市中医院',
		// 341202256: '阜阳市精神病医院',
		// 341202257: '阜阳市第二人民医院（原阜阳地区...',
		// 341202258: '阜阳肿瘤医院',
		// 341203174: '颍东区正午镇卫生院',
		// 341203175: '颍东区新华街道办事处社区卫生服...',
		// 341203176: '颍东区枣庄镇卫生院',
		// 341203177: '颍东区插花中心卫生院',
		// 341203178: '颍东区老庙中心卫生院',
		// 341203179: '颍东区杨楼孜镇卫生院',
		// 341203180: '颍东区河东办事处社区卫生服务中...',
		// 341203181: '颍东区冉庙乡卫生院',
		// 341203182: '颍东区袁寨镇卫生院',
		// 341203183: '颍东区向阳办事处东平社区卫生服...',
		// 341203184: '阜阳七院爱尔眼科医院',
		341203185: '中国中铁阜阳中心医院',
		// 341203186: '阜阳市第七人民医院',
		// 341203187: '颍东区京九医院',
		// 341203188: '阜阳东方妇产医院',
		// 341203189: '颍东福兴医院',
		// 341203190: '颍东阜仁医院',
		// 341203191: '颍东区口孜中心卫生院',
		// 341203192: '颍东区第三人民医院',
		// 341203193: '颍东区口孜东矿医院',
		// 341203194: '铁道部第四工程局二处职工医院',
		// 341203195: '中铁四局集团第二医院',
		// 341204259: '颍泉区中市办事处宁堂社区卫生服...',
		// 341204260: '阜阳友好医院',
		// 341204261: '阜阳中西医结合眼耳鼻喉科医院',
		// 341204262: '阜阳市汽运集团职工医院',
		// 341204263: '阜阳阜阳玛丽娅妇产医院',
		// 341204264: '阜阳国济医院',
		// 341204265: '阜阳东方针灸医院',
		// 341204266: '阜阳市第五人民医院',
		// 341204267: '阜阳女子医院',
		// 341221150: '临泉县杨小街乡赵围孜村卫生室',
		// 341221151: '临泉县杨小街乡新集村卫生室',
		// 341221152: '临泉县杨小街乡王老庄村卫生室',
		// 341221153: '临泉县杨小街乡柳集村卫生室',
		// 341221154: '临泉县杨小街乡柳大庄村卫生室',
		// 341221155: '临泉县杨小街乡卫生院',
		// 341221156: '临泉县庙岔镇葛庄村卫生室',
		// 341221157: '临泉县庙岔镇王盐店村卫生室',
		// 341221158: '临泉县庙岔镇卫生院',
		// 341221159: '临泉县张新镇卫生院',
		// 341221160: '临泉县谭棚镇卫生院',
		// 341221161: '临泉县庞营乡卫生院',
		// 341221162: '临泉县瓦店镇卫生院',
		// 341221163: '临泉县谢集乡高集村卫生室',
		// 341221164: '临泉县谢集乡前周村卫生室',
		// 341221165: '临泉县谢集乡东陈村卫生室',
		// 341282166: '界首市协和医院',
		// 341282167: '界首京安骨科医院',
		// 341282168: '界首市妇幼保健',
		// 341282169: '界首市工人疗养院',
		// 341282170: '界首工人医院',
		// 341282171: '界首市中医院',
		// 341282172: '界首市人民医院',
		// 341282173: '界首市光武中心医院',
		// 350102298: '福州榕桦耳鼻喉医院',
		// 350102299: '福州东南妇幼医院',
		// 350102300: '福州瑞丽整形美容医院',
		// 350102301: '福州海峡美容医院',
		350102302: '福建协和医院',
		// 350102303: '福建中医学院国医堂医院',
		// 350102304: '福州鼓楼医院',
		// 350102305: '福建省煤矿中心医院',
		// 350102306: '名韩国际医学美容医院',
		// 350102307: '福州榕桦医院',
		// 350102308: '福州亚华口腔',
		350102309: '中国人民解放军第476医院',
		// 350102310: '福州鼓楼医院口腔科',
		// 350102311: '福建省建新医院',
		// 350102312: '福州市第七医院',
		// 350102313: '福建省级机关医院',
		// 350102314: '福建省老年医院',
		350102315: '福建中医学院附属第二人民医院',
		350102316: '福州市皮肤病防治院',
		350102317: '福建医科大学附属口腔医院',
		350102318: '福州市传染病医院',
		350102319: '福建医科大学附属协和医院',
		350102320: '福建省妇幼保健院',
		350102321: '南京军区福州总医院',
		// 350102322: '福州市按摩医院',
		350102323: '福州市儿童医院',
		350102324: '福州妇幼保健院',
		350102325: '福州市中医院',
		350102326: '福建中医学院附属省第二人民医院',
		350102327: '福建省中医学院附属第二人民医院',
		350102328: '福建省立医院',
		350102329: '福州传染病医院',
		// 350102330: '中国人民解放军第四七六医院',
		// 350102331: '福州鼓楼医院',
		350102332: '福建省军区机关医院',
		// 350103260: '福州台江整形医院',
		// 350103261: '福州东南眼科医院',
		// 350103262: '福州台江医院',
		// 350103263: '福州市台江区中医院',
		350103264: '福建省皮肤病性病防治院',
		350103265: '福州市第一医院',
		350103266: '福建医科大学附属第一医院',
		350103267: '福建省人民医院',
		// 350103268: '福州市第六医院',
		// 350104247: '福州则徐自愿戒毒所',
		// 350104248: '福州和睦佳妇产医院',
		// 350104249: '福建省师范大学医院',
		350104250: '福建蜂疗医院',
		350104251: '福州市第四医院',
		350104252: '福州肺科医院',
		350104253: '福州市第二医院',
		// 350104254: '福州市第三医院',
		350104255: '福州市第二医院二部',
		// 350104256: '福州市神经精神病防治院',
		// 350104257: '福州市结核病防治院',
		// 350104258: '福州市结核病防治院福州肺科医院',
		350104259: '福州眼科医院',
		// 350105297: '福州精神诊疗中心',
		// 350111270: '福兴妇产医院',
		// 350111271: '福州民卫医院',
		// 350111272: '福州福兴医院',
		// 350111273: '福州市晋安区医院',
		// 350111274: '福建省金鸡山温泉疗养院',
		// 350111275: '福州铁路中心医院',
		// // 350111276: '福州市二化集团有限公司职工医院...',
		// 350111277: '福州市第八医院',
		// 350111278: '福州神康医院',
		350111279: '福建省第二人民医院二化分院',
		350111280: '福建省肿瘤医院',
		// 350112291: '长乐区第二医院',
		// 350112292: '长乐区中医院',
		// 350112293: '长乐区医院',
		// 350121294: '闽侯县第二医院',
		// 350122289: '连江县医院',
		// 350122290: '连江县中医院',
		// 350123288: '罗源县中医院',
		// 350124295: '闽清县医院',
		350124296: '福建省闽清精神病防治院',
		// 350125281: '永泰县医院',
		// 350128269: '平潭县医院',
		// 350181282: '福清向阳医院',
		// 350181283: '福州神经精神病防治院',
		// 350181284: '福清中山医院',
		// 350181285: '福清市融强医院',
		// 350181286: '福清市医院',
		// 350181287: '福清市医院',
		// 350203049: '厦门新开元医院整形美容科',
		// 350203050: '厦门益康男科医院',
		// 350203051: '厦门益康医院',
		// 350203052: '厦门思明安黛美整形外科门诊部',
		// 350203053: '厦门思明康博口腔门诊部',
		// 350203054: '厦门友好妇科医院',
		350203055: '解放军第一七四医院',
		// 350203056: '厦门思明华美医疗美容门诊部',
		// 350203057: '厦门大学附属中山医院厦禾分部',
		// 350203058: '厦门市思明区妇幼保健院',
		// 350203059: '厦门市家庭医疗保健所',
		// 350203060: '厦门市第一医院莲前第一社区医疗...',
		// 350203061: '厦门市中医院梧村社区医疗服务中...',
		// 350203062: '厦门大学附属中山医院员当第一社...',
		// 350203063: '厦门市第一医院鹭江社区医疗服务...',
		// 350203064: '厦门大学附属中山医院开元社区医...',
		// 350203065: '东方天使厦门妇产医院',
		// 350203066: '厦门友好妇产医院',
		// 350203067: '厦门市第一医院厦港社区医疗服务...',
		// 350203068: '厦门市第一医院中华社区医疗服务...',
		// 350203069: '厦门市第一医院滨海社区医疗服务...',
		// 350203070: '厦门金尚医院',
		// 350203071: '厦门思明区梧村骨科医院',
		// 350203072: '厦门市第一医院嘉莲社区医疗服务...',
		// 350203073: '厦门市第一医院鼓浪屿社区医疗服...',
		// 350203074: '厦门大学漳州校区医院',
		// 350203075: '厦门大学附属中山医院筼筜第一社...',
		// 350203076: '厦门市第一医院中华社区卫生服务...',
		// 350203077: '厦门友好妇产医院',
		// 350203078: '张铭整形整容医院',
		350203079: '厦门大学附属厦门眼科中心医院',
		// 350203080: '厦门海峡整形美容医院',
		// 350203081: '厦门新开元整形美容医院',
		// 350203082: '厦门欧菲整形美容医院',
		// 350203083: '厦门登特口腔医院',
		// 350203084: '厦门新开元医院',
		// 350203085: '厦门国泰妇科医院',
		// 350203086: '水警区医院',
		// 350203087: '厦门警备区医院门诊部',
		// 350203088: '厦门市福利中心松柏医院',
		// 350203089: '厦门市博爱康复医院',
		// 350203090: '厦门市口腔医院前埔分院',
		// 350203091: '厦门市中山医院金榜分院',
		// 350203092: '思明区人民医院',
		350203093: '厦门眼科中心',
		// 350203094: '厦门前埔医院',
		// 350203095: '厦门市鹭海医院',
		// 350203096: '厦门市第一医院鼓浪屿分部',
		// 350203097: '厦门市思明区中医骨伤科医院',
		350203098: '厦门市口腔医院',
		// 350203099: '厦门天济医院',
		// 350203100: '厦门大学医院',
		// 350203101: '厦门仁安医院',
		// 350203102: '厦门莲花医院',
		// 350203103: '厦门卫生队医院',
		// 350203104: '厦门市中医院禾祥东门诊部',
		// 350203105: '厦门市第二医院鼓浪屿分院',
		350203106: '厦门市中医院',
		350203107: '厦门市第一医院',
		350203108: '厦门大学附属中山医院',
		350203109: '厦门市妇幼保健院',
		// 350205110: '厦门市海沧区东孚卫生院',
		// 350205111: '厦门市海沧区妇幼保健院',
		// // 350205112: '厦门市海沧区海沧街道石塘社区卫...',
		// // 350205113: '厦门市海沧区海沧街道海沧社区卫...',
		// 350205114: '厦门海沧新阳医院',
		350205115: '厦门长庚医院',
		// 350206116: '厦门湖里中山门诊部',
		// 350206117: '厦门湖里安康门诊部',
		// 350206118: '厦门残联康复医院',
		350206119: '厦门科宏眼科医院',
		// 350206120: '厦门光亮骨伤科医院',
		// 350206121: '厦门湖里健民医院',
		// // 350206122: '厦门大学附属中山医院湖里第一社...',
		// // 350206123: '厦门大学附属中山医院殿前社区医...',
		// // 350206124: '厦门市中医院江头社区医疗服务中...',
		// 350206125: '厦门市湖里区妇幼保健院',
		// 350206126: '厦门湖里曙光医院',
		// 350206127: '厦门市仙岳医院北区',
		// 350206128: '厦门湖里安兜医院',
		// 350206129: '厦门市中医院金山社区医疗服务中...',
		// 350206130: '厦门市中医院禾山社区医疗服务中...',
		350206131: '厦门五缘医院(在建)',
		// 350206132: '厦门玛莉妇科医院',
		// 350206133: '厦门市江头医院',
		// 350206134: '厦门市湖里医院',
		350206135: '厦门市仙岳医院',
		350206136: '厦门大学附属中山医院湖里分院',
		350206137: '复旦大学附属中山医院厦门医院',
		// 350206138: '厦门聪海皮肤病医院',
		// 350211148: '厦门市杏林区医院',
		// 350211149: '厦门市疾病预防控制中心门诊部',
		// // 350211150: '厦门市集美区杏滨街道社区卫生服...',
		// // 350211151: '厦门市集美区集美街道社区卫生服...',
		// 350211152: '厦门市集美区后溪卫生院',
		// // 350211153: '厦门市集美区侨英街道社区卫生服...',
		// 350211154: '厦门集美杏西医院',
		// 350211155: '厦门集美东南医院',
		// 350211156: '厦门市集美区妇幼保健站',
		350211157: '厦门市第二医院',
		// 350211158: '厦门第一医院杏林院区',
		// 350211159: '厦门市集美区灌口医院',
		// 350211160: '厦门现代口腔门诊部',
		// 350211161: '厦门市杏滨街道卫生服务中心',
		// 350211162: '厦门市杏林区康复医疗中心',
		// 350212028: '厦门同安城南医院',
		// 350212029: '厦门天使妇产医院',
		// 350212030: '厦门市同安区新民卫生院',
		// 350212031: '厦门市同安区西柯中心卫生院',
		// 350212032: '厦门市同安区汀溪卫生院',
		// // 350212033: '厦门市同安区大同卫生院祥平街道...',
		// 350212034: '厦门市医药研究所综合门诊部',
		// 350212035: '厦门同安博爱医院',
		// 350212036: '厦门市同安区皮肤病防治院',
		// 350212037: '厦门市翔安区大嶝医院',
		// 350212038: '厦门市同安区莲花卫生院',
		// 350212039: '厦门市同安区大同卫生院',
		// 350212040: '厦门市同安区洪塘卫生院',
		// 350212041: '厦门市翔安区新圩中心卫生院',
		// 350212042: '厦门市同安区五显卫生院',
		// 350212043: '中国人民解放军海军同安闽海医院',
		// 350212044: '厦门市同安区妇幼保健院',
		// 350212045: '厦门市同安区中医院',
		// 350212046: '厦门市同安区医院',
		350212047: '厦门市第三医院',
		// 350212048: '厦门同安闽海医院',
		// 350213139: '厦门市同安区同民医院',
		// 350213140: '翔安区同民医院',
		// 350213141: '翔安莲河医院',
		350213142: '翔安医院(在建)',
		// 350213143: '厦门翔安平安医院',
		// 350213144: '厦门翔安平安医院',
		// 350213145: '厦门市翔安区内厝卫生院',
		// 350213146: '厦门市翔安区新店中心卫生院',
		// 350213147: '厦门市翔安区马巷卫生院',
		// 350502232: '泉州丰泽新视力眼科医院',
		// 350502233: '泉州市温陵医院',
		// 350502234: '泉州市成功医院',
		// 350502235: '泉州市妇幼保健院',
		// 350502236: '泉州市人民医院',
		// 350502237: '明新华侨医院',
		350502238: '泉州市第三医院',
		350502239: '福建医科大学附属第二医院',
		350502240: '泉州市第一医院',
		// 350502241: '泉州百信结石病专科医院',
		350502242: '泉州新视力眼科医院',
		// 350502243: '泉州东方妇科医院',
		// // 350502244: '泉州医学高等专科学校附属人民医...',
		// 350502245: '鲤城兴贤医院',
		// 350502246: '中国人民解放军第一八Ｏ医院',
		// 350503163: '丰泽区城东街道社区卫生服务中心',
		// 350503164: '华侨大学医院',
		// 350503165: '国立华侨大学医院',
		350503166: '泉州180医院体检中心',
		// 350503167: '泉州丰泽上医馆中医门诊部',
		// 350503168: '泉州济民耳鼻喉专科医院',
		// 350503169: '泉州东方九龙男科',
		// 350503170: '泉州长庚耳鼻喉专科医院',
		// 350503171: '泉州市正骨医院',
		// 350503172: '泉州市妇幼保健院',
		350503173: '泉州市中医院',
		350503174: '解放军第180医院',
		// 350503175: '泉州市东南医院',
		// 350503176: '泉州市南威医院',
		// 350503177: '泉州市丰泽区医院',
		350503178: '福建中医学院附属泉州中医院',
		// 350505229: '泉州市泉港医院',
		// 350521214: '惠安县玉埕医院',
		// 350521215: '安溪县桃舟卫生院',
		// 350521216: '惠安惠康医院',
		// 350521217: '惠安县中医院',
		// 350521218: '惠安县医院',
		// 350524185: '安溪中山医学门诊部',
		// 350524186: '安溪县明仁眼科医院',
		// 350524187: '安溪县参内卫生院',
		// 350524188: '安溪县魁斗卫生院',
		// 350524189: '安溪县芦田卫生院',
		// 350524190: '安溪县兰田卫生院',
		// 350524191: '安溪县大坪卫生院',
		// 350524192: '安溪县祥华卫生院',
		// 350524193: '安溪县城厢卫生院',
		// 350524194: '安溪县福田卫生院',
		// 350524195: '安溪县湖上卫生院',
		// 350524196: '安溪县感德卫生院',
		// 350524197: '安溪县尚卿卫生院',
		// 350524198: '安溪县虎邱卫生院',
		// 350524199: '安溪县金谷卫生院',
		// 350524200: '安溪县龙门卫生院',
		// 350524201: '安溪县蓬莱卫生院',
		// 350524202: '安溪县剑斗中心卫生院',
		// 350524203: '安溪县长坑中心卫生院',
		// 350524204: '安溪县龙涓中心卫生院',
		// 350524205: '安溪县西坪中心卫生院',
		// 350524206: '安溪县妇幼保健院',
		// 350524207: '安溪县湖头医院',
		// 350524208: '安溪县官桥医院',
		// 350524209: '安溪县铭选医院',
		// 350524210: '安溪县中医院',
		// 350524211: '安溪县第三医院',
		// 350525225: '福建省天湖山矿区医院',
		// 350525226: '永春县天湖山矿务局医院',
		// 350525227: '福建省天湖山能源公司医院',
		// 350525228: '永春县中医院',
		// 350526212: '德化县中医院',
		// 350526213: '德化县医院',
		// 350581230: '石狮科盾部队医院',
		// 350581231: '石狮市华侨医院',
		// 350582219: '晋江九洲医院',
		// 350582220: '男科、妇科、不孕不育、中医皮肤...',
		// 350582221: '晋江市安海医院',
		// 350582222: '晋江市医院',
		// 350582223: '晋江市中医院',
		// 350582224: '泉州市博海医院',
		// 350583179: '南安市妇幼保健院',
		// 350583180: '南安市海都医院',
		// 350583181: '南安市国专医院',
		// 350583182: '南安市中医院',
		// 350583183: '南安市医院',
		// 350583184: '泉州市光前医院',
		// 350702002: '延平协和中西医门诊部',
		// 350702003: '南平博爱肝病医院（民营）',
		350702004: '南平九二医院',
		// 350702005: '南平市人民医院',
		// 350702006: '中国人民解放军第九十二医院',
		// 350702007: '中国水利水电闽江工程局医院',
		// 350702008: '南平市延平区医院',
		// 350702009: '南平森工医院',
		// 350702010: '南平市中医院',
		// 350702011: '南平市妇幼保健院',
		350702012: '南平市第一医院',
		// 350703014: '南平市第二医院',
		// 350721027: '顺昌县医院',
		// 350722020: '浦城县中医院',
		// 350723000: '光泽县中医院',
		// 350723001: '光泽县医院',
		// 350724017: '松溪县医院',
		// 350725015: '政和县中医院',
		// 350725016: '政和县医院',
		// 350781021: '邵武市妇幼保健院',
		// 350781022: '邵武市第二医院',
		// 350781023: '邵武铁路医院',
		// 350781024: '邵武市中医院',
		// 350781025: '邵武市立医院',
		// 350781026: '福建省邵武市人民医院',
		// 350782018: '武夷山市立医院',
		// 350782019: '武夷山市中医院',
		// 350783013: '建瓯市立医院',
		// 130202252: '华北煤炭医学院附属医院路南分院',
		// 130202253: '健民肿瘤研究路肿瘤医院',
		// 130202254: '唐山市友谊医院',
		// 130202255: '唐山美中妇产医院',
		// 130202256: '铁道部第一工程局唐山医院',
		// 130202257: '开滦精神卫生中心',
		// 130202258: '唐山市卫生学校附属医院',
		// 130202259: '开滦医疗集团',
		// 130202260: '唐山市路南区医院',
		// 130202261: '唐山市第十医院',
		// 130202262: '华北煤炭医学院路南分院',
		// 130202263: '唐山友谊医院',
		// 130202264: '唐山市农工心脑血管病防治医院',
		// 130202265: '唐山武警总队医院',
		// 130202266: '唐山冀东医院',
		// 130202267: '唐山同济医院',
		// 130202268: '唐山华新纺织集团医院',
		// 130202269: '唐山市二运集团有限公司医院',
		// 130202270: '唐山市一运集团有限公司医院',
		// 130202271: '唐山铁路中心医院',
		// 130202272: '唐山市眼科医院',
		// 130202273: '唐山中西医专科医院',
		// 130202274: '唐山荷花坑医院',
		// 130202275: '唐山路南红十字会医院',
		// 130202276: '唐山平安医院',
		// 130202277: '唐山脉管炎医院',
		// 130202278: '唐山复兴医院',
		// 130202279: '唐山市路南区南郊医院',
		// 130202280: '唐山市路南区卫生协会脉管炎医院',
		// 130202281: '唐山市路南区妇幼保健站',
		// 130202282: '唐山中山医院',
		// 130202283: '唐山市第二医院',
		// 130202284: '唐山骨伤医院',
		// 130202285: '唐山市农工医院',
		// 130202286: '唐山市协和医院',
		// 130202287: '唐山市第五医院',
		// 130202288: '唐山市第四医院',
		130202289: '唐山市人民医院',
		// 130203203: '唐山冀东眼科医院',
		// 130203204: '唐山京顺中医医院',
		// 130203205: '唐山凤凰妇科医院',
		// 130203206: '中国人民解放军五二八三一部队专...',
		// 130203207: '唐山市第八人民医院唐山陶瓷集团...',
		// 130203208: '唐山曙光男科医院',
		// 130203209: '唐山华夏中西医结合医院',
		// 130203210: '唐山中西医医院',
		// 130203211: '健民肿瘤研究所肿瘤医院',
		// 130203212: '唐山市公安局安康医院',
		130203213: '华北煤炭医学院附属医院',
		// 130203214: '唐钢建筑工程公司医院',
		// 130203215: '华北煤炭医学院中西医结合医院',
		// 130203216: '唐山京城皮肤病医院',
		// 130203217: '中国建筑二局职工医院',
		// 130203218: '铁道部第一工程局职工医院',
		// 130203219: '华北煤炭医学院附属医院分院',
		// 130203220: '唐山市脑血管病医院',
		// 130203221: '开滦（集团）有限责任公司医院',
		// 130203222: '唐山市中医医院',
		// 130203223: '唐山市结核病防治所',
		// 130203224: '武警河北总队唐山医院',
		// 130203225: '唐山铁路医院',
		// 130203226: '唐山市商业医院',
		// 130203227: '唐山水泥机械厂职工医院',
		// 130203228: '唐山三友集团有限公司职工医院',
		// 130203229: '唐山钢铁集团有限公司医院',
		// 130203230: '唐山秀梅脑中风专科医院',
		// 130203231: '唐山市中西医结合医院',
		// 130203232: '唐山市新区妇幼保健站',
		// 130203233: '唐山市路北区妇幼保健站',
		// 130203234: '唐山市路北区卫协医院',
		// 130203235: '唐山市职业病防治医院',
		// 130203236: '唐山市第十一医院',
		// 130203237: '唐山市第九医院',
		// 130203238: '唐山市第八医院',
		// 130203239: '唐山市第七医院',
		// 130203240: '唐山市利明医院',
		// 130203241: '唐山市康复医疗中心',
		130203242: '唐山市中医院',
		130203243: '唐山市第六医院',
		130203244: '河北医科大附属唐山工人医院',
		// 130203245: '唐山眼科医院',
		// 130203246: '唐山市路北红十字会',
		// 130203247: '唐山市工人医院集团第九医院',
		// 130203248: '唐山市颈肩腰腿痛专科医院',
		130203249: '河北联合大学附属医院',
		130203250: '开滦医院',
		// 130203251: '唐山男科医院（唐山现代男科医院矿医院',
		// 130204163: '开滦（集团）有限责任公司赵各庄矿医院',
		// 130204164: '开滦（集团）有限责任公司吕家坨矿医院',
		// 130204165: '开滦（集团）有限责任公司唐家庄矿医院',
		// 130204166: '开滦（集团）有限责任公司范各庄矿医院',
		// 130204167: '开滦（集团）有限责任公司林西矿矿医院',
		// 130204168: '开滦（集团）范各庄矿业公司医院',
		// 130204169: '唐山市古冶区商业医院',
		// 130204170: '唐山市古冶区妇幼保健站',
		// 130204171: '唐山市古冶区中医院',
		// 130204172: '唐山市古冶区医院',
		// 130204173: '唐山市第三医院',
		// 130205177: '唐山市马家沟耐火材料有限责任公...',
		// 130205178: '唐山市丰润区城西医院',
		// 130205179: '唐山冶金矿山机械厂职工医院',
		// 130205180: '唐山半壁店钢铁公司医院',
		// 130205181: '唐山市开平区妇幼保健站',
		// 130205182: '唐山市开平区医院',
		// 130207129: '河北省丰南市中医医院',
		// 130207130: '河北省丰南市医院',
		// 130207131: '丰南市中医院',
		// 130207132: '丰南市医院',
		// 130208133: '冀东水泥集团公司医院',
		// 130208134: '天津铁路分局丰润医院',
		// 130208135: '河北省丰润县城西医院',
		// 130208136: '河北省丰润县中医院',
		// 130208137: '河北省丰润县人民医院',
		// 130208138: '中国二十二冶金医院',
		// // 130208139: '铁道部第十八工程局第二工程处医...',
		// 130208140: '冶金部第二十二冶职工医院',
		// // 130208141: '开滦（集团）有限责任公司精神卫...',
		// 130208142: '唐山市丰润区铁路医院',
		// 130208143: '唐山市丰润区交通局医院',
		// 130208144: '唐山市丰润区城关医院',
		// 130208145: '唐山市丰润区公安局法医创伤医院',
		// 130208146: '唐山市丰润区股份专科医院',
		// 130208147: '唐山市丰润区结肠炎医院',
		// 130208148: '唐山市丰润区德生医院',
		// 130208149: '唐山市利康医院',
		// 130208150: '唐山市丰润区肠粘连医院',
		// 130208151: '唐山市丰润区妇幼保健院',
		// 130208152: '唐山市丰润区中医院',
		// 130208153: '唐山市丰润区第二医院',
		// 130208154: '唐山市丰润区人民医院',
		// 130208155: '唐山机车车辆厂医院',
		// 130208156: '唐山市东矿区中医院',
		// 130224183: '河北省滦南县医院',
		// 130224184: '滦南县经委职工医院',
		// 130224185: '滦南县京东医院',
		// 130224186: '滦南县精神病医院',
		// 130224187: '滦南县妇幼保健院',
		// 130224188: '滦南县中医院',
		// 130224189: '滦南县医院',
		// 130225157: '河北省乐亭县医院',
		// 130225158: '长芦大清河盐化集团有限公司医院',
		// 130225159: '乐亭第二医院',
		// 130225160: '乐亭县中医院',
		// 130225161: '乐亭县医院',
		// 130225162: '王庄乡徐家店卫生院',
		// 130227295: '迁西县中医医院',
		// 130227296: '迁西县医院',
		// 130229197: '河北省玉田县中医医院',
		// 130229198: '河北省玉田县医院',
		// 130229199: '玉田县第二医院',
		// 130229200: '玉田县妇幼保健院',
		// 130229201: '玉田县中医院',
		// 130229202: '玉田县医院',
		// 130281297: '遵化市民族医院',
		// 130281298: '遵化市建明中心医院',
		// 130281299: '遵化市卫协医院',
		// 130281300: '遵化市仁康医院',
		// 130281301: '遵化市妇幼保健院',
		// 130281302: '遵化市人民医院',
		// 130281303: '遵化市第二医院',
		// 130281304: '遵化平安医院',
		// 130283290: '首都钢铁公司矿业公司医院',
		// 130283291: '首钢矿山医院',
		// 130283292: '迁安化工有限责任公司职工医院',
		130283293: '迁安市中医院',
		// 130283294: '迁安市人民医院',
		// 130284190: '滦县人民医院',
		// 130284191: '滦县癫痫病医院',
		// 130284192: '滦县第二人民医院',
		// 130284193: '滦县人民医院',
		// 130284194: '滦县妇幼保健站',
		// 130284195: '滦县癫痫病医院',
		// 130284196: '滦县中医院',
		// 130402799: '邯郸玛丽亚妇产医院',
		// 130402800: '邯郸市传染病医院',
		// 130402801: '邯郸手外医院',
		// 130402802: '邯郸市邯山区医院',
		// 130402803: '邯郸电力医院',
		// 130402804: '邯郸铁路医院',
		// 130402805: '邯郸市第六医院',
		// 130402806: '邯郸市第三医院',
		// 130402807: '邯郸市第二医院',
		// 130402808: '邯郸市商业职工医院',
		// 130402809: '邯郸市总工会职工医院',
		// 130402810: '邯郸钢铁集团有限责任公司医院',
		// 130402811: '邯郸市妇幼保健院',
		// 130402812: '邯郸市中西医结合医院',
		130402813: '邯郸市中医院',
		130402814: '邯郸市中心医院',
		// 130402815: '邯郸市电力医院',
		// 130402816: '邯郸市按摩医院',
		// 130402817: '河北省邯郸市马头镇医院',
		// 130402818: '中国人民解放军二八五医院',
		// 130403693: '邯郸现代丽人医院',
		130403694: '河北工程大学附属医院',
		// 130403695: '丛台区中医院',
		// 130403696: '邯郸第三棉纺织有限公司职工医院',
		// 130403697: '邯郸都市妇科医院',
		// 130403698: '邯郸现代丽人整形美容医院',
		130403699: '河北工程学院附属医院',
		// 130403700: '邯郸市丛台区中医院',
		// 130403701: '邯郸县第二医院',
		// 130403702: '邯郸市第三建筑公司职工医院',
		// 130403703: '邯郸同济医院',
		// 130403704: '邯郸建工集团有限公司职工医院',
		// 130403705: '邯郸冶金矿山管理局职工总医院',
		// 130403706: '邯郸市纺织职工总医院',
		// 130403707: '邯郸市口腔医院',
		130403708: '邯郸荣耀心血管病医院',
		130403709: '邯郸市第一医院',
		// 130403710: '邯邢矿务局职工总医院',
		// 130403711: '邯邢矿山局总医院',
		// 130403712: '邯郸肝病医院',
		// 130403819: '邯郸县济仁医院',
		// 130404716: '邯钢烧伤治疗中心',
		// 130404717: '邯郸矿务局总医院',
		// 130404718: '邯郸市第五医院',
		// 130404719: '邯矿集团总医院',
		// 130404720: '中煤第一建设公司职工医院',
		// 130404721: '中国华北冶金建设有限公司职工医...',
		// 130406724: '河北省峰峰矿务局通二矿医院',
		// 130406725: '河北省峰峰矿务局孙庄矿医院',
		// 130406726: '河北省峰峰矿务局小屯矿医院',
		// 130406727: '河北省峰峰矿务局精神卫生中心',
		// 130406728: '河北省峰峰矿务局牛儿庄矿医院',
		// 130406729: '河北省峰峰矿务局三矿医院',
		// 130406730: '河北省峰峰矿务局羊渠河矿医院',
		// 130406731: '河北省峰峰矿务局六十三处医院',
		// 130406732: '河北省峰峰矿务局第二医院',
		130406733: '峰峰矿务局第一医院',
		// 130406734: '峰峰集团公司万年矿职工医院',
		// 130406735: '峰峰集团小屯矿医院',
		// 130406736: '峰峰集团公司羊渠矿职工医院',
		130406737: '峰峰矿务局总医院',
		// 130406738: '峰峰集团三矿职工医院',
		// 130406739: '峰峰矿务局牛二庄矿医院',
		// 130406740: '峰峰矿务局孙庄矿医院',
		// 130406741: '峰峰矿务局第二医院',
		// 130406742: '峰峰集团公司孙庄矿职工医院',
		// 130406743: '峰峰集团妇幼保健院',
		// // 130406744: '峰峰公司集团第二医院河北工程学...',
		// 130406745: '峰峰集团公司六十三处医院',
		// 130406746: '峰峰集团公司妇幼保健院',
		// 130406747: '邯郸市峰峰矿区妇幼保健院',
		// 130406748: '峰峰矿务局薛村矿医院',
		// 130406749: '峰峰集团万年矿职工医院',
		// // 130406750: '峰峰集团第二医院河北工程学院附...',
		// 130406751: '河北太行集团公司职工医院',
		// // 130406752: '邯郸冶金矿山管理局西石门玉石洼...',
		// 130406753: '邯郸陶瓷集团总公司职工医院',
		// 130406754: '邯郸市第四医院',
		// 130406755: '蓝天医院',
		// 130407795: '河北省肥乡县医院',
		// 130407796: '肥乡县中医院',
		// 130407797: '肥乡县医院',
		// 130407798: '邯郸安康精神病医院',
		// 130408787: '永年县中医院',
		// 130408788: '永年县第一医院',
		// 130423713: '河北省临漳县人民医院',
		// 130423714: '临漳县卫校附属医院',
		// 130423715: '临漳县医院',
		// 130424761: '河北省成安县人民医院',
		// 130424762: '成安县中医院',
		// 130424763: '成安县人民医院',
		// 130424764: '成安县医院',
		130424765: '邯郸市第285医院',
		// 130425722: '大名县钱氏中医精神医院',
		// 130425723: '大名县人民医院',
		// 130426789: '涉县中医院',
		// 130427790: '河北省磁县医院',
		// 130427791: '磁县肿瘤医院',
		// 130427792: '磁县妇幼保健站',
		// 130427793: '磁县中医院',
		// 130427794: '磁县医院',
		// 130430820: '邱县人民医院',
		// 130432756: '靳庄医院',
		// 130432757: '广平县人民医院',
		// 130432758: '广平县中医院',
		// 130432759: '广平县医院',
		// 130432760: '广平县妇幼保健医院',
		// 130433821: '河北省馆陶县人民医院',
		// 130433822: '馆陶县王桥乡卫生院',
		// 130433823: '馆陶县康复医院',
		// 130433824: '馆陶县妇幼保健院',
		// 130433825: '馆陶县中医院',
		// 130433826: '馆陶县人民医院',
		// 130434827: '河北省魏县人民医院',
		// 130434828: '魏县妇幼保健院',
		// 130434829: '魏县中医院',
		// 130434830: '魏县人民医院',
		// 130434831: '邯郸市魏县中医院',
		// 130435766: '曲周县法医院',
		// 130435767: '曲周县中医院',
		// 130435768: '曲周县医院',
		// 130481769: '河北省邯郸矿务局云驾岭煤矿医院',
		// // 130481770: '新兴铸管（集团）有限责任公司医...',
		// 130481771: '河北省武安市妇幼保健院',
		// 130481772: '河北省武安市中医院',
		// 130481773: '河北省武安市医院',
		// 130481774: '磁山镇卫生院',
		// 130481775: '武安市矿山中心卫生院',
		// 130481776: '邯郸矿务局云驾岭煤矿医院',
		// 130481777: '武安市妇幼保健院',
		// // 130481778: '邯郸矿业集团云驾社区管理中心医...',
		// 130481779: '武安市冶金矿山职工医院',
		// 130481780: '武安市劳动服务中心医院',
		// 130481781: '新兴铸管',
		// 130481782: '武安市腰椎间盘突出症治疗中心',
		// 130481783: '武安市中医院',
		// 130481784: '武安市医院',
		// 130481785: '新兴铸管职工医院',
		// 130481786: '二六七二工厂医院',
		// 130521686: '河北省邢台县医院',
		// 130522553: '河北省临城县人民医院',
		// 130522554: '临城县中医院',
		// 130522555: '临城县人民医院',
		// 130523568: '河北省内邱县人民医院',
		// 130523569: '内丘市公安医院',
		// 130523570: '内丘市妇幼保健站',
		// 130523571: '内丘市中医院',
		// 130523572: '内丘市人民医院',
		// 130524617: '柏乡县人民医院',
		// 130524618: '邢台市柏乡中医院',
		// 130525687: '隆尧协和医院',
		// 130525688: '隆尧县现代医院',
		// 130525689: '隆尧县康复医院',
		// 130525690: '隆尧县妇幼保健站',
		// 130525691: '隆尧县医院',
		// 130525692: '隆尧县双碑乡卫生院',
		// 130526563: '邢台任县仁爱医院',
		// 130526564: '任县职工医院',
		// 130526565: '任县妇幼保健站',
		// 130526566: '任县中医院',
		// 130526567: '任县医院',
		// 130527573: '南和县妇幼保健站',
		// 130527574: '南和县中医院',
		// 130527575: '南和县医院',
		// 130527576: '河北省南和县人民医院',
		// 130528596: '河北省宁晋县医院',
		// 130528597: '宁晋县妇幼保健院',
		// 130528598: '宁晋县中医院',
		// 130528599: '宁晋第二医院',
		// 130528600: '宁晋县中西医结合医院',
		// 130528601: '宁晋县医院',
		// 130528602: '河渠镇卫生院',
		// 130529603: '巨鹿县中医院',
		// 130529604: '巨鹿县医院',
		// 130530616: '新河县医院',
		// 130531612: '广宗县李么正骨医院',
		// 130531613: '广宗县妇幼保健院',
		// 130531614: '广宗县中医院',
		// 130531615: '广宗县医院',
		// 130532605: '河北省平乡县人民医院',
		// 130532606: '平乡县庞氏眼病专科',
		// 130532607: '平乡县妇幼保健站',
		// 130532608: '平乡县中医院',
		// 130532609: '平乡县第二医院',
		// 130532610: '平乡县医院',
		// 130532611: '后营村卫生室',
		// 130533585: '河北省威县人民医院',
		// 130533586: '威县朱氏正骨医院',
		// 130533587: '威县妇幼保健站',
		// 130533588: '威县中医院',
		// 130533589: '威县第二医院',
		// 130533590: '威县人民医院',
		// 130533591: '威县候贯中心卫生院',
		// 130533592: '威县李寨卫生院',
		// 130533593: '威县贺营乡卫生院',
		// 130533594: '贺钊卫生院',
		// 130533595: '陶庄卫生院',
		// 130534677: '清河县骨科医院',
		// 130534678: '清河县精神病医院',
		// 130534679: '清河县中医院',
		// 130534680: '清河县人民医院',
		// 130534681: '清河医院海淀区卫校附属医院',
		// 130534682: '清河县第二医院',
		130534684: '淮安市清河医院',
		130534685: '淮安市第二人民医院',
		// 130535556: '河北省临西县人民医院',
		// 130535557: '临西县第二医院',
		// 130535558: '临西县中医院',
		// 130535559: '临西县医院',
		// 130535560: '临西中医院',
		// 130535561: '临西县第二人民医院',
		// 130535562: '临西县人民医院',
		// 130581577: '河北省南宫市人民医院',
		// 130581578: '南宫市肿瘤医院',
		// 130581579: '南宫红十字肿瘤医院',
		// 130581580: '南宫市中医院',
		// 130581581: '南宫市第二人民医院',
		// 130581582: '南宫市人民医院',
		// 130581583: '河北省新乐荣军精神病医院',
		// 130581584: '南宫市凤岗办普济桥村卫生所',
		// 130582665: '河北省沙河市中医院',
		// 130582666: '沙河市硫铁矿医院',
		// 130582667: '沙河市公安医院',
		// 130582668: '沙河市绿链医院',
		// 130582669: '沙河市眼科中心',
		// 130582670: '沙河市精神病院',
		// 130582671: '沙河市连城堂烧伤医院',
		// 130582672: '沙河市妇幼保健站',
		// 130582673: '沙河市中医院',
		// 130582674: '沙河市第二医院',
		// 130582675: '沙河市医院',
		// 130582676: '邢台市建强医院',
		// 130602060: '保定市高科区医院',
		// 130602061: '保定市高开区医院',
		// 130602062: '保定市第一绵纺厂职工医院',
		// 130602063: '保定市七一医院',
		// 130602064: '保定市公安治疗腰椎间盘专科医院',
		// 130602065: '保定市中医院',
		// 130602066: '中国人民解放军93428部队癫...',
		// 130602067: '保定市红十字医院',
		// 130606000: '保定市第三中心医院',
		// 130606001: '保定市人民医院',
		130606002: '河北省第六人民医院',
		// 130606003: '保定市第五医院',
		// 130606004: '保定市按摩医院',
		// 130606005: '中国人民解放军第二五二医院',
		// 130606006: '保定市东华医院',
		// // 130606007: '中国人民解放军51034部队桥...',
		// 130606008: '保定铁路医院保定肛肠医院',
		// 130606009: '保定市第三医院',
		// 130606010: '空军保定医院',
		// 130606011: '保定市传染病院',
		// 130606012: '保定市国医甲状腺颈椎病研究所',
		130606013: '保定市妇幼保健院',
		// 130606014: '保定市刘守庙医院',
		// 130606015: '保定市古城医院',
		// 130606016: '保定法医医院',
		// 130606017: '保定东大肛肠医院',
		// 130606018: '保定市南市区区直医院',
		// 130606019: '保定市南市区妇幼保健站',
		// 130606020: '保定市第四医院',
		// 130606021: '河北省荣军医院',
		// 130606022: '保定东方医院',
		// 130606023: '保定市中西医结合医院',
		// 130606024: '保定世纪协和医院',
		// 130606025: '保定市第一医院',
		// 130606026: '保定市第一中心医院',
		130606027: '河北省职工医学院附属医院',
		130606028: '保定市第一中医院',
		130606029: '河北大学附属医院',
		// 130607110: '河北省满城县医院',
		// 130607111: '满城县妇幼保健院',
		// 130607112: '保定市第七医院',
		// 130607113: '满城县医院',
		// 130607114: '第六劳防队医院',
		// 130608107: '河北省清苑县人民医院',
		// 130608108: '清苑县妇幼保健院',
		// 130608109: '清苑县人民医院',
		// 130609057: '石油部地球物探局职工医院',
		// 130609058: '徐水县人民医院',
		// 130609059: '河北省徐水县人民医院',
		// 130623088: '河北省涞水县医院',
		// 130623089: '涞水县妇幼保健院',
		// 130623090: '涞水县医院',
		// 130623091: '涞水县白涧医院',
		// 130623092: '涞水县永阳镇卫生院',
		// 130623093: '涞水县三坡镇卫生院',
		// 130623094: '涞水县义安镇卫生院',
		// 130623095: '涞水县龙门乡卫生院',
		// 130623096: '涞水县娄村乡卫生院',
		// 130623097: '涞水县明义乡卫生院',
		// 130623098: '涞水县石亭镇中心卫生院',
		// 130623099: '涞水县九龙中心卫生院',
		// 130623100: '涞水县赵各庄中心卫生院',
		// 130623101: '涞水县东文山乡卫生院',
		// 130626043: '定兴县职工医院',
		// 130626044: '定兴县医院',
		// 130627030: '河北省唐县中医院',
		// 130627031: '河北省唐县人民医院',
		// 130627032: '唐县妇幼保健所',
		// 130627033: '唐县人民医院唐县白求恩医院',
		// 130627034: '北店头乡卫生院',
		// 130627035: '唐县中医院',
		// 130628126: '河北省高阳县医院',
		// 130628127: '高阳县医院',
		// 130628128: '高阳县脑血管病医院',
		// 130629054: '容城县人民医院',
		// 130629055: '容城县八于乡卫生院',
		// 130629056: '容城县中医医院',
		// 130630102: '涞源县医院',
		// 130631084: '河北省望都县妇幼保健站',
		// 130631085: '河北省望都县医院',
		// 130631086: '望都县中医院',
		// 130631087: '望都县医院',
		// 130632041: '安新县医院',
		// 130632042: '保定市中西医结合肿瘤治疗中心保...',
		// 130633068: '易县医院',
		// 130633069: '易县梁格庄卫生院',
		// 130633070: '易县西陵中心卫生院',
		// 130633071: '易县安格庄乡卫生院',
		// 130633072: '易县狼牙山中心卫生院',
		// 130633073: '易县七峪乡卫生院',
		// 130633074: '易县独乐乡卫生院',
		// 130633075: '易县龙华卫生院',
		// 130633076: '易县桥家河乡卫生院',
		// 130633077: '易县甘河净乡乡卫生院',
		// 130633078: '易县坡仓乡卫生院',
		// 130634079: '河北省曲阳县中医院',
		// 130634080: '河北省曲阳县医院',
		// 130634081: '曲阳县第二医院',
		// 130634082: '曲阳县中医院',
		// 130634083: '曲阳县人民医院',
		// 130635115: '河北省蠡县医院',
		// 130635116: '蠡县医院',
		// 130635117: '蠡县妇幼保健院',
		// 130635118: '辛兴脑血管医院',
		// 130636121: '顺平县医院',
		// 130638119: '河北省雄县医院',
		// 130638120: '雄县医院',
		130681103: '涿州市结核病医院',
		// 130681104: '涿州市中医院',
		// 130681105: '保定市第二中心医院',
		// 130681106: '涿州市医院',
		// 130682045: '定州市大鹿庄乡卫生院',
		// 130682046: '河北省定州市中医院',
		// 130682047: '定州市协和医院',
		// 130682048: '定州市福利眼科医院',
		// 130682049: '定州市人民医院',
		// 130682050: '定州武警部队医院',
		// 130682051: '定州市妇幼保健院',
		// 130682052: '定州市中心医院',
		// 130682053: '河北省第七人民医院',
		// 130683036: '河北省安国市医院',
		// 130683037: '安国协和医院',
		// 130683038: '安国协和医院',
		// 130683039: '安国市中医院',
		// 130683040: '安国市医院',
		// 130684122: '河北省高碑店市医院',
		// 130684123: '高碑店市中铁十八局医院',
		// 130684124: '高碑店市医院',
		// 130684125: '十八局高碑店医院',
		// 130702365: '张家口探矿机械总厂医院',
		// // 130702366: '张家口市桥东区鱼儿山社区卫生服...',
		// 130702367: '河北北方学院附属第三医院',
		// 130702368: '张家口市第二医院',
		// 130702369: '北京铁路分局张家口铁路医院',
		// 130702370: '张家口市中医院',
		// 130702371: '张家口创伤医院（民营）',
		// 130702372: '张家口市妇幼保健院',
		// 130702373: '张家口市建设职工医院',
		// 130702374: '张家口市口腔医院',
		// 130702375: '张家口市第五医院',
		// 130702376: '张家口市第六医院',
		// 130702377: '地矿部张家口探矿机械厂医院',
		// 130702378: '张家口市交通医院',
		// 130702379: '张家口煤矿机械有限公司医院',
		// 130702619: '邢台妇科医院',
		// 130702620: '邢台协和医院',
		// 130702621: '邢台现代医院',
		// 130702622: '邢台中西医结合医院',
		// 130702623: '邢台和平医院',
		// 130702624: '邢台县工农医院',
		// 130702625: '邢台冶金职工医院',
		// 130702626: '邢台市桥东区风湿病医院',
		// 130702627: '邢台市桥东区烧伤医院',
		// 130702628: '邢台市桥东区精神病医院',
		// 130702629: '河北省荣誉军人康复医院',
		// 130702630: '邢台市桥东区妇幼保健所',
		// 130702631: '邢台市桥东区医院',
		// 130702632: '邢台市公安医院',
		// 130702633: '邢台市康宁医院',
		// 130702634: '东方神功邢台康复医院',
		// 130702635: '邢台市眼科医院',
		// 130702636: '邢台聋哑康复医院',
		// 130702637: '邢台爱心医院',
		// 130702638: '邢台脑系专科医院',
		// 130702639: '邢台市中医院',
		// 130702640: '邢台市第四医院',
		// 130702641: '邢台钢铁有限责任公司职工医院',
		// 130702642: '邢台市第一医院',
		130702643: '邢台市人民医院',
		// 130703380: '张家口市传染病医院',
		// 130703381: '张家口博爱医院（民营）',
		// 130703382: '张家口市博爱医院',
		// 130703383: '张家口市轻工业局医院',
		// // 130703384: '张家口市老年病医院张家口市商业...',
		// 130703385: '张家口第四医院',
		// 130703386: '张家口市第一医院',
		// 130703644: '邢台市心血管病医院',
		// 130703645: '邢台矿业集团公司邢台矿医院',
		// 130703646: '邢台矿业集团总医院',
		// 130703647: '邢台市桥西区精神病医院',
		// 130703648: '邢台市桥西区妇幼保健站',
		// 130703649: '邢台市桥西区第二医院',
		// 130703650: '邢台市桥西医院',
		// 130703651: '邢台市聋哑医院',
		// 130703652: '邢台市结核病防治所',
		// 130703653: '邢台市桥西区南小汪兰痔瘘医院',
		// 130703654: '邢台西郊医院',
		// 130703655: '邢台腰椎间盘突出症医院',
		// 130703656: '邢台胃病专科医院',
		// 130703657: '邢台市妇幼保健院',
		// 130703658: '河北省复员军人医院',
		// 130703659: '邢台市桥西区医院',
		// 130703660: '邢台县医院',
		130703661: '邢台医专第二附属医院',
		// 130703662: '邢台县妇幼保健站',
		// 130703663: '邢台县第三医院',
		// 130703664: '邢台市第三医院',
		// 130705350: '宣化化肥集团有限公司职工医院',
		// // 130705351: '中国人民解放军51052部队医...',
		// 130705352: '宣化龙烟矿山职工医院',
		// 130705353: '张家口市宣化区医院',
		// 130705354: '宣化钢铁公司龙烟医院',
		// 130705355: '河北北方学院附属第二医院',
		// 130706349: '张家口市下花园区医院',
		// 130708348: '万全县中医院',
		// 130709357: '崇礼县医院',
		// 130722358: '张北县医院',
		// 130722359: '国营察北牧场职工医院',
		// 130724387: '沽源县医院',
		// 130725356: '尚义县医院',
		// 130726390: '河北省蔚县人民医院',
		// 130726391: '蔚县中医院',
		// 130726392: '蔚县人民医院',
		// 130727394: '阳原县中医院',
		// 130730360: '怀来县医院',
		// 130730361: '怀来同济医院',
		// 130730362: '怀来县县医院',
		// 130730363: '怀来县中医院',
		// 130730364: '张家口市',
		// 130731388: '涿鹿县中医院',
		// 130731389: '涿鹿县医院',
		// 130732393: '赤城县第一人民医院',
		130802403: '中国人民解放军第二六六医院',
		// 130802404: '承德九洲医院',
		// 130802405: '承德安康医院',
		// 130802406: '承德九洲医院',
		// 130802407: '承德市第五医院',
		// 130802408: '承德市双桥区妇幼保健院',
		// 130802409: '承德市精神病防治中心',
		// 130802410: '承德市北方医院',
		// 130802411: '承德市第二康复医院',
		// 130802412: '承德康复医院',
		// 130802413: '承德市妇幼保健院',
		// 130802414: '承德市荣复军人医院',
		// 130802415: '承德市骨伤病医院',
		130802416: '承德医学院第二附属医院',
		130802417: '承德医学院附属医院',
		// 130803418: '承德钢铁集团有限公司总医院',
		// 130803419: '承德市双滦区精神病院',
		// 130803420: '承德市双滦区妇幼保健站',
		// 130803421: '承德市双滦区人民医院',
		// 130804452: '铜城人民医院',
		// 130821437: '承德市中医院',
		// 130821438: '承德县中医院',
		// 130821439: '承德县医院',
		// 130822399: '河北省兴隆县人民医院',
		// 130822400: '兴隆县妇幼保健院',
		// 130822401: '兴隆县中医院',
		// 130822402: '兴隆县人民医院',
		// 130824440: '河北省滦平县中医院',
		// 130824441: '河北省滦平县医院',
		// 130824442: '滦平县妇幼保健院',
		// 130824443: '滦平县中医院',
		// 130824444: '滦平县医院',
		// 130825445: '河北省隆化县中医院',
		// 130825446: '隆化县第二医院',
		// 130825447: '隆化县妇幼保健院',
		// 130825448: '隆化县康复医院',
		// 130825449: '隆化县眼科研究所',
		// 130825450: '隆化县中医院',
		// 130825451: '隆化县医院',
		// 130826395: '丰宁县第二人民医院',
		// 130826396: '丰宁县妇幼保健院',
		// 130826397: '丰宁县中医院',
		// 130826398: '丰宁县医院',
		// 130827425: '河北省宽城满族自治县医院',
		// 130827426: '宽城县医院',
		// 130827427: '宽城县中医院',
		// 130827428: '宽城满族自治县中医院',
		// 130827429: '宽城满族自治县医院',
		// 130828422: '河北省御道口牧场医院',
		// 130828423: '围场县中医院',
		// 130828424: '围场县妇幼保健站',
		// 130881430: '平泉县精神病防治院',
		// 130881431: '平泉县木器厂职工医院',
		// 130881432: '平泉县西坝创伤骨科医院',
		// 130881433: '平泉县肛肠专科医院',
		// 130881434: '平泉县中医院',
		// 130881435: '平泉县第二医院',
		// 130881436: '平泉县医院',
		// 130902482: '沧州华美妇产医院',
		// 130902483: '沧州市第十三化建医院',
		// 130902484: '沧州市中西医结合医院东院区',
		// 130902485: '沧州市民族医院',
		// 130902486: '沧州市健民医院',
		// 130902487: '沧州市和平医院',
		130902488: '沧州市人民医院',
		// 130902489: '天津铁路分局沧州医院',
		// 130902490: '华北石油精神康复医院',
		// 130902491: '沧州市心血管病研究所',
		// 130903522: '沧州中科皮肤病医院',
		// 130903523: '沧州市传染病医院',
		// 130903524: '沧州军分区长城医院',
		// 130903525: '沧州化肥厂职工医院',
		// 130903526: '沧州监狱新生医院',
		// 130903527: '沧州市新华区妇幼保健站',
		// 130903528: '沧州市运河区眼科医院',
		// 130903529: '沧州市运河区妇幼保健站',
		// 130903530: '沧州市运河区医院',
		// 130903531: '沧州市口腔医院',
		// 130903532: '沧州市妇幼保健院',
		// 130903533: '沧州市中医院',
		130903534: '沧州市中西医结合医院',
		130903535: '沧州市中心医院',
		// 130903536: '沧州市血液病研究所',
		// 130921492: '沧县崔尔庄中心卫生院',
		// 130921493: '姚官屯乡卫生院',
		// 130921494: '沧县精神病院',
		// 130921495: '沧县人民医院',
		// 130921496: '沧州市精神病医院',
		// 130922537: '青县第二人民医院',
		// 130922538: '青县人民医院',
		// 130922539: '大杜庄卫生院',
		// 130922540: '青县金牛中心卫生院',
		// 130922541: '华北石油管理局第一机械厂医院',
		// 130923453: '东光县中医院',
		// 130923454: '东光县医院',
		// 130924508: '海兴县城关医院',
		// 130924509: '海兴县医院',
		// 130925513: '盐山县寿甫中医院',
		// 130925514: '盐山县人民医院',
		// 130926515: '肃宁县交通事故医院',
		// 130926516: '肃宁县心脑血管病医院',
		// 130926517: '肃宁县骨伤科医院',
		// 130926518: '肃宁县肿瘤医院',
		// 130926519: '肃宁交通创伤医院',
		// 130926520: '肃宁县妇幼保健站',
		// 130926521: '肃宁县人民医院',
		// 130927473: '南皮康平肿瘤医院',
		// 130927474: '南皮县妇幼保健院',
		// 130927475: '南皮县医院',
		// 130928476: '河北省吴桥县第二医院',
		// 130928477: '河北省吴桥县医院',
		// 130928478: '吴桥县第二医院',
		// 130928479: '吴桥县医院',
		// 130929510: '河北省献县人民医院',
		// 130929511: '献县妇幼保健站',
		// 130929512: '献县人民医院',
		// 130930480: '孟村回族自治县中医院',
		// 130930481: '孟村县医院',
		// 130981501: '河北省泊头市第二医院',
		// 130981502: '河北省泊头市中医医院',
		// 130981503: '泊头市第二医院',
		// 130981504: '泊头市眼耳鼻喉医院',
		// 130981505: '泊头市妇幼保健医院',
		// 130981506: '泊头市中医院',
		// 130981507: '泊头市医院',
		// 130982455: '河北省任丘市人民医院',
		// 130982456: '任丘市妇幼保健院',
		// 130982457: '任丘市人民医院',
		// 130982458: '任丘友谊创伤骨科医院',
		// 130982459: '任丘市急救中心',
		// 130982460: '任丘创伤骨科医院',
		// 130982461: '任丘法医医院',
		// 130982462: '任丘市肿瘤医院',
		// 130982463: '华北石油管理局采一医院',
		// 130982464: '华北石油中医院',
		// 130982465: '华北石油管理局井下作业公司医院',
		// // 130982466: '华北石油管理局第二油田建设工程...',
		// // 130982467: '华北石油管理局第一钻井工程公司...',
		// 130982468: '华北石油管理局第二医院',
		// 130982469: '华北石油机关医院',
		// 130982470: '中国化学工程第十三建设公司医院',
		// // 130982471: '华北石油管理局第三钻井工程公司...',
		// 130982472: '华北石油二部医院',
		// 130983542: '河北省黄骅市中西医结合医院',
		// 130983543: '黄骅市人民医院',
		// 130983544: '黄骅育康医院',
		// 130983545: '黄骅安定医院',
		// 130983546: '黄骅市妇幼保健站',
		// 130983547: '黄骅市中西医结合医院',
		// 130983548: '沧州中捷医院',
		// 130983549: '南大港农场医院',
		// 130983550: '国营中捷农场职工医院',
		// 130983551: '南大港人民医院',
		// 130983552: '沧州市中捷友好医院',
		// 130984497: '河间市沙河桥镇中心卫生院',
		// 130984498: '河间博康医院',
		// 130984499: '河间市人民医院',
		// 130984500: '河间市妇幼保健站',
		// 131002322: '廊坊武警学院医院',
		// 131003323: '廊坊友谊医院',
		// 131003324: '廊坊市广阳区人民医院',
		// 131003325: '廊坊红十字骨伤科医院',
		131003326: '廊坊市妇幼保健中心',
		131003327: '廊坊市中医院',
		131003328: '廊坊市人民医院',
		// 131003329: '廊坊市中心血站',
		// 131003330: '华北石油廊坊矿区第一医院',
		// 131003331: '石油部天然气集团公司中心医院',
		131003332: '中国石油中心医院',
		// 131022314: '河北省固安县医院',
		// 131022315: '固安县妇幼保健站',
		// 131022316: '固安县人民医院',
		// 131023334: '永清县脑血管病医院',
		// 131023335: '永清县人民医院',
		// 131023336: '永清县后奕镇中心卫生院',
		// 131023337: '永清县疾病控制中心',
		// 131024344: '香河县中医院',
		// 131024345: '香河县人民医院',
		// 131024346: '廊坊市利康医院',
		// 131024347: '河北省香河县人民医院',
		131025319: '大城县第四医院',
		131025320: '大城县第二医院',
		131025321: '大城县医院',
		// 131026333: '文安县中医院',
		// 131028317: '大厂县妇幼保健院',
		// 131028318: '大厂回族自治县医院',
		// 131081338: '霸州市妇幼保健院',
		// 131081339: '廊坊市第四医院霸州市第一医院',
		// 131081340: '霸州市第一医院',
		// 131081341: '霸州市中医院',
		// 131081342: '霸州市第二医院',
		// 131081343: '廊坊市第四人民医院',
		// 131082305: '河北燕达医院',
		// 131082306: '三河燕郊永泰医院',
		// 131082307: '燕京妇产医院',
		// 131082308: '三河煤矿医院',
		// 131082309: '三河市妇幼保健院',
		// 131082310: '京东中美医院',
		// 131082311: '燕郊人民医院',
		// 131082312: '核工业二三建设公司职工医院',
		// 131082313: '东彬医院（民营）',
		// 130209174: '中国石油冀东油田职工医院',
		// 130209175: '唐海县妇幼保健院',
		// 130209176: '唐海县医院',
		410102457: '河南医学院第一附属医院',
		410102458: '郑州大学男科医院',
		410102459: '郑州希褔中医肿瘤医院',
		410102460: '郑大四附院',
		410102461: '郑州153解放军医院东区',
		// 410102462: '郑州仁和老年病医院',
		// 410102463: '郑州市二砂医院',
		// 410102464: '河南省直第三人民医院',
		// 410102465: '郑州市中原区人民医院',
		410102466: '郑州市中心医院',
		// 410102467: '郑州市第二砂厂职工医院',
		410102468: '河南省人民医院',
		410102469: '中原油田总医院',
		// 410102470: '中原丽人医疗美容门诊部',
		// 410102471: '郑州济民中医院',
		// 410102472: '郑州美信中医院',
		// 410102473: '郑州金笛医院',
		410102474: '河南弘大心血管病医院',
		// 410102475: '郑州广慈医院',
		// 410102476: '郑州防空兵医院',
		// 410102477: '中原油田采油四厂医院',
		// 410102478: '中原油田医院第七分院',
		410102479: '中国人民解放军第一五三中心医院',
		410102480: '郑州解放军153医院',
		410102481: '郑州153东区妇产科',
		// 410102482: '郑州蓝雀有限公司职工医院',
		// // 410102483: '郑州电缆（集团）股份有限公司医...',
		// 410102484: '郑州六棉有限公司职工医院',
		410102485: '河南弘大心血管医院',
		// 410102486: '河南现代医学研究院医院',
		// 410102487: '郑州阳光男科医院',
		// 410102488: '郑州民生耳鼻喉医院',
		410102489: '郑州协和医院',
		// 410103493: '河南省传染病医院',
		// 410103494: '河南省肛肠医疗中心',
		410103495: '郑州中医骨伤病医院',
		// 410103496: '河南省电力医院',
		// 410103497: '郑州市第二人民医院',
		410103498: '郑州市第一人民医院',
		410103499: '郑州市骨科医院',
		410103500: '郑州市红十字医院',
		410103501: '郑州大学第三附属医院',
		410103502: '郑州大学第五附属医院',
		// 410103503: '郑州市第十人民医院',
		// 410103504: '郑州爱馨医院',
		// 410103505: '郑州中医骨伤病医院',
		410103506: '郑州大学附属第五医院',
		// 410103507: '郑州神源泌尿外科医院',
		// 410103508: '河医大校医院',
		// 410103509: '郑州三棉有限公司职工医院',
		// // 410103510: '郑州市第六人民医院郑州市结核病...',
		// 410103511: '河南省郑州血液病研究院',
		// 410103512: '郑州丰益肛肠医院',
		// 410103513: '郑州市精神病防治医院',
		// 410103514: '郑州警备区医院',
		// 410104537: '郑州市管城区中医院',
		// 410104538: '郑州市第三人民医院',
		// 410104539: '郑州市管城回族区人民医院',
		410104540: '郑州市管城中医院肿瘤科',
		410104541: '中国人民解放军郑州技术医院',
		// 410104542: '郑州金华针刀门诊部',
		// // 410104543: '华仁医院河南省中医研究院附属癫...',
		// 410104544: '中国抗癌协会郑州分会和平医院',
		// 410104545: '郑州华泰医院',
		// 410105553: '医光中医皮肤病研究所',
		// 410105554: '郑州芳艺整形美容医院',
		// 410105555: '郑州新世纪女子医院',
		// 410105556: '河南省中医药学会门诊部',
		// 410105557: '郑州济华骨科医院',
		// 410105558: '河南省郑州市郑纺机医院',
		// 410105559: '郑州市大学医院',
		// 410105560: '郑州圣玛妇产医院',
		// 410105561: '金水体坛诊所',
		// 410105562: '河南曙光医院',
		// 410105563: '郑州东方整形美容医院',
		// 410105564: '郑州市第一按摩医院',
		// 410105565: '郑州铁路分局医院',
		// 410105566: '河南整形美容医院',
		// 410105567: '郑州市银屑病研究所',
		// 410105568: '郑州金水丽人医院',
		// 410105569: '郑州建国医学研究院',
		// 410105570: '郑州华肤皮肤病研究所',
		// 410105571: '郑州现代妇科医院',
		// 410105572: '郑州美奥齿科医院',
		// 410105573: '植得口腔',
		// 410105574: '郑州市金水区中医院',
		// 410105575: '郑州天伦不孕不育医院',
		// 410105576: '河南省胸科医院',
		// 410105577: '郑州市第九人民医院',
		// 410105578: '河南省煤炭总医院',
		// 410105579: '郑州市妇幼保健院',
		410105580: '郑州市儿童医院',
		// 410105581: '郑州市第五人民医院',
		// 410105582: '河南省职工医院',
		410105583: '郑州大学第一附属医院',
		410105584: '郑州大学第二附属医院',
		410105585: '河南省军区医院',
		410105586: '郑州同济医院',
		410105587: '郑州商都妇产医院',
		// 410105588: '郑州博大泌尿外科医院',
		410105589: '河南省肿瘤医院',
		410105590: '河南省中医院',
		410105591: '河南中医学院第一附属医院',
		// 410106456: '中国长城铝业公司总医院',
		// 410108520: '郑州大学第四附属医院',
		// 410122490: '石油部管道局第三工程公司职工医...',
		// 410122491: '张杨贵皮肤科',
		// 410122492: '中牟县第一人民医院',
		// 410181515: '巩义市妇幼保健院',
		// 410181516: '巩义市人民医院',
		// 410181517: '巩义市中医院',
		// 410181518: '巩义市中医医院',
		// 410181519: '武警8680部队医院',
		// 410182546: '郑文友中医肿瘤医院（民营）',
		// 410182547: '荥阳市中医院',
		// 410182548: '荥阳市妇幼保健院',
		// 410182549: '荥阳市人民医院',
		// 410182550: '荥阳市中医医院',
		// 410183521: '郑煤集团总医院',
		// 410183522: '新密市新华医院',
		// 410183523: '新密市第一人民医院',
		// 410183524: '新密市中医院',
		// 410183525: '新密市红十字中心医院',
		// 410184526: '八千乡中心卫生院',
		410184527: '新郑博爱医院',
		// 410184528: '郑州华泰医院',
		// 410184529: '新郑卷烟厂职工医院',
		// 410184530: '新郑市中医院',
		// 410184531: '新郑市人民医院',
		// 410185532: '登封市妇幼保健院',
		// 410185533: '登封市中医院',
		// 410185534: '登封市水利医院',
		// 410185535: '登封市第三人民医院',
		// 410185536: '登封市人民医院',
		// 410202218: '开封市第二中医院',
		// 410202219: '开封市金明区人民医院',
		// 410202229: '开封新区圣玛医院',
		410202230: '河南大学第一附属医院',
		410202231: '河南大学淮河医院',
		// 410203220: '中国化学工程总公司第十一建设公...',
		// 410203221: '开封市妇幼保健院',
		// 410203222: '开封市第一中医院',
		// 410203223: '开封市第四人民医院',
		410203224: '开封市第二人民医院',
		// 410203225: '开封市回族医院',
		410203226: '开封光大医院',
		410203227: '开封市第一人民医院',
		410204228: '开封市儿童医院',
		// 410212210: '开封铁路中心医院',
		// 410212211: '开封县第二人民医院',
		// 410212212: '开封县第一人民医院',
		// 410221213: '杞县妇幼保健院',
		// 410221214: '杞县人民医院',
		// 410221215: '阳古卫生院',
		// 410222216: '通许县人民医院',
		// 410222217: '四所楼镇卫生院',
		// 410223207: '尉氏县第一人民医院',
		// 410223208: '尉氏县第三人民医院',
		// 410223209: '尉氏县第二人民医院',
		// 410225181: '兰考县小宋卫生院',
		// 410225182: '兰考县公费医疗医院',
		// 410225183: '兰考县公疗医院',
		// 410225184: '兰考县中医院',
		// 410225185: '兰考县人民医院',
		// 410302347: '铁道部第十五工程局医院',
		// 410302348: '洛阳同丹中医院',
		// 410302349: '洛阳市肛肠医院',
		// 410302350: '洛阳市中州人民医院',
		// 410302351: '洛阳市按摩医院',
		// 410302352: '洛阳市交通医院',
		410303353: '洛阳第五人民医院',
		// 410303354: '洛阳市棉纺厂职工医院',
		// 410303355: '铁道部隧道工程局中心医院',
		// // 410303356: '中国建筑第二工程局第二公司职工...',
		// 410303357: '洛阳王城医院（民营）',
		// 410303358: '洛阳市第四人民医院',
		// 410303359: '洛阳市精神病医院',
		// // 410303360: '洛阳中西医结合医院洛阳男科医院...',
		// 410303361: '洛阳金盾医院',
		// 410303362: '洛阳仁和医院',
		// 410303363: '洛阳凯旋口腔医疗中心',
		// 410303364: '洛阳真爱门诊',
		// 410303365: '洛阳不孕不育症医院',
		// 410303366: '河南省第六建筑工程公司职工医院',
		410303367: '洛阳市第一中医院',
		// 410303368: '洛阳市第五人民医院',
		// 410303369: '河南科技大学第二附属医院',
		// 410303370: '洛阳市妇女儿童医疗保健中心',
		// 410303371: '洛阳市商业职工医院',
		// 410303372: '洛阳白马集团职工医院',
		410303373: '中国人民解放军一五Ｏ中心医院',
		410303374: '洛阳市中心医院',
		// 410303375: '洛阳市洛硅医院',
		// 410303376: '洛阳武警医院',
		// 410303377: '洛阳市白马医院',
		// 410304339: '洛阳铁路分局洛阳铁路医院',
		// 410304340: '铁道部第十五工程局中心医院',
		// 410304341: '廛河区中窑村卫生室',
		// 410304342: '洛阳市机车厂医院',
		// 410304343: '洛阳济仁肾病医院',
		410304344: '洛阳市第三人民医院',
		// 410304345: '洛阳市第一人民医院',
		410304346: '河南省洛阳正骨医院',
		// 410305312: '洛阳市洛耐医院',
		// 410305313: '中国人民解放军第五三四医院',
		// // 410305314: '洛阳北方企业集团(原5111厂...',
		// 410305315: '洛阳市二○二医院',
		// 410305316: '中信重机中心医院',
		// 410305317: '洛阳市洛铜医院',
		// 410305318: '洛阳市洛轴总医院',
		410305319: '中国人民解放军第150医院',
		410305320: '河南科技大学第一附属医院',
		// 410305321: '洛阳烧伤医院',
		// 410305322: '洛阳市黄金医院',
		// 410305323: '洛阳市涧西区人民医院',
		// 410305324: '洛阳电力医院',
		// 410305325: '洛阳市第二中医院',
		// 410305326: '洛阳二Ｏ二医院',
		// 410305327: '洛阳市第六人民医院',
		// 410305328: '洛阳东方医院',
		// 410305329: '国营第四Ｏ七厂职工医院',
		// // 410305330: '东方医院（原洛阳市一拖拉机集团...',
		// 410305331: '洛阳市矿山机器厂职工医院',
		// 410305332: '洛阳耐火材料集团有限责任公司职...',
		// 410305333: '中信重型机械公司中心医院',
		// 410305334: '洛阳轴承集团有限公司总医院',
		// 410305335: '洛阳铜加工集团有限责任公司职工...',
		// 410305336: '洛阳南峰航空精密机电有限公司职...',
		// 410305337: '洛阳医学高等专科学校附属医院',
		// 410305338: '洛阳市耐火材料厂职工医院',
		// 410306286: '中国石化集团洛阳石油化工总厂医...',
		// 410306287: '洛阳市吉利区人民医院',
		// 410311305: '洛阳铁路分局南阳铁路医院',
		// // 410311306: '中国石油天然气第一建设公司职工...',
		// 410311307: '洛阳市精神卫生中心',
		// 410311308: '洛阳脑血管病医院',
		// 410311309: '洛阳仁和医院',
		// 410311310: '洛阳市荣康医院',
		// 410311311: '洛阳市肿瘤医院',
		// 410322288: '孟津县人民医院',
		// 410323295: '洛阳市精神卫生中心洛阳市第二精...',
		// 410323296: '新安县中医院',
		// 410323297: '新安县人民医院',
		// 410324298: '栾川县公疗医院',
		// 410324299: '栾川县人民医院',
		// 410325292: '嵩县人民医院',
		// 410325293: '嵩县中医院',
		// 410325294: '嵩县西关骨科医院',
		// 410326300: '汝阳县人民医院',
		// 410327289: '宜阳县妇幼保健院',
		// 410327290: '宜阳县中医院',
		// 410327291: '宜阳县人民医院',
		// 410328301: '洛宁县卫生学校附属医院',
		// 410328302: '洛宁县妇幼保健院',
		// 410328303: '洛阳阳光男科医院',
		// 410328304: '洛宁县人民医院',
		// 410329276: '伊川县卫生学校附属医院',
		// 410329277: '伊川县人民医院',
		// 410329278: '伊川县中医院',
		// 410329279: '伊川县公费医疗医院',
		// 410381280: '偃师市第四人民医院',
		// 410381281: '偃师市职工医院',
		// 410381282: '偃师市妇幼保健院',
		// 410381283: '偃师市人民医院',
		// 410381284: '偃师市第二人民医院',
		// 410381285: '偃师市中医院',
		// 410402148: '河南煤炭卫生学校附属医院',
		410402149: '中国人民解放军第一五二医院',
		// 410402150: '中平能化医疗集团七星公司医院',
		// 410402151: '平顶山市武警医院',
		// 410402152: '平顶山市妇幼保健院',
		// 410402153: '平顶市武警医院',
		// 410402154: '平顶山市棉纺织厂职工医院',
		// 410402155: '平顶山市煤业集团公司一矿职工医...',
		// 410402156: '平顶山市妇幼保健院平顶山市第四...',
		410402157: '平顶山市第二人民医院',
		// 410402158: '平顶山市华中老年病康复院（民营...',
		// 410402159: '平顶山协和皮肤保健院',
		// 410403134: '平顶山贝斯特口腔医院',
		// // 410403135: '平顶山市煤业集团公司八矿职工医...',
		// 410403136: '平顶山市卫东区人民医院',
		410403137: '平顶山市中医院',
		// 410403138: '平顶山市郊区人民医院',
		// 410403139: '平顶山市民政医院',
		// 410404172: '平顶山煤业（集团）公司高庄矿中...',
		// 410404173: '中平能化医疗集团一矿医院',
		// 410411167: '平顶山现代妇产医院',
		410411168: '平顶山市中医医院',
		// 410411169: '平顶山神马化纤纺织有限责任公司...',
		// 410411170: '平顶山煤业（集团）公司职业病防...',
		// 410411171: '平煤集团职业病医院',
		// 410421145: '平顶山市二院宝丰分院',
		// 410421146: '宝丰县中医院',
		// 410421147: '宝丰县人民医院',
		// 410422140: '叶县中医院',
		// 410422141: '叶县人民医院',
		// 410422142: '平顶山康复医院（民营）',
		// 410422143: '顺济堂中医诊所',
		// 410422144: '平顶山市第四人民医院',
		// 410423177: '鲁山县公疗医院',
		// 410423178: '鲁山县中医院',
		// 410423179: '鲁山县人民医院',
		// 410423180: '江河机械厂江河医院',
		// 410425175: '郏县中医院',
		// 410425176: '郏县人民医院',
		// 410481174: '舞钢市人民医院',
		// 410482160: '汝州市骨伤科医院',
		// 410482161: '汝州市第二人民医院',
		// 410482162: '汝州市第一人民医院',
		// 410482163: '汝州市中医院',
		// 410482164: '汝州市第三人民医院',
		// 410482165: '平顶山市梨元矿务局职工医院',
		// 410482166: '中国有色总公司六建职工医院',
		// 410502115: '安阳丽人医院',
		// 410502116: '安阳市洹北糖尿病医院',
		// 410502117: '安阳市天顺中医肛肠专科医院',
		// 410502118: '中国人民解放军第一五一医院',
		// 410502119: '安阳市口腔医院',
		// 410502120: '安阳市妇幼保健院',
		// 410502121: '安阳市糖尿病医院',
		// // 410502122: '安阳市第二人民医院',
		// 410502123: '安阳市灯塔医院',
		// 410503102: '安阳市第三人民医院',
		410503103: '安阳市中医院',
		410503104: '安阳市人民医院',
		410503105: '安阳市肿瘤医院',
		410503106: '安阳地区人民医院',
		// 410503107: '安阳市第二人民医院',
		// 410505127: '安阳市新兴医院',
		// 410522108: '安阳县第三人民医院',
		// 410522109: '安阳中医药学校附属医院',
		// 410522110: '安阳矿务局总医院',
		// 410522111: '安阳县第二人民医院',
		// 410522112: '安阳县第一人民医院',
		// 410522113: '安阳县中医院',
		// 410522114: '安阳钢铁集团公司职工总医院',
		// 410523128: '汤阴县人民医院',
		// 410523129: '安阳市汤阴县古贤乡卫生院',
		// 410526130: '滑县第三人民医院',
		// 410526131: '滑县第二人民医院',
		// 410526132: '滑县人民医院',
		// 410526133: '滑县中医院',
		// 410527100: '内黄县中医院',
		// 410527101: '内黄县人民医院',
		// 410581124: '林州市眼科医院',
		// 410581125: '林州市人民医院',
		// 410581126: '林州市食管癌医院',
		// 410603615: '鹤壁建筑医院',
		// 410603616: '鹤壁煤业集团总医院',
		// 410603617: '鹤壁市肿瘤医院',
		410603618: '鹤壁市第一人民医院',
		// 410611626: '鹤壁市中医院',
		// 410621619: '河南浚县妇幼保健院',
		410621620: '浚县社会福利医院',
		// 410621621: '浚县小河镇中心卫生院',
		// 410621622: '河南浚县胃肠病肿瘤研究所',
		// 410621623: '浚县屯子卫生院',
		// 410622624: '淇县人民医院',
		// 410622625: '鹤壁京立医院河南朝歌肾病医院（...',
		// 410702245: '红旗美天医疗美容诊所',
		// 410702246: '新乡解放军91中心医院不孕不育...',
		410702247: '新乡生殖医学医院',
		410702248: '河南省精神病医院',
		// 410702249: '新乡市妇幼保健院',
		410702250: '新乡市第二人民医院',
		// 410702251: '华源新乡市中医院',
		// 410702252: '新乡医学院第三附属医院',
		410702253: '新乡市中心医院',
		// 410702254: '中国人民解放军第三七一医院',
		// 410702255: '新乡市直机关医院',
		// 410702256: '新乡仁爱医院',
		// 410703232: '新乡和平妇产医院',
		// 410703233: '新乡市卫滨区人民医院',
		// 410703234: '新乡市传染病医院',
		// 410703235: '新乡市铁路医院',
		// 410721241: '温志宏妇产科门诊',
		// 410721242: '七里营镇任庄村卫生所',
		// 410721243: '新乡白鹭化纤集团有限责任公司中...',
		// 410721244: '新乡县人民医院',
		// 410724257: '获嘉县黄堤镇卫生院',
		// 410724258: '中州铝厂医院',
		// 410724259: '获嘉县中医院',
		// 410724260: '获嘉县人民医院',
		// 410724261: '中州铝厂职工医院',
		// 410725238: '原阳县中医院',
		// 410725239: '原阳县红十字医院',
		// 410726240: '延津县人民医院',
		// 410728273: '长垣县中医院',
		// 410728274: '长垣县人民医院',
		// 410728275: '河南宏力医院',
		410781236: '新乡医学院一附院',
		410781237: '河南省结核病医院',
		// 410782262: '辉县市高庄乡卫生院',
		// 410782263: '辉县市峪河镇卫生院',
		// 410782264: '辉县市黄水乡卫生院',
		// 410782265: '辉县市薄壁镇卫生院',
		// 410782266: '辉县市赵固乡卫生院',
		// 410782267: '辉县市洪洲乡卫生院',
		// 410782268: '辉县市拍石头乡卫生院',
		// 410782269: '辉县市妇幼保健院',
		// 410782270: '辉县市第三人民医院',
		// 410782271: '辉县市中医院',
		// 410782272: '辉县市人民医院',
		// 410802417: '焦作煤业集团五官医院',
		// 410802418: '焦作市康复医院',
		// 410802419: '焦作市五官医院',
		// 410802420: '焦作市妇幼保健院',
		// 410802421: '焦作市中医院',
		// 410802422: '焦作市卫校附属医院',
		// 410802423: '焦作煤业集团中央医院',
		// 410802424: '焦作市第五人民医院',
		410802425: '焦作市第二人民医院',
		410802426: '焦作市人民医院',
		// 410802427: '焦作市中医药学校附院医院',
		// 410802428: '焦作市商业医院焦作市轻工医院',
		410802429: '中国人民解放军一六零医院',
		// 410802430: '焦作市第六人民医院焦作卫生学校...',
		// 410802431: '焦作市公费医疗医院',
		// 410802432: '焦作市煤业集团有限责任公司中央...',
		// 410802433: '焦作市公费医院',
		// 410804434: '焦作市马村区人民医院',
		// 410811404: '山阳县妇幼保健院',
		// 410811405: '山阳县中医院',
		// 410811406: '山阳县人民医院',
		// 410821397: '修武县公费医院',
		// 410821398: '修武县人民医院',
		// 410822399: '博爱县人民医院',
		// 410822400: '博爱县妇幼保健院',
		// 410823407: '武陟县妇幼保健院',
		// 410823408: '武陟县中医院',
		// 410823409: '武陟县人民医院',
		// 410825414: '温县妇幼保健院',
		// 410825415: '温县中医院',
		// 410825416: '温县人民医院',
		// 410882410: '中国红十字沁阳市医院',
		// 410882411: '沁阳市公费医疗医院',
		// 410882412: '沁阳市胃肠病医院',
		// 410882413: '沁阳市人民医院',
		// 410883401: '孟州市中医院',
		// 410883402: '孟州市第二人民医院',
		// 410883403: '孟州市人民医院',
		// 410902388: '濮阳市炎夏医院',
		// 410922390: '清丰县中医院',
		// 410922391: '清丰县人民医院',
		// 410923389: '南乐县人民医院',
		// 410926396: '范县人民医院',
		410928392: '濮阳市油田总医院',
		// 410928393: '濮阳县人民医院',
		// 410928394: '濮阳仁济医院',
		// 410928395: '濮阳苗仁堂中医喉科诊所',
		// 411002447: '许昌市结核病防治所',
		// 411002448: '许昌市颈肩腰腿疼专科医院',
		// 411002449: '许昌凤凰医院',
		// 411002450: '许昌市魏都区公疗医院',
		// 411002451: '许昌市魏都区第二人民医院',
		// 411002452: '许昌市第三人民医院',
		// 411002453: '许昌市人民医院',
		// 411002454: '许昌市中医院',
		411002455: '许昌市中心医院',
		// 411003442: '许昌县人民医院',
		// 411024443: '鄢陵县人民医院',
		// 411024444: '鄢陵县中医院',
		// 411025440: '襄城县人民医院',
		// 411025441: '襄城县中医院',
		// 411081435: '禹州华夏医院',
		// 411081436: '禹州市中医院',
		// 411081437: '禹州市人民医院',
		// 411081438: '禹州市职工医院',
		// 411081439: '禹州市公疗医院',
		// 411082445: '长葛市中医院',
		// 411082446: '长葛市人民医院',
		// 411102379: '漯河东方医院',
		// 411102380: '漯河市第六人民医院',
		// 411102381: '漯河市中医院',
		// 411102382: '漯河市第三人民医院',
		// 411102383: '漯河市第二人民医院',
		411102384: '漯河市中心医院',
		// 411102385: '漯河医学高等专科学校第三附属医...',
		// 411102386: '漯河职工医院（原漯河市离退休职...',
		// 411121387: '舞阳县公费医疗医院',
		// 411122378: '临颍县人民医院',
		// 411302047: '河南南阳油田总医院',
		// 411302048: '南阳油田总医院',
		// 411302049: '南阳市正骨医院',
		// 411302050: '南阳市第二人民医院',
		// 411302051: '南阳市第一人民医院',
		411302052: '南阳市中心医院',
		// 411303029: '南阳市卧龙区妇幼保健院',
		// 411303030: '南阳医学高等专科学校第一附属医...',
		411303031: '河南大学附属南石医院',
		// 411303032: '南阳协和医院',
		// 411303033: '南阳市卧龙区第二人民医院',
		// 411303034: '南阳中医中药学校附院',
		// 411303035: '南阳市精神病医院',
		// 411303036: '南阳市第九人民医院',
		// 411303037: '南阳医专医院',
		// 411303038: '南阳市中医院',
		// 411303039: '南阳市中医学校附属医院',
		// 411303040: '南阳市中医中药研究所附属医院',
		// 411303041: '南阳市中西医结合儿童医院',
		// 411303042: '中国建筑第七工程局医院',
		// 411303043: '南阳铁路医院',
		// 411321028: '红阳机械厂职工医院',
		// 411322058: '方城县人民医院',
		// 411322059: '方城县中医院',
		// 411322060: '国营中南机械厂职工医院',
		// 411323066: '西峡县人民医院',
		// 411323067: '西峡县南大医院',
		// 411323068: '南阳县人民医院',
		// 411324075: '镇平县中医院',
		// 411324076: '镇平县人民医院',
		// 411325026: '内乡县中医院',
		// 411325027: '内乡县人民医院',
		// 411326062: '淅川县人民医院',
		// 411326063: '淅川县中医院',
		// 411326064: '浙川县中医院',
		// 411327065: '社旗县人民医院',
		// 411328044: '唐河县妇幼保健院',
		// 411328045: '唐河县人民医院',
		// 411328046: '唐河县中医院',
		// 411329053: '新野县上庄乡卫生院',
		// 411329054: '新野县妇幼保健院',
		// // 411329055: '新野县卫生职业中等专业学校附属...',
		// 411329056: '新野县中医院',
		// 411329057: '新野县人民医院',
		// 411330061: '桐柏华夏医院',
		// 411381069: '邓州市骨伤医院',
		// 411381070: '邓州市中医院',
		// 411381071: '邓州市第二人民医院',
		// 411381072: '邓州市第一人民医院',
		// 411381073: '邓州市第三人民医院',
		// 411381074: '李医生颈腰椎门诊',
		// 411402081: '商丘世鹏医院',
		// 411402082: '商丘市第三人民医院',
		// 411402083: '商丘市公疗医院',
		// 411402084: '商丘市中医院',
		// 411403094: '商丘市第五人民医院',
		// 411403095: '商丘市妇幼保健院',
		// 411403096: '商丘县人民医院',
		// 411403097: '商丘市第一人民医院',
		// 411403098: '商丘市中心医院',
		// 411421085: '民权县公疗医院',
		// 411421086: '民权县中医院',
		// 411421087: '民权县人民医院',
		// 411422092: '睢县中医院',
		// 411422093: '睢县人民医院',
		// 411423078: '宁陵县人民医院',
		// 411424079: '柘城县人民医院',
		// 411424080: '柘城县中医院',
		// 411425099: '虞城县人民医院',
		// 411426077: '夏邑县人民医院',
		// 411481088: '永城市人民医院',
		// 411481089: '河南神火集团职工总医院',
		// 411481090: '清华骨医骨科门诊',
		// 411481091: '清华中医骨病医院',
		// 411502012: '信阳航空妇科医院',
		411502013: '信阳154中心医院',
		// 411502014: '信阳市第一人民医院',
		// 411502015: '信阳市人民医院',
		// 411502016: '信阳卫校附属医院',
		// 411502017: '信阳市第三人民医院',
		411502018: '信阳市中心医院',
		// 411502019: '信阳协和医院',
		// 411503005: '信阳市第四人民医院',
		// 411503006: '武汉铁路分局信阳铁路医院',
		// 411503007: '信阳铁路医院',
		// 411521023: '罗山县中医医院',
		// 411521024: '罗山县中医院',
		// 411521025: '罗山县人民医院',
		// 411522000: '光山县人民医院',
		// 411523011: '新县人民医院',
		// 411524001: '商城县人民医院',
		// 411525002: '固始县妇幼保健院',
		// 411525003: '固始县人民医院',
		// 411525004: '固始县中医医院',
		// 411526022: '潢川县人民医院',
		// 411527020: '淮滨县人民医院',
		// 411527021: '淮滨县公疗医院',
		// 411528008: '息县公疗医院',
		// 411528009: '息县妇幼保健院',
		// 411528010: '息县人民医院',
		411702606: '驻马店市精神病医院',
		411702607: '中国人民解放军第159中心医院',
		// 411702608: '驻马店市铁东医院',
		// 411702609: '驻马店孙全贵私立骨科医院（民营...',
		// 411702610: '驻马店市妇幼保健院',
		// 411702611: '驻马店妇科医院',
		// 411702612: '驻马店泌尿专科医院',
		// 411702613: '驻马店第二人民医院',
		// 411702614: '驻马店市第四人民医院',
		// 411721604: '西平县人民医院',
		// 411722592: '上蔡县人民医院',
		// 411723593: '平舆县第二人民医院',
		// 411723594: '平舆县中医院',
		// 411723595: '平舆县公疗医院',
		// 411723596: '平舆县人民医院',
		// 411724598: '正阳县人民医院',
		// 411725603: '确山县人民医院',
		// 411726601: '泌阳县中医院',
		// 411726602: '泌阳市人民医院',
		// 411727599: '汝南县中医院',
		// 411727600: '汝南县人民医院',
		// 411728605: '遂平县人民医院',
		// 411729597: '新蔡县人民医院',
		// 410192551: '郑东美眼医疗美容门诊部',
		// 410192552: '郑州铁路分局长治北铁路医院',
		// 220102217: '长春市整形美容医院',
		// 220102218: '百色地区结核病防治所',
		// 220102219: '长春同康医院',
		// 220102220: '长春协和妇科医院',
		// 220102221: '吉林安贞医院',
		// 220102222: '长春阳光妇科医院',
		// 220102223: '长春静珠妇产科医院',
		// 220102224: '长春成方中西医结合医院',
		// 220102225: '长春市南关区妇幼保健院',
		// 220102226: '吉林省电力医院',
		// 220102227: '长春市口腔医院',
		220102228: '长春市妇产科医院',
		// 220102229: '吉林大学第二医院民康医院',
		220102230: '长春市中医院',
		220102231: '吉林大学第二医院',
		220102232: '长春市中心医院',
		// 220102233: '长春汇锋眼科医院',
		220102234: '长春中医药大学第二附属医院',
		220102235: '长春市第三医院',
		// 220102236: '长春同仁眼科医院',
		220102237: '长春同春堂皮肤病医院',
		220102238: '吉林恒达眼科医院',
		// 220102239: '长春仁爱医院',
		// 220102240: '长春市南关区结核病防治所',
		// 220102241: '长春市南关区环城医院',
		// 220102242: '南关区长通医院',
		// 220102243: '南关区环城医院',
		// 220102244: '南关区结核病防治所',
		// 220102245: '南关区妇幼保健院',
		// 220102246: '长春市南关区中医院',
		// 220102247: '长春市东北师范大学医院',
		// 220102248: '吉林省工业大学医院',
		// 220102249: '南关区曙光医院',
		// 220102250: '长春市南关区曙光医院',
		// 220102251: '南关区中西医结合医院',
		// 220102252: '长春市南关区中西医结合医院',
		// 220102253: '吉林农业大学囊虫病防治所附属医院',
		// 220102254: '开封市中西医结合中心医院',
		// 220102255: '开封市肿瘤医院',
		220102256: '中国人民解放军第一五五医院',
		// 220102257: '吉林大学南岭校区医院',
		// 220102258: '长春市中医医院',
		// 220102259: '长春圣心医院',

		// 220103250: '长春市仁术医院',
		// 220103251: '长春皮肤泌尿专科医院',
		// 220103252: '长春市人民医院',
		// 220103253: '长春市精神病医院',
		// 220103254: '吉林省监狱管理局中心医院',
		// 220103255: '长春民族医院',
		// 220103256: '长春嘉和医院',
		// 220103257: '长春仁术医院',
		220103258: '长春骨伤医院',
		220103259: '长春市宽城区肿瘤医院',
		220103260: '长春市宽城区妇幼保健所',
		220103261: '长春市宽城区民族医院',
		220103262: '宽城区肿瘤医院',
		220103263: '宽城区妇幼保健所',
		220103264: '宽城区民族医院',
		220103265: '宽城区站前医院',
		220103266: '吉林炭素集团长春糖尿病专科医院',
		220103267: '长春市宽城区站前医院',
		220103268: '长春市宽城区铁北医院',
		220103269: '长春市按摩医院',
		220103270: '吉林省胜利医院',
		220103271: '宽城区铁北医院',
		220103272: '长春市春日脑血管病医院',
		220103273: '长春脉管病医院',
		220103274: '长春市神经精神病医院',
		220103275: '长春铁路分局长春医院',
		220103276: '长春铁路医院',
		220103277: '吉林省劳改中心医院',
		220103278: '长春市宽城区中医院',
		220103279: '长春市凯旋医院',
		220103280: '长春机车厂职工医院',
		220103281: '宽城区兰家卫生院',
		// 220103282: '中国第一汽车集团专用车厂职工医...',
		220103283: '长春医学高等专科学校附属医院',
		220103284: '长春和美妇科专业医院',
		220104287: '长春市朝阳区清和医院',
		220104288: '长春市朝阳区白菊医院',
		220104289: '吉林省中医中药研究院',
		220104290: '长春市朝阳区人民医院',
		220104291: '长春市公交医院',
		220104292: '朝阳区清和医院',
		220104293: '朝阳区白菊医院',
		220104294: '长春市朝阳区中医院',
		220104295: '长春中医学院风湿医院',
		220104296: '中国人民解放军第四六一医院',
		220104297: '中国人民解放军65316医院',
		220104298: '长春新康呼吸专科医院',
		220104299: '长春中医药大学附属医院',
		220104300: '朝阳区人民医院',
		220104301: '长春市耳聋医院',
		220104302: '长春中医药大学附属针灸骨伤医院',
		220104303: '吉林省中西医结合医院',
		220104304: '长春市儿童医院',
		220104305: '吉林省人民医院',
		220104306: '吉林大学第一医院',
		220104307: '吉林省肿瘤医院',
		220104308: '吉林省中医院',
		220104309: '长春市心理医院',
		220104310: '长春理工大学医院',
		220104311: '长春八一医院',
		220104312: '长春春城人民医院',
		220104313: '吉林心脏病医院',
		220104314: '长春普济医院',
		220104315: '吉林省职业病防治院',
		220104316: '长春中医药大学附属风湿病医院',
		220104317: '长春脊髓病医院',
		220104318: '吉林大学第三医学院分院',
		220104319: '吉林省吉林中西医结合医院',
		220104320: '吉林大学第三医院分院',
		220104321: '吉林大学医院',
		220104322: '吉林省第二建筑公司职工医院',
		220104323: '长春市地矿医院',
		220104324: '长春市友谊医院',
		220104325: '长春中医学院附属针灸骨伤医院',
		220104326: '长春市朝阳区铭仁医院',
		220104327: '长春红星医院',
		220104328: '长春市兴华医院',
		220104329: '吉林省老科协医院',
		220104330: '长春铭仁医院',
		220104331: '吉林大学第一医院邮电医院',
		220104332: '吉林省前卫医院',
		220104333: '吉林省第一建筑公司职工医院',
		220104334: '吉林省体育系统运动创伤医院',
		220104335: '吉林乳腺病医院',
		220104336: '长春中医药大学风湿医院',
		220104337: '吉林省妇幼保健院',
		220104338: '长春市博爱中医院',
		220104339: '长春市脑积水医院',
		220104340: '长春都市丽人医院',
		// 220104341: '吉林省长飞医院（原吉林省省卫协...',
		220104342: '吉林大学口腔医院',
		220104343: '朝阳区小红门医院',
		220104344: '吉林大学中日联谊医院二部',
		220104345: '吉林大学中日联谊医院三部',
		220104346: '吉林中西医结合医院',
		// 220104347: '中华医学会吉林省分会附属医院吉...',
		220104348: '国营第二二八厂职工医院',
		220104349: '长春九龙皮肤病医院',
		220104350: '吉林大学第一医院',
		// 220105185: '首钢吉林柴油机厂职工医院',
		// 220105186: '中铁第十三工程局中心医院',
		220105187: '长春市经济技术开发区中心医院',
		// 220105188: '长春市二道区妇幼保健院',
		// 220105189: '长春市二道河子区结核病防治所',
		// 220105190: '二道河子区结核病防治所',
		// 220105191: '二道区妇幼保健院',
		// 220105192: '二道区第二医院',
		// 220105193: '长春市二道区第二医院',
		// 220105194: '长春经济技术开发区医院',
		// 220105195: '长春市二道区英俊医院',
		// 220105196: '长春市二道河子区人民医院',
		// 220105197: '长春市二道区人民医院',
		// 220105198: '二道河子区人民医院',
		// 220105199: '吉林珍爱妇产医院',
		220105200: '吉林大学中日联谊医院',
		// 220105201: '长春拖拉机制造厂职工医院',
		220105202: '吉林大学第三医院',
		// 220105203: '二道区第二医院',
		// 220105204: '长春君安医院',
		// 220105205: '长春烧伤医院',
		220106354: '长春市英平类风湿医院',
		220106355: '航空航天工业部长春二四六医院',
		220106356: '一汽总医院',
		220106357: '长春市卫校附属医院',
		220106358: '长春市传染病医院',
		220106359: '吉林省肝病专科医院',
		220106360: '长春博大喜宝妇产医院',
		220106361: '长春航天医院甲状腺科',
		220106362: '吉林国健妇产医院',
		220106363: '吉林省伤残康复医院',
		220106364: '吉林北方肝胆医院',
		220106365: '长春市绿园区城西医院',
		220106366: '长春市郊区妇幼保健站',
		220106367: '长春市绿园妇幼保健站',
		220106368: '长春市郊区结核病防治所',
		220106369: '长春市脑血管病医院',
		220106370: '吉林省中山医院',
		// 220106371: '长春银龙纺织集团有限公司职工医...',
		220106372: '吉林省北方肝胆病医院',
		220106373: '长春英平类风湿医院',
		220106374: '长春市第二医院',
		220106375: '长春纺织厂职工医院',
		220106376: '长春市急救中心',
		220106377: '长春市和平囊虫病医院',
		220106378: '吉林北方肝胆病医院',
		220106379: '长春市绿园区人民医院',
		// 220106380: '中国人民解放军第208医院伽玛...',
		220106381: '吉林大学第四医院',
		220106382: '长春市绿园区中医院',
		220106383: '吉林新华医院',
		220106384: '长春市锦西医院',
		// 220112239: '长春市双阳区妇幼保健站',
		// 220112240: '双阳区云山医院',
		// 220112241: '长春市双阳医学会医院',
		// 220112242: '长春市双阳区云山医院',
		// 220112243: '长春市双阳区平湖医院',
		// 220112244: '双阳区中医院',
		// 220112245: '双阳医学会医院',
		// 220112246: '长春市双阳区中医院',
		// 220112247: '长春市双阳区医院',
		// 220112248: '双阳区平湖医院',
		// 220112249: '双阳区妇幼保健站',
		// 220122206: '农安县中医医院',
		// 220122207: '农安县第二医院',
		// 220122208: '农安市兴华人民医院',
		// 220122209: '农安县中医院',
		// 220122210: '农安县兴华人民医院',
		// 220122211: '农安市妇幼保健医院',
		// 220122212: '农安县人民医院',
		// 220122213: '农安市中医院',
		// 220122214: '农安市人民医院',
		// 220122215: '农安县妇幼保健院',
		// 220122216: '长春市康宁医院',
		220182350: '榆树市中医院',
		220182351: '榆树市医院',
		220182352: '榆树市妇幼保健院',
		220182353: '榆树市东方医院',
		220183285: '德惠市人民医院',
		220183286: '德惠市中医院',
		// 220202008: '吉林市凤凰妇产医院',
		// 220202009: '吉林市传染病医院',
		// // 220202010: '吉林化纤集团有限责任公司职工医...',
		// 220202011: '吉林市九站医院',
		// // 220202012: '吉林市徐景信中医肿瘤皮肤病研治...',
		// 220202013: '吉林市第五人民医院',
		// 220202014: '昌邑区职工医院',
		// 220202015: '昌邑区骨科医院',
		// 220202016: '昌邑区妇幼保健院',
		// 220202017: '吉林市鸿博医院',
		// 220202018: '吉林造纸业股份公司职工医院',
		// 220202019: '吉林市昌邑区口腔医院',
		// 220202020: '吉林市昌邑区医院',
		// 220202021: '吉林市昌邑区骨伤医院',
		// 220202022: '吉林市交通医院',
		// 220202023: '吉林市公共交通总公司职工医院',
		// 220202024: '吉林市昌邑区妇幼保健站',
		// 220202025: '吉林市骨伤医院',
		// 220202026: '吉林市中西医结合肛肠医院',
		220202027: '吉林铁路中心医院',
		220202028: '吉林市中心医院',
		// 220202029: '吉林市凤凰妇产医院',
		// 220202030: '中国人民解放军第5704厂职工...',
		// 220202031: '吉林市第四医院',
		// 220202032: '吉林市中医联合医院',
		// 220202033: '吉林市京华医院',
		// 220202034: '吉林市儿童医院',
		// 220202035: '吉林市创伤医院',
		// 220202036: '吉林市回民医院',
		// 220202037: '吉林市第二人民医院',
		220202038: '吉林市第二中心医院',
		// 220203091: '吉林市龙潭区妇幼保健院',
		// 220203092: '吉林市龙潭区医院',
		// 220203093: '吉林市龙潭区江北医院',
		// 220203094: '吉林市龙潭区口腔医院',
		// 220203095: '吉林市职业病医院',
		// 220203096: '吉林新世纪医院',
		// 220203097: '吉林江北机械厂职工医院',
		// 220203098: '吉林市口腔医院',
		// 220203099: '吉林市龙潭区铁东医院',
		// 220203100: '吉林市龙潭区中医院',
		220203101: '吉林省吉林化学工业公司职工医院',
		// 220204067: '吉林市精神病医院',
		// 220204068: '吉林惠仁医院',
		// 220204069: '吉林耳鼻喉医院',
		// 220204070: '长春日坛血管病医院',
		// 220204071: '吉林市新华明眼科医院',
		220204072: '北华大学附属医院',
		// 220204073: '吉林市船营区中心妇幼保健院',
		// 220204074: '吉林市船营区牙科医院',
		// 220204075: '吉林市中西医结合糖尿病医院',
		// 220204076: '吉林市中西医结合心脑血管病医院',
		// 220204077: '吉林市船营区沙河医院',
		// 220204078: '吉林市红十字会第一医院',
		// 220204079: '吉林市船营区中医院',
		// 220204080: '吉林市船营区医院',
		// 220204081: '吉林市妇产医院',
		// 220204082: '吉林市船营区妇幼保健院',
		// 220204083: '吉林市船营区二医院',
		// 220204084: '吉林市中医院',
		220204085: '中国人民解放军第二二二医院',
		220204086: '吉林市第三人民医院',
		// 220204087: '吉林卫校附属医院',
		// 220211000: '吉林市丰满区口腔医院',
		// 220211001: '吉林市丰满区妇幼保健院',
		// 220211002: '吉林市丰满区医院',
		// 220211003: '吉林市丰满区中医院',
		// 220211004: '吉林医药学院附属医院（原:中国...',
		// 220211005: '吉林华侨医院',
		// 220211006: '吉林市第六人民医院',
		// 220211007: '吉林市高新博华医院',
		// 220221051: '永吉县医院',
		// 220221052: '永吉县中医院',
		// 220221053: '永吉县第二人民医院',
		// 220221054: '永吉县妇幼保健院',
		// 220281088: '蛟河市中医院',
		// 220281089: '蛟河市人民医院',
		// 220281090: '吉林市新站结核病医院',
		// 220282039: '红石林业局职工医院',
		// // 220282040: '中国水利水电第一工程局红石分局...',
		// 220282041: '夹皮沟黄金矿业公司职工医院',
		// 220282042: '桦甸市肛肠医院',
		// 220282043: '桦甸市妇幼保健站',
		// 220282044: '桦甸市康复医院',
		// 220282045: '桦甸市城区医院',
		// 220282046: '桦甸市中医院',
		// 220282047: '桦甸市第二人民医院',
		// 220282048: '桦甸市人民医院',
		// 220282049: '桦甸市第三人民医院',
		// 220282050: '桦甸市财贸医院',
		// 220283063: '舒兰精神病院',
		// 220283064: '舒兰市中医院',
		// 220283065: '舒兰市人民医院',
		// 220283066: '舒兰矿务局总医院',
		// 220284055: '吉林镍业集团有限责任公司职工医...',
		// 220284056: '磐石市妇幼保健院',
		// 220284057: '磐石医院',
		// 220284058: '磐石市人民医院',
		// 220284059: '磐石市第三人民医院',
		// 220284060: '磐石市第二人民医院',
		// 220284061: '磐石市中医院',
		// 220284062: '钦州地区复退军人医院',
		// 222401118: '延边专科口腔门诊部',
		// 222401119: '延吉天泉生殖健康医院',
		// 222401120: '延吉泌尿肾病医院',
		// 222401121: '延边妇幼保健院',
		// 222401122: '延边朝鲜族民族医院',
		222401123: '延边大学附属延边医院',
		// 222401124: '延边州中医院',
		// 222401125: '延边中西医医院',
		// 222401126: '延边华侨烧伤专科医院',
		// 222401127: '延边脑科医院',
		// 222401128: '延边朝鲜族自治州眼耳鼻喉科医院',
		// 222401129: '延边皮肤病防治院',
		// 222401130: '延边第二人民医院',
		// 222401131: '延吉市中医院',
		// 222401132: '延边州第二人民医院',
		// 222401133: '延吉市董昌林美容外科医院',
		// 222401134: '延边卫校附属医院',
		// 222401135: '延吉市妇幼保健所',
		// 222401136: '延边社会精神病院',
		// // 222401137: '延边州中医院延边大学医学院附属...',
		// 222401138: '延边大学医学院福祉医院',
		// 222401139: '延边眼耳鼻喉科医院',
		// 222401140: '吉林省延吉妇幼保健医院',
		// 222401141: '延吉市肛肠医院',
		// 222401142: '延吉市骨科医院',
		// 222401143: '延吉市医院',
		// 222401144: '延吉市肝胆风湿专科医院',
		// 222401145: '延吉市肿瘤',
		// 222401146: '延吉诺布尔口腔医院',
		// 222401147: '延吉市红十字中医院',
		// 222401148: '延边同仁医院',
		// 222402108: '图们市医院',
		// 222402109: '图们市中医院',
		// 222402110: '图们铁路医院',
		// 222402111: '图们市口腔医院',
		// 222402112: '图们市妇幼保健院',
		// 222403149: '敦化市大石头林业医院',
		// 222403150: '敦化市第二人民医院',
		// 222403151: '敦化市类风湿专科医院',
		// 222403152: '敦化市妇幼保健所',
		// 222403153: '敦化林业局职工医院',
		// 222403154: '敦化市铁路职工医院',
		// 222403155: '敦化铁路职工医院',
		// 222403156: '敦化市中医院',
		// 222403157: '敦化市医院',
		// 222403158: '吉林省敦化监狱医院',
		// 222404164: '珲春市二医院',
		// 222404165: '珲春林业局职工医院',
		// 222404166: '珲春市矿务局总医院',
		// 222404167: '珲春市康复医院',
		// 222404168: '珲春市红十字医院',
		// 222404169: '珲春市妇幼保健所',
		// // 222404170: '珲春矿区建设指挥部蛟河煤矿职工...',
		// 222404171: '珲春市医院',
		// 222405172: '龙井市结核病防治所',
		// 222405173: '龙井市中医院',
		// 222405174: '龙井市人民医院',
		// 222405175: '龙井市妇幼保健院',
		// 222405176: '龙井市地区医院',
		// 222406102: '和龙市妇幼保健院',
		// 222406103: '和龙林业局职工医院',
		// 222406104: '和龙市康复医院',
		// 222406105: '和龙煤矿职工医院',
		// 222406106: '和龙市中医院',
		// 222406107: '和龙市人民医院',
		// 222424159: '汪清县地区医院',
		// 222424160: '汪清县人民医院',
		// 222424161: '汪清天桥岭林业局职工医院',
		// 222424162: '汪清林业局职工医院',
		// 222424163: '汪清县中医院',
		// 222426113: '安图县第二人民医院',
		// 222426114: '安图县医院',
		// 222426115: '安图县保健站',
		// 222426116: '安图县第三人民医院',
		// 222426117: '安图县白河林业局职工医院',
		// 220113177: '九台市铁北医院',
		// 220113178: '九台市第二人民医院',
		// 220113179: '九台市人民医院',
		// 220113180: '九台市商业职工医院',
		// 220113181: '九台市妇幼保健站',
		// 220113182: '九台市中医院',
		// 220113183: '九台市第三人民医院',
		// 220113184: '舒兰矿务局营城煤矿职工医院',
		530102006: '昆明天伦妇产医院',
		530102007: '云南省红十字会医院',
		530102008: '昆玻社区卫生服务中心',
		530102009: '云南省传染病专科医院秀苑路门诊',
		// 530102010: '云南省精神卫生防治中心心理专科...',
		530102011: '昆明机床厂职工医院',
		530102012: '昆明市妇科医院',
		// 530102013: '昆明市中级人民法院直属医院昆明...',
		530102014: '昆明和平医院',
		530102015: '云南皮肤病专科医院',
		530102016: '昆明市眼科医院',
		530102017: '云南中医胃肠病医院',
		530102018: '昆明普瑞眼科医院',
		530102019: '昆明市五华区妇幼保健所',
		530102020: '昆明市五华区中医院',
		530102021: '云南白癜疯专科冯德孔诊所',
		530102022: '昆明市南屏口腔医院',
		530102023: '昆明医学院附属康复医院',
		530102024: '云南仁爱医院',
		530102025: '昆明中山医院',
		530102026: '昆明国防路医院',
		530102027: '云南古楼医院',
		530102028: '昆明市五华区红十字会医院',
		530102029: '云南省康复医院',
		530102030: '昆明铁路分局精神病结核病防治院',
		530102031: '昆明市精神病院',
		530102032: '昆明结核病防治院',
		530102033: '昆明国泰医院',
		530102034: '昆明市妇幼保健院',
		530102035: '昆明市五华区人民医院',
		530102036: '云南省邮电医院',
		530102037: '云南梯古眼科医院',
		530102038: '云南糖尿病专科医院',
		530102039: '云南省妇幼保健院',
		530102040: '陆军五十七医院',
		530102041: '云南省建工医院',
		530102042: '云南省中医医院',
		530102043: '云南省第二人民医院',
		530102044: '云南省妇幼保健院',
		530102045: '云南省邮电医院',
		530102046: '昆明医学院第二附属医院',
		530102047: '昆明肛泰肛肠医院',
		530102048: '云南华美美莱美容医院',
		530102049: '解放军昆明总医院',
		530102050: '和万家妇科在线咨询',
		530102051: '云南昆明整形美容医院',
		530103101: '云南九州泌尿专科医院（民营）',
		530103102: '云南省商业厅职工医院',
		530103103: '昆明神康脑科癫痫病医院',
		530103104: '云南天使儿童医学研究院',
		530103105: '盘龙韩美北京路口腔门诊部',
		530103106: '昆明东方医院',
		530103107: '昆明博美整形美容医院',
		530103108: '春城荣军古楼医院',
		530103109: '昆明肝病医院',
		530103110: '云南九洲医院',
		530103111: '昆明泌尿专科医院',
		530103112: '云南妇科泌尿专科医院',
		530103113: '昆明市盘龙区红十字会医院',
		530103114: '云南省监狱管理局中心医院',
		530103115: '昆明市盘龙区长春医院',
		530103116: '昆明市盘龙区妇幼保健所',
		530103117: '昆明新华医院',
		530103118: '云南国泰医院',
		530103119: '云南省林业中西医结合医院',
		530103120: '爱尔口腔医院',
		530103121: '昆明万华医院',
		530103122: '云南省精神病院',
		530103123: '昆明冶金职业病防治院',
		530103124: '昆明市盘龙区人民医院',
		530103125: '云南老年病医院',
		530103126: '昆明市盘龙区中医院',
		530103127: '云南同仁新华医院',
		530103128: '昆明铁路局中心医院',
		530103129: '昆明市延安医院',
		530103130: '光明中西医医院',
		530103131: '昆明市中医医院',
		530103132: '昆明市盘龙区人民医院',
		530103133: '云南省精神病院',
		530103134: '云南省第三人民医院',
		530103135: '昆明送子鸟医院',
		530103136: '昆明友谊口腔医院',
		530103137: '云南疼痛病医院',
		530103138: '昆明锦康皮肤病医院',
		530103139: '昆明医学院附属延安医院',
		530103140: '昆明医学院附属口腔医院',
		530103141: '昆明延安医院',
		530111057: '昆明市第二人民医院',
		530111058: '云南中医学院第二附属医院',
		530111059: '昆明市官渡区中医医院',
		530111060: '官渡区妇幼保健院',
		530111061: '昆明滇宝医院',
		530111062: '昆明福德医院',
		530111063: '昆明市官渡区中医院',
		530111064: '昆明官庄医院',
		530111065: '云南南疆医院',
		530111066: '昆明东郊骨伤科医院',
		530111067: '昆明市官渡区人民医院',
		530111068: '解放军第四七八医院',
		530111069: '昆明市皮肤病防治院',
		530111070: '云南省荣誉军人康复医院',
		530111071: '昆明市口腔医院',
		530111072: '昆明市第三人民医院',
		530111073: '昆明昆陆医院',
		530111074: '昆明市官渡区人民医院',
		530111075: '云南省交通中心医院',
		530111076: '云南航天工业公司疗养院',
		530111077: '昆明船舶医院',
		530111078: '云南老拨云堂医院',
		530111079: '云南省中医院望城分院',
		530111080: '云南国济医院（民营）',
		530111081: '云南骨科医院',
		530111082: '云南航天医院',
		// 530111083: '昆明市官渡区东郊杏德社区卫生服...',
		530112147: '云南癫痫病防治研究中心',
		530112148: '昆明丽莎妇产医院',
		530112149: '昆明当代妇产医院',
		530112150: '昆明西昌路医院',
		530112151: '昆明市西山区妇幼保健所',
		530112152: '云南平安中西医结合分医院',
		530112153: '昆明皮肤病专科医院',
		530112154: '成都军区昆明总医院',
		530112155: '昆明皮肤病医院',
		530112156: '昆明男性专科医院',
		530112157: '昆明大众眼科医院',
		530112158: '云南和平医院',
		530112159: '昆明市西山区人民医院',
		530112160: '昆明市西山区红十字会医院',
		530112161: '昆明市计划生育服务中心医院',
		530112162: '昆明市西山区皮肤病防治院',
		530112163: '昆明市西山区中医院',
		530112164: '云南肾脏病医院',
		530112165: '云南省第一人民医院',
		530112166: '云南省肿瘤医院',
		530112167: '昆明市西山区人民医院',
		530112168: '昆明市儿童医院',
		530112169: '昆明市第一人民医院',
		530112170: '昆明同仁医院',
		530112171: '云南医学科学院第一附属医院',
		530112172: '昆明新兴妇产医院',
		530112173: '恒康肿瘤医院（民营）',
		530112174: '国营西南仪器厂职工医院',
		530112175: '云南省泌尿男科诊疗基地',
		530112176: '昆明市中级人民法院直属医院',
		530112177: '云南光学仪器厂职工医院',
		530112178: '王和腰椎间盘专科医院',
		530112179: '云南省交通局中心职工医院',
		530112180: '昆明专科骨科医院',
		530113000: '昆明市东川区妇幼保健院',
		530113001: '昆明市东川市中医院',
		530113002: '东川市第一人民医院',
		530113003: '昆明市东川区第二人民医院',
		530113004: '昆明市东川区人民医院',
		530113005: '昆明市中医院',
		530114052: '呈贡县中医院',
		530114053: '呈贡县人民医院',
		530114054: '云南国济医院',
		530115098: '晋宁县第二人民医院',
		530115099: '晋宁县人民医院',
		530115100: '昆明磷矿职工医院',
		530124089: '富民县人民医院',
		530125084: '宜良县妇幼保健所',
		// 530125085: '宜良县脑病康复医院宜良县红十字...',
		530125086: '宜良县人民医院',
		530125087: '宜良县中医院',
		530125088: '云南省可保煤矿职工医院',
		530126142: '石林县人民医院',
		530126143: '路南县圭山民族医院',
		530126144: '路南县人民医院',
		530127093: '嵩明县太平龙骨科医院',
		530127094: '嵩明县人民医院',
		530127095: '嵩明县兰茂中医院',
		530127096: '云南省四营煤矿职工医院',
		530127097: '中国有色十四冶职工医院',
		530128145: '禄劝彝族苗族自治县中医院',
		530128146: '禄劝县第一人民医院',
		530129090: '寻甸县中医院',
		530129091: '寻甸回族彝族自治县妇幼保健院',
		530129092: '寻甸回族自治县第一人民医院',
		530181055: '昆明钢铁集团有限责任公司医院',
		530181056: '云南省精神病医院',
		530302206: '曲靖整形美容医院',
		530302207: '成都军区昆明总医院曲靖分院',
		530302208: '曲靖国立中医院',
		530302209: '曲靖市第一人民医院',
		530302210: '曲靖市妇幼保健院',
		530302211: '曲靖市第五人民医院',
		530302212: '曲靖市第三人民医院',
		530302213: '曲靖市妇幼医院',
		530302214: '曲靖三九中医院',
		530302215: '曲靖市麒麟区人民医院',
		530302216: '曲靖市第二人民医院',
		530302217: '曲靖五洲妇产医院',
		530302218: '曲靖国医堂中医院中医馆',
		530303197: '沾益县医院',
		530304204: '马龙县康复中心',
		530304205: '马龙县人民医院',
		530322199: '陆良培芳医院',
		530322200: '陆良县培芳医院',
		530322201: '陆良县妇幼保健院',
		530322202: '陆良县中医院',
		530322203: '陆良县人民医院',
		530323194: '师宗县妇幼保健院',
		530323195: '师宗仁和医院',
		530323196: '师宗县人民医院',
		530324198: '罗平县中医院',
		530325193: '云南省后所煤矿职工医院',
		530326181: '以礼河电厂职工医院',
		530326182: '云南冶金四矿职工医院',
		530326183: '会泽县妇幼保健院',
		530326184: '会泽县人民医院',
		530326185: '会泽县中医院',
		530381186: '宣威市交通医院',
		530381187: '宣威市妇幼保健站',
		530381188: '宣威锦森康复医院',
		530381189: '宣威精神病专科医院',
		530381190: '宣威市精神病专科医院',
		530381191: '云南省田坝煤矿职工医院',
		530381192: '云南云峰化学工业公司职工医院',
		620102021: '甘肃侯丽萍中医风湿病医院',
		620102022: '甘肃省民政康复医院',
		620102023: '甘肃大肠肛门病医院',
		620102024: '甘肃省铁道部第一工程局兰州医院',
		620102025: '城关镇凤潭医院',
		620102026: '城关医院',
		620102027: '兰州市肺科医院',
		620102028: '甘肃省劳改局兰州医院',
		620102029: '兰州市城关区第二人民医院',
		620102030: '兰州市城关区人民医院',
		620102031: '甘肃省干部医疗保健医院',
		620102032: '甘肃省铁路中心医院',
		620102033: '兰州抗白白癜风研究所',
		620102034: '兰州男健医院',
		620102035: '甘肃中医学院附属医院',
		620102036: '兰州医学院第二附属医院',
		620102037: '兰州医学院第一附属医院',
		620102038: '中国人民解放军第一医院',
		620102039: '兰州大学第一附属医院',
		620102040: '兰州中西结合医院',
		620102041: '兰州市军工医院',
		620102042: '兰州市窑街煤电公司医院',
		620102043: '兰州邮电医院',
		620102044: '兰州大学医院',
		620102045: '兰州市城关区第三人民医院',
		620102046: '兰州红十字康乐医院',
		620102047: '兰州市妇幼保健院',
		620102048: '甘肃省西北民族学院附属医院',
		620102049: '兰州市第二人民医院',
		620102050: '兰州中西医结合医院',
		620102051: '兰州市中医骨伤科医院',
		620102052: '甘肃省康复中心医院',
		620102053: '兰州市传染病医院',
		620102054: '兰州慈和堂医院',
		620102055: '兰州铁路局中心医院',
		620102056: '兰州大学口腔医院',
		620102057: '甘肃省人民医院',
		620102058: '兰州大学第二医院',
		620102059: '武警甘肃省总队医院',
		620102060: '西北民族学院医学院附属医院',
		620102061: '中国民航西北管理局兰州医院',
		620102062: '兰州肺科医院',
		620103000: '兰州军区兰州总医院',
		620103001: '兰州市七里河区中医医院',
		620103002: '兰州脊椎病骨科医院',
		620103003: '兰州电机厂职工医院',
		620103004: '甘肃省妇幼保健院',
		620103005: '兰州通用机械厂职工医院',
		620103006: '中北集团兰州机车厂医院',
		620103007: '兰州市第三人民医院',
		620103008: '兰州石油化工机械厂职工医院',
		620103009: '甘肃省中医院',
		620103010: '兰州市第一人民医院',
		620103011: '兰州军区总医院',
		620103012: '兰州安定医院',
		620103013: '兰州市七里河区人民医院',
		620103014: '兰州市残疾儿童康复中心',
		620103015: '兰州市社会福利精神病院',
		620103016: '兰州第一人民医院',
		620103017: '甘肃省肿瘤医院',
		620103018: '兰州市第四人民医院',
		620103019: '阿干镇煤矿职工医院',
		620103020: '武警甘肃总队医院',
		620104082: '甘肃省兰州维尼纶厂职工医院',
		620104083: '甘肃省黄河铝业有限公司职工医院',
		620104084: '兰州西固区妇幼保健院',
		620104085: '兰州炼油化工职工医院',
		620104086: '兰州平板玻璃厂职工医院',
		620104087: '兰州西固区中医院',
		620104088: '兰州市西固区人民医院',
		620104089: '兰州化学工业公司职工医院',
		620104090: '兰州大学附属天浩医院',
		// 620104091: '中国核工业总公司五零四厂职工医...',
		620104092: '有色总公司一建公司第二职工医院',
		620104093: '国营五Ｏ四厂职工医院',
		620105063: '甘肃省西北师范大学医院',
		620105064: '兰州市安宁区人民医院',
		620105065: '安宁市中医院',
		620105066: '安宁市妇幼保健院',
		620105067: '安宁市人民医院',
		620105068: '兰州军区总医院安宁分院',
		620105069: '兰州曙光中医医院',
		620105070: '兰州市精神病康复医院',
		// 620105071: '中国人民解放军第四七三医院空军...',
		620111079: '窑街煤电集团公司总医院医院',
		620111080: '兰州炭素工业公司职工医院',
		620111081: '兰州市红古区人民医院',
		620121075: '永登县水泥厂职工医院',
		620121076: '永登县中医院',
		620121077: '永登县人民医院',
		620121078: '兰州连城铝厂职工医院',
		620123072: '榆中县妇幼保健院',
		620123073: '榆中县第一人民医院',
		620123074: '榆中县中医院',
		450102032: '南宁天使盛天地口腔门诊部',
		450102033: '南宁医博中医肛肠医院',
		// 450102034: '广西壮族自治区妇幼保健院厢竹分...',
		450102035: '南宁广济高峰医院',
		450102036: '南宁友爱医院',
		450102037: '南宁东大中西医医院',
		450102038: '南宁梦想医疗美容医院',
		450102039: '南宁市残疾儿童康复中心',
		450102040: '南宁市红中医医院',
		450102041: '南宁亭凉医院',
		450102042: '南宁市口腔医疗中心',
		450102043: '南宁市红十字会医院',
		450102044: '南宁市中医院',
		450102045: '南宁市邕宁区中医院',
		450102046: '南宁市第七人民医院',
		450102047: '南宁市第四人民医院',
		450102048: '广西中医学院瑞康医院',
		450102049: '广西民族医院',
		450102050: '南宁市第五人民医院',
		450103114: '南宁南国医院',
		450103115: '南宁微笑天使口腔门诊部',
		450103116: '南宁健民医院',
		450103117: '南宁市思贤联合医院',
		450103118: '南宁市固卫矫形医院',
		450103119: '南宁市结核病防治所',
		450103120: '南宁市天使口腔医院',
		450103121: '南宁市协和医院',
		450103122: '南宁市矿务局医院',
		450103123: '南宁市退离休专家教授南湖医院',
		450103124: '南宁市茅桥中心医院',
		450103125: '广西职业病防治研究所',
		450103126: '广西壮族自治区第三人民医院',
		450103127: '广西壮族自治区人民医院',
		450103128: '南宁303医院',
		450103129: '广西中医学院第一附属医院',
		450103130: '广西医科大学第一附属医院',
		450103131: '南宁市第一人民医院',
		450103132: '广西医科大学附属口腔医院',
		// 450103133: '广西壮族自治区肿瘤医院、广西医...',
		// 450103134: '中国有色金属工业总公司南宁公司...',
		450103135: '广西亭凉医院',
		450103136: '南宁市郊区妇幼保健院',
		450103137: '广西民族学院医院',
		450103138: '广西劳动劳教中心医院',
		450103139: '南宁青山精神病院',
		450103140: '广西区人民医院整形美容激光中心',
		450103141: '广西亭凉医院（麻风病院）',
		450105069: '南宁协和医院',
		450105070: '广西江滨医院',
		450105071: '南宁中南妇科医院',
		450105072: '江南造船厂职工医院',
		450105073: '广西壮族自治区工人医院',
		450105074: '广西南宁第二医院生殖医疗中心',
		450105075: '南宁市第二人民医院',
		450105076: '广西体育运动创伤专科医院',
		450107079: '南宁市同济医院',
		450107080: '广西壮族自治区皮肤病医院',
		450107081: '广西医科大学附属第一医院',
		450107082: '广西区皮肤病医院',
		450107083: '广西医科大附属南宁中山医院',
		450107084: '南宁中山医院',
		450107085: '南宁希望快乐天使口腔门诊部',
		450107086: '南宁长江医院',
		450107087: '广西总队医院糖尿病临床研究中心',
		450107088: '南宁市玛莉亚门诊部',
		450107089: '南宁市长江医院',
		450107090: '南宁市友爱医院',
		450107091: '南宁市南湖联合医院',
		450107092: '南宁市郊区人民医院',
		450107093: '广西边防总队医院',
		450107094: '广西壮医医院',
		450107095: '武警广西总队医院',
		450107096: '南宁市妇女儿童医院',
		450107097: '南宁市社会福利医院',
		450107098: '南宁市第六人民医院',
		450107099: '南宁市第三人民医院',
		450107100: '南宁市第八人民医院',
		450107101: '南宁民族医院',
		450107102: '南宁肛泰肛肠医院',
		450107103: '广西骨伤医院',
		450107104: '广西区妇幼保健院',
		450108077: '南宁市第二人民医院五象医院',
		450108078: '那马精神病医院',
		450109105: '邕宁县妇幼保健院',
		450109106: '南宁地区复退军人医院',
		450109107: '南宁地区复退军人精神病医院',
		450109108: '南宁市邕宁区人民医院',
		450109109: '邕宁区中医院',
		450902001: '玉林市传统医疗中心',
		450110062: '武鸣县灵水医院',
		450110063: '武鸣县人民中医院',
		450110064: '武鸣县妇幼保健院',
		450110065: '武鸣县华侨农场医院',
		450110066: '武鸣县中医院',
		450110067: '武鸣县更昌医院',
		450110068: '武鸣县人民医院',
		450123110: '隆安县妇幼保健院',
		450123111: '隆安县巴发医院',
		450123112: '隆安县中医院',
		450123113: '隆安县人民医院',
		450124142: '广西壮族自治区妇幼保健院',
		450124143: '马山县民族医院',
		450124144: '马山县妇幼保健院',
		450124145: '马山县妇幼保健所',
		450124146: '马山县皮肤病防治站',
		450124147: '马山县人民医院',
		450124148: '马山县中医院',
		450125029: '上林县保健所',
		450125030: '上林县中医院',
		450125031: '上林县人民医院',
		450126051: '南宁市宾阳县博爱医院',
		450126052: '广西壮族自治区廖平农场医院',
		450126053: '宾阳县人民医院',
		450126054: '南宁地区民族卫生学校附属医院',
		450126055: '南宁市第九人民医院',
		450126056: '宾阳县中医院',
		450126057: '宾阳县妇幼保健院',
		450127058: '横县骨科医院',
		450127059: '横县妇幼保健院',
		450127060: '横县中医院',
		450127061: '横县人民医院',
		450202149: '柳州美丽焦点医疗美容门诊部',
		450202150: '柳州市人民医院',
		450202151: '柳州铁路局工程处医院',
		450202152: '柳州市民族中医院',
		450202153: '柳州市城中区医院',
		450202154: '柳州市中医院分部',
		450202155: '柳州市红十字会医院',
		450202156: '柳州市妇幼保健院',
		450202157: '柳州市瘫痪康复医院',
		450202158: '柳州市中医院',
		450203195: '柳州市郊区妇幼保健站',
		450203196: '柳州福康医院',
		450203197: '柳州机械厂职工医院',
		450203198: '柳州市燎原医院',
		450203199: '柳州市柳东医院',
		450203200: '柳州市鱼峰区医院',
		450203201: '柳州市东环医院',
		450203202: '柳州市大桥弘康医院',
		450203203: '柳州市肿瘤医院',
		450203204: '柳州市箭盘山医院',
		450203205: '中国人民解放军第一五八医院',
		450203206: '柳州市荣誉军人康复医院',
		450203207: '柳州市第二人民医院',
		// 450203208: '柳州医学高等专科学校附属医院广...',
		450203209: '广西地质职工医院',
		450203210: '柳州市龙潭医院',
		450203211: '柳州市中西医结合医院',
		450203212: '柳州市第三人民医院',
		450203213: '柳州市工人医院',
		450204168: '柳州丽人不孕不育医院',
		450204169: '柳州五菱医院',
		450204170: '柳州市柳南区人民医院分院',
		450204171: '柳州市柳南区人民医院',
		450204172: '柳州市康华骨伤科医院',
		450204173: '柳州市第二妇幼保健院',
		450204174: '柳州工程机械集团职工医院',
		450204175: '柳州铁路局中心医院',
		450204176: '柳二空医院',
		450204177: '柳州二空医院',
		450205159: '柳州化肥厂职工医院',
		450205160: '柳州市长塘医院',
		450205161: '柳州市第四人民医院',
		450205162: '柳州市柳北区医院',
		// 450205163: '柳州医学高等专科学校第一附属医...',
		450205164: '柳钢集团职工医院',
		450205165: '广西建工职工医院',
		450205166: '广西建工医院',
		450205167: '柳州地区妇幼保健院',
		450206185: '柳江县妇幼保健院',
		450206186: '柳江县建都中医骨科医院',
		450206187: '柳江县中医院',
		450206188: '柳江县人民医院',
		450222178: '柳城县妇幼保健院',
		450222179: '柳城县骨伤科医院',
		450222180: '柳城县妇保院',
		450222181: '柳城县妇保所',
		450222182: '柳城县骨伤科医院',
		450222183: '柳城县中医院',
		450222184: '柳城县人民医院',
		450223214: '鹿寨县妇幼保健站',
		450223215: '鹿寨县城关医院',
		450223216: '鹿寨县中医院',
		450223217: '鹿寨县人民医院',
		450224189: '融安县妇幼保健院',
		450224190: '融安县中医院',
		450224191: '融安县人民医院',
		450225192: '融水苗族自治县人民医院',
		450225193: '融水苗族自治县妇幼保健院',
		450225194: '融水县台湾联谊会综合医院',
		450302270: '桂林市正阳路中医院',
		450302271: '桂林市第三人民医院',
		450302272: '桂林市口腔医院',
		450302273: '桂林市第八人民医院',
		450302274: '桂林市红十字会博爱医院',
		450302275: '桂林市妇女儿童医院',
		450302276: '桂林医学院附属医院',
		450302277: '中国人民解放军76140部队',
		450303239: '广西长海厂职工医院',
		450303240: '桂林市穿山医院',
		450303241: '桂林地区妇幼保健院',
		450303242: '桂林铁路医院',
		450303243: '桂林市精神病院',
		450303244: '桂林市中医学校附属医院',
		450303245: '桂林市第九人民医院',
		450303246: '桂林市第二人民医院',
		450304281: '桂林天鹅塘医院',
		450304282: '中国人民解放军一八一医院',
		450304283: '南溪山医院',
		450304284: '桂林市自愿戒毒康复中心',
		450304285: '桂林市中西医结合肛肠医院',
		450304286: '桂林市中医院',
		450304287: '桂林市人民医院',
		450305218: '桂林市东江医院',
		450305219: '中国人民解放军第181医院',
		450305220: '广西壮族自治区南溪山医院',
		450305221: '桂林市漓江医疗保健院',
		450305222: '桂林市第七人民医院',
		450305223: '桂林空军炮院医院',
		450305224: '桂林地区石油医院',
		450305225: '桂林市皮肤病防治医院',
		450305226: '桂林市第五人民医院',
		450312227: '临桂县人民医院',
		450321291: '阳朔县妇幼保健院',
		450321292: '阳朔县中医院',
		450321293: '阳朔县人民医院',
		450323266: '灵川梧桐墅医院',
		450323267: '灵川县妇幼保健院',
		450323268: '灵川县中医院',
		450323269: '灵川县人民医院',
		450324228: '全州县骨伤医院',
		450324229: '全州县妇幼保健院',
		450324230: '全州县人民医院',
		450324231: '全州县中医院',
		450325232: '兴安第二人民医院',
		450325233: '兴安县红十字会骨伤医院',
		450325234: '兴安县妇幼保健院',
		450325235: '兴安县中医院',
		450325236: '兴安县人民医院',
		450325237: '兴安骨伤医院兴安分院',
		450325238: '桂林市第六人民医院',
		450326258: '永福县妇幼保健院',
		450326259: '永福县中医院',
		450326260: '永福县人民医院',
		450327261: '灌阳县妇幼保健院',
		450327262: '灌阳县妇幼保健站',
		450327263: '灌阳县灌江医院',
		450327264: '灌阳县中医院',
		450327265: '灌阳县人民医院',
		450328294: '广西龙胜各族自治县人民医院',
		450328295: '龙胜各族自治县中医院',
		450328296: '龙胜县人民医院',
		450329288: '资源县延东医院',
		450329289: '资源县妇幼保健院',
		450329290: '资源县人民医院',
		450330247: '平乐县精神病医院',
		450330248: '平乐县中医院',
		450330249: '平乐县妇幼保健院',
		450330250: '平乐县人民医院',
		450332251: '恭城康华医院',
		450332252: '恭城瑶族自治县中医院',
		450332253: '恭城瑶族自治县人民医院',
		450332254: '恭城瑶族自治县妇幼保健院',
		450332255: '恭城县妇幼保健院',
		450332256: '栗木有色金属公司工人医院',
		450332257: '恭城县人民医院',
		450381278: '荔浦县妇幼保健站',
		450381279: '荔浦县人民医院',
		450381280: '荔浦县中医院',
		450403297: '梧州中山医院',
		450403298: '广西桂东人民医院',
		450403299: '广西桂江造船厂职工医院',
		450403300: '梧州市皮肤病防治院',
		450403301: '梧州市第三人民医院',
		450403302: '梧州市第二人民医院',
		450403303: '梧州市人民医院',
		450403304: '梧州市工人医院',
		450403305: '桂东人民医院',
		450403306: '梧州地区皮肤病防治院',
		450403307: '桂江造船厂职工医院',
		450403328: '梧州市莲花山医院',
		450403329: '梧州市按摩康复医院',
		450403330: '梧州市卫校附属医院',
		450403331: '梧州市中西医结合医院',
		450403332: '梧州市妇幼保健院',
		450403333: '梧州市红十字会医院',
		450405334: '梧州市肿瘤防治研究所',
		450405335: '梧州市中医院',
		450405336: '梧州市里湖（传染病）医院',
		450421315: '苍梧县妇幼保健院',
		450421316: '苍梧县中医院',
		450421317: '苍梧县人民医院',
		450421318: '梧州地区卫校附属医院',
		450422323: '藤县潭东精神病院',
		450422324: '藤县妇幼保健院',
		450422325: '藤县人民医院',
		450422326: '藤县中医院',
		450422327: '藤县第二人民医院',
		450423319: '蒙山县精神病防治院',
		450423320: '蒙山县妇幼保健院',
		450423321: '蒙山县中医院',
		450423322: '蒙山县人民医院',
		450481308: '岑溪市义江医院',
		450481309: '岑溪市康复医院',
		450481310: '岑溪市精神病医院',
		450481311: '岑溪市皮肤性病防治院',
		450481312: '岑溪市妇幼保健院',
		450481313: '岑溪市中医院',
		450481314: '岑溪市人民医院',
		450502012: '北海市博铧医院',
		450502013: '北海安琪妇产医院',
		450502014: '北海真爱妇科门诊部',
		450502015: '北海市精神病收容所',
		450502016: '北海市先觉新医正骨医院',
		450502017: '北海市万博骨科医院',
		450502018: '北海市华侨医院',
		450502019: '北海市皮肤病防治院',
		450502020: '北海市第二人民医院',
		450502021: '北海港口医院',
		450502022: '北海北部湾职业病院',
		450502023: '北海市妇幼保健院',
		450502024: '北海市中医院',
		450502025: '北海市人民医院',
		450503028: '北海市结核病防治院',
		450512026: '铁山港区妇幼保健院',
		450512027: '北海市铁山港区人民医院',
		450521000: '合浦现代医院',
		450521001: '合浦仁爱医院',
		450521002: '合浦卫生学校附属医院',
		450521003: '合浦县皮肤性病防治院',
		450521004: '合浦县妇幼保健院',
		450521005: '合浦县戒毒康复中心',
		450521006: '合浦县精神病医院',
		450521007: '合浦县红十字会医院',
		450521008: '合浦县中医院',
		450521009: '合浦县人民医院',
		450521010: '北海市合浦卫校附院',
		520102025: '贵州省红十字会妇女儿童医院',
		520102026: '贵阳林城医院',
		520102027: '贵阳阿波罗男科医院',
		520102028: '贵阳市第六人民医院',
		520102029: '贵州电力职工医院',
		520102030: '贵阳市南明区人民医院',
		520102031: '贵阳市口腔医院',
		520102032: '贵阳铭依楼医院',
		520102033: '贵阳耳鼻喉医院',
		520102034: '贵州利美康妇科医院',
		520102035: '贵阳东大肛肠专科医院',
		520102036: '贵阳市肺科医院',
		520102037: '贵阳市妇幼保健院',
		520102038: '贵阳市第一人民医院',
		520102039: '贵州省骨科医院',
		520102040: '贵阳市第三人民医院',
		520102041: '贵阳市第二人民医院',
		520102042: '贵州省中山医院',
		520102043: '中国建筑四局职工医院',
		// 520102044: '贵阳皮肤病专科医院贵阳国济皮肤...',
		// 520102045: '中国人民解放军总后勤部第八职工...',
		520102046: '贵阳利美康医院',
		520102047: '贵州铝厂职工医院',
		520102048: '贵阳铁路医院',
		520103002: '贵州开磷总医院',
		520103003: '贵州省红十字会医院',
		520103004: '贵阳圣济男科医院',
		520103005: '贵阳港雅医院',
		520103006: '贵阳利美康外科医院妇科',
		520103007: '贵阳利美康妇科医院',
		520103008: '贵阳中医康复医院',
		520103009: '贵阳市云岩区人民医院',
		520103010: '贵阳市第五人民医院',
		520103011: '贵阳市第四人民医院',
		520103012: '贵阳现代女子医院',
		520103013: '贵州省第二人民医院',
		520103014: '贵阳中医学院第二附属医院',
		520103015: '贵阳中医学院第一附属医院',
		520103016: '贵阳医学院附属医院',
		520103017: '贵州省人民医院',
		520103018: '贵阳养和医院',
		520103019: '中铁五局集团职工医院',
		// 520103020: '贵阳皮肤病专科医院贵阳国济皮肤...',
		520103021: '贵阳市精神病医院',
		520103022: '贵阳中医研究所附属医院',
		520103023: '贵阳铭仁耳鼻喉医院',
		520112000: '贵州中医肝病医院',
		520112001: '贵阳市乌当区人民医院',
		520113052: '贵阳市白云区人民医院',
		520113053: '贵州省铝厂职工医院',
		520122052: '息烽县人民医院',
		520123024: '修文县人民医院',
		520181053: '清镇妇科医院',
		520181054: '清镇市人民医院',
		520181055: '清镇市第一人民医院',
		520302065: '遵义市中医院',
		520302066: '遵义医学院附属医院',
		520302067: '遵义医院',
		520302068: '遵义华西妇产医院',
		520302069: '贵州省田沟监狱医院',
		520302070: '遵义医药高等专科学校附属医院',
		520303062: '汇川区广州路李永鹏爱齿口腔诊所',
		520303063: '遵义春晖家园精神病医院',
		// 520304074: '贵州省遵义铁合金（集团）责任公...',
		520304075: '遵义市精神病院',
		520304076: '遵义县人民医院',
		520322060: '桐梓县人民医院',
		520323071: '绥阳县人民医院',
		520324061: '正安县人民医院',
		520327059: '凤冈县人民医院',
		520328064: '湄潭县人民医院',
		520329058: '余庆县人民医院',
		520381072: '赤水市人民医院',
		520381073: '赤水市红十字医院',
		520382056: '仁怀市人民医院',
		520382057: '贵州茅台酒厂集团公司职工医院',
		520182049: '贵阳解放军第44医院',
		520182051: '贵航集团三ＯＯ医院',
		460105023: '海南杏林肛肠医院',
		460105024: '海南中医药研究所附属琼岛医院',
		460105025: '海口市秀英区海滨社区卫生服务站',
		460105026: '海南省军区医院',
		460105027: '海南187男科医院',
		460105028: '海南187口腔医院',
		460105029: '海南省海口港集团公司海港医院',
		460105030: '海南省人民医院',
		460105031: '海南省农垦总局医院',
		460105032: '海秀乡卫生院',
		460105033: '海南南方中西医结合医院',
		460105034: '海口市长流中心卫生院',
		460105035: '海口市中医院',
		460105036: '海口市秀英区妇幼保健站',
		460105037: '海口市秀英区秀英社区卫生服务站',
		460105038: '海南省骨科医院',
		460105039: '秀英区海秀乡卫生院',
		460106056: '中国人民解放军海军四二四医院',
		460106057: '海南琼岛男科医院',
		460106058: '海南省地质医院',
		460106059: '海南省安宁医院',
		460106060: '海南省口腔医院',
		460106061: '海南省眼科医院',
		460106062: '海南医学院附属医院',
		460106063: '中国人民解放军第一八七中心医院',
		460106064: '龙华区金盘社区卫生服务站',
		460106065: '海口市振东区妇幼保健所',
		460106066: '海口市职业病防治所',
		460106067: '海南省骨科医院',
		460106068: '海口妇产科医院',
		460106069: '海口市二轻医院',
		460106070: '海南省皮肤病医院',
		460106071: '海南中医药研究所附属医院',
		460107015: '海口市府城医院',
		460107016: '海口市琼山区桂林洋农场医院',
		460107017: '海南省琼山区府城医院',
		460107018: '海口市琼山区中医院',
		460107019: '海口市琼山人民医院',
		460107020: '海南省妇幼保健院',
		460107021: '国营红明农场医院',
		460107022: '海口市琼山区大园社区卫生服务站',
		460108040: '海口肤康医院',
		460108041: '武警海南省总队医院',
		460108042: '海南省中医院',
		460108043: '海口市人民医院',
		460108044: '海南省农垦总局医院东湖院区',
		460108045: '海南南方肛肠病医院',
		460108046: '海南现代妇女儿童医院',
		460108047: '海口市结核病防治所',
		460108048: '海南港岛联合中医肿瘤医院',
		460108049: '海南中西医结合医院',
		460108050: '海口市妇幼保健院',
		460108051: '海南新建康美兆体检医院',
		460108052: '海南武警总队医院',
		460108053: '海口市振东区博爱医疗中心',
		460108054: '海口市人民医院海口市红十字医院',
		460108055: '海口市皮肤性病防治中心',
		460204000: '三亚市天涯中心卫生院',
		460204001: '三亚博大男科',
		460204002: '国营南田农场医院',
		460204003: '海军榆林保障基地医院',
		460204004: '广州中医药大学附属粤海医院',
		460204005: '三亚博仕门诊部',
		460204006: '海南省国营南新农场医院',
		460204007: '三亚市中医院',
		460204008: '三亚市中医院中西结合分院',
		460204009: '海南省国营南岛农场医院',
		460204010: '三亚市人民医院',
		460204011: '三亚市妇幼保健院',
		460204012: '三亚市解放军四二五医院',
		460204013: '三亚市琼南眼科医院',
		460204014: '海南省农垦三亚医院',
		360102033: '南昌东大肛肠专科医院',
		360102034: '南昌市妇幼保健所',
		360102035: '南昌市第八医院',
		360102036: '江西凤凰第一医院',
		360102037: '江西棉纺织印染厂职工医院',
		360102038: '江西省儿童医院',
		360102039: '南昌市第一医院',
		360102040: '南昌大学第二附属医院',
		360102041: '南昌大学第一附属医院',
		360102042: '江西省胸科医院',
		360102043: '南昌大学附属口腔医院',
		360102044: '江西省人民医院',
		360102045: '江西医学院第二附属医院',
		360102046: '江西医学院第一附属医院',
		360102047: '江西同仁中西医门诊部',
		360103061: '南昌宏昌医院',
		360103062: '南昌市中西医结合医院',
		360104065: '南昌市男科医院',
		360104066: '南昌佳美美容医院',
		360104067: '南昌华夏医院',
		360104068: '南昌三三四医院',
		360104069: '武警江西总队医院',
		360104070: '江西仁济医院南昌三三四医院',
		360111071: '江西江纺有限责任公司职工医院',
		360111072: '江西省九三老年关怀医院',
		360111073: '南昌哪家医院治痔疮',
		360111074: '南昌市高新医院',
		360111075: '南昌钢铁公司职工医院',
		// 360112056: '江西省南昌市新建县铁河中心卫生...',
		360112057: '新建县大塘中心卫生院',
		360112058: '江西中寰医院',
		360112059: '新建县中医院',
		360112060: '新建县人民医院',
		360121048: '南昌铁路局南昌铁路中心医院',
		360121049: '南昌铁路局鹰潭医院',
		360121050: '南昌市中医院',
		360121051: '南昌大学向塘医院',
		360121052: '南昌县中医院',
		360121053: '南昌县人民医院',
		360123054: '安义县中医院',
		360123055: '安义县医院',
		360124063: '进贤县中医院',
		360124064: '进贤县人民医院',
		360302086: '湘雅萍矿合作医院',
		// 360302087: '萍乡矿业集团有限责任公司第二医...',
		360302088: '江西省萍乡高坑中心卫生院',
		360302089: '萍乡市铁路医院',
		360302090: '萍乡市第三人民医院',
		360302091: '萍乡市第二人民医院',
		360302092: '萍乡矿业集团有限公司总医院',
		360302093: '萍乡市妇幼保健院',
		360302094: '萍乡市中医院',
		360302095: '萍乡矿业集团有限责任公司总医院',
		360302096: '萍乡市人民医院',
		360302097: '南昌大学萍乡医院',
		360313098: '萍乡赣西医院',
		360313099: '萍乡市电厂职工医院',
		360313100: '萍乡市钢铁厂职工医院',
		360313101: '萍乡市湘东区中医院',
		360313102: '新余钢铁有限责任公司中心医院',
		360321104: '莲花县人民医院',
		360322085: '上栗县人民医院',
		360323103: '芦溪县人民医院',
		360403015: '九江协和医疗美容门诊部',
		360403016: '九江仁爱医院',
		360403017: '浔阳区人民医院',
		360403018: '九江市中医院',
		360403019: '九江学院附属医院',
		360403020: '九江石化医院',
		360403021: '九江市第五人民医院',
		360403022: '九江市妇幼保健院',
		360403023: '江西共青城人民医院',
		360403024: '九江市妇女儿童医院',
		360403025: '九江市第一人民医院',
		360423011: '武宁县中医院',
		360423012: '武宁县人民医院',
		360424001: '修水县第二人民医院',
		360424002: '修水县中医院',
		360424003: '修水县人民医院',
		360425013: '永修县中医院',
		360425014: '永修县人民医院',
		360426008: '德安县中医院',
		360426009: '德安县人民医院',
		360428031: '都昌县中医院',
		360428032: '都昌县人民医院',
		360429026: '湖口县人民医院',
		360430006: '彭泽县中医院',
		360430007: '彭泽县人民医院',
		// 360481027: '六二一四医院（江西江州造船厂职...',
		360481028: '赛湖农场职工医院',
		360481029: '瑞昌市中医院',
		360481030: '瑞昌市人民医院',
		360483004: '九江县中医院',
		360483005: '九江市庐山区人民医院',
		360483010: '星子县人民医院',
		360490000: '九江县人民医院',
		360502077: '新余市第二人民医院',
		360502078: '新钢公司第二医院',
		360502079: '新钢公司第一医院',
		360502080: '新余市妇幼保健院',
		360502081: '新余市人民医院',
		360502082: '新余市中医院',
		360502083: '新余市水北中心卫生院',
		360502084: '武警水电第二总队医院',
		360521076: '分宜县中医院',
		360702161: '赣州市第五人民医院',
		360702162: '赣州市红十字会医院',
		360702163: '赣州市康复医院',
		360702164: '赣南中西医结合医院',
		360702165: '赣州济世堂门诊部',
		360702166: '赣州市第一人民医院',
		360702167: '赣州中医院',
		360702168: '赣州市肿瘤医院',
		360702169: '赣州市妇幼保健院',
		360702170: '赣州市中西医结合医院',
		360702171: '赣州市中医院',
		360702172: '赣州地区妇幼保健院',
		360702173: '赣州市人民医院',
		360703136: '江西省南康市横寨卫生院',
		360703137: '南康市镜坝卫生院',
		360703138: '南康市红十字会医院',
		360703139: '南康市中医院',
		360703140: '南康市第二人民医院',
		360703141: '南康市第一人民医院',
		360704174: '赣县中医院',
		360704175: '赣县人民医院',
		360722120: '赣南水泥厂职工医院',
		360722121: '信丰县人民医院',
		360722122: '信丰县中医院',
		360723142: '西华山钨矿职工医院',
		360723143: '大余县人民医院',
		360723144: '漂塘钨矿职工医院',
		// 360723145: '江西省赣州市大余县新城镇新城村...',
		360723146: '荡萍钨矿职工医院',
		360724105: '上犹县人民医院',
		360724106: '上犹县社溪中心卫生院',
		360724107: '上犹县寺下中心医院',
		360724108: '上犹县东山鎮卫生院',
		360724109: '安和乡卫生院',
		360724110: '上犹县中稍卫生院',
		360724111: '上犹县双溪乡卫生院',
		360725155: '崇义县中医院',
		360725156: '崇义县人民医院',
		360726150: '安远县中医院',
		360726151: '安远县人民医院',
		360727176: '龙南县中医院',
		360727177: '龙南县人民医院',
		360727178: '龙南县夹湖镇卫生院',
		360727179: '江西省龙南县杨村卫生院',
		360727180: '江西省龙南县武当镇卫生院',
		360727181: '赣州市龙南县程龙镇卫生院',
		360728152: '定南县人民医院',
		360728153: '岿美山钨矿职工医院',
		360729123: '江西省全南县大吉山矿医院',
		360729124: '全南县龙下卫生院',
		360729125: '龙源坝中心卫生院',
		360729126: '全南县陂头中心卫生院',
		360729127: '全南县陂头镇陂头卫生院',
		360729128: '八一垦殖场职工医院',
		360729129: '全南县中医院',
		360729130: '全南县人民医院',
		360730147: '宁都县中医院',
		360730148: '宁都县人民医院',
		360730149: '江西省宁都县固村医院',
		360731112: '盘古山钨矿职工医院',
		360731113: '赣州盘古山钨矿职工医院',
		360731114: '于都县第二人民医院',
		360731115: '于都真爱妇科医院',
		360731116: '于都县中医院',
		360731117: '于都县人民医院',
		360732131: '兴国县人民医院',
		360732132: '兴国县中医院',
		360732133: '兴国县人民医院',
		360732134: '兴国县第二人民医院',
		360732135: '兴国县妇女儿童医院',
		360733118: '会昌县中医院',
		360733119: '会昌县人民医院',
		360734154: '寻乌县人民医院',
		360735159: '石城县中医院',
		360735160: '石城县人民医院',
		360781157: '瑞金市中医院',
		360781158: '瑞金市人民医院',
		210102214: '沈阳曙光医院',
		210102215: '沈阳军区联勤部医院',
		210102216: '沈阳和平欣博美医疗美容门诊部',
		210102217: '沈阳和平北大中医院',
		210102218: '沈阳新时代女子医院',
		210102219: '沈阳市和平区中医院',
		210102220: '沈阳市中西医结合医院',
		210102221: '沈阳市光荣医院',
		210102222: '辽宁中医药大学附属肛肠医院',
		210102223: '沈阳市第七人民医院',
		210102224: '辽宁省职业病医院',
		210102225: '沈阳东方医疗集团妇婴医院',
		210102226: '沈阳市第二建筑工程公司职工医院',
		210102227: '沈阳急救中心',
		210102228: '沈阳市第六人民医院',
		210102229: '沈阳市爱尔眼科医院',
		210102230: '沈阳市诚添康宁医院',
		210102231: '沈阳市新兴医院',
		210102232: '沈阳市皮肤性病防治所',
		210102233: '中国人民解放军第二Ｏ二医院',
		210102234: '沈阳医学院沈洲医院',
		210102235: '沈阳医学院第二附属医院',
		210102236: '中国医科大学第一附属医院',
		210102237: '辽宁省妇幼保健院',
		210102238: '沈阳医学院附属第二医院',
		210102239: '沈阳市和平区朝鲜族医院',
		210102240: '沈阳市和平区妇婴医院',
		210102241: '沈阳市和平区红十字会医院',
		210102242: '沈阳市和平区第三中医院风湿病专科',
		210102243: '沈阳市和平区第三中医院',
		210102244: '沈阳市和平区第二中医院',
		210102245: '沈阳市和平区第七医院',
		210102246: '沈阳市和平区第四医院',
		210102247: '沈阳市和平区第二医院',
		210102248: '沈阳市和平区第一医院',
		210102249: '沈阳市和平区人民医院',
		210102250: '沈阳市和平区中心医院',
		210102251: '沈阳市天桥中医院',
		210102252: '沈阳纺织厂职工医院',
		210102253: '沈阳市房天职工医院',
		210102254: '沈阳市市政医院',
		210102255: '沈阳市公安医院',
		210102256: '沈阳市口腔医院',
		210102257: '沈阳市中医院',
		210102258: '沈阳铁路局沈阳中心医院',
		210102259: '辽宁中医药大学附属第三医院',
		210102260: '中国医大一院南昌集体医院',
		210102261: '东北大学医院',
		210102262: '沈阳爱尔眼科医院',
		210102263: '沈阳金桥医院',
		210102264: '沈阳公安医院',
		210102265: '沈阳市生殖医学技术研究中心专科医院',
		210102266: '辽宁中医药大学附属第三医院/辽宁省肛肠医院',
		210102267: '中国医科大学附属盛京医院',
		210103361: '沈阳和美妇产医院',
		210103362: '航空航天工业部第六Ｏ六研究所职工医院',
		210103363: '沈阳市故宫医院',
		210103364: '沈阳六零六所医院',
		210103365: '沈阳市故宫医院（民营）',
		210103366: '桓仁县中医院',
		210103367: '沈阳男健医院',
		210103368: '中一东北国际医院（99健康网）',
		210103369: '沈阳脑康中医院',
		210103370: '沈阳705医院',
		210103371: '沈阳东澳疼痛专科',
		210103372: '辽宁中医药大学附属医院中西医结合分院',
		210103373: '沈阳男科医院（沈阳军区政治部医院男科',
		210103374: '辽宁省东方医药研究院临床医院',
		210103375: '沈河区眼病专科医院',
		210103376: '沈河区烧伤专科医院',
		210103377: '沈河区结核病防治所',
		210103378: '沈河区回民医院',
		210103379: '辽宁省友谊医院残疾儿童康复中心',
		210103380: '辽宁省人民医院',
		210103381: '沈阳市沈河区红十字会医院',
		210103382: '辽阳省金秋医院',
		210103383: '沈阳市红十字会医院',
		210103384: '沈阳军区总医院',
		210103385: '沈阳市沈河区眼病专科医院',
		210103386: '沈阳市沈河区烧伤专科医院',
		210103387: '沈阳市沈河区结核病防治所',
		210103388: '沈阳市沈河区回民医院',
		210103389: '沈阳工业学院医院中俄眼科',
		210103390: '沈阳市仁济医院',
		210103391: '辽宁省东北电业中心医院',
		210103392: '沈阳市故宫耳鼻喉医院',
		210103393: '沈阳军区总医院放射肿瘤治疗中心',
		210103394: '辽宁省荣军康复医院',
		210103395: '沈阳市沈河区妇婴医院',
		210103396: '沈阳市沈河区中医院',
		210103397: '沈阳市沈河区第八医院',
		210103398: '沈阳市沈河区第七医院',
		210103399: '沈阳市沈河区第五医院',
		210103400: '沈阳市沈河区第三医院',
		210103401: '沈阳市沈河区人民医院',
		210103402: '沈阳市红十字博爱专科医院',
		210103403: '沈阳市类风湿病专科医院',
		210103404: '辽宁电力中心医院',
		210103405: '沈阳市金林医院',
		210103406: '沈阳市沈河区第九医院',
		210103407: '沈阳市沈河区第六医院',
		210103408: '沈阳市妇婴医院',
		210103409: '辽宁省金秋医院',
		210103410: '沈阳军区政治部医院',
		210103411: '沈阳市华泰医院',
		210104267: '辽宁省中医院分院',
		210104268: '桓仁县秋实医院',
		210104269: '沈阳新生医院',
		210104270: '沈阳协和医院',
		210104271: '沈阳桃仙国际机场民航沈阳医院',
		210104272: '辽宁省血液中心沈阳中心血站',
		210104273: '辽宁省黎明二Ｏ四医院',
		210104274: '中国人民解放军第三五Ｏ五医院',
		210104275: '沈阳阳光健康管理有限公司大东门诊部',
		210104276: '沈阳骨科医院',
		210104277: '大东区结核病防治所',
		210104278: '大东区北海糖尿病医院',
		210104279: '大东区牙病防治所',
		210104280: '大东区中医骨科医院',
		210104281: '大东区红十字会医院',
		210104282: '大东区津桥中医院',
		210104283: '大东区小北中医院',
		210104284: '大东区第二中医院',
		210104285: '大东区中医院',
		210104286: '大东区第九医院',
		210104287: '大东区第八医院',
		210104288: '大东区第六医院',
		210104289: '大东区第五医院',
		210104290: '大东区第四医院',
		210104291: '大东区第三医院',
		210104292: '大东区第二人民医院',
		210104293: '大东区人民医院',
		210104294: '沈阳矿山机械厂职工医院',
		210104295: '沈阳第四建筑公司职工医院',
		210104296: '沈阳铁路工程处医院',
		210104297: '沈阳市第一人民医院',
		210104298: '中国人民解放军第463医院',
		210104299: '辽宁省肿瘤医院',
		210104300: '沈阳市大东区结核病防治所',
		210104301: '沈阳市大东区北海糖尿病医院',
		210104302: '沈阳市大东区牙病防治所',
		210104303: '沈阳市大东区中医骨科医院',
		210104304: '沈阳市大东区津桥中医院',
		210104305: '沈阳市大东区小北中医院',
		210104306: '沈阳市大东区第二中医院',
		210104307: '沈阳市大东区中医院',
		210104308: '沈阳沈东铁路医院',
		210104309: '沈阳医学会东城医院',
		210104310: '沈阳市胸科医院',
		210104311: '沈阳市德济医院',
		210104312: '沈阳市沈海医院',
		210104313: '沈阳市东城医院',
		210104314: '沈阳二0四医院生殖中心',
		210104315: '沈阳市振东一五八医院',
		210104316: '沈阳市中医肿瘤医药研究所',
		210104317: '沈阳市医学会南塔脑血管病医院',
		210104318: '沈阳市市政建设工程公司职工医院',
		210104319: '沈阳建筑机械厂职工医院',
		210104320: '沈阳市大东区中医肿瘤医院',
		210104321: '沈阳中捷人民友谊厂职工医院',
		210104322: '沈阳市大东区人民医院',
		210104323: '沈阳市大东区新生医院',
		210104324: '沈阳市大东区红十字会医院',
		210104325: '沈阳市骨科医院',
		210104326: '中国人民解放军一五七医院',
		210104327: '沈阳市大东区第九医院',
		210104328: '沈阳市大东区第八医院',
		210104329: '沈阳市大东区第六医院',
		210104330: '沈阳市大东区第五医院',
		210104331: '沈阳市大东区第四医院',
		210104332: '沈阳市大东区第三医院',
		210104333: '沈阳市大东区第二人民医院',
		210104334: '中国人民解放军第二Ｏ一医院',
		210105416: '沈阳市皇姑区北陵医院',
		210105417: '皇姑区红十字会肛肠医院',
		210105418: '皇姑区妇婴医院',
		210105419: '皇姑区红十字会医院',
		210105420: '皇姑区中医院',
		210105421: '皇姑区第八人民医院',
		210105422: '皇姑区第七人民医院',
		210105423: '皇姑区第四人民医院',
		210105424: '皇姑区第二人民医院',
		210105425: '皇姑区第一人民医院',
		210105426: '辽宁省中医药研究院',
		210105427: '辽宁省沈安劳动改造支队医院',
		210105428: '沈阳市第三建筑工程公司职工医院',
		210105429: '沈阳机车车辆厂医院',
		210105430: '沈阳医学院附属第三医院',
		210105431: '辽宁省中医院',
		210105432: '辽宁中医学院龙江中医院',
		210105433: '沈阳市博爱医院',
		210105434: '沈阳市大众医院',
		210105435: '沈阳市安宁医院皇姑分院',
		210105436: '辽宁中医药大学龙江中医院',
		210105437: '沈阳市皇姑区中医医院',
		210105438: '沈阳军区总医院北方医院',
		210105439: '辽宁求实白癫疯研究所',
		210105440: '沈阳市老年病康复医院',
		210105441: '沈阳中医前列腺病研究所',
		210105442: '沈阳市眼病医院',
		210105443: '沈阳市皇姑区中心医院',
		210105444: '沈阳市松陵医院',
		210105445: '沈阳市蓝天医院',
		210105446: '沈阳何氏眼科医院',
		210105447: '沈阳市第四人民医院',
		210105448: '沈阳市妇儿医疗保健中心',
		210105449: '沈阳市儿童医院',
		210105450: '皇姑区第一人民医院',
		210105451: '沈阳市皇姑区妇婴医院',
		210105452: '沈阳市皇姑区红十字会医院',
		210105453: '沈阳市皇姑区中医院',
		210105454: '沈阳市皇姑区第八人民医院',
		210105455: '沈阳市皇姑区第七人民医院',
		210105456: '沈阳市皇姑区第四人民医院',
		210105457: '沈阳市皇姑区第二人民医院',
		210105458: '沈阳市皇姑区红十字会肛肠医院',
		210105459: '沈阳市七三九医院',
		210105460: '辽宁省军区医院',
		210105461: '辽宁省精神卫生防治基地',
		210105462: '沈阳第四人民医院',
		210105463: '沈阳松陵医院',
		210105464: '沈阳二四二医院',
		210105465: '沈阳市宝岩整形美容外科医院',
		210105466: '沈阳华泰医院',
		210105467: '沈阳一一九五医院',
		210105468: '沈阳市精神病康复医院',
		210111469: '沈阳市苏家屯区四院',
		210111470: '沈阳市苏家屯区三院',
		210111471: '沈阳矿务局红菱煤矿职工医院',
		210111472: '沈阳苏家屯铁路医院',
		210111473: '沈阳矿务局林盛煤矿职工医院',
		210111474: '沈阳有色金属加工厂职工医院',
		210111475: '沈阳血栓病医疗中心',
		210111476: '沈阳市苏家屯区妇婴医院',
		210111477: '沈阳市苏家屯区第四医院',
		210111478: '沈阳市苏家屯区中心医院',
		210111479: '辽宁省血栓病中西医结合医疗中心',
		210111480: '沈阳市苏家屯区结核病防治所',
		210111481: '沈阳市苏家屯区红十字会医院',
		210111482: '沈阳市苏家屯区第三医院',
		210111483: '沈阳市苏家屯区第二医院',
		210112146: '沈阳市浑南新区医院',
		210112147: '辉山畜牧场职工医院',
		210112148: '沈阳铁路局结核病医院',
		210112149: '沈阳市第一结核病医院',
		210112150: '沈阳静安精神卫生医院',
		210112151: '沈阳市东陵区中心医院',
		210112152: '沈阳市工人医院',
		210112153: '沈阳市北方脑血管病医院',
		210112154: '辽宁高科技肿瘤研究所肺科医院',
		210112155: '辽宁西磁研究中心血磁医院',
		210112156: '沈阳空军93303部队医院',
		210112157: '沈阳市传染病院',
		210112158: '沈阳市肝胆病医院',
		210112159: '沈阳市东陵区望花医院',
		210112160: '沈阳医学会血磁医院',
		210112161: '辽宁省糖尿病治疗中心',
		210112162: '沈阳市东陵区中医院',
		210112163: '沈阳市第二工人医院',
		210112164: '沈阳市东陵区红十字会医院',
		210112165: '沈阳铁路局结核防治病医院',
		210112166: '沈阳市精神市卫生中心',
		210112167: '中国人民解放军第四六三医院',
		210112168: '东陵区地方病防治站',
		210112169: '东陵区汪家乡骨科医院',
		210112170: '东陵区结核病防治所',
		210112171: '东陵区肝胆病专科医院',
		210112172: '东陵区第二医院',
		210112173: '东陵区中心医院',
		210112174: '东陵区人民医院',
		210112175: '沈阳市东陵区地方病防治站',
		210112176: '沈阳市东陵区汪家乡骨科医院',
		210112177: '沈阳市东陵区结核病防治所',
		210112178: '沈阳市东陵区肝胆病专科医院',
		210112179: '沈阳市东陵区第二医院',
		210112180: '沈阳市东陵区人民医院',
		210113340: '沈阳市益民医院',
		210113341: '沈阳矿务局职工医院',
		210113342: '沈阳矿务局沈阳职工总医院',
		// 210113343: '沈阳矿务局沈阳职工总医院清水分...',
		210113344: '沈阳煤业（集团）公司总医院',
		210113345: '沈阳市新城子区妇幼保健院',
		210113346: '沈阳市新城子区红十字会医院',
		210113347: '沈阳市新城子区中医院',
		210113348: '沈阳市新城子区第三医院',
		210113349: '沈阳市新城子区第二医院',
		210113350: '沈阳市煤炭工业公司职工医院',
		210113351: '沈阳市新城子区医院',
		210114181: '辽宁省马三家劳动教养院医院',
		210114182: '沈阳市盛京华侨医院（民营）',
		210114183: '沈阳市盛京华侨医院',
		210114184: '沈阳市松山医院',
		210114185: '沈阳市高丽精神病医院',
		210114186: '沈阳市邮电医院',
		210114187: '沈阳市杨士联合中医院',
		210114188: '沈阳市铁西精神病医院',
		210114189: '沈阳市于洪区红十字会医院分院',
		210114190: '沈阳市于洪区人民医院',
		210114191: '辽宁省邮电医院',
		210114192: '沈阳市公安局安康医院',
		210114193: '沈阳市于洪区第四人民医院',
		210114194: '沈阳市于洪区第二人民医院',
		210114195: '于洪区康复医院',
		210114196: '于洪区肿瘤医院',
		210114197: '于洪区红十字会医院二院',
		210114198: '于洪区红十字会医院',
		210114199: '于洪区中西区结合医院',
		210114200: '于洪区中医院',
		210114201: '于洪区第五人民医院',
		210114202: '于洪区第三人民医院',
		210114203: '于洪区人民医院',
		210114204: '中国医科大学第四附属医院',
		210114205: '中国医科大学附属第四医院',
		210114206: '沈阳市于洪区康复医院',
		210114207: '沈阳市于洪区肿瘤医院',
		210114208: '沈阳市于洪区红十字会医院二院',
		210114209: '沈阳市于洪区红十字会医院',
		210114210: '沈阳市于洪区中西区结合医院',
		210114211: '沈阳市于洪区中医院',
		210114212: '沈阳市于洪区第五人民医院',
		210114213: '沈阳市于洪区第三人民医院',
		210115484: '辽中县全科医疗医院',
		210115485: '辽中县结核病医院',
		210115486: '辽中县妇幼保健站',
		210115487: '辽中县中医院',
		210115488: '辽中县骨科医院',
		210115489: '辽中县传染病院',
		210115490: '辽中县肿瘤研究所',
		210115491: '辽中县第三人民医院',
		210115492: '辽中县第二人民医院',
		210115493: '辽中县人民医院',
		210123335: '康平县精神病防治院',
		210123336: '康平县妇幼保健站',
		210123337: '康平县中医院',
		210123338: '康平县第二人民医院',
		210123339: '康平县人民医院',
		210124412: '法库县中医院',
		210124413: '法库县第一医院',
		210124414: '法库县精神病防治院',
		210124415: '法库县第二医院',
		210181352: '新民市康复医院',
		210181353: '新民市妇幼保健所',
		210181354: '新民市中医院',
		210181355: '新民市第五人民医院',
		210181356: '新民市第三人民医院',
		210181357: '新民市第二人民医院',
		210181358: '新民市人民医院',
		210181359: '新民市妇婴医院',
		210181360: '新民市胸科医院',
		210202000: '大连造船医院',
		210202001: '大连艺星医疗美容医院',
		210202002: '沈阳铁路局大连医院',
		210202003: '大连王进利医院',
		210202004: '大连眼科医院',
		210202005: '大连春和太原集体医院',
		210202006: '大连市轻工医院',
		210202007: '大连市中山医院',
		210202008: '大连港医院',
		210202009: '大连市轻工局医院',
		210202010: '大连市中医院',
		210202011: '大连大学附属中山医院',
		210202012: '大连市中医医院',
		210202013: '大连市友谊医院',
		210202014: '大连市第六人民医院',
		210202015: '大连铁路医院',
		210202016: '大连老虎滩骨科医院',
		210203107: '大连市结核病医院',
		210203108: '解放军第210医院',
		210203109: '大连市药材公司神经内科集体医院',
		210203110: '中国人民解放军第四六九医院',
		210203111: '大连公安医院',
		210203112: '大连阳光医院',
		210203113: '大连九州医院',
		210203114: '大连市商业职工医院',
		210203115: '大连市张国礼神经精神医院',
		210203116: '大连今华夏医院',
		210203117: '大连东方医院',
		210203118: '大连港肿瘤集体医院',
		210203119: '中国人民解放军二一四医院',
		210203120: '大连煤矿医院',
		210203121: '大连市西岗区妇幼保健院',
		210203122: '大连市西岗区人民医院',
		210203123: '大连市旅顺口区中医院',
		210203124: '大连药材集团医院',
		210203125: '中国人民解放军第二一Ｏ医院',
		210203126: '大连市第二人民医院',
		210204030: '大连渤水总公司集体医院',
		210204031: '大连机车医院',
		210204032: '大连市妇幼保健院',
		210204033: '大连重工集团有限公司医院',
		210204034: '大连皮肤病医院',
		210204035: '大连大重医院',
		210204036: '大连市沙河口区医院',
		210204037: '大连市口腔医院',
		// 210204038: '大连老龄委星海心脑血栓病集体医...',
		210204039: '大连红十字骨科医院',
		210204040: '大连市甘井子区红旗地区医院',
		210204041: '大连市八一建工集体医院',
		210204042: '大连市同济医院',
		210204043: '大连市沙河口区妇幼保健院',
		210204044: '大连机车厂职工医院',
		// 210204045: '大连市老年卫协血检病康复集体医...',
		210204046: '大连市和平骨科集体医院',
		210204047: '大连辽南晋科集体医院',
		210204048: '大连市皮肤病医院',
		210204049: '大连沙河口长城集体医院',
		210204050: '大连起重机器厂医院',
		210204051: '大连渤海水产总公司医院',
		210204052: '大连机床集团有限公司医院',
		210204053: '大连何氏眼科医院',
		210204054: '大连蓝盾医院',
		210204055: '大连市沙河口区人民医院',
		210204056: '大连海辰企业集团医院',
		210204057: '大连鑫凌集团医院',
		210204058: '大连电机厂职工医院',
		210204059: '大连市妇产医院',
		210204060: '大连市中心医院',
		210204061: '大连医科大学附属第一医院',
		210204062: '大连大学附属新华医院',
		210204063: '中国人民解放军第四Ｏ三医院',
		210204064: '大连市第五人民医院',
		210204065: '大连医科大学附属第二医院',
		210204066: '大连大学医学院附属医院',
		210204067: '大连空军航天医院',
		210204068: '中国人民解放军第四零三医院',
		210211078: '大连市甘井子区妇幼保健院',
		210211079: '大连市第三人民医院',
		210211080: '大连石油化工公司职工医院',
		210211081: '大连药材公司友谊街肿瘤医院',
		210211082: '大连市职业病院',
		210211083: '大连市海洋渔业集团公司医院',
		210211084: '大连市石油化工公司职工医院',
		210211085: '大连张国礼医院',
		210211086: '大连新世纪医院',
		210211087: '大连医科大学附属第二医院北院',
		210211088: '大连市老龄委金西路集体医院',
		210211089: '大连市第七人民医院',
		210211090: '大连市公安局安康医院',
		210211091: '大连市结核医院',
		210211092: '大连纺织厂职工医院',
		210211093: '大连海洋渔业集团公司医院',
		210211094: '大连集团有限责任公司医院',
		210211095: '大连理工大学医院',
		210211096: '大连市甘井子冠心病专科',
		210211097: '大连市甘井子区南关岭地区医院',
		210211098: '大连市甘井子区凌水地区医院',
		210211099: '大连海事大学医院',
		210211100: '大连东华总公司医院',
		210211101: '大连市老年病医院',
		210211102: '大连湾地区医院',
		210211103: '大连张国礼医院（民营）',
		210211104: '国营五二三厂职工医院',
		210211105: '鞍钢矿山公司大连医院',
		210211106: '连宋和风湿病医院（民营）',
		210212024: '大连市旅顺口区中医医院',
		210212025: '大连市旅顺口区妇幼保健站',
		210212026: '大连市旅顺口区人民医院',
		210212027: '大连奶牛厂职工医院',
		210213127: '大连金州传染病医院',
		210213128: '大连市金州区南山医院',
		210213129: '大连市金州区中医医院',
		210213130: '大连铁道公司大石桥医院',
		210213131: '大连市金州区第四人民医院',
		210213132: '大连市金州区第二人民医院',
		210213133: '大连市金州区第一人民医院',
		210213134: '大连经济技术开发区医院',
		210213135: '大连市金州区结核病防治所',
		210213136: '大连市金州区传染病医院',
		210213137: '大连市金州区中医院',
		210213138: '大连医科大学附属第三医院',
		210213139: '大连市金州纺织厂职工医院',
		210213140: '大连经济技术开发区海湾医院',
		210213141: '大连经济技术开发区中医骨科医院',
		210213142: '大连市金州区口腔医院',
		210213143: '大连市金州血栓病医院',
		210213144: '大连市金州重型机械厂职工医院',
		210213145: '大连新港职工医院',
		210214028: '大连市安波理疗医院',
		210214029: '大连盐工医院',
		210281069: '大连大学附属瓦房店医院',
		210281070: '瓦房店市中医医院',
		210281071: '瓦房店市第三人民医院',
		210281072: '瓦房店轴承集团有限公司职工医院',
		210281073: '瓦房店市中心医院',
		210281074: '葫芦岛市中心医院',
		210281075: '大连大学医学院老年病医院',
		210281076: '大连大学老年病医院',
		210281077: '瓦房店第三医院',
		210283017: '庄河市康复医院',
		210283018: '庄河市妇幼保健院',
		210283019: '庄河市第三人民医院',
		210283020: '庄河市结核病防治所',
		210283021: '庄河市第二人民医院',
		210283022: '庄河市中心医院',
		210283023: '庄河市人民医院',
		210303494: '中国人民解放军第三五Ｏ一工厂职工医院',
		210303495: '中国医科大学附属盛京医院滑翔分院',
		210303496: '铁西区中西医结合医院',
		210303497: '铁西区第二医院',
		210303498: '沈阳共济爱婴医院',
		210303499: '沈阳市沈辽老年病院',
		210303500: '沈阳市中环中医院',
		210303501: '沈阳市松辽激光医院',
		210303502: '沈阳市虹桥医院',
		210303503: '沈阳市铁西凌空医院',
		210303504: '桓仁县第二人民医院',
		210303505: '桓仁县秋实医院',
		210303506: '沈阳东药医院',
		210303507: '沈阳高压开关有限责任公司职工医院',
		210303508: '沈阳电缆厂职工医院',
		210303509: '沈阳化工集团有限公司职工医院',
		210303510: '沈阳机床三厂职工医院',
		210303511: '沈阳市第九人民医院',
		210303512: '辽宁省肢体伤残矫形专科医院',
		210303513: '沈阳永新化工厂有限公司职工医院',
		210303514: '沈阳变压器有限责任公司职工医院',
		210303515: '沈阳市铁西区痔瘘医院',
		210303516: '沈阳工业大学医院',
		210303517: '沈阳市天北医院',
		210303518: '沈阳重型机械集团公司职工医院',
		210303519: '沈阳市铁西区神经精神病医院',
		210303520: '沈阳市铁西区红十字医院',
		210303521: '铁西区妇婴医院',
		210303522: '铁西区第八医院',
		210303523: '铁西区肿瘤医院',
		210303524: '铁西区第四医院',
		210303525: '铁西区不孕不育专科医院',
		210303526: '铁西区红十字医院',
		210303527: '铁西区中西结合医院',
		210303528: '铁西区中医院',
		210303529: '铁西区第九医院',
		210303530: '铁西区胆结石专科病医院',
		210303531: '铁西区第七医院',
		210303532: '铁西区第二医院',
		210303533: '铁西区第一医院',
		210303534: '沈阳市铁西区中心医院',
		210303535: '沈阳市西城中医院',
		210303536: '沈阳水泵厂职工医院',
		210303537: '沈阳第一机床厂职工医院',
		210303538: '沈阳冶炼厂职工医院',
		210303539: '沈阳轿车制造厂职工医院',
		210303540: '沈阳市第五人民医院',
		210303541: '中国医科大学第二附属医院二部',
		210303542: '沈阳市铁西区肿瘤医院',
		210303543: '沈阳市铁西区胆结石专科病医院',
		210303544: '沈阳市铁西区不孕不育专科医院',
		210303545: '沈阳市铁西区妇婴医院',
		210303546: '沈阳市铁西区中西结合医院',
		210303547: '沈阳市铁西区中医院',
		210303548: '沈阳市铁西区第九医院',
		210303549: '沈阳市铁西区第八医院',
		210303550: '沈阳市铁西区第七医院',
		210303551: '沈阳市铁西区第四医院',
		210303552: '沈阳市铁西区第二医院',
		210303553: '沈阳市铁西区第一医院',
		210303554: '沈阳市沈辽老年病集体医院',
		210303555: '沈阳医学院奉天医院',
		640104000: '银川欧亚男健医院',
		640104001: '银川军大皮肤病医院',
		640104002: '宁夏泌尿专科医院',
		640104003: '银川市中医院',
		640104004: '宁夏西京妇产医院',
		640104005: '宁夏回族自治区妇幼保健院',
		640104006: '中国人民解放军第五医院',
		640104007: '宁夏回族自治区中医院',
		640104008: '银川博爱医院（民营）',
		640105016: '银川市第三人民医院',
		640105017: '银川铁路医院',
		640105018: '宁夏回族自治区社会福利院',
		640106021: '宁夏新协和医院',
		640106022: '宁夏回族自治区社会福利院（宁夏民政厅精神康复医院)',
		640106023: '宁夏回族自治区人民医院',
		640106024: '中国石化宁夏化工厂职工医院',
		640106025: '银川市第二人民医院',
		640121011: '永宁县人民医院',
		640122019: '国营暖泉农场职工医院',
		640122020: '贺兰县中医院',
		640181012: '灵武市东方医院',
		640181013: '灵武市中医院',
		640181014: '宁夏回族自治区宁安医院',
		640181015: '灵武中心医院',
		640104009: '银川市铁路医院',
		640104010: '银川市第一人民医院',
		370102095: '济南华医中医药研究所',
		370102096: '山东省中医院',
		370102097: '济南第一藏医院',
		370102098: '济南齐鲁花园医院',
		370102099: '山东大学耳鼻喉眼科医院',
		370102100: '济南神康医院',
		370102101: '济南仁济妇科医院',
		370102102: '山东省胸科医院',
		370102103: '济南长虹医院',
		370102104: '山东省荣军总医院',
		370102105: '山东省荣军医院',
		370102106: '山东省中医药研究所',
		370102107: '济南市历下区人民医院',
		370102108: '济南医院',
		370102109: '济南市第一人民医院',
		370102110: '山东省地矿医院',
		370102111: '济南中山肝病医院',
		370102112: '济南安康医院',
		370102113: '山东省精神卫生中心',
		370102114: '山东省千佛山医院',
		370102115: '山东大学齐鲁医院',
		370102116: '山东手足外科医院',
		370102117: '济南市中心医院',
		370102118: '山东中医药大学附属医院',
		370102119: '济南华圣肿瘤医院',
		370102120: '济南华圣医院',
		370102121: '山东大学口腔医院',
		370102122: '历下区博杏霖中医门诊部',
		370102123: '济南阳光女子医院',
		370102124: '济南中西医糖尿病医院',
		370104190: '济南机车医院',
		370104191: '山东电力中心医院',
		370104192: '济南市传染病医院',
		370104193: '济南市第五人民医院',
		370104194: '济南市槐荫人民医院',
		370104195: '山东省立医院西院',
		370104196: '山东省皮肤病医院',
		370104197: '济南市儿童医院',
		370104198: '济南市妇幼保健院',
		370104199: '山东省肿瘤医院',
		370104200: '山东省立医院',
		370104201: '济南中医脑血管病医院',
		370104202: '济南中医集团济南痔瘘医院',
		370104203: '济南癫痫病医院',
		370104204: '山东大学齐鲁儿童医院',
		370104205: '山东省疾病预防控制中心',
		370105143: '济南仁品耳鼻喉科医院',
		370105144: '中国重型汽车集团有限公司医院',
		370105145: '济南糖尿病医院',
		370105146: '济南中德骨科医院',
		370105147: '济南九龙泌尿专科医院',
		370105148: '济南万厚不孕不育医院',
		370105149: '济南协和肝病医院',
		370105150: '山东黄河医院',
		370105151: '济南市天桥人民医院',
		370105152: '山东省红十字博爱医院',
		370105153: '山东心脑血管病医院',
		370105154: '济南市第四人民医院',
		370105155: '山东省交通医院',
		370105156: '济南工人医院',
		370105157: '山东省医学科学院附属医院',
		370105158: '济南天伦不孕不育医院',
		370105159: '中国人民解放军第四五六医院中国人民解放军四十四医院',
		370112125: '济南市皮肤病防治院',
		370112126: '济南历城骨伤病医院',
		370112127: '济南化纤总公司职工医院',
		370112128: '山东大学医院',
		370112129: '中国建筑第八工程局职工医院',
		370112130: '山东省妇产医院',
		370112131: '济南钢铁总医院',
		370112132: '济南市第三人民医院',
		370112133: '济南市第二人民医院',
		370112134: '济南市历城区人民医院',
		370112135: '山东省红十字会介入医院',
		370112136: '济南爱尔眼科医院',
		370112137: '兖矿集团公司第三医院',
		370112138: '济南康泰医院',
		370112139: '济南钢铁集团总公司职工医院',
		370113216: '长清县人民医院',
		370113217: '济南市长清区中医院',
		370113218: '济南市长清区人民医院',
		370113219: '长清县中医医院',
		370113220: '长清县人民医院',
		370114208: '章丘仁爱医院',
		370114209: '济南明水眼科医院',
		370114210: '章丘市妇幼保健院',
		370114211: '章丘市第二人民医院',
		370114212: '章丘市中医院',
		370114213: '章丘市人民医院',
		370114214: '章丘市中医医院',
		370114215: '淄博矿业集团有限公司埠村煤矿医院',
		370115206: '济阳县人民医院',
		370115207: '济阳县中医院',
		370124184: '山东省平阴县孔村卫生院',
		370124185: '山东省平阴县妇幼保健站',
		370124186: '平阴惠民门诊部',
		370124187: '平阴县中医医院',
		370124188: '平阴县中医院',
		370124189: '平阴县人民医院',
		370126140: '兖州市矿山医院',
		370126141: '商河县人民医院',
		370126142: '商河县中医院',
		370202596: '青岛华韩整形美容医院',
		370202597: '青岛市市南区浮山医院',
		370202598: '青岛第九人民医院',
		370202599: '青岛洪强骨科医院',
		370202600: '青岛耳鼻喉治疗中心',
		370202601: '解放军401医院',
		370202602: '海天医院皮肤防治中心',
		370202603: '青岛元美医疗美容门诊部',
		370202604: '青岛铁路分局青岛铁路医院',
		370202605: '青岛市南区人民医院',
		370202606: '青岛市按摩康复医院',
		370202607: '青岛市市南区医院',
		370202608: '山东省青岛疗养院山东康复中心',
		370202609: '青岛大学医学院附属心血管病医院',
		370202610: '青岛市第五人民医院',
		370202611: '青岛市人民医院',
		370202612: '青岛开发区友爱医院',
		370202613: '青岛市第九人民医院',
		370202614: '青岛东方医院',
		370202615: '青岛大学医学院附属医院',
		370202616: '青岛市皮肤病防治院',
		370202617: '青岛市内分泌糖尿病医院',
		370202618: '青岛市口腔医院',
		370202619: '青岛市市南区妇幼保健所',
		370202620: '青岛华厦眼科医院',
		370202621: '青岛市双星医院',
		370202622: '解放军四零一医院',
		370202623: '青岛海天医院（民营）',
		370202624: '山东省友好医院',
		370202625: '青岛市双星医院（民营）',
		370202626: '成海卫人民医院',
		370202627: '青岛市华青医院',
		370203533: '青岛世海医院（民营）',
		370203534: '青岛市传染病医院青岛市第六人民医院',
		370203535: '青岛海伦心脑血管专科医院（民营)',
		370203536: '青岛新兴医院',
		370203537: '中国南车集团四方机车车辆有限责任公司职工医院',
		370203538: '青岛安康皮肤病医院',
		370203539: '青岛德仁医院',
		370203540: '青岛恒博医院',
		370203541: '青岛大学医学院松山医院',
		370203542: '青岛肛泰肛肠医院',
		370203543: '青岛市四方区医院',
		370203544: '青岛市妇女儿童医疗保健中心',
		370203545: '青岛市肿瘤医院',
		370203546: '青岛市中心医院',
		370203547: '青岛市市立医院',
		370203548: '青岛市传染病医院',
		370203549: '青岛市四方区妇幼保健所',
		370203550: '青岛市精神卫生中心',
		370203551: '青岛纺织机械厂职工医院',
		370203552: '青岛公安消防医院',
		370203574: '青岛市建筑安装工程总公司职工医院',
		370203575: '青岛市骨伤科医院',
		370203576: '青岛新兴医院（民营）',
		370203577: '青岛市京山医院',
		370203578: '青岛市北京北胃肠医院',
		370203579: '青岛惠好糖尿病医院',
		370203580: '青岛民生皮肤病医院',
		370203581: '青岛市市北区医院',
		370203582: '青岛静康医院中风偏瘫治疗中心',
		370203583: '青岛坤如玛丽妇产医院',
		370203584: '青岛博士整形医院',
		370203585: '青岛鱼鳞病研究院',
		370203586: '青岛市海慈医疗集团',
		370203587: '青岛市市北区中医医院',
		370203588: '青岛大学医学院附属松山医院',
		370203589: '青岛市肛肠医院',
		370203590: '青岛市港口医院',
		370203591: '青岛市市北区中医院',
		370203592: '青岛海洋渔业公司职工医院',
		370203593: '青岛市交通医院',
		370203594: '青岛海员医院',
		370203595: '青岛市市北区妇幼保健所',
		370211651: '胶南丽华医院',
		370211652: '青岛上苑医院',
		370211653: '胶南市妇幼保健院',
		370211654: '胶南市经济技术开发区医院',
		370211655: '胶南市中医院',
		370211656: '胶南同济医院',
		370211657: '胶南同济医院',
		370211658: '胶南市人民医院',
		370211673: '青岛华欧集团医院（民营）',
		370211674: '青岛黄岛双珠医院',
		370211675: '青岛雅典娜妇女儿童医院',
		370211676: '青岛市黄岛区中医院',
		370211677: '青岛经济技术开发区薛家岛医院',
		370211678: '青岛市黄岛骨伤医院',
		370211679: '青岛经济技术开发区第一医院',
		370212562: '青岛静康医院',
		370212563: '青岛市崂山区妇幼保健院',
		370212564: '中国人民解放军青岛肝病治疗中心',
		370212565: '青岛开泰耳鼻喉头颈外科医院',
		370212566: '青岛瑞金妇科医院',
		370212567: '青岛市麦岛精神病院',
		370212568: '青岛万杰医院',
		370212569: '中国人民解放军一四一医院',
		370212570: '言林医院（民营）',
		370212571: '青岛市妇幼保健院',
		370212572: '言林医院',
		370212573: '青岛市东方医院',
		370213634: '青岛钢铁有限公司职工医院',
		370213635: '武警山东总队医院青岛分院',
		370213636: '青岛李沧区医院',
		370213637: '青岛九洲医院',
		370213638: '青岛龙田金秋妇女儿童医院',
		370213639: '青岛市第八人民医院',
		370213640: '青岛新阳光妇产医院',
		370213641: '青岛市李沧区医院',
		370213642: '青岛市李沧区医院',
		370213643: '青岛市第三人民医院',
		370213644: '青岛协合妇产医院',
		370213645: '青岛市沧口区医院',
		370213646: '青岛钢铁总公司职工医院',
		370213647: '青岛脊椎病医院',
		370213648: '青岛市第四人民医院',
		370213649: '青岛市李沧区第五医院',
		370213650: '青岛市李沧区第三医院',
		370214553: '青岛市城阳区人民医院',
		370214554: '青岛古镇正骨医院',
		370214555: '青岛盐业职工医院',
		370214556: '青岛皮肤病医院',
		370214557: '中国人民军科青岛哮喘病诊疗中心',
		370214558: '青岛市城阳区第二人民医院',
		370214559: '青岛城阳人民医院',
		370214560: '青岛盐业职工医院',
		370214561: '城阳第三人民医院',
		370215529: '即墨同德眼科医院（民营）',
		370215530: '即墨市市北医院',
		370215531: '即墨市人民医院',
		370215532: '即墨市中医院',
		370281659: '中国石油天然气总公司第七建设公司职工医院',
		370281660: '胶州市兴华医院',
		370281661: '青岛市中心医疗集团',
		370281662: '胶州湾医院',
		370281663: '青州眼科医院',
		370281664: '胶州现代医院',
		370281665: '胶州市人民医院',
		370281666: '胶州中心医院',
		370283628: '平度市第二人民医院',
		370283629: '平度市第五人民医院',
		370283630: '平度市第三人民医院',
		370283631: '平度市中医院',
		370283632: '平度市人民医院',
		370283633: '青岛侬谊整骨医院（民营）',
		370285667: '莱西市妇幼保健院',
		370285668: '莱西市第三人民医院',
		370285669: '莱西友好医院',
		370285670: '莱西世纪协和医院',
		370285671: '莱西市中医院',
		370285672: '莱西市人民医院',
		370302349: '淄博市第一医院分院',
		370302350: '齐都医院',
		370302351: '淄博市淄川区中医院',
		370302352: '淄博市淄川区医院',
		370302353: '淄博矿业集团有限责任公司中心医院',
		370302354: '淄博市精神卫生中心',
		370303319: '淄博天大皮肤病医院',
		370303320: '张店钢铁总厂医院',
		370303321: '山东淄博向东美容整形医院',
		370303322: '淄博博爱医院',
		370303323: '淄博广电院',
		370303324: '淄博一美天成整形美容医院',
		370303325: '淄博市张店区人民医院',
		370303326: '淄博市张店区人民医院淄博康明眼科医院',
		370303327: '淄博口腔医院',
		370303328: '山东铝业公司医院',
		370303329: '淄博市第七人民医院',
		370303330: '淄博市张店区中医院',
		370303331: '淄博市红十字会癫痫病医院',
		370303332: '淄博市中心医院',
		370303333: '青岛铁路分局淄博铁路医院',
		370303334: '山东新华制药厂职工医院',
		370303335: '山东淄博第八人民医院',
		370303336: '淄博市市级机关医院',
		370303337: '国营第四八一厂职工医院',
		370303338: '淄博市第八人民医院',
		370303339: '淄博市铁路医院',
		370303340: '淄博新华医院',
		370304297: '淄博万杰肿瘤医院',
		370304298: '淄博市博山区医院',
		370304299: '淄博市博山区中医院',
		370304300: '淄博万杰医院',
		370304301: '淄博市第一医院',
		370304302: '淄博矿业集团有限责任公司夏庄煤矿职工医院',
		370304303: '山东淄博博山烧伤整形医院',
		370304304: '淄博颜山医院',
		370304305: '淄博制酸厂职工医院',
		370304306: '国营山东机器厂职工医院',
		370304307: '南定煤矿职工医院',
		370305291: '中国石化第十建设职工医院',
		370305292: '中国石化集团第十建设公司职工医院',
		370305293: '齐鲁石化医院集团一分院',
		370305294: '淄博市临淄区中医院',
		370305295: '淄博市临淄区妇幼保健院',
		370305296: '淄博市临淄区人民医院',
		370306308: '周村区萌水镇中心卫生院',
		370306309: '山东省生建八三厂医院',
		370306310: '淄博市红十字国昌医院',
		370306311: '中国人民解放军第一四八中心医院',
		370306312: '淄博市中医院',
		370306313: '淄博市周村区人民医院',
		370306314: '中国人民解放军54871部队专科医院',
		370306315: '淄博市周村区妇幼保健站',
		370306316: '淄博周村区人民医院',
		370306317: '中国人民解放军第一四八医院',
		370306318: '解放军第148中心医院',
		370321341: '桓台县骨伤专科医院',
		370321342: '桓台县中医院',
		370321343: '桓台县妇幼保健院',
		370321344: '桓台县人民医院',
		370321345: '淄博圣洁脑科医院',
		370322355: '高青县中医院',
		370322356: '高青县人民医院',
		370323346: '沂源县妇幼保健站',
		370323347: '沂源县人民医院',
		370323348: '沂源县中医院',
		370402160: '山东医学科学院',
		370402161: '济南友谊医院（民营）',
		370402162: '枣庄市中区妇幼保健院',
		370402163: '济南中医脉管炎医院',
		370402164: '济南妇科医院',
		370402165: '济南尖锐湿疣医院',
		370402166: '济南乳腺病医院',
		370402167: '山东施尔明眼科医院',
		370402168: '山东红十字会医院',
		370402169: '济南市第八人民医院',
		370402170: '山东红十字会介入泌尿医院',
		370402171: '枣庄市立医院',
		370402172: '济南骨科医院',
		370402175: '山东省石化医院山东省石油化学职业病防治研究所',
		370402176: '济南市市中区人民医院',
		370402177: '山东红十字会医院',
		370402178: '山东省警官总医院',
		370402179: '山东省邮电医院',
		370402180: '山东省劳改局中心医院',
		370402181: '济南市中医院',
		370402182: '山东省中西医结合医院',
		370402183: '济南市民族医院',
		370402240: '济宁市妇幼保健院',
		370402241: '山东医学科学院济宁清华医院',
		370402242: '济宁老年血管病医院（民营）',
		370402243: '中国人民解放军山东省军区济宁医院',
		370402244: '济宁鲁抗医院',
		370402245: '济宁口腔医院济宁市牙病防治中心',
		370402246: '济宁烧伤整形医院',
		370402247: '济宁丽人医院',
		370402248: '济宁协和医院',
		370402249: '济宁市中区人民医院',
		370402250: '济宁市抗生素厂职工医院',
		370402251: '济宁市棉纺织厂职工医院',
		370402252: '济宁市交通医院',
		370402253: '济宁市直机关医院',
		370402254: '济宁市任城区烧伤医院',
		370402255: '济宁市任城区中医院',
		370402256: '济宁市口腔医院',
		370602435: '泰山中医院',
		370602436: '华厦眼科医院集团烟台康爱眼科医院',
		370602437: '烟台家美妇产医院',
		370602438: '烟台坤成实业美神诊所',
		370602439: '烟台凤凰台医院',
		370602440: '烟台市妇幼保健院',
		370602441: '烟台毓璜顶医院',
		370602442: '烟台市芝罘医院',
		370602443: '烟台市肿瘤医院',
		370602444: '烟台市口腔医院',
		370602445: '烟台市开发区医院',
		370602446: '烟台魏氏疗法癫痫治疗中心',
		370602447: '烟台市中医院胜利路分院',
		370602448: '烟台市海港医院',
		370602449: '烟台市烟台山医院',
		370602450: '烟台市传染病医院',
		370602451: '中国人民解放军第一Ｏ七中心医院',
		370602452: '烟台曙光医院',
		370602453: '烟台幸福医院',
		370602454: '烟台骨科医院烟台凤凰台医院（民营)',
		370602455: '烟台市中医医院',
		370602456: '烟台万华合成革集团公司职工医院',
		370602457: '烟台毓璜顶医院青岛大学医学院附属医院',
		370602458: '烟台康博医院',
		370602459: '中国人民解放军第四Ｏ七医院',
		370602460: '烟台市职业病防治院',
		370602461: '中国蛇协烟台54685部队血栓病防治院',
		370611431: '烟台市福山区人民医院',
		370611432: '烟台市福山区妇幼保健院',
		370611433: '烟台经济技术开发区医院',
		370611434: '张格庄医院',
		370612425: '烟台市牟平中医院',
		370612426: '烟台市牟平区人民医院',
		370612427: '烟台市牟平区新建医院',
		370612428: '烟台市佳肤烧伤专科医院',
		370612429: '烟台市牟平区新牟联谊医院',
		370612430: '烟台市新建医院（民营）',
		370613462: '烟台康复中心',
		370613463: '山东工商学院医院',
		370634478: '长岛县人民医院',
		370681479: '龙口九龙男科',
		370681480: '南山心血管病医院',
		370681481: '龙口矿务局中心医院',
		370681482: '龙口市北海医院',
		370681483: '龙口市第二人民医院',
		370681484: '龙口市人民医院',
		370681485: '龙口市中医院',
		370681486: '烟台市北海医院',
		370682468: '莱阳市中心医院',
		370682469: '莱阳市妇幼保健院',
		370682470: '莱阳市中医院',
		370682471: '莱阳市第二人民医院',
		370682472: '烟台莱阳中心医院',
		370682473: '中国人民解放军第一四五医院',
		370682474: '莱阳卫校医院',
		370683464: '莱州市中医院',
		370683465: '莱州市第三人民医院',
		370683466: '莱州市第二人民医院',
		370683467: '莱州市人民医院',
		370684475: '蓬莱市中医院',
		370684476: '蓬莱市人民医院',
		370684477: '中国人民解放军第四Ｏ五医院',
		370685419: '招远市人民医院',
		370685420: '招远市中医院',
		370685421: '招远市英诚医院',
		370686420: '栖霞市人民医院',
		370686421: '烟台市桃村中心医院',
		370686422: '栖霞市中医院',
		370687422: '海阳市中医院',
		370687423: '海阳市人民医院',
		370687424: '海阳市第二人民医院',
		370702397: '潍坊耳鼻喉医院',
		370702398: '潍坊市立第二医院',
		370702399: '潍坊市工业医院',
		370702400: '东方肾脏病医院',
		370702401: '潍坊潍城经济开发区医院',
		370702402: '潍坊口腔医院',
		370702403: '山东潍城经济开发区人民医院',
		370702404: '中国人民解放军第八十九医院',
		370702405: '潍坊市交通医院',
		370704362: '潍坊市坊子区人民医院',
		370705363: '潍坊彭胜三叉神经医院',
		370705364: '潍坊玛丽妇产医院',
		370705365: '潍坊和平医院',
		370705366: '潍坊奎文仁和医院',
		370705367: '潍坊肛肠外科医院',
		370705368: '潍坊市柴油机厂职工医院',
		370705369: '潍坊市和平医院',
		370705370: '潍坊市哮喘病医院',
		370705371: '潍坊市第四棉纺织厂职工医院',
		370705372: '潍坊市中医院',
		370705373: '潍坊市人民医院',
		370705374: '华星医院（原潍坊市棉纺织厂职工医院)',
		370705375: '潍坊市肿瘤医院',
		370705376: '潍坊新城医院',
		370705377: '潍坊市市直机关医院',
		370705378: '潍坊市第四人民医院潍坊肿瘤医院',
		370705379: '电力三社区卫生服务站',
		370705380: '潍坊市皮肤病医院潍坊市性病防治中心',
		370705381: '潍坊肾脏病医院（民营）',
		370705382: '潍坊开发区骨伤病医院',
		370705383: '潍坊市糖尿病医院',
		370705384: '潍坊仁德医院',
		370705385: '潍坊仁德医院（民营）',
		370724357: '临朐县九山中心卫生院',
		370724358: '临朐县朐山医院',
		370724359: '临朐县人民医院',
		370724360: '临朐县中医院',
		370724361: '临朐县第三人民医院',
		370725393: '昌乐县人民医院',
		370725394: '潍坊市第三人民医院',
		370781409: '青州市立医院',
		370781410: '青州市妇幼保健院',
		370781411: '青州市中医院',
		370781412: '青州市人民医院',
		370781413: '潍坊市益都中心医院',
		370782406: '诸城市立医院',
		370782407: '诸城市人民医院',
		370782408: '诸城市中医院',
		370783391: '寿光市中心医院',
		370783392: '寿光市人民医院',
		370784386: '安丘市妇幼保健院',
		370784387: '安丘市中医院',
		370784388: '安丘市人民医院',
		370784389: '安丘市石堆镇卫生院',
		370784390: '安丘市石堆镇西莲池村卫生室',
		370785414: '高密市骨伤科医院',
		370785415: '高密市第三人民医院',
		370785416: '高密市人民医院',
		370785417: '高密市整骨总医院',
		370785418: '高密市中医院',
		370786395: '昌邑市中医院',
		370786396: '昌邑市人民医院',
		370811221: '山东推土机总厂医院',
		370811222: '济宁任城心理医院',
		370811223: '济宁市鲁抗医院',
		370811224: '济宁市任城区妇幼保健院',
		370811225: '济宁市第二人民医院',
		370811226: '济宁医学院附属医院',
		370811227: '济宁市妇女儿童医院',
		370811228: '济宁医学院第二附属医院',
		370811229: '济宁市第一人民医院',
		370812230: '兖州市中医院',
		370812231: '兖州市人民医院',
		370812232: '兖州久益医院',
		370812233: '济宁市荣复军人医院',
		370812234: '兖矿集团总公司兴隆庄医院',
		370812235: '中国人民解放军第九十一医院',
		370826257: '微山县夏镇卫生院城东分院',
		370826258: '微山县中医院',
		370826259: '微山县人民医院',
		370827289: '济宁仁爱医院',
		370827290: '鱼台县人民医院',
		370828286: '金乡县妇幼保健院',
		370828287: '金乡县中医院',
		370828288: '金乡县人民医院',
		370829236: '嘉祥县红十字会医院',
		370829237: '嘉祥县妇幼保健院',
		370829238: '嘉祥县中医院',
		370829239: '嘉祥县人民医院',
		370830271: '汶上县中医院',
		370830272: '汶上县人民医院',
		370831273: '泗水县第二人民医院',
		370831274: '泗水县商业职工医院',
		370831275: '泗水县红十字会医院',
		370831276: '泗水县精神病防治院',
		370831277: '泗水县妇幼保健院',
		370831278: '泗水县中医院',
		370831279: '泗水县人民医院',
		370832264: '梁山县第三人民医院',
		370832265: '梁山骨科医院',
		370832266: '梁山县妇幼保健站',
		370832267: '梁山县中医院',
		370832268: '梁山县第二人民医院',
		370832269: '梁山县人民医院',
		370832270: '济宁市粮食系统友谊医院',
		370881260: '曲阜市口腔医院',
		370881261: '曲阜市盛德医院',
		370881262: '曲阜市妇幼保健院',
		370881263: '曲阜市人民医院',
		370883280: '兖矿集团南屯矿医院',
		370883281: '邹城协和医院',
		370883282: '邹城市人民医院',
		370883283: '兖矿集团公司东滩矿医院',
		370883284: '兖矿集团公司第二医院',
		370883285: '兖矿集团公司总医院',
		// 370902059: '泰安市儿童医院泰安市第三人民医...',
		370902060: '泰安市第四人民医院',
		370902061: '泰安市泰山医学院附属医院',
		370902062: '山东省煤炭泰山疗养院',
		370902063: '泰安市中心医院分院查体中心',
		370902064: '泰安市精神病医院',
		// 370902065: '泰安市第二人民医院泰安市精神卫...',
		370902066: '泰安市交通医院',
		370902067: '泰安爱尔光明医院',
		370902068: '泰安市泰山区人民医院',
		370902069: '泰安北大泌尿医院',
		370902070: '莱芜市人民医院',
		// 370902071: '泰山医学院附属新泰医院新泰市人...',
		370902072: '泰山脉管炎专科医院',
		370902073: '泰山市慢性病医院',
		370902074: '泰山医学院附属莱钢医院',
		370902075: '泰安市中医医院',
		370902076: '泰安市泰山区妇幼保健院',
		370902077: '泰安市骨科医院',
		370902078: '泰安市口腔病防治院',
		370902079: '泰安市职业病防治院',
		370902080: '泰安市皮肤病医院',
		370902081: '中国人民解放军第八十八医院',
		370902082: '泰安市中心医院',
		370902083: '泰安市妇幼保健院',
		370902084: '88医院',
		370902085: '肥城中医院',
		370902086: '泰山医学院附属医院',
		370911048: '泰安市郊区汶口医院',
		370911049: '泰安市岱岳区妇幼保健院',
		370911050: '泰安市郊区第二人民医院',
		370921045: '宁阳县疾病预防控制中心',
		370921046: '宁阳县中医院',
		370921047: '宁阳县第一人民医院',
		370923041: '泰安东平老科协医院',
		370923042: '东平县中医院',
		370923043: '东平县第一人民医院',
		370923044: '东平县人民医院',
		370982051: '新泰市工业医院',
		370982052: '新泰市第三人民医院',
		370982053: '新泰市第二人民医院',
		370982054: '新泰市第一人民医院',
		// 370982055: '新汶矿业集团有限责任公司孙村医...',
		// 370982056: '新汶矿业集团有限责任公司协庄医...',
		// 370982057: '新汶矿业集团有限责任公司张庄医...',
		370982058: '新汶矿业集团中心医院',
		370983087: '肥城矿业集团第二医院',
		370983088: '肥城市汶阳医院',
		370983089: '肥城市精神卫生中心',
		370983090: '肥城市妇幼保健站',
		370983091: '肥城市中医院',
		370983092: '肥城市人民医院',
		370983093: '肥城矿业集团精神病医院',
		370983094: '肥城矿业中心医院',
		371102027: '齐鲁医院日照分院',
		371102028: '日照港口医院',
		// 371102029: '日照市第三人民医院日照市精神卫...',
		371102030: '日照市精神卫生中心',
		371102031: '日照现代男科医院',
		371102032: '日照市港口医院',
		371102033: '日照市东港医院',
		371102034: '日照市中医院',
		371102035: '日照市第三人民医院',
		371103038: '日照市大岭中医正骨医院',
		371103039: '日照市第二人民医院',
		371121036: '五莲县中医院',
		371121037: '五莲县人民医院',
		371122040: '莒县人民医院',
		371402011: '中国水电十三局医院',
		371402012: '德州十三局职工医院',
		371402013: '德州联合医院',
		371402015: '德州市德城区世纪协和医院',
		371402016: '德州市立医院',
		371402017: '德州市中医院',
		371402018: '德州市结核病防治所',
		371402019: '德州市人民医院',
		371403022: '陵县人民医院',
		371403023: '陵县中医院',
		371422005: '宁津县人民医院',
		371423009: '庆云县人民医院',
		371423010: '庆云县第二人民医院',
		371424000: '临邑县中医院',
		371424001: '临邑县人民医院',
		371425024: '齐河县中医医院',
		371425025: '齐河县中医院',
		371425026: '齐河县人民医院',
		371426006: '平原县中医院',
		371426007: '平原县第一人民医院',
		371426008: '平原县第二人民医院',
		371427004: '夏津县中医院',
		371428020: '武城县人民医院',
		371481002: '乐陵市人民医院',
		371481003: '乐陵市中医院',
		371482021: '禹城市人民医院',
		371502487: '聊城市耳鼻喉医院',
		371502488: '聊城市手足外科医院',
		371502489: '聊城市肿瘤医院',
		371502490: '聊城市脑科医院',
		371502491: '聊城地区复退军人医院',
		371502492: '聊城第四人民医院',
		371502493: '聊城市复退军人医院',
		371502494: '聊城市肿瘤防治院',
		371502495: '聊城大学医院',
		371502496: '聊城博爱妇女儿童医院',
		371502497: '聊城妇科医院',
		371502498: '聊城肛肠医院',
		371502499: '聊城市东昌府区人民医院',
		371502500: '聊城国际和平医院',
		371502501: '聊城市东昌府人民医院',
		371502502: '聊城市第四人民医院',
		371502503: '聊城市第三人民医院',
		371502504: '鲁西骨科医院',
		371502505: '聊城市中医院',
		371502506: '聊城市人民医院',
		371521523: '阳谷县大布乡杨庄卫生院',
		371521524: '阳谷县七级镇中心卫生院',
		371521525: '阳谷县妇幼保健院',
		371521526: '阳谷县中医院',
		371521527: '阳谷县第一人民医院',
		371522521: '山东省莘县人民医院',
		371522522: '莘县中医院',
		371523515: '山东省茌平县王老卫生院',
		371523516: '茌平县冯屯镇卫生院',
		371523517: '茌平县第三人民医院',
		371523518: '茌平县第二中医医院',
		371523519: '聊城市茌平县关爱医院',
		371523520: '茌平阳光医院',
		371524507: '东阿县人民医院',
		371525510: '山东省冠县桑阿镇中心卫生院',
		371525511: '冠县儿科医院',
		371525512: '冠县城关医院',
		371525513: '冠县中医院',
		371525514: '冠县人民医院',
		371526528: '高唐县人民医院',
		371581508: '临清市中医院',
		371581509: '临清市人民医院',
		140105170: '太原铁三局中心医院',
		140105171: '山西省荣军医院',
		140105172: '山西省一Ｏ九医院',
		140105173: '太原市商业职工医院',
		140105174: '太原市妇幼保健院',
		140105175: '武警山西总队医院',
		140105176: '中铁十七局集团中心医院',
		140105177: '太原显微手外科医院',
		140105178: '中铁三局集团中心医院',
		140105179: '山西中医学院第三中医院',
		140105180: '太原长城医院',
		140105181: '太原市太航医院',
		140105182: '山西益康脉管炎医院',
		140105183: '山西省中医院',
		140105184: '山西省中西医结合医院',
		140105185: '山西省儿童医院',
		140105186: '山西煤炭中心医院',
		140105187: '太原肿瘤医院',
		140105188: '太原东方国科医院（民营）',
		140105189: '太原航空仪表有限公司医院',
		140105190: '太原益民中医院',
		140105191: '山西省一零九医院',
		140105192: '中国辐射防护研究院附属医院',
		140105193: '山西省水利职工医院',
		140105194: '中铁十七局集团有限公司中心医院',
		140105195: '中辅院附属医院',
		140106233: '山西医科大学口腔医院',
		140106234: '太原九龙泌尿外科医院',
		140106235: '山西博大泌尿外科医院',
		140106236: '中国人民解放军第二六四医院',
		140106237: '山西省中西医结合妇科医院',
		140106238: '山西省第二人民医院',
		140106239: '太原市中心医院',
		140106240: '山西省精神卫生中心',
		140106241: '山西医科大学第一医院',
		140106242: '山西省中医学院附属医院',
		140106243: '山西省肛肠医院',
		140106244: '山西职工医学院附属医院',
		140106245: '太原建国泌尿外科医院',
		140106246: '太原迎泽医学美容医院',
		140106247: '山西肾移植透析中心',
		// 140106248: '山西省第二人民医院山西省职业病...',
		// 140106249: '山西省精神卫生中心太原市精神病...',
		140106250: '山西省中西医结合妇科',
		140106251: '山西省中医药研究院',
		140106252: '山西中西医结合医院',
		140106253: '山西现代疼痛医院',
		140106254: '太原市传染病医院',
		// 140106255: '山西气管炎专科医院山西省商业供...',
		140106256: '太原市精神病医院',
		140107207: '民革太原中山创伤医院',
		140107208: '中国第十三冶金建设公司职工医院',
		140107209: '太原市儿童医院',
		140107210: '山西现代女子医院',
		140107211: '山西省戒毒康复医院',
		140107212: '太原市杏花岭区中医医院',
		140107213: '太原市中医医院',
		140107214: '山西医科大学第二医院',
		140107215: '太原同济医院',
		140107216: '大同永泰医院',
		// 140107217: '山西中北能源机械有限责任公司职...',
		140107218: '太原市杏花岭区创伤骨科医院',
		140107219: '太原市杏花岭区中医院',
		140107220: '太原市人民医院',
		140107221: '山西省肿瘤医院',
		140107222: '太原市北城区中心医院',
		140107223: '太原市第二人民医院',
		140107224: '太原市中医研究所附属医院',
		140107225: '太原市第七人民医院',
		140107226: '太原市杏花岭区中心医院',
		140107227: '太原市传染病医院第三人民医院',
		140107228: '山西医科大学附属太钢总医院',
		140107229: '山西中医学院中西医结合医院',
		140107230: '山西第三人民医院肿瘤医院',
		140107231: '山西医科大学附属第二医院',
		140107232: '山西省眼科医院',
		140108196: '太原市第九人民医院',
		140108197: '太原市尖草坪区中心医院',
		140108198: '山西博爱医院',
		140108199: '华北工学院医院',
		140108200: '太原泽祥医院（民营）',
		// 140108201: '太原钢铁（集团）有限公司总医院...',
		140108202: '山西新华化工厂职工医院',
		140109140: '太原纺织医院',
		140109141: '山西贞爱妇产医院',
		140109142: '山西省心血管病医院',
		140109143: '中铁十二局集团有限公司中心医院',
		140109144: '太原煤气化职工医院',
		140109145: '山西华晋骨科医院',
		140109146: '山西三针脑血管病医院',
		140109147: '太原市化工厂医院',
		140109148: '中铁十二局中心医院',
		140109149: '山西医科大学附属第三医院',
		140109150: '山西中医学院附属第二中医院',
		140109151: '山西医科大学第三医院',
		140109152: '太原市太行医院',
		140109153: '山西省心血管疾病医院',
		140109154: '西山煤矿总公司职工总医院',
		140109155: '山西省人民医院',
		// 140109156: '太原煤炭气化（集团）有限责任公...',
		140109157: '山西焦煤公司西铭矿职工医院',
		140109158: '山西省西山矿务职工总医院',
		// 140109159: '山西焦煤西山煤电集团公司职工总...',
		140109160: '国营大众机械厂职工医院',
		140109161: '山西省职工总医院',
		// 140109162: '山西焦煤集团有限责任公司职工总...',
		140109163: '太原市纺织职工医院',
		140109164: '山西省太原第一监狱医院',
		140109165: '西山煤电集团公司职工总医院',
		140110203: '山西省工人晋祠疗养院',
		// 140110204: '中国化学工程第二建设公司职工医...',
		// 140110205: '太原化学工业集团公司化肥厂职工...',
		140110206: '山西省工人晋祠第二疗养院',
		140122257: '阳曲县中医院',
		140181166: '古交市中医医院',
		// 140181167: '西山煤电（集团）有限责任公司古...',
		140181168: '古交市妇幼保健院',
		140181169: '西山矿务局第二职工医院',
		140212115: '大同激光医学专科医院',
		140212116: '大同市第七人民医院',
		140212117: '大同市新荣区妇幼保健站',
		140212118: '大同市新荣区人民医院',
		140213082: '同煤集团第一职工医院',
		140213083: '大同市二轻医院',
		140213084: '大同消化病医院',
		140213085: '大同曙光男科医院',
		140213086: '大同曙光泌尿医院',
		// 140213087: '大同市城区人民医院大同市城区妇...',
		140213088: '喀什兵团农三师医院',
		140213089: '大同市城区人民医院',
		140213090: '大同市商业职工医院',
		140213091: '大同市社会精神病医院',
		140213092: '大同生殖医学专科医院',
		140213093: '大同市同祥红十字医院',
		140213094: '大同医学老专家协会医院',
		140213095: '大同新康专科医院',
		140213096: '大同市风湿病医院',
		140213097: '大同医学专科学校中医院',
		140213098: '大同市第二人民医院',
		140213099: '大同市交通医院',
		140213100: '大同皮肤病医院',
		140213101: '大同煤矿集团公司总医院',
		140213102: '大同市煤炭工业局职工医院',
		// 140213103: '大同市第四人民医院大同市传染病...',
		140213104: '大同县中医院',
		140213105: '大同县人民医院',
		140213106: '大同兴康医院',
		140213107: '大同县妇幼保健站',
		140213108: '大同铁路中心医院',
		// 140213109: '大同市第六人民医院大同市精神病...',
		140213110: '大同机车厂医院',
		// 140213111: '山西大同同煤集团第二职工医院清...',
		140214060: '大同市南郊区人民医院',
		140214061: '大同市第六人民医院',
		140214062: '大同市脊椎病医院',
		140214063: '大同市永泰医院',
		140214064: '大同市创伤骨科医院',
		140214065: '大同市男科医院',
		140214066: '大同精神卫生防治院',
		140214067: '大同残联医院',
		140214068: '大同世纪协和医院',
		140214069: '大同市第三人民医院',
		140214070: '大同市煤炭工业职工医院',
		140214071: '大同市第一人民医院',
		140214072: '大同市基本建设职工医院',
		140214073: '大同市第二轻工业局职工医院',
		140214074: '大同市南郊区妇幼保健站',
		140214075: '大同市第三人民医院肿瘤分院',
		140214076: '大同市口腔医院',
		140214077: '大同市红十字会康科口腔医院',
		140214078: '大同骨科医院',
		140214079: '大同市第五人民医院',
		140214080: '中国人民解放军第三二二医院',
		140214081: '国营山西柴油机厂职工医院',
		140221138: '阳高县中医院',
		140221139: '阳高县人民医院',
		140222112: '天镇县人民医院',
		140224119: '大同铁路医院',
		140224120: '灵邱县人民医院',
		140226113: '左云县人民医院',
		140226114: '大同高峰康乐医院',
		140303121: '大同煤矿集团晋华宫矿医院',
		140303122: '大同煤矿集团公司三医院',
		140303123: '大同市矿区人民医院',
		140303124: '大同市中西医结合医院',
		140303125: '大同煤矿集团有限责任公司三医院',
		140303126: '大同市矿区中医院',
		140303127: '大同市第四人民医院',
		// 140303128: '大同煤矿集团有限责任公司白洞医...',
		// 140303129: '大同煤矿集团有限责任公司忻州窑...',
		// 140303130: '大同市三医院唐山沟煤矿联合体医...',
		140303131: '大同市妇幼保健所',
		140303132: '大同市中医院',
		// 140303133: '大同煤矿集团有限责任公司结核病...',
		// 140303134: '大同煤矿集团有限责任公司王村矿...',
		140303135: '大同煤矿集团公司二医院',
		140303136: '大同煤矿集团公司一医院',
		140303137: '同煤集团总医院',
		141002009: '临汾市眼科医院',
		// 141002010: '太钢集团临汾钢铁有限公司职工医...',
		141002011: '临汾市中医医院',
		141002012: '临汾铁路医院',
		141002013: '临汾市中医院',
		141002014: '临汾地区骨科医院',
		141002015: '临汾市精神病医院',
		141002016: '临汾市中医骨伤科医院',
		141002017: '山西亚太治癌研究中心',
		141002018: '临汾地区秦蜀医院',
		141002019: '临汾皮肤病医院',
		141002020: '临汾京联外科医院',
		141002021: '临汾博大微创手足外科医院',
		141002022: '临汾西京耳鼻喉专科医院',
		141002023: '临汾先平妇产医院',
		141002024: '临汾建宁妇产医院',
		141002025: '临汾市尧都区中医骨伤科医院',
		141002026: '中国人民解放军二八九医院',
		141002027: '临汾市尧都区骨伤科医院',
		141002028: '临汾市肿瘤医院',
		141002029: '临汾市第四人民医院',
		141002030: '临汾市人民医院',
		141002031: '临汾地区人民医院',
		141002032: '太原铁路分局临汾铁路中心医院',
		141002033: '临汾市眼科医院',
		// 141002034: '太钢集团临汾钢铁有限公司职工医...',
		141002035: '临汾市中医医院',
		141002036: '临汾铁路医院',
		141002037: '临汾市中医院',
		141002038: '临汾地区骨科医院',
		141002039: '临汾市精神病医院',
		141002040: '临汾市中医骨伤科医院',
		141002041: '山西亚太治癌研究中心',
		141002042: '临汾地区秦蜀医院',
		141002043: '临汾皮肤病医院',
		141002044: '临汾京联外科医院',
		141002045: '临汾博大微创手足外科医院',
		141002046: '临汾西京耳鼻喉专科医院',
		141002047: '临汾先平妇产医院',
		141002048: '临汾建宁妇产医院',
		141022054: '翼城县国营山西锻造厂职工医院',
		141022055: '翼城县人民医院',
		141023056: '襄汾县人民医院',
		141024050: '山西焦化集团有限公司职工医院',
		141024051: '洪洞县人民医院',
		141024052: '山西三维华邦集团职工医院',
		141025007: '吉县人民医院',
		141027053: '浮山县人民医院',
		141030008: '大宁县医院',
		141034049: '汾西矿业集团总医院',
		141081000: '侯马平阳医院',
		141081001: '侯马博爱医院',
		// 141081002: '铁道部第十五工程局第四工程处职...',
		141081003: '侯马市侯纺医院痔瘘专科',
		141081004: '山西省一建公司医院',
		141081005: '山西省第一建筑工程公司职工医院',
		141081006: '侯马市人民医院',
		141082057: '霍州常氏仁德医院',
		141082058: '霍州市矿务局中心医院',
		141082059: '霍州市人民医院',
		610102273: '西安军海医院',
		610102274: '西安市中医皮外科医院',
		610102275: '杨陵示范区医院',
		610102276: '西安马应龙肛肠医院',
		610102277: '西安新城中大耳鼻喉医院',
		610102278: '西安医学院附属医院',
		610102279: '西安新城东环医院',
		610102280: '陕西省西铁肿瘤医院',
		610102281: '西安新城白癫风专科医院',
		610102282: '西安市新城区太华医院',
		610102283: '西安市按摩医院',
		610102284: '西安市新城区胡家庙医院',
		610102285: '西安市东郊第四职工医院',
		610102286: '西安市东郊第三职工医院',
		610102287: '西安市东郊第二职工医院',
		610102288: '西安太华医院',
		610102289: '西安市昆仑医院',
		610102290: '西安市新城区第二医院',
		610102291: '西安市东方医院',
		610102292: '陕西省建材医院',
		610102293: '西安市东郊第一职工医院',
		610102294: '西安市北方医院',
		610102295: '西安黄河医院',
		610102296: '西安武仁医院',
		610102297: '西安市华山中心医院',
		610102298: '西安市新城区兴庆医院',
		610102299: '西安市新城区中医院',
		610102300: '西安市第六医院',
		610102301: '西安市新城区妇幼保健院',
		610102302: '西安古城医院肿瘤防治分院',
		610102303: '西电集团医院',
		610102304: '西安市中医医院',
		610102305: '西安交通大学口腔医院',
		610102306: '西安市第四医院',
		610102307: '陕西省第二人民医院',
		610102308: '陕西省中西医结合医院',
		610102309: '西安光仁医院',
		610103351: '西安东大肛肠医院',
		610103352: '陕西天伦不孕不育医院',
		610103353: '西安普瑞眼科医院',
		610103354: '西安高一生医疗美容医院',
		610103355: '西安宇恒医院',
		610103356: '西安武警骨科医院',
		610103357: '西安市碑林区妇幼保健站',
		610103358: '西安市碑林区中医院',
		610103359: '西安市碑林区口腔医院',
		610103360: '西安民建中医院',
		610103361: '西安华夏医院',
		610103362: '西安华佗医院',
		610103363: '西安大同医院',
		610103364: '西安宇恒医院',
		610103365: '西安工程科技大学医院',
		610103366: '西安市碑林区东关医院',
		610103367: '西安市碑林区南大街医院',
		610103368: '西安市南天医院',
		610103369: '西安友谊中医院',
		610103370: '西安精英医院',
		610103371: '西安市碑林区东大街医院',
		610103372: '西安南关医院',
		610103373: '西安市皇城医院',
		610103374: '西安市红缨路医院',
		610103375: '陕西省公路局职工医院',
		610103376: '陕西省交通医院',
		610103377: '西安市碑林区红十字会医院',
		610103378: '西安市眼科医院',
		610103379: '西安肝硬化医院',
		610103380: '西安市红十字会医院骨伤分院',
		610103381: '中国人民解放军四五一医院',
		610103382: '中国人民解放军三二三医院',
		610103383: '西安市第一医院',
		610103384: '西安市红十字会医院',
		610103385: '陕西省人民医院',
		610103386: '西安学典医院',
		// 610103387: '西安交通大学第二附属皮肤性病医...',
		610103388: '陕西省肿瘤医院分院',
		610103389: '陕西中西医结合糖尿病医院',
		610103390: '西安市城东红十字医院',
		610103391: '西安四维肝病医院',
		610103392: '西安市结核病康复病分院',
		610103393: '西安市红十字会截瘫康复分院',
		610103394: '陕西省友谊医院',
		610103395: '西安市中医院',
		610103396: '西安碑林春生医院',
		610103397: '西北工业大学医院',
		610103398: '西北有色医院',
		610103399: '陕西省中西医结合医院',
		610103400: '陕西同济医院',
		610104401: '西安博仕生殖保健院医院',
		610104402: '西安莲湖北大医院',
		610104403: '西安雁塔益群中医门诊部',
		610104404: '西安中际中西医结合脑病医院',
		610104405: '西北有色冶金机械厂职工医院',
		610104406: '西安炎黄中医专科医院',
		610104407: '西安市莲湖区青年路医院',
		610104408: '西安市莲湖区庙后街医院',
		610104409: '西安市莲湖区西关医院',
		610104410: '西安市莲湖区桃园路医院',
		610104411: '西安市莲湖区北关医院',
		610104412: '陕西省第一建筑工程公司职工医院',
		610104413: '西安市中心医院分院一病区',
		610104414: '西安冶金机械厂职工医院',
		610104415: '西安市莲湖区妇幼保健站',
		610104416: '西安市立山医院',
		610104417: '西安远东医院',
		610104418: '西安市莲湖区中医院',
		610104419: '西安市第二医院',
		610104420: '西安市中心医院第二分院',
		610104421: '陕西省邮电医院',
		610104422: '陕西地质矿产局职工医院',
		610104423: '庆安宇航设备厂职工医院',
		610104424: '西安市莲湖区红庙坡医院',
		610104425: '西安市莲湖区红十字会医院',
		610104426: '西安市儿童医院分院',
		610104427: '西安市儿童医院',
		610104428: '陕西省中医医院',
		610104429: '西安市中心医院',
		610104430: '陕西中医骨伤研究院',
		610104431: '西安市第五医院西安市风湿病医院',
		610104432: '西安铁路中心医院分院',
		610104433: '西安市中医院痔瘘分院',
		610104434: '陕西省生殖保健中心',
		610104435: '陕西中医学院附属医院',
		610104436: '西安黄河医院',
		610104437: '汉唐医院',
		610104438: '西安美立方整形美容医院',
		610104439: '西安莲湖瑞丽医院',
		610104440: '西安都市医院',
		610104441: '西安北方中医皮肤病医院',
		// 610104442: '西安童颜堂黄褐斑抗衰老医学研究...',
		610104443: '西安安琪儿妇产医院',
		610111341: '西安市灞桥区红十字会医院',
		610111342: '西安市灞桥区中医院',
		610111343: '西安市灞桥区妇幼保健站',
		610111344: '西安市灞桥区精神病院',
		610111345: '西安市灞桥区中医整骨医院',
		610111346: '西安市灞桥区人民医院',
		610111347: '陕西省纺织医院',
		610111348: '第四军医大学唐都医院',
		610111349: '陕西省人民医院唐城分院',
		610111350: '西安灞桥东城医院',
		610112310: '西安市西航集团医院',
		610112311: '西安未央施尔绮医疗美容诊所',
		610112312: '西安三桥武警医院',
		610112313: '西安友豪医院',
		610112314: '西安肿瘤医院',
		610112315: '西安未央绿港医疗美容诊所',
		610112316: '西安未央妇保站妇产医院',
		610112317: '西安张家堡肛肠医院',
		610112318: '西安郭家村工商公司医院',
		610112319: '西安市未央区草滩医院',
		610112320: '西安市未央区二府庄医院',
		610112321: '陕西第十棉织厂医院',
		610112322: '西安市未央区谭家医院',
		610112323: '西安市未央区妇幼保健站',
		610112324: '西安市未央区三桥镇医院',
		610112325: '西安未央康乐医院',
		610112326: '西安惠仁医院',
		610112327: '西安太和医院',
		610112328: '西安西郊纺织医院',
		610112329: '西安航空发动机公司职工医院',
		610112330: '西安市未央区未央宫医院',
		610112331: '西安市唐城医院',
		610112332: '西安市未央区大明宫骨科医院',
		610112333: '西安市未央区中医院',
		610112334: '西安市未央区第二人民医院',
		610112335: '西安市未央区第一人民医院',
		610112336: '西安长安医院',
		610112337: '西安交通大学附属肝胆病医院',
		610112338: '西安祥和医院',
		610112339: '西安中医儿童医院',
		610112340: '陕西省妇幼保健院',
		610113472: '中铁一局医院',
		610113473: '陕西广慈泌尿专科医院',
		610113474: '西安雁塔百思美口腔诊所',
		610113475: '西安雁塔天润中医医院',
		610113476: '西安华厦眼科医院',
		610113477: '陕西冶金医院（骨科）',
		610113478: '西安美好美容医院',
		610113479: '西安碑林金花综合门诊部',
		610113480: '西安交通大学医院',
		610113481: '西安电子科技大学医院',
		610113482: '西安公路交通大学医院',
		610113483: '西安市安康医院',
		610113484: '西安康太医院',
		610113485: '陕西省新安中心医院',
		610113486: '西安市雁塔截瘫康复医疗中心',
		610113487: '西安同仁医院',
		610113488: '西安市朱雀医院',
		610113489: '西安中华医院',
		610113490: '西安四医医院',
		610113491: '陕西正和医院',
		610113492: '西安电力中心医院',
		610113493: '西安建筑科技大学医院',
		610113494: '陕西博爱医院',
		610113495: '西安市精神病院康复病房',
		610113496: '西安东郊骨病医院',
		610113497: '西安高新医院',
		610113498: '西安交通大学医学院第一附属医院',
		610113499: '第四军医大学西京医院',
		610113500: '西安市铁路中心医院',
		610113501: '陕西省肿瘤医院',
		610113502: '西安脑病医院',
		610113503: '西安市中医皮外科医院',
		610113504: '陕西冶金医院',
		610113505: '曲江妇产医院',
		610113506: '西安中山医院',
		610113507: '陕西省新安中心医院',
		610113508: '西安雁塔区精神病院',
		610113509: '西安市雁塔区中医肿瘤医院',
		610113510: '西安市雁塔区肿瘤医院',
		610113511: '西安神龙中医院',
		610113512: '西安仁芳医院',
		610113513: '西安二六二医院',
		610113514: '西安市雁塔区等驾坡医院',
		610113515: '西安市雁塔区疑难病医院',
		610113516: '陕西省钢厂职工医院',
		610113517: '西安国医肿瘤医院',
		610113518: '陕西冶金哮喘医院',
		610114461: '西安市阎良区华夏医院',
		610114462: '西安交通大学医学院第一附属医院',
		610114463: '西安一四一医院分院',
		610114464: '西安市阎良区妇幼保健站',
		610114465: '西安市阎良区人民医院',
		610114466: '西安阎良精神病医院',
		610114467: '西安市阎良区精神病医院',
		610114468: '西安市阎良铁路医院',
		610114469: '西安一四一医院',
		610114470: '西安市阎良区中医院',
		610114471: '西安市阎良区铁路医院精神病分院',
		610115250: '西安临潼协和综合门诊部',
		610115251: '临潼县人民医院',
		// 610115252: '陕西省核工业四一七医院临潼职业...',
		610115253: '临潼妇幼保健院',
		610115254: '临潼县中医院',
		610115255: '第四军医大学口腔医院',
		610115256: '临潼县中医院',
		610116448: '西安交通大学第二附属医院',
		610116449: '西安航天总医院',
		610116450: '西安市结核病胸部肿瘤医院',
		610116451: '西安市长安区妇幼保健院',
		610116452: '西安市长安区精神病院',
		610116453: '西安市长安区中医院',
		610116454: '西安市长安区医院',
		610116455: '长安县医院',
		610116456: '西安长安龙泉医院',
		610116457: '西安尚德医院',
		610116458: '西安长安秦通医院',
		610116459: '西安交通大学医学院第二附属医院',
		610116460: '陕西省结核病防治院',
		610117519: '高陵县光达眼病医院',
		610117520: '高陵县中医院',
		610117521: '高陵县医院',
		610118265: '西安肾病医院',
		610118266: '陕西省森工医院',
		610118267: '西安惠安医院',
		610118268: '户县妇幼保健院',
		610118269: '户县中医院',
		610118270: '户县人民医院',
		610118271: '西安济仁医院',
		610118272: '西安中医肾病医院',
		610122444: '向阳公司职工医院',
		610122445: '蓝田县康复医院',
		610122446: '蓝田县中医院',
		610122447: '蓝田县医院',
		610124257: '新忠医院（民营）',
		610124258: '周至县镇东联合医院（民营）',
		610124259: '周至县镇东联合医院',
		610124260: '周至县会仁医院',
		610124261: '周至县中医院',
		610124262: '周至县人民医院',
		610124263: '周至县妇幼保健站',
		610124264: '铁路周至医院（民营）',
		610302154: '宝鸡男健医院',
		610302155: '宝鸡市渭滨医院',
		610302156: '陕西省长岭机器厂职工医院',
		610302157: '宝鸡市渭滨区神农镇医院',
		610302158: '宝鸡市渭滨区石坝河医院',
		610302159: '宝鸡市渭滨区妇幼保健站',
		610302160: '宝鸡市渭滨中医院',
		610302161: '宝鸡市开发区医院',
		610302162: '宝鸡有色金属加工厂职工医院',
		610302163: '陕西电子四Ｏ九医院',
		610302164: '宝鸡市第二人民医院',
		610302165: '宝鸡协和医院',
		610302166: '宝鸡市马营医院',
		610302167: '宝鸡市陈仓医院',
		610302168: '宝鸡市妇幼保健院',
		610302169: '宝鸡市中心医院',
		610302170: '宝钛集团职工医院',
		610303179: '陕西省第十二棉纺织厂职工医院',
		// 610303180: '陕西省铁道部宝鸡工程机械厂职工...',
		610303181: '宝鸡市博爱医院',
		610303182: '宝鸡市四联中医眼科研究所',
		610303183: '宝鸡市金台区妇幼保健院',
		610303184: '宝鸡市金台区中医院',
		610303185: '宝鸡卷烟厂职工医院',
		610303186: '宝鸡市长寿医院',
		610303187: '宝鸡市卧龙寺医院',
		610303188: '宝鸡石油医院',
		610303189: '宝鸡市斗鸡医院',
		610303190: '宝鸡市金台医院',
		610303191: '宝鸡市康复医院',
		610303192: '宝鸡市口腔医院',
		610303193: '陕西省荣复军人第一医院',
		610303194: '宝鸡市同仁医院',
		610303195: '宝鸡市人民医院',
		610303196: '宝鸡市中医院',
		610303197: '宝鸡华仁男性专科医院',
		610303198: '中国人民解放军第三中心医院',
		// 610303199: '中国人民解放军第二十八医院武警...',
		610304131: '宝鸡市陈仓区医院',
		610304132: '解放军第三医院',
		610304133: '陕西电子409医院',
		610304134: '537医院',
		610304135: '陕西省机床厂职工医院',
		610304136: '宝鸡县千河骨科医院',
		610304137: '宝鸡县妇幼保健院',
		610304138: '宝鸡县中医院',
		610304139: '宝鸡县医院',
		610304140: '陈仓区阳平中心卫生院',
		610304141: '群力无线电厂职工医院',
		610304205: '陈仓区妇幼保健院',
		610322121: '陕西省关中工具厂职工医院',
		610322122: '凤翔县妇幼保健站',
		610322123: '凤翔县中医院',
		610322124: '凤翔县医院',
		610322125: '风翔县医院',
		610322126: '风翔县中医院',
		610323142: '岐山县妇幼保健院',
		610323143: '陕西培荣中医眼科医院',
		610323144: '岐山县中医院',
		610323145: '岐山县医院',
		610323146: '西北机器厂职工医院',
		610323147: '陕九职工医院',
		610324148: '陕西省胜利机械厂职工医院',
		610324149: '扶风县医院',
		610324150: '扶风县胜利医院',
		610324151: '扶风县中医院',
		610324152: '扶风县人民医院',
		610324153: '扶风县妇幼保健院',
		610326171: '眉县常兴胃肠病医院',
		610326172: '眉县骨科医院',
		610326173: '眉县妇幼保健医院',
		610326174: '眉县中西医结合医院',
		610326175: '眉县同仁医院',
		610326176: '眉县中医院',
		610326177: '眉县人民医院',
		610326178: '槐芽妇儿医院（民营）',
		610327200: '陇县妇幼保健院',
		610327201: '陇县东风地段医院',
		610327202: '陇县同仁医院',
		610327203: '陇县中医院',
		610327204: '陇县人民医院',
		610328127: '千阳县中医院',
		610328128: '千阳县人民医院',
		610329206: '麟游县中医院',
		610330119: '凤县人民医院',
		610330120: '凤县中医院',
		610331129: '太白县中医院',
		610331130: '太白县医院',
		610402077: '中铁二十局中心医院',
		610402078: '咸阳彩虹医院',
		610402079: '成阳市朝阳医院',
		610402080: '中铁一局集团第四医院',
		610402081: '四四零零厂职工医院',
		610402082: '采虹集团公司彩虹医院',
		610402083: '咸阳市皇甫中医药研究所医院',
		610402084: '陕西金兰皮肤病研究所',
		610402085: '咸阳市第一人民医院',
		610402086: '陕西省第二纺织医院',
		610402087: '咸阳市朝阳医院',
		610402088: '咸阳市雨茂医院',
		610402089: '咸阳中医肿瘤医院',
		610402090: '咸阳市秦都区联合医院',
		610402091: '咸阳市秦都区第二人民医院',
		610402092: '咸阳市精神病康复医院',
		610402093: '咸阳市肺科医院',
		610402094: '咸阳市肿瘤医院',
		610402095: '咸阳市妇幼保健院',
		610402096: '咸阳市秦都区第一人民医院',
		610402097: '陕西中医学院附属医院',
		610402098: '陕西省核工业二一五医院',
		610403043: '杨凌示范区医院',
		610403044: '杨凌示范区杨凌精神病院',
		// 610404059: '中铁二十局集团中心医院咸阳心脑...',
		610404060: '铁二十工程局中心医院',
		610404061: '成阳市口腔医院',
		610404062: '咸阳市口腔医院',
		610404063: '咸阳五零五医院',
		610404064: '咸阳五零五医院（民营）',
		610404065: '咸阳市康复医院',
		610404066: '咸阳市第二人民医院',
		610422000: '三原县骨科医院',
		610422001: '三原县同城医院',
		610422002: '三原县广仁医院',
		610422003: '三原县秦原医院',
		610422004: '三原县东周职工医院',
		610422005: '三原县徐木医院',
		610422006: '三原县大程医院',
		610422007: '三原县眼科医院',
		610422008: '三原县妇幼保健院',
		610422009: '三原县新中医院',
		610422010: '三原县医院',
		610422011: '三原县陵前地段医院',
		610422012: '三原县神龙医院',
		610422013: '三原县洪水医院',
		610422014: '三原县马额医院',
		610422015: '三原县肛肠医院',
		610422016: '三原县精神病医院',
		610422017: '三原县中医院',
		610423050: '泾阳县妇幼保健院',
		610423051: '泾阳县商业职工医院',
		610423052: '泾阳县云阳医院',
		610423053: '泾阳县骨科医院',
		610423054: '泾阳县中医院',
		610423055: '泾阳县人民医院',
		610424018: '乾县骨伤科研究所',
		610424019: '乾县奉东康复中心',
		610424020: '乾县妇幼保健院',
		610424021: '乾县乾陵医院',
		610424022: '乾县中医院',
		610424023: '乾县人民医院',
		610425067: '礼泉县中医医院',
		610425068: '礼泉县中西医结合医院',
		610425069: '礼泉县儿童医院',
		610425070: '礼泉县城关医院',
		610425071: '礼泉县烟霞医院',
		610425072: '礼泉县中医痔瘘医院',
		610425073: '礼泉县精神病医院',
		610425074: '礼泉县妇幼保健医院',
		610425075: '礼泉县中医院',
		610425076: '礼泉县人民医院',
		610426048: '永寿县中医院',
		610426049: '永寿县人民医院',
		610428099: '长武县中医院',
		610428100: '长武县人民医院',
		610429041: '旬邑县中医院',
		610429042: '旬邑县医院',
		610430056: '淳化县石桥医院',
		610430057: '淳化县十里原医院',
		610430058: '淳化县医院',
		610431045: '武功县车站医院',
		610431046: '武功县精神病院',
		610431047: '武功县人民医院',
		610481024: '兴平市新胜综合医院',
		610481025: '陕西省柴油机厂职工医院',
		610481026: '兴平市人民医院',
		610481027: '陕西省建筑十一公司职工医院',
		610481028: '兴平市大阜医院',
		610481029: '兴平市妇幼保健院',
		610481030: '陕西省铁四处医院',
		610481031: '兴平市脑病康复研究所',
		610481032: '兴平市中医院',
		610481033: '兴平一四五医院',
		610481034: '兴平市秦岭医院',
		610482035: '彬县协和医院',
		610482036: '彬县煤矿骨科医院',
		610482037: '彬县车家庄中心医院',
		610482038: '彬县妇幼保健站',
		610482039: '彬县中医院',
		610482040: '彬县人民医院',
		610602215: '延安市宝塔区中医院',
		610602216: '延安市第二人民医院',
		610602217: '延安市宝塔区南泥湾地段医院',
		610602218: '延安市宝塔区元龙寺医院',
		610602219: '延安市宝塔区妇幼保健院',
		610602220: '延安市博爱医院',
		610602221: '延安市中医院',
		610602222: '延安市宝塔区人民医院',
		610602223: '延安市宝塔区中医院',
		610602224: '延安市中医院',
		610602225: '延安市人民医院',
		610602226: '延安市急救中心',
		610602227: '延安市中医医院',
		610602228: '姚家坡监狱职工医院',
		610603212: '安塞县人民医院',
		610621235: '延安大学附属医院',
		610621236: '延安市宝塔区结核病防治所',
		610621237: '延长油矿管理局职工医院',
		610621238: '延长县中医院',
		610621239: '延长县人民医院',
		610622232: '延川县中医院',
		610622233: '延川县人民医院',
		610622234: '延川县妇幼保健站',
		610623211: '子长县中医院',
		610625240: '志丹县中医院',
		610625241: '志丹县人民医院',
		610626207: '吴旗县人民医院',
		610626208: '吴旗县妇幼保健站',
		610626209: '吴起县中医院',
		610626210: '吴起县人民医院',
		610627244: '甘泉县中医院',
		610627245: '甘泉县人民医院',
		610628229: '富县人民医院',
		610628230: '富县妇幼保健站',
		610628231: '长庆石油勘探开发公司职工医院',
		610629242: '洛川县中医院',
		610629243: '洛川县医院',
		610630213: '宜川县人民医院',
		610630214: '宜川县中医院',
		610631249: '黄龙县人民医院',
		610632246: '黄陵县妇幼保健站',
		610632247: '黄陵县中医院',
		610632248: '黄陵县人民医院',
		611002105: '商洛市中心医院',
		611002106: '商州区人民医院',
		611002107: '商洛市卫校附属医院',
		611002108: '商洛市疗养院',
		611002109: '商洛市中医院',
		611002110: '商洛市医院',
		611002111: '商洛市民政局复退军人精神病医院',
		611002112: '商洛地区康复医院',
		611021116: '洛南县妇幼保健院',
		611022101: '丹凤县中医院',
		611022102: '丹凤县人民医院',
		611023103: '商南县妇幼保健站',
		611023104: '商南县中医院',
		611025117: '镇安县人民医院',
		611025118: '镇安县中医院',
		611026113: '柞水县妇幼保健站',
		611026114: '柞水县中医院',
		611026115: '柞水县人民医院',
		230102176: '哈拖医院',
		230102177: '黑龙江省第二医院',
		230102178: '哈尔滨市卫协第一医院',
		// 230102179: '中国一汽哈尔滨汽车齿轮厂职工医...',
		// 230102180: '黑龙江省第二医院黑龙江省职业病...',
		230102181: '黑龙江虹桥医院',
		230102182: '哈尔滨友好风湿病医院',
		230102183: '黑龙江惠好医院',
		230102184: '哈尔滨维多利亚妇产医院',
		230102185: '哈尔滨市道里区中医医院',
		230102186: '哈尔滨市车辆厂职工医院',
		230102187: '哈尔滨市公安医院',
		230102188: '哈尔滨市道里区公费医疗医院',
		230102189: '哈尔滨市道里区牙病防治院',
		230102190: '哈尔滨市道里区妇幼保健所',
		230102191: '哈尔滨道里区明明医院',
		230102192: '哈尔滨市朝鲜民族医院',
		230102193: '哈尔滨市第十医院',
		230102194: '哈尔滨市眼科医院',
		230102195: '哈尔滨市口腔医院',
		230102196: '哈尔滨市第一医院',
		230102197: '黑龙江省医院',
		230102198: '哈尔滨市中医医院',
		230102199: '哈尔滨市红十字中心医院',
		230102200: '哈尔滨市儿童医院',
		230102201: '哈尔滨市妇幼保健院',
		230102202: '哈尔滨市道里区新发红十字医院',
		230102203: '哈尔滨市道里区妇产医院',
		230102204: '哈尔滨市寄生虫病防治院',
		230102205: '哈尔滨市介入专科医院',
		230102206: '哈尔滨市道里区中西医结合医院',
		230102207: '哈尔滨锅炉厂职工医院',
		230102208: '哈尔滨市木器厂职工医院',
		230102209: '哈尔滨儿童医院',
		230103009: '黑龙江省青年医学会附属医院',
		230103010: '哈尔滨市南岗区王岗中心医院',
		230103011: '空军八六00一部队医院',
		230103012: '铁道部第三工程局哈尔滨医院',
		// 230103013: '黑龙江省中龙医药专科研究院（民...',
		230103014: '哈尔滨铁路局肿瘤医院',
		230103015: '中国人民解放军哈尔滨宏征医院',
		230103016: '武警黄金第一总队医院',
		230103017: '黑龙江红十字医疗康复中心',
		230103018: '黑龙江省邮电医院',
		230103019: '哈尔滨市职业病防治院',
		230103020: '黑龙江省皮肤病防治研究所',
		230103021: '哈尔滨壮一堂中医医院',
		230103022: '黑龙江和美妇产医院',
		230103023: '哈尔滨哈美莱医疗美容医院',
		230103024: '黑龙江现代肛肠医院',
		230103025: '哈尔滨友谊神康医院',
		230103026: '献臣皮肤病专科医院',
		230103027: '哈尔滨皮肤病研究所',
		230103028: '哈尔滨臻美医疗美容门诊部',
		230103029: '哈尔滨圣安口腔专科医院',
		230103030: '黑龙江省总工会医院',
		230103031: '黑龙江省总工会医院',
		230103032: '黑龙江和平医院',
		230103033: '哈尔滨武警黄金医院',
		230103034: '黑龙江省和平医院',
		230103035: '哈尔滨市穆斯林医院',
		230103036: '哈尔滨市南岗区大成医院',
		230103037: '哈尔滨市人防医院',
		230103038: '哈尔滨市南岗区妇幼保健所',
		230103039: '哈尔滨市南岗区妇产医院',
		230103040: '哈尔滨市养老院康复医院',
		230103041: '哈尔滨市师大脑血栓医院',
		230103042: '哈尔滨工程大学医院',
		230103043: '黑龙江省农垦总局总医院',
		230103044: '黑龙江中医药大学针灸推拿学院',
		230103045: '黑龙江省康复医院',
		230103046: '哈尔滨医科大学附属肿瘤医院',
		230103047: '哈尔滨医科大学附属第四医院',
		230103048: '哈尔滨市骨伤医院',
		230103049: '哈尔滨骨伤科医院',
		230103050: '哈尔滨医科大学附属第二医院',
		230103051: '哈尔滨医科大学附属第一医院',
		230103052: '中国人民解放军第二一一医院',
		230103053: '哈尔滨市南岗区新春医院',
		230103054: '哈尔滨市南岗区跃进医院',
		230103055: '哈尔滨市南岗区和平人民医院',
		230103056: '哈尔滨市南岗区红十字康复医院',
		230103057: '哈尔滨南岗区口腔病防治院',
		230103058: '哈尔滨市南岗区中西医结合医院',
		230103059: '哈尔滨市南岗区人民医院',
		230103060: '黑龙江瑞丽整形美容医院',
		230103061: '哈尔滨市北方中医药研究院',
		230103062: '哈尔滨献臣白癜风疑难病研究所',
		230103063: '哈尔滨长安医院',
		230103064: '哈尔滨电力医院',
		230103065: '哈尔滨穆斯林医院',
		230103066: '哈尔滨船舶工程学院医院',
		230103067: '哈尔滨市南岗区中医院',
		230103068: '哈尔滨市第九医院',
		230103069: '哈尔滨铁路局结核病医院',
		230104088: '哈尔滨市红十字创伤医院',
		230104089: '哈尔滨第一机器制造厂职工医院',
		230104090: '哈尔滨市红十字中心血站',
		230104091: '哈尔滨铁路分局哈尔滨医院',
		230104092: '哈尔滨市社会福利院伤残康复医院',
		230104093: '哈尔滨市太平区中西医结合医院',
		230104094: '哈尔滨市太平区人民医院',
		230104095: '哈尔滨市结核病医院',
		230104096: '哈尔滨市胸科医院',
		230104097: '哈尔滨市第二工人医院',
		230104098: '哈尔滨铁路医院',
		230104099: '哈尔滨水泥厂职工医院',
		230104100: '哈尔滨市心理卫生中心',
		230104138: '哈尔滨市肉类联合加工厂职工医院',
		230104139: '黑龙江省松滨监狱医院',
		230104140: '哈尔滨铁路分局北安医院',
		230104141: '哈尔滨市道外区人民医院',
		230104142: '哈尔滨市道外区中医院',
		230104143: '哈尔滨市卫协第二医院',
		230104144: '哈尔滨东方肿瘤病研究所',
		230104145: '哈尔滨正一泰骨病中医院',
		230104146: '哈尔滨市第二中医医院',
		230104147: '哈尔滨市道外区中医医院',
		230104148: '哈尔滨云辉不孕症防治研究所',
		230104149: '哈尔滨老医协骨病研究所',
		230104150: '哈尔滨市疾病预防控制中心',
		230104151: '哈尔滨市林机医院',
		230104152: '哈尔滨汽轮机厂职工医院',
		230104153: '哈尔滨市第一工具厂职工医院',
		230104154: '哈尔滨市社会保险局医院',
		230104155: '哈尔滨市普宁医院',
		230104156: '哈尔滨道外区神经专科医院',
		230104157: '哈尔滨市道外区东莱医院',
		230104158: '哈尔滨市老年医院',
		230104159: '哈尔滨市第七医院',
		230104160: '哈尔滨市北方中医肿瘤研究所',
		230104161: '哈尔滨市太平区民主医院',
		230104162: '哈尔滨市道外区纯化医院',
		230104163: '哈尔滨市太平区团结医院',
		230104164: '哈尔滨市道外区中西医结合医院',
		230104165: '哈尔滨市第八医院',
		230104166: '哈尔滨市第七人民医院',
		230104167: '哈尔滨市粮食局职工医院',
		230104168: '哈尔滨市道外区牙病防治院',
		230104169: '哈尔滨道外区中医骨科医院',
		230104170: '哈尔滨市道外区太古医院',
		230104171: '哈尔滨市中西医结合医院',
		230104172: '哈尔滨市第一专科医院',
		230104173: '哈尔滨市第二医院',
		230104174: '哈尔滨市第四医院',
		230104175: '哈尔滨市道外区妇幼保健所',
		230108116: '东北轻合金有限责任公司职工医院',
		230108117: '哈尔滨市平房区平房镇医院',
		230108118: '哈尔滨市平房区人民医院',
		230108119: '哈尔滨市第三医院',
		230108120: '哈尔滨市平房区平新医院',
		230108121: '哈尔滨第二四二医院',
		230110216: '哈尔滨市香坊区肛肠医院',
		230110217: '黑龙江远东心脑血管医院',
		// 230110218: '哈尔滨市TDP研究会慢性病康复...',
		230110219: '哈尔滨工业大学医院',
		230110220: '哈尔滨市香坊区口腔病防治院',
		230110221: '哈尔滨市第一职工医院',
		230110222: '哈尔滨铁路分局第二职工医院',
		230110223: '哈尔滨市动力区朝阳医院',
		230110224: '哈尔滨市动力区黎明医院',
		230110225: '哈尔滨市香坊区产院',
		230110226: '哈尔滨市香坊区红十字妇产医院',
		230110227: '哈尔滨轴承厂职工医院',
		230110228: '哈尔滨市第六医院',
		230110229: '哈尔滨市动力区第二人民医院',
		230110230: '哈尔滨市动力区护理医院',
		230110231: '动力区第二人民医院',
		230110232: '黑龙江省中医院',
		// 230110233: '黑龙江省林业总医院黑龙江省红十...',
		230110234: '哈尔滨市传染病医院',
		230110235: '哈尔滨市第五医院',
		230110236: '黑龙江中医药大学附属第一医院',
		230110237: '黑龙江省公安安康医院',
		230110238: '哈尔滨市动力区中医院',
		230110239: '黑龙江国营建成机械厂职工医院',
		230110240: '哈尔滨传染病院',
		230110241: '国营哈尔滨亚麻纺织厂职工医院',
		230110242: '哈尔滨建成集团有限公司医院',
		230110243: '黑龙江省电力医院',
		230110244: '黑龙江省公安厅安康医院',
		230111077: '呼兰区第一人民医院',
		230111078: '呼兰区第二人民医院',
		230111079: '呼兰县第二中医院',
		230111080: '呼兰县第二人民医院',
		230111081: '哈尔滨市呼兰区中医院',
		230111082: '呼兰县妇幼保健院',
		230111083: '呼兰县第二中医院',
		230111084: '呼兰县第二人民医院',
		230111085: '呼兰县中医院',
		230111086: '呼兰县第一人民医院',
		230111087: '黑龙江省第四医院',
		230112210: '阿城市骨伤医院',
		230112211: '阿城市妇幼保健院',
		230112212: '阿城市中医院',
		230112213: '阿城市人民医院',
		230112214: '黑龙江涤纶厂职工医院',
		230112215: '阿城市儿童医院',
		230113070: '双城市中医院',
		230113071: '双城市骨伤科医院',
		230113072: '双城市人民医院',
		230113073: '双城市保险医院',
		230113074: '双城市同仁医院',
		230113075: '双城市广济医院',
		230113076: '双城市结核病防治所',
		230123006: '依兰县妇幼保健院',
		230123007: '依兰县中医院',
		230123008: '依兰县人民医院',
		230124123: '方正县妇幼保健院',
		230124124: '方正县精神病院',
		230124125: '方正县林业局高楞职工医院',
		230124126: '方正县中医院',
		230124127: '方正县人民医院',
		230125101: '宾县宾安中心医院',
		230125102: '宾县口腔病防治所',
		230125103: '宾县妇幼保健院',
		230125104: '宾县人民医院',
		230126110: '巴彦县妇幼保健站',
		230126111: '巴彦县第二人民医院',
		230126112: '巴彦中医院',
		230126113: '巴彦县乌鸦泡林业局医院',
		230126114: '巴彦淖尔盟蒙医医院',
		230126115: '巴彦县人民医院',
		230127128: '木兰县红十字医院',
		230127129: '木兰县第二人民医院',
		230127130: '木兰县人民医院',
		230127131: '木兰县精神病院',
		230127132: '木兰县妇幼保健院',
		230127133: '木兰县中医院',
		230128134: '通河县清河林业局职工医院',
		230128135: '通河县肛肠专科医院',
		230128136: '通河县中医院',
		230128137: '通河县人民医院',
		230129122: '延寿县妇幼保健站',
		230183105: '黑龙江省苇河林业局职工医院',
		230183106: '尚志市妇幼保健院',
		230183107: '尚志市人民医院',
		230183108: '尚志市妇幼保健站',
		230183109: '尚志市中医院',
		230184000: '五常市妇幼保健院',
		230184001: '五常市人民医院',
		230184002: '五常市第二人民医院',
		230184003: '五常市口腔医院',
		230184004: '五常市中医院',
		230184005: '五常市中医医院',
		230202335: '齐齐哈尔市龙沙区医院',
		230202336: '齐齐哈尔第二机床厂职工医院',
		230202337: '齐齐哈尔第一医院',
		230202338: '齐齐哈尔市建筑职工医院',
		230202339: '齐齐哈尔市第二机床厂职工医院',
		230202340: '齐齐哈尔造纸厂职工医院',
		230202341: '齐齐哈尔联谊防水材料厂职工医院',
		230202342: '齐齐哈尔市第一机床厂职工医院',
		230202343: '齐齐哈尔第一机床厂职工医院',
		230202344: '齐齐哈尔市传染病防治院',
		// 230203267: '齐齐哈尔市第一神经精神病院(齐...',
		230203268: '齐齐哈尔市第四医院',
		230203269: '协育友好医院',
		230203270: '齐齐哈尔市口腔医院',
		230203271: '齐齐哈尔市五官医院',
		230203272: '中国人民解放军第二○三医院',
		230203273: '齐齐哈尔市第一神经精神病医院',
		230203274: '齐齐哈尔公安医院',
		230203275: '齐齐哈尔肛肠医院',
		230203276: '齐齐哈尔市建华厂医院',
		230203277: '齐齐哈尔解放军203医院',
		230203278: '齐齐哈尔第二医院',
		230203279: '齐齐哈尔玛丽亚妇产医院',
		230203280: '齐齐哈尔玛丽亚妇产医院',
		230203281: '齐齐哈尔市建华医院',
		230203282: '齐齐哈尔建华厂医院',
		230203283: '齐齐哈尔市工交医院',
		230203284: '齐齐哈尔铁路专科医院',
		230203285: '齐齐哈尔市第二医院',
		230203286: '齐齐哈尔民康医院',
		230204310: '哈尔滨铁路局齐齐哈尔专科医院',
		230204311: '齐齐哈尔市中医医院',
		230204312: '齐齐哈尔医学院附属第三医院',
		230204313: '齐齐哈尔市肿瘤医院',
		230204314: '齐齐哈尔市铁锋区医院',
		230204315: '齐齐哈尔市结核病防治院',
		230204316: '齐齐哈尔市第六医院',
		230204317: '齐齐哈尔市结核病院',
		230204318: '齐齐哈尔和平医院',
		230204319: '齐齐哈尔铁路车辆集团医院',
		230204320: '齐齐哈尔医学院第三附属医院',
		230204321: '齐齐哈尔市商业职工医院',
		230204322: '齐齐哈尔市铁锋区中医院',
		230204323: '齐齐哈尔胃肠病医院',
		230204324: '齐齐哈尔中医院',
		230204325: '齐齐哈尔建筑防水材料厂职工医院',
		230204326: '齐齐哈尔铁路分局中心医院',
		230206255: '第一重型机器制造厂职工医院',
		230206256: '齐齐哈尔医学院第一附属医院',
		// 230206257: '北满特殊钢集团有限责任公司职工...',
		230206258: '齐齐哈尔一重医院',
		230207305: '齐齐哈尔碾子山区人民医院',
		230207306: '齐齐哈尔华安医院',
		// 230208292: '齐齐哈尔市梅里斯达斡尔族区中医...',
		230208293: '梅里斯达区人民医院',
		230221327: '杜蒙县中蒙医医院',
		230221328: '龙江和济医院',
		230221329: '龙江电厂职工医院',
		230221330: '龙江县第二人民医院',
		230221331: '龙江县妇幼保健站',
		230221332: '龙江县中医院',
		230221333: '龙江县第一医院',
		230221334: '黑龙江省六三监狱医院',
		230223245: '依安县中医院',
		230223246: '依安县人民医院',
		230224294: '黑龙江泰来县中医院',
		230224295: '泰来汽车厂医院',
		230224296: '泰来县新生医院',
		230224297: '泰来县妇幼保健院',
		230224298: '泰来县人民医院',
		230225299: '甘南县北京市双河农场医院',
		230225300: '甘南县商业职工医院',
		230225301: '甘南县妇幼保健院',
		230225302: '甘南县中医院',
		230225303: '甘南州人民医院',
		230225304: '甘南县人民医院',
		// 230227259: '齐齐哈尔市第六医院齐齐哈尔市第...',
		230227260: '齐齐哈尔市第一医院分院',
		230227261: '富裕县二道湾精神病院',
		230227262: '富裕县妇幼保健院',
		230227263: '富裕县富裕牧场医院',
		230227264: '富裕县富路医院',
		230227265: '富裕县中医院',
		230227266: '富裕县人民医院',
		230229250: '克山县妇幼保健站',
		230229251: '克山县中西医结合医院',
		230229252: '克山县中医院',
		230229253: '克山县人民医院',
		230229254: '齐齐哈尔糖厂职工医院',
		230230247: '克东县妇幼保健院',
		230230248: '克东县中医院',
		230230249: '克东县人民医院',
		230231287: '拜泉县传染病院',
		230231288: '拜泉县妇幼保健站',
		230231289: '拜泉县牙病防治所',
		230231290: '拜泉县中医院',
		230231291: '拜泉县人民医院',
		230281307: '讷河市妇幼保健院',
		230281308: '讷河市中医院',
		230281309: '讷河市第一人民医院'
	}
};